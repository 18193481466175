import { createSelector } from "reselect";
import * as fromReducer from '../reducers/sent-requests.reducer';

const selectState = state => state.sentRequestsState;

export const selectSentRequestsState = createSelector(selectState, fromReducer.selectState);
export const selectSentRequests = createSelector(
    selectState,
    fromReducer.selectAll
);

export const selectSentRequestsEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);