import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInfoCircle, AiFillPlayCircle, AiOutlineClose } from 'react-icons/ai';
import { IconProvider } from '.';
import { FONT, THEME } from '../enums';
import { currentWindowWidth } from '../variables';
import CustomText from "./custom-text";


/* ----------------- Video Input --------------------- */

interface VideoInputProps {
    label?: string,
    placeholder?: string,
    customStyle?: any,
    video: any,
    onClick: (doc: File) => void,
    onRemove: () => void,
    onVideo: (uri: string) => void,
    onInfo?: ()=> void,
}

export function VideoInput(props: VideoInputProps) {
    const { label, placeholder, customStyle, video, onClick, onRemove, onVideo, onInfo } = props;
    const inputFile = useRef<HTMLInputElement>(null);

    return (
        <div style={{ ...styles.container, ...customStyle }}>
            <input ref={inputFile} id={"video-file-input"} type={'file'} accept={"video/*"} style={{ display: 'none' }} onChange={onChange} />
            {label ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CustomText customStyle={styles.lableHeader}>{label}</CustomText>
                <AiFillInfoCircle size={20} color={"gray"} onClick={onInfo} />
            </div> : null}
            <div style={styles.imgInputContainer}>
                <VideoPreview
                    video={video}
                    placeholder={placeholder}
                    onAddMore={onAddMore}
                    onVideo={onVideo}
                    onRemove={onFileRemove}
                />
            </div>
        </div >
    );

    function onFileRemove() {
        const element = document.getElementById("video-file-input") as HTMLInputElement;
        if(element) {
            element.value = '';
            element.files = null;
        }
        onRemove();
    }


    function onChange(e) {
        if (e.target.files[0]) onClick(e.target.files[0]);
    }

    function onAddMore() {
        if (inputFile.current) inputFile?.current.click();
    }
}

interface VideoPreviewProps {
    video: any,
    title?: string,
    placeholder?: string,
    onRemove: () => void,
    onAddMore: () => void,
    onVideo: (uri: string) => void,
}
const thumbSize = (100 / 2) - 5;
function VideoPreview(props: VideoPreviewProps) {
    const { video, placeholder, title, onRemove, onAddMore, onVideo } = props;
    const { t, i18n } = useTranslation();
    const videoRef = useRef<any>(null);
    const onLoad = (event: Event) => {
        // if (CURRENT_OS === DEVICE_TYPE.ANDROID) videoRef.current.seek(0);
    }
    if (video) {
        return (
            <div style={styles.imgInstructions}>
                <div
                    style={{
                        ...{
                            position: 'relative',
                            width: `${thumbSize}%`,
                            maxHeight: `${thumbSize - 5}`,
                            marginRight: 20
                        }
                    }}
                    onClick={() => onVideo(video.uri)}
                >
                    <video
                        ref={videoRef}
                        src={URL.createObjectURL(video)}   // Can be a URL or a local file. { uri: state.videoUrl }
                        style={{ width: '100%', height: '100%', boxSizing: 'border-box' }}
                        controls={false}
                    />
                    <div style={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }}>
                        <AiFillPlayCircle size={25} color={'#fff'} />
                    </div>
                    <div
                        style={{
                            ...{ width: 20, height: 20, borderRadius: 10, position: 'absolute', top: -5, right: -5, cursor: 'pointer', backgroundColor: 'red' },
                            // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : 'red' }
                        }}
                        onClick={() => onRemove()}
                    >
                        <AiOutlineClose size={20} color={'#fff'} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={styles.imgInstructions}>
            <div style={{ width: 32, height: 40 }}>
                <IconProvider type={"video"} />
            </div>
            <CustomText customStyle={styles.imgInputPlaceholder}>{placeholder}</CustomText>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
                <CustomText customStyle={{ marginRight: 5 }}>{'or'}</CustomText>
                <div
                    style={{}}
                    onClick={onAddMore}
                >
                    <CustomText
                        className={"active-text"}
                        style={{
                            ...styles.brwTxt,
                            // ...{
                            //     color: pressed ? 'rgba(230,230,230,0.2)' : '#359BE0'
                            // }
                        }}
                    >{title ? title : t("browseYourVideoGallery")}</CustomText>

                </div>
            </div>
        </div >
    )
}



const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: 13,
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        marginBottom: 10,
        marginTop: 10,
        marginRight: 5,
        color: THEME.BLACK
    },


    //selector styles
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        boxSizing: 'border-box'
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 10,
        boxSizing: 'border-box'
    },


    //Image input

    imgInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        borderRadius: 10,
        padding: 10,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'dashed',
        boxSizing: 'border-box'
    },
    imgInstructions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box'
    },

    imgInputPlaceholder: {
        fontSize: 16, color: '#B2B2B2'
    },
    brwTxt: {
        textDecorationLine: 'underline'
    },

});