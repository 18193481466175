import React, { Component } from 'react';
import { Country, Draft } from '../interfaces';
import restService from './rest.service';
import * as SurveyService from './surveys.service';

export const getDrafts = (doc: {page: number, limit: number}) => {
    return restService.getDrafts(doc).then(response => {
        return {
            ...doc,
            countAll: parseInt(response.headers["x-total-count"]),
            result: response.data
        };
    });
}

export const postDraft = (doc: any): Promise<any> => {
    return restService.postDraft(doc).then(response => response.data);
}

export const updateDraft = (doc: any): Promise<any> => {
    return restService.updateDraft(doc).then(response => response.data);
}

export const deleteDraft = (id: string): Promise<any> => {
    return restService.deleteQuestion(id).then(response => response.data);
}

export const updateDraftAndPostQuestion = (doc) => {
    return updateDraft(doc).then(res => SurveyService.updateQuestionWithPatch(res));
}