import * as fromActions from '../actions/answers.actions';
import { AppError, SurveyCategory, SurveyAnswer } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<SurveyAnswer> {
    loading: boolean,
    selectedAnswer: SurveyAnswer | null,
    error?: AppError | null,
    selectedError?: AppError | null,
}

const answersAdapter: EntityAdapter<SurveyAnswer> = createEntityAdapter<SurveyAnswer>({
    selectId: (doc) => doc.id
})

const initialState: State = answersAdapter.getInitialState({
    loading: false,
    selectedAnswer: null
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getAnswers, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getAnswersSuccess, (state, action) => answersAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.getAnswersError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getMyAnswer, (state, action) => ({ ...state, loading: true, selectedError: null }))
        .addCase(fromActions.getMyAnswerSuccess, (state, action) => ({ ...state, loading: false, selectedAnswer: action.payload }))
        .addCase(fromActions.getMyAnswerError, (state, action) => ({ ...state, selectedError: action.payload, loading: false }))

        .addCase(fromActions.postAnswer, (state, action) => ({ ...state, loading: true, selectedError: null }))
        .addCase(fromActions.postAnswerSuccess, (state, action) => ({ ...state, loading: false, selectedAnswer: action.payload }))
        .addCase(fromActions.postAnswerError, (state, action) => ({ ...state, selectedError: action.payload, loading: false }))

        .addCase(fromActions.updateAnswer, (state, action) => ({ ...state, loading: true, selectedError: null }))
        .addCase(fromActions.updateAnswerSuccess, (state, action) => ({ ...state, loading: false, selectedAnswer: action.payload }))
        .addCase(fromActions.updateAnswerError, (state, action) => ({ ...state, selectedError: action.payload, loading: false }))

        .addCase(fromActions.removeAnswers, (state, action) => answersAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.removeSelectedAnswer, (state, action) => ({ ...state, selectedError: null, selectedAnswer: null }))
        .addCase(fromActions.clearAnswersError, (state, action) => ({ ...state, error: null }))
        .addCase(fromActions.clearSelectedAnswerError, (state, action) => ({ ...state, selectedError: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = answersAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;