import React, { PureComponent } from 'react';
// import { ActivityIndicator, StyleSheet, Pressable } from 'react-native';
import { FONT, THEME } from '../enums';
import CustomText from '../utils/custom-text';
import { CircularProgress } from '@material-ui/core';

interface Props {
  size?: any,
  color?: any,
  status?: any,
  backgroundColor?: any,
  customStyle?: any
}

export function SpinnerScreen(props: Props) {
  const { size, color, status, backgroundColor, customStyle } = props;
  return (
    <div
      onClick={() => {/* do nothing */ }}
      style={{
        ...styles.loadScreen,
        backgroundColor: backgroundColor ? backgroundColor : THEME.WHITE,
        ...customStyle
      }}
    >
      <CircularProgress style={{ color: color ? color : THEME.ORANGE_RED }} size={size ? size : 35} />
      {
        status ? <CustomText
          style={{
            fontFamily: FONT.MANROPE_700,
            marginTop: 10,
            color: THEME.ORANGE_RED
          }}
        >
          {status}
        </CustomText>
          : null
      }
    </div >
  )
}

const styles: { [key: string]: React.CSSProperties } = ({
  loadScreen: {
    // ...StyleSheet.absoluteFillObject,
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: '100%',
    // paddingBottom: 120
  }
});

