import { createSelector } from "reselect";
import { getUserName } from "../../services";
import { CONTACT_TYPE } from "../../enums";
import * as fromReducer from '../reducers/contacts.reducer';

const selectState = state => state.contactsState;

export const selectContactsState = createSelector(selectState, state => state);
export const selectContacts = createSelector(
    selectState,
    fromReducer.selectAll
);


export const selectContactsWithType = createSelector(
    selectContacts,
    contacts => contacts.map(doc => ({ 
        ...doc, 
        name: getUserName(doc.recipientUser),
        type: CONTACT_TYPE.CONTACT 
    }))
);