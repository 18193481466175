import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { GroupsViewList, ScreenHolder } from "../components";
import { THEME } from "../enums";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, } from "../variables";
import * as fromStore from "../store";
import { GroupInfo } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = '92%';

export function GroupsScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchGroups();

        return () => {

        }
    }, []);

    function fetchGroups() {
        dispatch(fromStore.getGroups());

    }



    function onGroupClick(item: GroupInfo) {
        history.push({
            pathname: `${MAIN_APP_ROUTES.EDIT_GROUP}/${item.id}`,
            state: { id: item.id }
        });
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('groups')}</CustomText>
                </div>
                <div style={{
                    width: '100%',
                    height: contactsWindowHeight,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    boxSizing: 'border-box',
                    overflow: 'hidden'
                }}>
                    <GroupsViewList
                        contactsWindowHeight={contactsWindowHeight}
                        onClick={onGroupClick}
                        onRefresh={fetchGroups}
                    />
                </div>
            </div>

        </ScreenHolder>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'hidden'
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20
    },
})