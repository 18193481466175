
import * as fromActions from '../actions/user.actions';
import { User, AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    idToken?: string | null,
    user?: User | null,
    loading: boolean,
    userExist: boolean,
    error?: AppError | null,
}

const initialState: State = {
    idToken: null,
    loading: false,
    userExist: true
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.checkUserExist, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.userExist, (state, action) => ({ ...state, loading: true, userExist: true, idToken: action.payload }))
        .addCase(fromActions.newUser, (state, action) => ({ ...state, country: action.payload, loading: false, userExist: false }))

        .addCase(fromActions.missingUserInfo, (state, action) => ({ ...state }))

        .addCase(fromActions.getUser, (state, action) => ({ ...state, loading: true }))
        .addCase(fromActions.getUserSuccess, (state, action) => ({ ...state, user: action.payload, loading: false, userExist: true }))
        .addCase(fromActions.getUserError, (state, action) => ({ ...state, error: action.payload, loading: false, userExist: false }))

        .addCase(fromActions.addUser, (state, action) => ({ ...state, user: action.payload }))
        // .addCase(fromActions.addUserSuccess, (state, action) => ({ ...state, user: action.payload }))
        // .addCase(fromActions.addUserError, (state, action) => ({ ...state, error: action.payload }))

        .addCase(fromActions.removeUser, (state, action) => ({ ...state }))
        .addCase(fromActions.removeUserSuccess, (state, action) => ({ ...state, user: null, country: null, userExist: false, idToken: null }))
        .addCase(fromActions.removeUserError, (state, action) => ({ ...state, user: null, country: null, error: action.payload, userExist: false, idToken: null }))

        .addCase(fromActions.saveUserIdToken, (state, action) => ({ ...state }))
        .addCase(fromActions.saveUserIdTokenSuccess, (state, action) => ({ ...state }))
        .addCase(fromActions.saveUserIdTokenError, (state, action) => ({ ...state, error: action.payload }))

        

        .addCase(fromActions.clearUserError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
export const selectUser = (state: State) => state.user;
