import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';

const getSurveyEpic = (action$, state$): Observable<Action> => action$.pipe(
    ofType(fromActions.getSurvey),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveysService.getSurveyById(payload)
            .then(response => fromActions.getSurveySuccess(response))
            .catch(error => fromActions.getSurveyError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

const getInvitedSurveyEpic = (action$, state$) => action$.pipe(
    ofType(fromActions.getInvitedSurvey),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveysService.getInvitedSurveyById(payload)
            .then(response => fromActions.getInvitedSurveySuccess(response))
            .catch(error => fromActions.getInvitedSurveyError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

export const surveyEpics = combineEpics(
    getSurveyEpic,
    getInvitedSurveyEpic
);