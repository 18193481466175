import React, { Component } from 'react';
import restService from './rest.service';
import AppStorage from './storage.service';
import { CURRENT_OS } from '../variables';
import { LOGIN_TYPE, DEVICE_TYPE } from '../enums';
import { ContactUsForm, FacebookLoginForm, GoogleLoginForm, ProfileSettingsForm, ResetPasswordForm } from '../interfaces';
import { getImageFormData } from './helpers.services';


/*------------- check user standard --------------------*/

export const checkStandard = (standards, code) => {
    return new Promise((resolve, reject) => {
        const marked = standards.filter(doc => doc.code === code);
        if (marked && marked.length > 0) resolve(null);
        else reject({ message: "No matching standard found" });
    })
}

/*------------- Set & get user state --------------------*/

export const setUserState = (id) => {
    return AppStorage.setIdToken(JSON.stringify(id));
}


/*------------- user request --------------------*/

export const getUserDetails = () => {
    return restService.getUserDetails().then(response => response.data);
}

export const updateUserProfile = (id, doc) => {
    return restService.updateUserProfile(id, doc).then(response => response.data);
}

export const updateProfileSettings = (doc: ProfileSettingsForm) => {
    return restService.updateProfileSettings(doc).then(response => response.data);
}

export const resetPassword = (email: string) => {
    return restService.initiateResetPassword(email).then(response => response.data);
}

export const changeUserPassword = (doc: ResetPasswordForm) => {
    return restService.finishResetPassword(doc).then(response => response.data);
}

export const activateAccount = (key: string) => {
    return restService.activateAccount(key).then(response => response.data);
}


export const uploadUserProfilePicture = (doc) => {
    return restService.uploadProfilePicture(getImageFormData(doc)).then(response => response.data);
}




/*-------------email signup/login requests --------------------*/

export const emailSignupRequest = (user) => {
    return restService.signUpRequest(user).then(response => response.data);
}

export const emailLoginRequest = (user) => {
    return restService.loginRequest(user)
        .then(response => {
            return {
                ...response.data,
                id_token: response.data.id_token
            }
        })
}

//sslogin requests

export const socialMediaLoginRequest = (doc) => {
    return restService.ssoLogin(doc)
        .then(response => {
            // console.log(response.data);
            return {
                ...response.data,
                id_token: response.data.id_token
            }
        })
}

//google login request

export const googleLoginRequest = (res: GoogleLoginForm) => {
    // console.log(res);
    return socialMediaLoginRequest(res);

}

export const checkGoogleStatusCodes = (error) => {
    if (error.code === "") {
        // user cancelled the login flow
        return null;
    } else {
        // some other error happened
        logoutGoogleSignin();
        return error;
    }
    // else if (error.code === statusCodes.IN_PROGRESS) {
    //     // operation (e.g. sign in) is in progress already
    // } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
    //     // play services not available or outdated
    // } 

}

//facebook login request

export const facebookLoginRequest = (res: FacebookLoginForm) => {
    // console.log(res);
    return socialMediaLoginRequest(res)
}


// export const facebookUserDataRequest = () => {
//     return AccessToken.getCurrentAccessToken()
//         .then(data => {
//             if (data != null) return graphRequest(data.accessToken)
//                 .then(result => {
//                     // console.log(result);
//                     console.log({
//                         signinType: LOGIN_TYPE.FACEBOOK,
//                         facebookSigninRequest: {
//                             tokenData: data,
//                             userDetails: result
//                         }
//                     });
//                     return socialMediaLoginRequest({
//                         signinType: LOGIN_TYPE.FACEBOOK,
//                         facebookSigninRequest: {
//                             tokenData: data,
//                             userDetails: result
//                         }
//                     })
//                 })
//             return new Error('unable to get access token');
//         })

// }



// export const graphRequest = (accessToken) => {
//     return new Promise((resolve, reject) => {
//         const req = new GraphRequest('/me?fields=first_name,last_name,email,picture&access_token=' + accessToken, null,
//             (error, result) => {
//                 if (error) {
//                     reject(error);
//                 } else {
//                     resolve(result);
//                 }
//             }
//         );
//         new GraphRequestManager().addRequest(req).start();
//     })
// }

//apple login requests

// export const appleLoginRequest = () => {
//     return appleAuth.performRequest({
//         requestedOperation: appleAuth.Operation.LOGIN,
//         requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
//     })
//         .then(appleAuthRequestResponse => {
//             return socialMediaLoginRequest({
//                 signinType: LOGIN_TYPE.APPLE, appleSigninRequest: {
//                     authorizationCode: appleAuthRequestResponse.authorizationCode,
//                     email: appleAuthRequestResponse.email,
//                     familyName: appleAuthRequestResponse.fullName.familyName,
//                     givenName: appleAuthRequestResponse.fullName.givenName,
//                     identityToken: appleAuthRequestResponse.identityToken,
//                     nonce: appleAuthRequestResponse.nonce,
//                     realUserStatus: appleAuthRequestResponse.realUserStatus,
//                     user: appleAuthRequestResponse.user
//                 }
//             })
//         })
// }

export const checkAppleStatusCodes = (error) => {
    if (error.code === "1001") return null;
    if (error.code === "1000") return null;
    //logoutAppleSignin();
    return error;

}

export const checkUserCancelErrors = (error) => {
    if (error.code === "1001") return null;
    // if (error.code === statusCodes.SIGN_IN_CANCELLED) return null;
    return error;
}


//logout requests
export const logoutRequest = (loginType) => {
    switch (loginType) {
        case LOGIN_TYPE.FACEBOOK: return handleFbLogout();
        case LOGIN_TYPE.GOOGLE: return handleGoogleLogout();
        case LOGIN_TYPE.APPLE: return handleAppleLogout();
        default: return handleEmailLogout();
    }
}


export const handleEmailLogout = () => {
    return dummyLogout();
    // return restService.logout();
}

export const handleFbLogout = () => {
    // LoginManager.logOut();
    return dummyLogout()
    // return restService.logout();
}

export const handleGoogleLogout = () => {
    return dummyLogout()
    // return GoogleSignin.revokeAccess()
    //     .then(() => GoogleSignin.signOut())
    //     // .then(() => restService.logout())
    //     .then(() => dummyLogout())
}

export const handleAppleLogout = () => {
    return dummyLogout()
    // return appleAuth.performRequest({
    //     requestedOperation: appleAuth.Operation.LOGOUT,
    // }).then(appleAuthRequestResponse => {
    //     return restService.logout()
    // })
}

export const logoutGoogleSignin = () => {
    return dummyLogout()
    // return GoogleSignin.revokeAccess().then(() => GoogleSignin.signOut());
}

export const logoutAppleSignin = () => {
    return dummyLogout()
    // return appleAuth.performRequest({
    //     requestedOperation: appleAuth.Operation.LOGOUT,
    // })
}

export const logoutFacebookSignin = () => {
    return dummyLogout()
    // return LoginManager.logOut();
}

export const forgotPassword = (user) => {
    return restService.forgotPassword(user).then(response => response.data);
}

//logout without api

export const logoutWithoutApi = () => {
    return AppStorage.removeIdToken();
}

export const dummyLogout = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => { resolve('success') }, 1000)
    })
}


export const contactUs = (doc: ContactUsForm) =>{
    return restService.contactUs(doc).then(response => response.data);
}