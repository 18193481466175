import { createSelector } from "reselect";
import * as fromReducer from '../reducers/draft.reducer';

const selectState = state => state.draftState;

export const selectDraftState = createSelector(selectState, fromReducer.selectState);

export const selectDraft= createSelector(
    selectState,
    fromReducer.selectDraft
);