import * as fromActions from '../actions/countries.actions';
import { AppError, Country } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<Country>{
    loading: boolean,
    error?: AppError | null,
}

const countryAdapter: EntityAdapter<Country> = createEntityAdapter<Country>({
    selectId: (doc) => doc.code
})

const initialState: State = countryAdapter.getInitialState({
    loading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getCountries, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getCountriesSuccess, (state, action) => countryAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.getCountriesError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.removeCountries, (state, action) => countryAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearCountriesError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = countryAdapter.getSelectors();
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;