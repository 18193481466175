import React, { Component } from 'react';
import { Country } from '../interfaces';
import restService from './rest.service';

// const countriesJson = require('../../assets/countries/countries.json');
export const getCountries = (): Promise<Country[]> => {
    return restService.getCountries().then(response => {
        return Object.keys(response.data).map((key) => ({ ...response.data[key], code: key }));
    });
    // return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //         const list = Object.keys(countriesJson).map((key) => ({...countriesJson[key], Code: key }))
    //         resolve(list);
    //     }, 1000) 
    // })
}