export enum NOTIFICATION_INTENT {
    DASHBOARD = "DASHBOARD",
    TCT = "TCT",
    SMS = "SMS",
    VOC = "VOC",
    ACT = "ACT",
    PEG = "PEG",
    SEL = "SEL",
    ELL = "ELL",
    BLOG = "BLOG",
    BOOKMARKS = "BOOKMARKS",
    PROFILE = "PROFILE",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    STANDARD_SELECTION = "STANDARD_SELECTION",
    SUBSCRIPTION = "SUBSCRIPTION",
    ABOUT = "ABOUT",
    HELP = "HELP",
}