import { createSelector } from "reselect";
import * as fromReducer from '../reducers/countries.reducer';

const selectState = state => state.countriesState;

export const selectCountriesState = createSelector(selectState, state => state);
export const selectCountries = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectCountriesEntities = createSelector(selectState, fromReducer.selectEntities);
