import { createAction } from '@reduxjs/toolkit';
import { AppError, ContactInfo } from '../../interfaces';

export const toggleUserMenu = createAction<boolean>('@app/toggle-user-menu');
export const removeAppState = createAction('@app/remove/state');

export const toastMessage = createAction<string | null >('@app/toast');
export const clearToastMessage = createAction('@app/clear/toast');

export const setAppScreenResolution = createAction<{width: number, height: number}>('@app/screen-resolution')

export const disgardAction = createAction('@app/disgard-action');