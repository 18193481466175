// CustomText.js    
import React, { PureComponent, useEffect, useState } from 'react';
// import {
//     Text,
//     StyleSheet,
//     Dimensions,
// } from 'react-native';
import { FONT_SIZE_PORTRAIT, FONT_SIZE_LANDSCAPE, currentOrientation, moderateScale } from '../variables';
import { ORIENTATION, FONT } from '../enums'

function getDefaultStyles(item): React.CSSProperties {
    if (!item) return styles.defaultStyle;
    if (currentOrientation() === ORIENTATION.PORTRAIT) return {
        ...styles.defaultStyle,
        ...{
            ...item,
            fontSize: item.fontSize ? moderateScale(item.fontSize) : moderateScale(FONT_SIZE_PORTRAIT),
        },
    };
    return {
        ...styles.defaultStyle,
        ...{
            ...item,
            fontSize: item.fontSize ? moderateScale(item.fontSize - 2) : moderateScale(FONT_SIZE_LANDSCAPE),
        },
    };
}

export default function CustomText(props) {
    // const [styleArray, setStyleArray] = useState<React.CSSProperties>(props.customStyle ? props.customStyle : getDefaultStyles(props.customStyle));
    const [orientation, setOrientation] = useState(currentOrientation());

    // useEffect(() => {
        // Dimensions.addEventListener('change', orientationChange);
        // if (!props.customStyle) return;
        // let arr = Array.isArray(props.customStyle);
        // if (arr) return calculateFontSizeForArray(orientation);
        // calculateFontSizeForObj(orientation);
        // return () => {
            // Dimensions.removeEventListener('change', orientationChange);
        // }
    // }, [orientation, props.customStyle]);

    // function calculateFontSizeForArray(orientation) {
    //     let doc = props.customStyle.find(doc => doc && doc.fontSize && doc.fontSize !== null);
    //     if (orientation === ORIENTATION.PORTRAIT) {
    //         setStyleArray({
    //             ...styles.defaultStyle,
    //             ...props.customStyle,
    //             ...{ fontSize: doc ? moderateScale(doc.fontSize) : moderateScale(FONT_SIZE_PORTRAIT) },
    //             // { fontWeight: doc && doc.fontWeight === "bold" ? "bold" : "normal" }
    //         });
    //     }
    //     else {
    //         setStyleArray({
    //             ...styles.defaultStyle,
    //             ...props.customStyle,
    //             ...{ fontSize: doc ? moderateScale(doc.fontSize - 2) : moderateScale(FONT_SIZE_LANDSCAPE) },
    //             // { fontWeight: doc && doc.fontWeight === "bold" ? "bold" : "normal" }
    //         });
    //     }
    // }

    // function calculateFontSizeForObj(orientation) {
    //     if (orientation === ORIENTATION.PORTRAIT) {
    //         setStyleArray({
    //             ...styles.defaultStyle,
    //             ...props.customStyle,
    //             ...{ fontSize: props.customStyle && props.customStyle.fontSize ? moderateScale(props.customStyle.fontSize) : moderateScale(FONT_SIZE_PORTRAIT) },
    //             // { fontWeight: props.customStyle && props.customStyle.fontWeight === "bold" ? "bold" : "normal" }
    //         });
    //     }
    //     else {
    //         setStyleArray({
    //             ...styles.defaultStyle,
    //             ...props.customStyle,
    //             ...{ fontSize: props.customStyle && props.customStyle.fontSize ? moderateScale(props.customStyle.fontSize - 2) : moderateScale(FONT_SIZE_LANDSCAPE) },
    //             // { fontWeight: props.customStyle && props.customStyle.fontWeight === "bold" ? "bold" : "normal" }
    //         });
    //     }
    // }

    // function orientationChange() {
    //     setOrientation(currentOrientation())
    // }

    return (
        <span
            // numberOfLines={props.numberOfLines}
            // ellipsizeMode={props.ellipsizeMode}
            className={props.className}
            style={{
                ...styles.defaultStyle,
                ...props.customStyle
            }}
        >
            {props.children}
        </span>
    );

}

const styles: { [key: string]: React.CSSProperties } = ({
    // ... add your default style here
    defaultStyle: {
        // fontFamily: FONT.MANROPE_REGULAR
        maxWidth: '100%',
        boxSizing: 'border-box'
    },
});