
import * as fromActions from '../actions/contact-us.actions';
import { AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    loading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.submitUserQuery, (state, action) => ({ ...state, loading: true }))
        .addCase(fromActions.submitUserQuerySuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.submitUserQueryError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.clearContactUSError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
