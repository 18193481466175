
import * as fromActions from '../actions/device.actions';
import { User, AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'
import { currentWindowHeight, currentWindowWidth } from '../../variables';


export interface State {
    userMenu: boolean,
    screenResolution: { width: number, height: number },
    toast: {
        text: string | null,
        show: boolean,
    }
    error?: AppError | null,
}

const initialState: State = {
    userMenu: false,
    screenResolution: { width: currentWindowWidth(), height: currentWindowHeight() },
    toast: {
        text: null,
        show: false
    }
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.toggleUserMenu, (state, action) => ({ ...state, userMenu: action.payload }))
        .addCase(fromActions.setAppScreenResolution, (state, action) => ({ ...state, screenResolution: action.payload }))
        .addCase(fromActions.toastMessage, (state, action) => {

            return ({ ...state, toast: { text: action.payload, show: true } });
        })
        .addCase(fromActions.clearToastMessage, (state, action) => {

            return ({ ...state, toast: { text: null, show: false } })
        })
        .addCase(fromActions.removeAppState, (state, action) => ({ ...initialState  }))
        .addCase(fromActions.disgardAction, (state, action) => ({ ...state  }))
        .addDefaultCase(state => ({ ...state }));
})

export const userMenu = (state: State) => state.userMenu;
export const selectState = (state: State) => state;
export const selectScreenResolution = (state: State) => state.screenResolution;