import React from 'react';
// import { Dimensions, Platform, PixelRatio, StatusBarProps } from 'react-native';
import { join } from 'path';
import { ORIENTATION, THEME } from './enums';
//window (without android bottom navigations Like "< O D" )
// const WINDOW_HEIGHT = Dimensions.get('window').height;
// const WINDOW_WIDTH = Dimensions.get('window').width;

// export const statusBarProps: StatusBarProps = {
//   backgroundColor: THEME.BLACK,
//   barStyle: 'light-content',
//   animated: true,
//   showHideTransition: 'fade'
// }

const currentWindowWidth = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return width;
}

const currentWindowHeight = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return height;
}

//screen (including android bottom navigations Like "< O D" )
// const DEVICE_HEIGHT = Dimensions.get('screen').height;
// const DEVICE_WIDTH = Dimensions.get('screen').width;

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const currentOrientation = () => {
  const { innerWidth: width, innerHeight: height } = window;
  if (height >= width) return ORIENTATION.PORTRAIT;
  else return ORIENTATION.LANDSCAPE;
}

const scale = size => window.innerWidth / guidelineBaseWidth * size;
const verticalScale = size => window.innerHeight / guidelineBaseHeight * size;
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;

const DEFAULT_SAFE_AREA_STYLES = { display: 'flex', backgroundColor: '#000' };


const CURRENT_OS = "web";

const APP_THEME_COLOR = "#A3238E";
const APP_CONTRAST_COLOR = "#522E91";
const WHITE_COLOR = "#ffffff";
const ASPECT_RATIO = currentWindowWidth() / currentWindowHeight();
// const PIXEL_RATIO = PixelRatio.get();

const BUTTON_HEIGHT = 50;
const BOOK_BUTTON_HEIGHT = 75;
const ICON_HEIGHT = 100;

const FONT_SIZE_PORTRAIT = 12;
const FONT_SIZE_LANDSCAPE = 10;


const ICON_SIZE_PORTRAIT = 12;
const ICON_SIZE_LANDSCAPE = 12;

const MIN_WINDOW_WIDTH = 600;


const DEFAULT_BORDER_BOTTOM_COLOR = "#D9D9D9";

const EMAIL_PATTERN = /^[_aA-zZ0-9-]+(\.[_aA-zZ0-9-]+)*(\+[aA-zZ0-9-]+)?@[aA-zZ0-9-]+(\.[aA-zZ0-9-]+)*$/;

const HALF_SCREEN_HEADER_HEIGHT = currentWindowHeight() / 3;
const HALF_SCREEN_WRAPPER_HEIGHT = HALF_SCREEN_HEADER_HEIGHT * 2;

const FULL_SCREEN_HEADER_HEIGHT = 60;
const FULL_SCREEN_WRAPPER_HEIGHT = currentWindowHeight() - (FULL_SCREEN_HEADER_HEIGHT + 15);

const BOTTOM_NAV_BAR_HEIGHT = 50;

const GENDERS = [
  { name: "male", code: "MALE" },
  { name: "female", code: "FEMALE" },
  { name: "others", code: "OTHERS" },
];

const FILE_SIZE_LIMIT = 30;

export {
  // WINDOW_HEIGHT,
  // WINDOW_WIDTH,

  // DEVICE_HEIGHT,
  // DEVICE_WIDTH,

  scale, verticalScale, moderateScale, currentOrientation,
  currentWindowWidth, currentWindowHeight,

  ASPECT_RATIO,
  // PIXEL_RATIO,
  ORIENTATION,
  BUTTON_HEIGHT,
  BOOK_BUTTON_HEIGHT,
  ICON_HEIGHT,

  CURRENT_OS,

  APP_THEME_COLOR,
  APP_CONTRAST_COLOR,

  DEFAULT_BORDER_BOTTOM_COLOR,

  FONT_SIZE_PORTRAIT,
  FONT_SIZE_LANDSCAPE,

  ICON_SIZE_PORTRAIT,
  ICON_SIZE_LANDSCAPE,

  MIN_WINDOW_WIDTH,

  EMAIL_PATTERN,
  DEFAULT_SAFE_AREA_STYLES,
  WHITE_COLOR,

  //wrapper
  HALF_SCREEN_HEADER_HEIGHT,
  HALF_SCREEN_WRAPPER_HEIGHT,

  FULL_SCREEN_HEADER_HEIGHT,
  FULL_SCREEN_WRAPPER_HEIGHT,

  BOTTOM_NAV_BAR_HEIGHT,
  GENDERS,
  FILE_SIZE_LIMIT,
};




