import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomButton, IndividualList, ScreenHolder } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, THEME } from "../enums";
import { AppError, ContactInfo, GroupForm, ImageResponse } from "../interfaces";
import { ActionDialogHolder, ErrorMessage, FieldForm, IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import { checkErrorResponse, extractError } from "../services";
import { MAIN_APP_ROUTES } from "../routes";
import * as ContactService from '../services/contacts.service';
import * as fromStore from '../store';
import { CircularProgress } from "@material-ui/core";

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = '85%';

const groupInput = {
    field: 'name',
    label: '',
    secure: false,
    type: 'none',
    placeholder: 'typeGroupName',
    pattern: ''
}

export function NewGroupScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const inputFile = useRef<HTMLInputElement>(null)
    const [hideKeyboard, setHideKeyboard] = useState(false);
    const [visible, setVisible] = useState(false);
    const [create, setCreate] = useState(false);
    const [selectedContacts, setSelectedContacts] = useState<ContactInfo[]>([]);
    const [state, setState] = useState<any>({
        name: '',
        image: null
    });
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [state.image])

    function readImageFile() {
        if (state.image === null) {
            setDataUrl(null);
            return;
        }
        let file = state.image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
                // console.log(event.target.result)
            }

        };

        reader.readAsDataURL(file);
    }

    useEffect(() => {

        dispatch(fromStore.getContacts());
        // Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
        // Keyboard.addListener('keyboardDidHide', _keyboardDidHide);
        return () => {
            // Keyboard.removeListener('keyboardDidShow', _keyboardDidShow);
            // Keyboard.removeListener('keyboardDidHide', _keyboardDidHide);
        }
    }, []);

    function _keyboardDidShow() {
        setHideKeyboard(true)
    }

    function _keyboardDidHide() {
        setHideKeyboard(false);
    }


    // function launchCamera() {
    //     ImagePicker.launchCamera(
    //         {
    //             mediaType: 'photo',
    //             includeBase64: false,
    //             //   maxHeight: 200,
    //             //   maxWidth: 200,
    //         },
    //         (response) => {
    //             // console.log(response);
    //             if (response && response.errorCode == "camera_unavailable") return;
    //             if (response && response.didCancel) return;
    //             handleImageChange(response);
    //         },
    //     )
    // }

    // function addImage() {
    //     ImagePicker.launchImageLibrary(
    //         {
    //             mediaType: 'photo',
    //             includeBase64: false,
    //             //   maxHeight: 200,
    //             //   maxWidth: 200,
    //         },
    //         (response) => {
    //             // console.log(response);
    //             if (response && response.didCancel) return;
    //             handleImageChange(response);
    //         },
    //     )
    // }

    const handleImageChange = (response: File) => {
        setState({
            ...state,
            image: response,
        });
    }

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function addContact(item: ContactInfo) {
        setSelectedContacts([...selectedContacts, item]);
    }

    function removeContact(item: ContactInfo) {
        let index = selectedContacts.indexOf(item);
        selectedContacts.splice(index, 1);
        let newList = [...selectedContacts];
        setSelectedContacts(newList);
    }


    function getUserIdArray(selectedContacts: ContactInfo[]) {
        return selectedContacts.map(doc => {
            return {
                id: doc.recipientUser.id
            }
        })
    }

    function _isFormValid() {
        const { name, image } = state;
        if (name == '') return false;
        if (!selectedContacts) return false;
        // if (nameTheSurvey == '') return false;
        if (selectedContacts.length === 0) return false;
        return true;
    }

    function onChange(e) {
        if (e.target.files[0]) handleImageChange(e.target.files[0]);
    }

    function onAddMore() {
        if (inputFile.current) inputFile?.current.click();
    }

    return (
        <ScreenHolder location={location} history={history} >
            <input ref={inputFile} type={'file'} accept={"image/*"} style={{ display: 'none' }} onChange={onChange} />
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <div style={{ width: 30, height: 30, marginRight: 15 }}>
                        <IconProvider type={"create-group"} />
                    </div>
                    <CustomText customStyle={styles.shdtxt}>{t('createANewGroup')}</CustomText>
                </div>
                <div style={styles.screenContent}>
                    <div style={styles.groupForm}>
                        <div
                            style={{
                                ...styles.imageInput,
                                // ...{ backgroundColor: pressed ? 'rgba(244,244,244,0.2)' : THEME.INPUT_BACKGROUND }
                            }}
                            onClick={onAddMore}
                        >


                            {dataUrl ?
                                <img
                                    src={dataUrl}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        backgroundColor: "transparent",
                                        borderRadius: 25,
                                        objectFit: 'cover'
                                    }}
                                />
                                :
                                <div style={{ width: 20, height: 20, borderRadius: 20, }}>
                                    <IconProvider type={"camera"} />
                                </div>}

                        </div>
                        <div style={styles.nameInput}>
                            <FieldForm
                                key={groupInput.field}
                                id={groupInput.field}
                                onChange={handleFieldChange}
                                secure={groupInput.secure}
                                placeholder={t(groupInput.placeholder)}
                                type={groupInput.type}
                                value={state[groupInput.field]}
                                pattern={groupInput.pattern}
                                label={groupInput.label}
                                placeholderTextColor={THEME.PLACEHOLDER}
                            />
                        </div>
                    </div>
                    <div style={styles.contactsList}>
                        <IndividualList
                            selected={selectedContacts}
                            contactsWindowHeight={'70%'}
                            onRefresh={() => { dispatch(fromStore.getContacts()); }}
                            onSelect={addContact}
                            onDeselect={removeContact}
                            onSelectAll={selectAllContacts}
                            onDeselectAll={deselectAllContacts}
                        />
                    </div>
                    {!hideKeyboard && <div style={styles.action}>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('submit')}
                                enable={_isFormValid()}
                                padding={12}
                                onClick={() => { console.log(state); setCreate(true) }}
                            />
                        </div>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('cancel')}
                                enable={true}
                                padding={12}
                                backgroundColor={THEME.CANCEL}
                                onClick={() => { history.goBack() }}
                            />
                        </div>
                    </div>}
                </div>

            </div>
            {create && <ActionDialogHolder
                visible={create}
                wrapperWidth={'65%'}
                onClose={() => { }}
                onDissmiss={() => { }}
            >
                <CreateProgress
                    visible={create}
                    doc={{ ...state, members: getUserIdArray(selectedContacts) }}
                    onClick={() => { }}
                    onClose={() => { setCreate(!create) }}
                    onSuccess={(response) => {
                        dispatch(fromStore.addGroup(response));
                        setCreate(!create)
                        history.push(MAIN_APP_ROUTES.GROUPS);
                    }}
                />
            </ActionDialogHolder>}
            {/* <CustomMedia
                type={"photo"}
                visible={visible}
                onMedia={handleImageChange}
                onDissmiss={() => { setVisible(false) }}
                onClose={() => { setVisible(false) }}
            /> */}
        </ScreenHolder>
    )

    function selectAllContacts(contacts: ContactInfo[]) {
        setSelectedContacts([...contacts]);
    }

    function deselectAllContacts() {
        setSelectedContacts([]);
    }
}


interface CreateProgressProps {
    visible: boolean,
    doc: {
        name: string,
        members: Array<{ id: string }>,
        image: File
    },
    onClick: () => void,
    onSuccess: (response: any) => void,
    onClose: () => void,
}

interface CreateProgressState {
    status: string,
    error: AppError | null,
}

function CreateProgress(props: CreateProgressProps) {
    const { visible, doc, onClick, onSuccess, onClose } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState<CreateProgressState>({
        status: '',
        error: null
    });

    useEffect(() => {
        if (visible && doc) handleGroup();
    }, [])



    async function handleGroup() {
        try {
            // console.log(startTime.toISOString(), endTime.toISOString());
            setState({ ...state, status: 'Uploading image' });
            const ImageResponse: ImageResponse | null = doc.image ? await ContactService.uploadGroupImage(doc.image) : null;
            setState({ ...state, status: 'Creating Group' });
            const data: GroupForm = {
                name: doc.name,
                imagePath: ImageResponse ? ImageResponse?.path : null,
                members: doc.members,
            }

            const response = await ContactService.createNewGroup(data);
            setState({ ...state, status: `Success...` });
            // console.log(response);
            onSuccess(response);



        } catch (error: any) {

            const extError = extractError(error, MAIN_APP_ROUTES.CREATE_GROUP);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }

    function onErrorClose() {
        setState({ ...state, error: null });
        onClose();
    }

    if (state.error) {
        return (
            <div style={styles.progressContainer}>
                <div style={styles.centeredView}>
                    <ErrorMessage
                        text={checkErrorResponse(state.error)}
                        onClose={onErrorClose}
                    />
                </div>
            </div>
        )
    }


    return (
        <div style={styles.progressContainer}>
            <div style={styles.centeredView}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    boxSizing: 'border-box'
                }}>
                    <CircularProgress size={20} style={{ marginBottom: 10 }} />
                    <CustomText customStyle={styles.modalText}>{state.status}</CustomText>
                </div>
            </div>
        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({

    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        width: '100%',
        boxSizing: 'border-box',

    },

    groupForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20, paddingBottom: 10,
        width: '100%',
        boxSizing: 'border-box'
    },
    imageInput: {
        backgroundColor: THEME.INPUT_BACKGROUND,
        width: 40,
        height: 40,
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        cursor: 'pointer'
    },
    nameInput: {
        width: '90%'
    },
    contactsList: {
        height: contactsWindowHeight,
        paddingLeft: 20,
        paddingRight: 20,
        width: '100%',
        boxSizing: 'border-box'
    },
    action: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        bottom: 15,
        backgroundColor: THEME.WHITE,
        width: '100%',
        boxSizing: 'border-box'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },

    //model styles

    progressContainer: {
        position: 'relative',
        width: '100%',
        backgroundColor: THEME.WHITE,
        borderRadius: 10,

        display: 'flex',
        flexDirection: 'column',
        minHeight: 100,
        boxSizing: 'border-box'
    },

    centeredView: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 100,
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        padding: 15,
        alignItems: "center",
        justifyContent: 'center',
        boxSizing: 'border-box'
        // marginTop: 22
    },



    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },
})