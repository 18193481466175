

import * as React from 'react';
// import { CommonActions, DrawerActions, TabActions, NavigationContainerRef } from '@react-navigation/native';

// export const isMountedRef = React.createRef<boolean>();

// export const navigationRef = React.createRef<any>();

// function navigate(name:string, params?:any) {
//   if (isMountedRef.current && navigationRef.current) {
//     // Perform navigation if the app has mounted
//     navigationRef.current.navigate(name, params);
//   } else {
//     // You can decide what to do if the app hasn't mounted
//     // You can ignore this, or add these actions to a queue you can call later
//     // setTimeout(()=> navigationRef.current.navigate(name, params), 500);
//   }
// }

// export default {
//   navigate,
// };

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();