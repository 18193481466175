import React from "react";
import { THEME } from "../enums";
import { MySurvey, SurveyQuestion } from "../interfaces";
import { getCustomDateFormat } from "../services";
import { IconProvider, getIconNameByQuestionType, ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";
import { AiOutlineCheck, AiOutlineBook } from 'react-icons/ai'



interface SurveyItemProps {
    item: MySurvey,
    onClick: (doc: MySurvey) => void;
}

export function SurveyItem(props: SurveyItemProps) {
    const { item, onClick } = props;
    const { type, id, category } = item;

    function isCompleted(): boolean {
        const date = new Date();
        const endDate = new Date(item.endTime);
        if (date >= endDate) return true;
        return false;
    }
    return (
        <div
            className={"on-press-cls"}
            style={{
                ...styles.decisionContainer,
                //    ...{ backgroundColor: pressed ? 'rgba(220,220,220,0.2)' : THEME.WHITE }
            }}
            onClick={() => onClick(item)}
        >
            <div style={styles.decisionItem}>
                <div style={styles.prevIcon}>
                    {/* {(category && category.imageUrl) ? <ImageWithSpinner
                        resizeMode={"contain"}
                        customStyle={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            borderRadius: 53,
                        }}
                        uri={category.imageUrl}
                        spinnerSize={20}
                    /> : <IconProvider type={getIconNameByQuestionType(type)} />} */}
                       <ImageWithSpinner
          resizeMode={"contain"}
          customStyle={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: 45,
          }}
          imgStyle={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: 45,
          }}
          // uri={require('../../assets/others/default-profile.jpeg')}
          uri={'https://mobile.pubrule.com/favicon.ico'}
          spinnerSize={20}
        /> 
                </div>
                <div className={"list-item-cls"} style={styles.decisionContent}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CustomText customStyle={styles.question}>{item.text}</CustomText>
                        {isCompleted() && <AiOutlineCheck style={{}} color={'green'} size={20} />}
                    </div>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{item.startTime ? getCustomDateFormat(item.startTime) : ''}</CustomText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <AiOutlineBook style={{ marginRight: 10 }} color={"red"} size={12} /> */}
                            {/* <CustomText customStyle={styles.comments}>{`${item.commentsCount} Comment`}</CustomText> */}
                        </div>

                    </div>
                </div>
            </div>

        </div>

    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    //Decision item styles

    decisionContainer: {
        position: 'relative',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 15,
        width: '100%',
        cursor: 'pointer',
        boxSizing: 'border-box'
    },
    decisionItem: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        position: 'relative',

    },
    prevIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    decisionContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    question: {
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 10,
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777",
        marginRight: 10,
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    loadMoreContainer: {
        // height: 60, 
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

})