import React, { useState } from "react";
import { DRAWER_TYPE, FONT, THEME } from "../enums";
import { useTranslation } from 'react-i18next';
import { currentWindowHeight, currentWindowWidth, EMAIL_PATTERN } from "../variables";
import CustomText from "../utils/custom-text";
import { FormInput, SpinnerScreen } from "../utils";
import { CustomButton, ScreenHolder } from "../components";
import { MAIN_APP_ROUTES } from "../routes";
import * as AuthService from '../services/auth.service';
import { useDispatch, useSelector } from "react-redux";
import * as fromStore from '../store';
import { extractError } from "../services";

const CONTACT_FORM = [
    {
        field: 'name',
        label: '',
        secure: false,
        type: 'name',
        placeholder: 'NAME',
        pattern: '',

    },
    {
        field: 'email',
        label: '',
        secure: false,
        type: 'emailAddress',
        placeholder: 'EMAIL',
        pattern: EMAIL_PATTERN

    },
    {
        field: 'comment',
        label: '',
        secure: false,
        type: 'none',
        placeholder: 'COMMENTS',
        pattern: '',
        multiline: true,
        numberOfLines: 4,
    },
];

interface State {
    name: string,
    email: string,
    comment: string
}

const initialState: State = {
    name: '',
    email: '',
    comment: ''
}

export function ContactUsScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState<State>(initialState);
    const { loading, error } = useSelector(state => fromStore.selectContactUSState(state));
    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { name, email, comment } = state;
        const pattern = EMAIL_PATTERN;
        if (name && name === '') return false;
        if (email && email === '') return false;
        if (pattern.test(email) == false) return false;
        if (comment && comment === '') return false;
        return true;
    }

    if (loading) {
        return (
            <ScreenHolder drawerType={DRAWER_TYPE.HALF} history={history} location={location} >
                <SpinnerScreen />
            </ScreenHolder>
        )
    }
    return (
        <ScreenHolder drawerType={DRAWER_TYPE.HALF} history={history} location={location} >
            <div style={styles.formContainer}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.headerText}>{t('contactUS')}</CustomText>
                </div>
                <div style={styles.infoContainer}>
                    <CustomText customStyle={styles.subInfoText}>{t('contactUSInfo')}</CustomText>
                </div>
                <div style={styles.inputHolder}>
                    {CONTACT_FORM.map((doc, index) => (
                        <div key={doc.field} style={styles.inputField}>
                            <FormInput
                                id={doc.field}
                                onChange={handleFieldChange}
                                secure={doc.secure}
                                placeholder={doc.placeholder}
                                type={doc.type}
                                value={state[doc.field]}
                                pattern={doc.pattern}
                                label={doc.label}
                                // keyboardtype={doc?.keyboardtype}
                                multiline={doc.multiline}
                                numberOfLines={doc.numberOfLines}
                            />
                        </div>
                    ))}
                </div>
                <div style={styles.action}>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('send')}
                            enable={_isFormValid()}
                            padding={10}
                            onClick={submitQuery}
                        />
                    </div>
                    <div style={styles.btn}>
                        <CustomButton
                            textStyle={{ textTransform: 'uppercase' }}
                            name={t('cancel')}
                            enable={true}
                            padding={10}
                            backgroundColor={THEME.CANCEL}
                            onClick={() => { 
                                setState(initialState);
                                history.push(MAIN_APP_ROUTES.SETTINGS) 
                            }}
                        />
                    </div>
                </div>
            </div>
        </ScreenHolder>
    )


    async function submitQuery() {
        try {
            // console.log(state);
            dispatch(fromStore.submitUserQuery(state));
            const response = await AuthService.contactUs(state);
            dispatch(fromStore.submitUserQuerySuccess(response));
            dispatch(fromStore.toastMessage(t('submittedSuccessfully')));
            history.push(MAIN_APP_ROUTES.SETTINGS);
        } catch(error: any){
            const newError = extractError(error, MAIN_APP_ROUTES.CONTACT_US);
            dispatch(fromStore.submitUserQueryError(newError));
        }
        
    }
}


const styles: { [key: string]: React.CSSProperties } = ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,

    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        // marginBottom: 20,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK
    },
    infoContainer: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 25,
        boxSizing: 'border-box'
    },
    subInfoText: {
        fontSize: 13,
        // textAlign: 'auto',
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.BLACK,
    },
    inputHolder: {
        paddingLeft: 20,
        paddingRight: 20,

    },
    action: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        marginBottom: 20,
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
    inputField: {
        marginBottom: 10
    },
})
