import React from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../components";
import { FONT, THEME } from "../enums";
import { MAIN_APP_ROUTES } from "../routes";
import CustomText from "../utils/custom-text";

const OPTIONS = [
    { name: 'singleOptionTF', route: MAIN_APP_ROUTES.SINGLE_OPTION },
    { name: 'multipleChoice', route: MAIN_APP_ROUTES.MULTIPLE_CHOICE },
    { name: 'shortAnswer', route: MAIN_APP_ROUTES.SHORT_ANSWER }
]

export function CreateQuestionScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerTxt}>{t('pleaseSelectTheQuestionType')}</CustomText>
            </div>
            <div style={styles.content}>
                {OPTIONS.map((doc, index) => (
                    <div key={index} style={styles.btn}>
                        <CustomButton name={t(doc.name)} padding={20} enable={true} onClick={() => history.push(doc.route)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    headerTxt: {
        fontSize: 18,
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.ORANGE_RED
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        paddingLeft: 25,
        paddingRight: 25,
    },
    btn: {
        marginBottom: 20,
    },

    //Decision screen

    action: {
        marginTop: 20,
        paddingLeft: 25,
        paddingRight: 25,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },


    decisions: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },


})