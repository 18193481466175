import * as fromActions from '../actions/friend-requests.actions';
import { AppError, FriendRequest } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<FriendRequest> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,

    addState: {
        id: string | null,
        loading: boolean,
        error?: AppError | null,
    },
    acceptState: {
        id: string | null,
        loading: boolean,
        error?: AppError | null,
    },
    rejectState: {
        id: string | null,
        loading: boolean,
        error?: AppError | null,
    }
    error?: AppError | null,
}

const friendRequestsAdapter: EntityAdapter<FriendRequest> = createEntityAdapter<FriendRequest>({
    selectId: (doc) => doc.id
})

const initialState: State = friendRequestsAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
    addState: {
        id: null,
        loading: false,
    },
    acceptState: {
        id: null,
        loading: false,
    },
    rejectState: {
        id: null,
        loading: false,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getFriendRequests, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getFriendRequestsSuccess, (state, action) => friendRequestsAdapter.setAll({ ...state, loading: false, }, action.payload))
        .addCase(fromActions.getFriendRequestsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.addFriend, (state, action) => ({
            ...state,
            addState: {
                ...state.addState,
                id: action.payload.recipientUser.id,
                loading: true,
                error: null
            }
        }))
        .addCase(fromActions.addFriendSuccess, (state, action) => ({
            ...state,
            addState: {
                ...state.addState,
                id: null,
                loading: false,
            }
        }))
        .addCase(fromActions.addFriendError, (state, action) => ({
            ...state,
            addState: {
                ...state.addState,
                id: null,
                loading: false,
                error: action.payload
            }
        }))

        .addCase(fromActions.acceptFriend, (state, action) => ({
            ...state,
            acceptState: { ...state.acceptState, id: action.payload, loading: true, error: null }
        }))
        .addCase(fromActions.acceptFriendSuccess, (state, action) => friendRequestsAdapter.removeOne({
            ...state,
            acceptState: { ...state.acceptState, id: null, loading: false },
        }, action.payload.id))
        .addCase(fromActions.acceptFriendError, (state, action) => ({
            ...state,
            acceptState: { ...state.acceptState, id: null, loading: false, error: action.payload },
        }))


        .addCase(fromActions.rejectFriend, (state, action) => ({
            ...state,
            rejectState: { ...state.rejectState, id: action.payload, loading: true, error: null }
        }))
        .addCase(fromActions.rejectFriendSuccess, (state, action) => friendRequestsAdapter.removeOne({
            ...state,
            rejectState: { ...state.rejectState, id: null, loading: false }
        }, action.payload.id))
        .addCase(fromActions.rejectFriendError, (state, action) => ({
            ...state,
            rejectState: { ...state.rejectState, id: null, loading: false, error: action.payload }
        }))

        .addCase(fromActions.removeFriendRequests, (state, action) => friendRequestsAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearFriendRequestsError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = friendRequestsAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectAddState = (state: State) => state.addState;
export const selectAcceptState = (state: State) => state.acceptState;
export const selectRejectState = (state: State) => state.rejectState;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;