import { createSelector } from "reselect";
import * as fromReducer from '../reducers/my-surveys.reducer';

const selectState = state => state.mySurveysState;

export const selectMySurveysState = createSelector(selectState, fromReducer.selectState);
export const selectMySurveys = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectMySurveysEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);

// export const selectSurveyResult = createSelector(
//     selectState,
//     fromReducer.selectSurveyResult
// );

