import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineBook } from "react-icons/ai";
import { DEVICE_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { CommentForm, MySurvey, SurveyAnswer, SurveyOption } from "../interfaces";
import { getCustomDateFormat, isSurveyActive } from "../services";
import { FormInput, getIconNameByQuestionType, IconProvider, ImageWithSpinner, RadioButton } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth, CURRENT_OS } from "../variables";
import { CustomButton } from "./button";
import { ProgressBar } from './progress-bar';
import { CommentsSection } from "./survey-comment";
import { SurveyTimer } from "./survey-timer";


const InputDoc = {
    field: 'comment',
    label: '',
    required: true,
    secure: false,
    type: 'none',
    textContentType: 'none',
    placeholder: 'typeYourComment',
    multiline: true,
    numberOfLines: 4,
    backgroundColor: THEME.INPUT_BACKGROUND

};
interface Props {
    survey: MySurvey,
    totalComments: number,
    answers: SurveyAnswer[],
    onImage: (uri: string) => void,
}

export function MultiChoiceResults(props: Props) {
    const {
        survey,
        answers,
        totalComments,
        onImage,

    } = props;
    const { t, i18n } = useTranslation();
    const { type, id, category } = survey;
console.log('dlmfkdsnfknsdkfnksd', survey,
answers,
totalComments,);


    return (
        <div style={styles.container}>
            <div style={styles.surveyInfo}>
                <div style={styles.surveyIcon}>
                    {/* {(category && category.imageUrl) ?  */}
                    <ImageWithSpinner
                        resizeMode={"contain"}
                        customStyle={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            borderRadius:"50%"
                        }}
                        uri={'https://mobile.pubrule.com/favicon.ico'}
                        spinnerSize={20}
                    /> 
                    {/* : <IconProvider type={getIconNameByQuestionType(type)} />} */}
                </div>
                <div style={styles.surveyData}>
                    <CustomText customStyle={styles.question}>{survey.text}</CustomText>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{survey.startTime ? getCustomDateFormat(survey.startTime) : ''}</CustomText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <AiOutlineBook style={{ marginRight: 10 }} color={"red"} size={12} /> */}
                            <CustomText customStyle={styles.comments}>{`${totalComments} ${t('comments')}`}</CustomText>
                        </div>
                    </div>
                </div>

            </div>
            <div style={styles.surveyOptionsStatus}>
                {
                    survey?.options.map((doc, index) => (
                        <OptionWithProgress
                            key={doc.id}
                            index={index}
                            option={doc}
                            optionAnswers={answers.filter(ans => ans.option && (ans.option.id === doc.id))}
                            onImage={onImage}
                            totalUsers={survey.sharedToUsers.length}
                        />
                    ))
                }
                <SurveyTimer timeStamp={survey.endTime} />
            </div>
        </div>
    )
}

interface OptionWithProgressProps {
    index: number,
    option: SurveyOption,
    optionAnswers: SurveyAnswer[],
    onImage: (uri: string) => void,
    totalUsers: number,
}

function OptionWithProgress(props: OptionWithProgressProps) {
    const { index, option, onImage, optionAnswers, totalUsers } = props;
    console.log('glddknfksndkfwewewe',index, option, onImage, optionAnswers, totalUsers);
    
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{}}>
                    
                </div>
                
            </div>
            <div style={{ position: "relative" }}>
                {option  &&
                    <div
                        style={{
                            ...{  borderWidth: 1, borderColor: THEME.INPUT_BORDER,float:"left",marginTop:"10px",position:"absolute",zIndex:1 }
                        }}
                        onClick={() => onImage(option?.imageUrl)}
                    >

                      {option.imageUrl  && <img
                            src={option?.imageUrl}
                            style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "transparent",
                                objectFit: 'cover',
                                borderRadius: "50%"
                            }}
                        />}

                        <CustomText customStyle={{ fontSize: 14, textTransform: 'capitalize',float:"right" }}>{`${index + 1}. ${option.value}`}</CustomText>
                    </div>
                    
                    }
                
                <ProgressBar votes={optionAnswers.length} totalUsers={totalUsers} />
            </div>
        </div>

    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    surveyInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',

    },
    surveyIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    surveyData: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    question: {
        color: THEME.ORANGE_RED,
        fontSize: 14
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    //survey options 
    surveyOptionsStatus: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#E1E1E1'
    },


    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    submitComment: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
})