import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { THEME, FONT, LOGIN_TYPE } from "../enums";
import { LOGIN_SIGNUP_ROUTES } from "../routes";
import { selectAuthState } from "../store/selectors";
import { CustomInput, IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowWidth, EMAIL_PATTERN } from "../variables";
import * as fromStore from "../store";
import { CustomButton } from "../components";
import '../scss/login.scss';
import { GoogleLogin, useGoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginForm, GoogleLoginForm } from "../interfaces";
import { environment } from "../app.config";

const LOGIN_FORM = [
    {
        field: 'email',
        label: 'Email ID',
        secure: false,
        type: 'emailAddress',
        placeholder: 'Enter your email address',
        pattern: EMAIL_PATTERN

    },
    {
        field: 'password',
        label: 'Password',
        secure: true,
        type: 'password',
        placeholder: 'Enter your password',
        pattern: /^\w+([\.-]?\w+)/
    }

]

const SSLOGINS = [
    { name: 'google', code: 'google' },
    { name: 'facebook', code: 'facebook' },
]

interface State {
    email: string,
    password: string
}

export function LoginScreen(props: any) {
    const { history, location } = props;
    const { signIn, loaded } = useGoogleLogin({
        onSuccess: onGoogleSucess,
        // onAutoLoadFinished,
        clientId: environment.GOOGLE_WEB_CLIENT_ID,
        cookiePolicy: 'single_host_origin',
        // loginHint,
        // hostedDomain,
        // autoLoad,
        // isSignedIn,
        // fetchBasicProfile,
        // redirectUri,
        // discoveryDocs,
        onFailure: onGoogleFailure,
        // uxMode,
        // scope,
        // accessType,
        // responseType,
        // jsSrc,
        // onRequest,
        // prompt
    })
    const [state, setState] = useState<State>({
        email: '',
        password: '',
    });
    const { loading, error } = useSelector((state) => fromStore.selectAuthState(state));
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     // console.log(props);
    //     // if(error) Alert.alert(JSON.stringify(error));
    //     console.log(location.state);
    //     return () => {

    //     }
    // }, []);



    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    function _isFormValid() {
        const { email, password } = state;
        const pattern = EMAIL_PATTERN;

        if (email == '') return false;
        if (pattern.test(email) == false) return false;
        if (password == '') return false;
        if (password && password.length < 6) return false;
        if (password && password.length >= 15) return false;
        return true;
    }

    if (loading) {
        return (
            <div className={"login-container"}>
                <SpinnerScreen />
            </div>
        )
    }

    return (
        <div className={"login-container"}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerTxt}>{t('login')}</CustomText>
            </div>
            <div style={styles.loginFormContainer}>
                {
                    LOGIN_FORM.map((doc, index) => (
                        <div key={doc.field} style={{ width: '100%', marginBottom: (index !== (LOGIN_FORM.length - 1)) ? 25 : 10 }}>
                            <CustomInput
                                id={doc.field}
                                onChange={handleFieldChange}
                                secure={doc.secure}
                                placeholder={doc.placeholder}
                                type={doc.type}
                                value={state[doc.field]}
                                pattern={doc.pattern}
                                label={doc.label}
                            />
                        </div>
                    ))
                }

                <div style={styles.forgotPasswordContainer}>
                    <div
                        style={styles.forgotPassword}
                        onClick={() => { history.push({ pathname: LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD, state: location.state, }) }}
                    >

                        <CustomText
                            className={'active-text'}
                            customStyle={{
                                ...styles.forgotPasswordTxt,
                                // ...{ color: pressed ? THEME.ORANGE_RED : THEME.BLACK },
                            }}
                        >
                            {t('forgotPassword?')}
                        </CustomText>


                    </div>
                </div>
            </div>
            <div style={styles.action}>
                <CustomButton
                    name={t('login')}
                    enable={_isFormValid()}
                    onClick={() => requestEmailLogin()}
                />
            </div>
            <div style={styles.registerContainer}>
                <CustomText customStyle={{ ...styles.registerText, ...{ marginRight: 5 } }}>{`${t('doNotHaveAnAccount')} `}</CustomText>
                <div
                    style={styles.register}
                    onClick={() => { history.push({ pathname: LOGIN_SIGNUP_ROUTES.REGISTER, state: location.state, }) }}
                >
                    <CustomText
                        className={'active-text'}
                        customStyle={{
                            ...styles.registerText,
                            ...{ fontWeight: 'bold' },
                        }}
                    >
                        {`${t('registerHere')}`}
                    </CustomText>
                </div>

            </div>
            <div style={styles.ssoLoginContainer}>
                <CustomText customStyle={styles.registerText}>{`${t('loginWithSocialMedia')}`}</CustomText>
                <div style={styles.socialMedia}>

                    <div
                        key={'google'}
                        className={'sso-btn'}
                        onClick={() => signIn()}
                    >
                        <div style={{ width: 20, height: 20 }}>
                            <IconProvider type={'google'} />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <CustomText customStyle={{}}>{t('google')}</CustomText>
                        </div>
                    </div>

                    <FacebookLogin
                        appId={environment.FACEBOOK_ID}
                        autoLoad={false}
                        fields={"name,email,picture"}
                        redirectUri={"https://pubrule.gendevs.com/"}
                        isMobile={false}
                        disableMobileRedirect={true}
                        callback={onFacebookLoginCallback}
                        render={renderProps => (
                            <div
                                key={'facebook'}
                                className={'sso-btn'}
                                onClick={() => renderProps.onClick()}
                            >
                                <div style={{ width: 20, height: 20 }}>
                                    <IconProvider type={'facebook'} />
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <CustomText customStyle={{}}>{t('facebook')}</CustomText>
                                </div>
                            </div>
                        )}
                    />

                </div>

            </div>
        </div>
    )

    function onFacebookLogin(res) {
        // console.log(res);
    }

    function onFacebookLoginCallback(res) {
        // console.log(res);
        const credentials: FacebookLoginForm = {
            facebookSigninRequest: {
                tokenData: {
                    accessToken: res.accessToken,
                    accessTokenSource: undefined,
                    applicationID: environment.FACEBOOK_ID,
                    dataAccessExpirationTime: res.data_access_expiration_time,
                    declinedPermissions: [],
                    expirationTime: res.expiresIn,
                    expiredPermissions: [],
                    lastRefreshTime: 0,
                    permissions: [],
                    userID: res.userID,
                },
                userDetails: {
                    email: res.email,
                    first_name: res.name.split(' ')[0],
                    id: res.id,
                    last_name: res.name.split(' ')[1],
                    picture: res.picture
                }
            },
            signinType: "FACEBOOK"
        }
        dispatch(fromStore.facebookLogin(credentials));
    }

    function onGoogleSucess(res: any) { //GoogleLoginResponse | GoogleLoginResponseOffline
        // console.log(res);
        const credentials: GoogleLoginForm = {
            signinType: LOGIN_TYPE.GOOGLE,
            googleSigninRequest: {
                idToken: res?.tokenId,
                scopes: res?.tokenObj.scope.split(' '),
                serverAuthCode: res?.accessToken,
                user: {
                    email: res?.profileObj?.email,
                    familyName: res?.profileObj.familyName,
                    givenName: res?.profileObj.givenName,
                    id: res?.profileObj.googleId,
                    name: res?.profileObj.name,
                    photo: res?.profileObj.imageUrl,
                }
            }
        }
        dispatch(fromStore.googleLogin(credentials));
    }



    function onGoogleFailure(error) {
        // console.log(error)
    }


    function requestEmailLogin() {
        const { email, password } = state;
        dispatch(fromStore.emailLogin({ username: email, password: password, rememberMe: true }))
    }



}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        overflowY: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 10,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    headerTxt: {
        fontSize: 17,
        fontWeight: 'bold',
        fontFamily: FONT.MANROPE_REGULAR,
        textTransform: 'uppercase',
    },
    loginFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20
    },
    forgotPasswordContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    forgotPassword: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'flex-end',
    },
    forgotPasswordTxt: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR,
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 30,
    },
    registerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
    },
    register: {
        display: 'flex',
        flexDirection: 'row',
    },
    registerText: {
        fontSize: 11,
        fontFamily: FONT.MANROPE_REGULAR,
    },
    ssoLoginContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        paddingBottom: 20,
    },
    socialMedia: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10
    },
    socialBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#FBFBFB',
        borderWidth: 1,
        borderColor: '#E1E1E1',
        width: '40%',
        borderRadius: 15,
    }
})