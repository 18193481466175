import React, { Component, useState } from "react";
// import { StyleSheet, View, Text, Image, TextInput, Pressable } from "react-native";
import { FONT, THEME } from "../enums";
// import Svg, { Path } from "react-native-svg";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import { Country } from "../interfaces";

interface Props {
    lable: string,
    selected: any,
    onClick: () => void,
}
export function Seclector(props: Props) {
    const { lable, selected, onClick } = props;

    return (
        <div style={styles.container}>
             <div
                style={{
                    ...styles.innercontainer,
                    // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
                }}
                onClick={onClick}
            >
                <div style={styles.innervalue}>
                    {selected?.url && <div style={styles.thumbnail}>
                        <img
                            src={selected?.url}
                            style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: 'cover' }}
                        />
                    </div>}
                    <div style={{ marginTop: 15 }}>
                        <CustomText customStyle={{ fontSize: 11, fontFamily: FONT.MANROPE_REGULAR }}>{selected?.name}</CustomText>
                    </div>
                </div>
                <div style={{
                    marginTop: 20
                }}>
                    {/* <Svg viewBox="0 0 10 6" style={styles.triangle}>
                        <Path
                            strokeWidth={0}
                            fill="rgba(88,91,90,1)"
                            fillOpacity={1}
                            strokeOpacity={1}
                            d="M5.00 0.00 L10.00 6.00 L0.00 6.00 L5.00 0.00 Z"
                        ></Path>
                    </Svg> */}
                </div>

             </div>
            <div style={styles.label}>
                <CustomText customStyle={styles.labelTxt}>{lable}</CustomText>
            </div>
            
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } =  ({
    container: {
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        width: '100%',
        height: 50,
        borderRadius: 10,
        borderWidth: 2,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15
    },
    label: {
        position: 'absolute',
        top: -8,
        left: 20,
        backgroundColor: THEME.WHITE,
        paddingLeft: 10,
        paddingRight: 10,
    },
    labelTxt:{
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
    },
    thumbnail: {
        width: 30, height: 25, marginTop: 10, marginRight: 10, borderRadius: 10
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },
})