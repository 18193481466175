import * as fromActions from '../actions/my-surveys.actions';
import { AppError, MySurvey } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<MySurvey> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
    delete: {
        loading: boolean,
        error?: AppError | null,
        id: string | null,
    },
}

const mySurveyAdapter: EntityAdapter<MySurvey> = createEntityAdapter<MySurvey>({
    selectId: (doc) => doc.id
})

const initialState: State = mySurveyAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
    delete: {
        loading: false,
        id: null,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getMySurveys, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getMySurveysSuccess, (state, action) => mySurveyAdapter.setAll({ ...state, loading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result))
        .addCase(fromActions.getMySurveysError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getMoreMySurveys, (state, action) => ({ ...state, nextLoading: true, error: null }))
        .addCase(fromActions.getMoreMySurveysSuccess, (state, action) => {
            if (action.payload.result.length === 0) return ({ ...state, nextLoading: false, countAll: state.ids.length });
            return mySurveyAdapter.addMany({ ...state, nextLoading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result);
        })
        .addCase(fromActions.getMoreMySurveysError, (state, action) => ({ ...state, error: action.payload, nextLoading: false }))

        .addCase(fromActions.postMySurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.postMySurveySuccess, (state, action) => {
            const { countAll, limit, page, ids, entities } = state;
            let newCountAll = countAll + 1;
            let newLimit = limit + 1;
            const newIds: any[] = [...ids];
            newIds.unshift(action.payload.id);
            return {
                ...state,
                ids: newIds,
                entities: { ...entities, [action.payload.id]: action.payload },
                countAll: newCountAll,
                limit: (countAll < limit && newCountAll <= limit) ? limit : newLimit,
                page: page,
            }
            // return mySurveyAdapter.addOne({ ...state, loading: false }, action.payload);
        })
        .addCase(fromActions.postMySurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.updateMySurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.updateMySurveySuccess, (state, action) => mySurveyAdapter.updateOne({ ...state, loading: false }, action.payload))
        .addCase(fromActions.updateMySurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.deleteMySurvey, (state, action) => ({ ...state, delete: { ...state.delete, id: action.payload, loading: true, error: null } }))
        .addCase(fromActions.deleteMySurveySuccess, (state, action) => mySurveyAdapter.removeOne({ 
            ...state, 
            countAll: state.countAll - 1, 
            delete: { ...state.delete, id: null, loading: false } 
        }, action.payload))
        .addCase(fromActions.deleteMySurveyError, (state, action) => ({ ...state, delete: { ...state.delete, loading: false, error: action.payload } }))

        .addCase(fromActions.removeMySurvays, (state, action) => mySurveyAdapter.removeAll({ ...state, error: null, surveyResult: null }))
        .addCase(fromActions.clearMySurveysError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = mySurveyAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;