import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';

const getDraftEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getDraft),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveysService.getSurveyById(payload)
            .then(response => fromActions.getDraftSuccess(response))
            .catch(error => fromActions.getDraftError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

export const draftEpics = combineEpics(
    getDraftEpic
);