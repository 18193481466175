

export enum FONT {
    MANROPE_500 = "Manrope-Medium",
    MANROPE_600 = "Manrope-SemiBold",
    MANROPE_700 = "Manrope-Bold",
    MANROPE_800 = "Manrope-ExtraBold",
    MANROPE_REGULAR = "Manrope-Regular"
}

export enum THEME {
    BLACK = "#000000",
    WHITE = "#FFFFFF",
    ORANGE_RED = "#FF6400",
    INPUT_BORDER = "rgb(234,234,234)",//"#CFCFD5"
    PLACEHOLDER = "#A4A4A4",
    CANCEL = "#888888",
    INPUT_BACKGROUND = "#EFEFEF",
    DEFAULT_BORDER = "#979797",
    RED = "red",
}

export enum POST_OPTIONS {
    PERSON = "person",
    GROUP = "group",
    EVERYONE = "everyone",
    INVITE_VIA_LINK = "invite",
}

export enum DRAWER_TYPE {
    HALF = "HALF",
    FULL = "FULL",
    SEARCH = "SEARCH",
    BOTTOM_NAV = "BOTTOM_NAV",
}

export enum NOTIFICATIONS_THEME {
    PURPLE = "#9C25F3",
    ORANGE = "#FFB05A",
    BLUE = "#4D78FF",
    GREEN = "#4FDE7B"
}

export enum ORIENTATION {
    LANDSCAPE = "LANDSCAPE",
    PORTRAIT = "PORTRAIT",
}

export enum DEVICE_TYPE {
    ANDROID = "android",
    IOS = "ios",
    WEB = "web"
}

export enum HANDSET_TYPE {
    ANDROID_PHONE = "ANDROID_PHONE",
    ANDROID_TABLET = "ANDROID_TABLET",
    IPHONE = "IPHONE",
    IPAD = "IPAD",
    WEB = "WEB",
    UNKNOWN = "UNKNOWN"
}


export enum LOGIN_TYPE {
    GOOGLE = "GOOGLE",
    FACEBOOK = "FACEBOOK",
    APPLE = "APPLE",
    EMAIL = "EMAIL",
}

export enum QUESTION_TYPE {
    SINGLE_OPTION = "DOUBLE_OPTION",
    SHORT_ANSWER = "SHORT_ANSWER",
    MULTIPLE_CHOICE = "MULTIPLE_OPTION"
}

export enum CONTACT_TYPE {
    CONTACT = "CONTACT",
    GROUP = "GROUP",
}

export enum SHARE_TYPE {
    EVERYONE = "EVERYONE",
    PERSON = "PERSON",
    GROUP = "GROUP",
    LINK = "LINK"
}

export enum WEB_URLS {
    PRIVACY_POLICY = "https://www.freeprivacypolicy.com/live/d234cf3c-e7a4-4bde-b092-a0e3204c66d2",
    ABOUT_US = "https://www.freeprivacypolicy.com/live/d234cf3c-e7a4-4bde-b092-a0e3204c66d2",
    HELP_CENTER = "https://www.freeprivacypolicy.com/live/d234cf3c-e7a4-4bde-b092-a0e3204c66d2",
}