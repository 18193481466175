import { createAction } from '@reduxjs/toolkit';
import { AddFriendForm, AppError, FriendRequest, GlobalContactInfo } from '../../interfaces';


export const getFriendRequests = createAction('@friend-requests/get');
export const getFriendRequestsSuccess = createAction<FriendRequest[]>('@friend-requests/get/success');
export const getFriendRequestsError = createAction<AppError | null>('@friend-requests/get/error');

export const addFriend = createAction<AddFriendForm>('@friend-requests/add-friend');
export const addFriendSuccess = createAction<FriendRequest>('@friend-requests/add-friend/success');
export const addFriendError = createAction<AppError | null>('@friend-requests/add-friend/error');

export const acceptFriend = createAction<string>('@friend-requests/accept');
export const acceptFriendSuccess = createAction<FriendRequest>('@friend-requests/accept/success');
export const acceptFriendError = createAction<AppError | null>('@friend-requests/accept/error');

export const rejectFriend = createAction<string>('@friend-requests/reject');
export const rejectFriendSuccess = createAction<FriendRequest>('@friend-requests/reject/success');
export const rejectFriendError = createAction<AppError | null>('@friend-requests/reject/error');


export const removeOneFriendRequest = createAction<string>('@friend-requests/remove/one');

export const removeFriendRequests = createAction('@friend-requests/remove');
export const clearFriendRequestsError = createAction('@friend-requests/clear/error');