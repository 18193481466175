import React, { Component, useState } from "react";
// import { StyleSheet, View, Text, ImageBackground, TextInput, Pressable, Image } from "react-native";
import { FONT, THEME } from "../enums";
// import Svg, { Path } from "react-native-svg";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import { Country } from "../interfaces";
import { IconProvider } from "../utils";
import { countries } from "../countries";

const boxHeight = 25;
interface Props {
    lable: string,
    selected: Country,
    listLength: number,
    onClick: () => void,
}

export function CountrySeclector(props: Props) {
    const { lable, listLength, selected, onClick } = props;

    return (
        <div style={styles.container}>
            <div
                style={{
                    ...styles.innercontainer,
                    // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
                }}
                onClick={onClick}
            >
                <div style={styles.innervalue}>
                    <div style={styles.thumbnail}>
                        <img
                            src={countries[selected?.code.toLowerCase()]}
                            style={{
                                height: '100%',
                                width: '100%',
                                borderRadius: 5,
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                    <div style={{}}>
                        <CustomText customStyle={{ fontSize: 11, fontFamily: FONT.MANROPE_REGULAR }}>{selected?.name}</CustomText>
                    </div>
                </div>
                <div style={{
                    marginTop: 20
                }}>
                    <div style={styles.triangle}>
                        <IconProvider type={"down-arr"} />
                    </div>          
                 </div>

            </div>
            <div style={styles.label}>
                <CustomText customStyle={styles.labelTxt}>{lable}</CustomText>
            </div>

        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        width: '100%',
        position: 'relative',
    },
    innercontainer: {
        position: 'relative',
        height: 50,
        borderRadius: 10,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15
    },
    label: {
        position: 'absolute',
        top: -6,
        left: 20,
        backgroundColor: THEME.WHITE,
        paddingLeft: 10,
        paddingRight: 10,
    },
    labelTxt: {
        fontSize: 12,
        fontFamily: FONT.MANROPE_REGULAR
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    thumbnail: {
        position: 'relative',
        width: 35,
        height: boxHeight,
        // marginTop: 10,
        marginRight: 10,
        borderRadius: 5
    },
    triangle: {
        height: 6,
        width: 10,
        backgroundColor: "transparent",
        borderColor: "transparent",
        // transform: [
        //     {
        //         rotate: "-180.00deg"
        //     }
        // ],

    },
})