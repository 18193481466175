import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar, CustomButton } from "../components";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { FriendRequest, GlobalContactInfo } from "../interfaces";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS, } from "../variables";
import * as fromStore from "../store";
import { CustomScrollView, RefreshWithError } from "../utils";
import { checkErrorResponse, getUserName } from "../services";
import * as ContactsService from '../services/contacts.service';
import { SpinnerScreen } from "../utils/spinner.screen";
import { ContactIcon } from "../icons";
// import { TabView, TabBar, SceneMap } from 'react-native-tab-view';

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = halfSize + halfSize / 2 - 10;

export function SentRequestScreen(props: any) {
    const { history, location, match } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');
    const [onSearch, setOnSearch] = useState<boolean>(false);

    const sentRquests = useSelector((state) => fromStore.selectSentRequests(state));
    const { error, loading } = useSelector((state) => fromStore.selectSentRequestsState(state));
    // const acceptState = useSelector((state) => fromStore.selectAcceptFriendState(state));
    // const rejectState = useSelector((state) => fromStore.selectRejectFriendState(state));

    useEffect(() => {
        getContactsInfo()
        return () => { }
    }, []);

    function getContactsInfo() {
        dispatch(fromStore.getSentRequests());
    }

    function filterUsers() {
        return sentRquests
            .filter(doc => {
                return doc.recipientUser.email && doc?.recipientUser.email.toLowerCase().includes(search?.toLowerCase()) ||
                    getUserName(doc.recipientUser) && getUserName(doc.recipientUser)?.toLowerCase().replace(/ /g, '').includes(search?.toLowerCase().replace(/ /g, ''));
            })
    }




    if (sentRquests.length === 0 && loading) {
        return (
            <div style={styles.refreshError}>
                <SpinnerScreen />
            </div>
        )
    }

    if (sentRquests && sentRquests.length === 0 && !error) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={t('noNewRequests')} onRefresh={getContactsInfo} />
            </div>
        )
    }

    if (error && sentRquests && sentRquests.length === 0) {
        return (
            <div style={styles.refreshError}>
                <RefreshWithError text={checkErrorResponse(error)} onRefresh={getContactsInfo} />
            </div>
        )
    }

    return (
        <div style={styles.screenContent}>
            <div style={{ width: '100%', boxSizing: 'border-box' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                    onFocus={(event) => { console.log("on Focus"); setOnSearch(true) }}
                    onBlur={(event) => { console.log("on blur"); setOnSearch(false) }}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedPeople')}</CustomText>
            </div>
            <div style={styles.listHolder}>
                {filterUsers().map((item, index) => (
                    <SentRequestItem
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>
        </div>
    );


}



interface SentRequestItemProps {
    item: FriendRequest,
}

function SentRequestItem(props: SentRequestItemProps) {
    const {
        item,
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.contactPressable}>
            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <img
                            src={ContactIcon}
                            style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25,
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                    <div style={{ width: '75%' }}>
                        <CustomText customStyle={{ fontSize: 14 }} ellipsizeMode={"tail"} numberOfLines={2}>{getUserName(item.recipientUser)}</CustomText>
                    </div>
                </div>
                <div style={{ marginRight: 15 }}>
                    {/* <CustomText customStyle={{color: 'green'}}>{`Pending...`}</CustomText> */}
                    <CustomButton
                        customStyle={{}}
                        textStyle={{ textTransform: 'uppercase', color: '#fff' }}
                        name={t('pending')}
                        padding={10}
                        enable={false}
                        onClick={() => { }}
                    />
                </div>
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    screenContent: {
        width: '100%',
        height: '100%',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        boxSizing: 'border-box'
    },

    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        height: '85%',
        overflowY: 'auto'
    },

    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '65%',

    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 12,
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: THEME.WHITE
    }
})