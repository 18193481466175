import React, { useEffect, useState } from 'react';
import { FONT, THEME } from '../enums';
import { useTranslation } from 'react-i18next';
import {
  currentWindowHeight,
  currentWindowWidth,
  EMAIL_PATTERN,
} from '../variables';
import CustomText from '../utils/custom-text';
import { FormInput } from '../utils';
import { CustomButton, ScreenHolder } from '../components';
import { ResetPasswordForm } from '../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import * as fromStore from '../store';
import * as AuthService from '../services/auth.service';
import { extractError } from "../services";
import { LOGIN_SIGNUP_ROUTES } from '../routes';

const CONTACT_FORM = [
  // {
  //   field: 'key',
  //   label: '',
  //   secure: false,
  //   type: 'none',
  //   textContentType: 'none',
  //   placeholder: 'key',
  // },
  {
    field: 'password',
    label: '',
    secure: true,
    type: 'password',
    textContentType: 'password',
    placeholder: 'password',
  },
  {
    field: 'confirmPassword',
    label: '',
    secure: true,
    type: 'confirmPassword',
    textContentType: 'password',
    placeholder: 'confirmPassword',
  },
];

interface State {
  key: string,
  password: string,
  confirmPassword: string,
}


export function ResetPasswordScreen(props: any) {
  const { history, location, match } = props;
  const { params } = match;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState<State>({
    key: '',
    password: '',
    confirmPassword: ''
  });
  const { loading, error, country } = useSelector((state) => fromStore.selectAuthState(state));

  useEffect(() => {
    if (params && params.key) setState({ ...state, key: params.key });
    return () => { }
  }, [])

  function handleFieldChange(fieldId, value) {
    setState({ ...state, [fieldId]: value });
  }

  function _isFormValid() {
    const { key, password, confirmPassword } = state;
    if (key && key === '') return false;
    if (password && password === '') return false;
    if (password.length < 6) return false;
    if (password.length >= 15) return false;
    if (confirmPassword === '') return false;
    if (confirmPassword && confirmPassword.length < 6) return false;
    if (confirmPassword && confirmPassword.length >= 15) return false;
    if (confirmPassword && confirmPassword != password) return false;
    return true;
  }
  return (
    <div style={styles.formContainer}>
      <div style={styles.header}>
        <CustomText customStyle={styles.headerText}>{t('resetPassword')}</CustomText>
      </div>
      <div style={styles.inputHolder}>
        {CONTACT_FORM.map((doc, index) => (
          <div key={doc.field} style={styles.inputField}>
            <FormInput
              id={doc.field}
              onChange={handleFieldChange}
              secure={doc.secure}
              placeholder={t(doc.placeholder)}
              type={doc.textContentType}
              value={state[doc.field]}
              label={doc.label}
              password={state.password}
            />
          </div>
        ))}
      </div>
      <div style={styles.action}>
        <div style={styles.btn}>
          <CustomButton
            textStyle={{ textTransform: 'uppercase' }}
            name={t('reset')}
            enable={_isFormValid()}
            loading={loading}
            padding={10}
            onClick={() => changeUserPassword()}
          />
        </div>
      </div>
    </div>
  );

  async function changeUserPassword() {
    try {
      const credentidals: ResetPasswordForm = {
        key: state.key,
        newPassword: state.confirmPassword
      }
      dispatch(fromStore.changeUserPassword(credentidals))
      const response = await AuthService.changeUserPassword(credentidals);
      dispatch(fromStore.changeUserPasswordSuccess());
      dispatch(fromStore.toastMessage(t('passwordChangedSuccessfully')));
      history.push(LOGIN_SIGNUP_ROUTES.LOGIN);
    } catch (error: any) {
      dispatch(fromStore.changeUserPasswordError(extractError(error, LOGIN_SIGNUP_ROUTES.RESET_PASSWORD)))
    }
  }
}

const styles: { [key: string]: React.CSSProperties } = ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: THEME.WHITE
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10
  },
  headerText: {
    fontSize: 17,
    color: THEME.BLACK,
  },
  inputHolder: {
    marginTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
  },
  btn: {
    width: `${(100 / 2) - 5}%`,
    height: 40,
  },
  inputField: {
    marginBottom: 10,
  },
});
