import * as fromActions from '../actions/comments.actions';
import { AppError, SurveyCategory, SurveyComment } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<SurveyComment> {
    loading: boolean,
    postLoading: boolean,
    postError?: AppError | null,
    error?: AppError | null,
    delete: {
        loading: boolean,
        error?: AppError | null,
        id: string | null,
    },
}

const commentsAdapter: EntityAdapter<SurveyComment> = createEntityAdapter<SurveyComment>({
    selectId: (doc) => doc.id
})

const initialState: State = commentsAdapter.getInitialState({
    loading: false,
    postLoading: false,
    delete: {
        loading: false,
        id: null,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getComments, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getCommentsSuccess, (state, action) => commentsAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.getCommentsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.postComment, (state, action) => ({ ...state, postLoading: true, postError: null }))
        .addCase(fromActions.postCommentSuccess, (state, action) => {
            const { ids, entities } = state;
            const newIds: any[] = [...ids];
            newIds.unshift(action.payload.id);
            return {
                ...state,
                postLoading: false,
                ids: newIds,
                entities: { ...entities, [action.payload.id]: action.payload },
            }
        })
        .addCase(fromActions.postCommentError, (state, action) => ({ ...state, postError: action.payload, postLoading: false }))

        .addCase(fromActions.deleteComment, (state, action) => ({ ...state, delete: { ...state.delete, id: action.payload, loading: true, error: null } }))
        .addCase(fromActions.deleteCommentSuccess, (state, action) => commentsAdapter.removeOne({ 
            ...state, 
            // countAll: state.countAll - 1, 
            delete: { ...state.delete, id: null, loading: false, } 
        }, action.payload))
        .addCase(fromActions.deleteCommentError, (state, action) => ({ ...state, delete: { ...state.delete, loading: false, error: action.payload } }))

        .addCase(fromActions.removeComments, (state, action) => commentsAdapter.removeAll({ ...state, error: null, postError: null }))
        .addCase(fromActions.clearCommentsError, (state, action) => ({ ...state, error: null, postError: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = commentsAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;