import { createAction } from '@reduxjs/toolkit';
import { AppError, MySurvey, SingleOptionSurveyForm } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: MySurvey[]
}

export const getMyActiveSurveys = createAction('@my-active-surveys/get');
export const getMyActiveSurveysSuccess = createAction<PageNation>('@my-active-surveys/get/success');
export const getMyActiveSurveysError = createAction<AppError | null>('@my-active-surveys/get/error');

export const getMoreMyActiveSurveys = createAction('@my-active-surveys/get/more');
export const getMoreMyActiveSurveysSuccess = createAction<PageNation>('@my-active-surveys/get/more/success');
export const getMoreMyActiveSurveysError = createAction<AppError | null>('@my-active-surveys/get/more/error');

export const postMyActiveSurvey = createAction<any>('@my-active-surveys/post');
export const postMyActiveSurveySuccess = createAction<any>('@my-active-surveys/post/success');
export const postMyActiveSurveyError = createAction<AppError | null>('@my-active-surveys/post/error');

export const updateMyActiveSurvey = createAction<any>('@my-active-surveys/update');
export const updateMyActiveSurveySuccess = createAction<any>('@my-active-surveys/update/success');
export const updateMyActiveSurveyError = createAction<AppError | null>('@my-active-surveys/update/error');

export const deleteMyActiveSurvey = createAction<any>('@my-active-surveys/delete');
export const deleteMyActiveSurveySuccess = createAction<any>('@my-active-surveys/delete/success');
export const deleteMyActiveSurveyError = createAction<AppError | null>('@my-active-surveys/delete/error');

export const removeMyActiveSurvays = createAction('@my-active-surveys/remove');
export const clearMyActiveSurveysError = createAction('@my-active-surveys/clear/error');