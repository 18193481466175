import { createSelector } from "reselect";
import { isSurveyActive } from "../../services";
import * as fromReducer from '../reducers/surveys-list.reducer';

const selectState = state => state.surveysListState;

export const selectSurveysListState = createSelector(selectState, fromReducer.selectState);
export const selectSurveysList = createSelector(
    selectState,
    fromReducer.selectAll
);



export const selectSurveysListEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);

// //Active surveys
// export const selectActiveSurveys = createSelector(
//     selectSurveysList,
//     docs => {
//         return docs.filter(doc => isSurveyActive(doc.endTime))
//     }
// );

// //Inactive surveys
// export const selectCompletedSurveys = createSelector(
//     selectSurveysList,
//     docs => {
//         return docs.filter(doc => isSurveyActive(doc.endTime) === false)
//     }
// )
