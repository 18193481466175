import React from "react";
import { useState } from "react";
// import { Image, StyleSheet, View } from "react-native";
// import Svg, { Path } from "react-native-svg";
import { FONT, QUESTION_TYPE, THEME } from "../enums";
import * as fromIcons from '../icons';
import { currentWindowWidth } from "../variables";
import { SpinnerScreen } from "./spinner.screen";
import { AiFillCaretDown, AiOutlineArrowDown, AiOutlineCaretDown, AiOutlineQuestion } from 'react-icons/ai';
import { ContactIcon } from "../icons";

interface IconProviderProps {
    type: string
}

export function IconProvider(props: IconProviderProps) {
    const { type } = props;

    switch (type) {
        case "down-arr": return <AiFillCaretDown color={"gray"} width="100%" height="100%" />;
        case "user-avatar": return <UserAvatar />;
        case "app-name-icon": return <PubRuleNameIcon />;
        case "mobile-camera": return <MobileCamera />;
        case "gallery": return <GalleryIcon />;
        case "email": return <fromIcons.EmailIcon width="100%" height="100%" />;
        case "emailId": return <fromIcons.EmailIcon width="100%" height="100%" />;
        case "currentPassword": return <fromIcons.LockIcon width="100%" height="100%" />;
        case "password": return <fromIcons.LockIcon width="100%" height="100%" />;
        case "confirmPassword": return <fromIcons.LockIcon width="100%" height="100%" />;
        case "google": return <GoogleIcon />;
        case "facebook": return <FacebookIcon />;
        case "arrow-ios-back": return <fromIcons.ArrowIOSBackIcon width="100%" height="100%" />;
        case "arrow-ios-forward": return <fromIcons.ArrowIOSForwardIcon width="100%" height="100%" />;
        case "arrow-forward": return <fromIcons.ArrowForwardIcon width="100%" height="100%" />;
        case "bell": return <fromIcons.BellIcon width="100%" height="100%" />;
        case "globe": return <fromIcons.GlobeIcon width="100%" height="100%" />;
        case "doller": return <fromIcons.DollerIcon width="100%" height="100%" />;
        case "credit-card": return <fromIcons.CreditCardIcon width="100%" height="100%" />;
        case "shield": return <fromIcons.ShieldIcon width="100%" height="100%" />;
        case "question-circle": return <fromIcons.QuestionCircleIcon width="100%" height="100%" />;
        case "logout": return <fromIcons.LogOutIcon width="100%" height="100%" />;
        case "search": return <fromIcons.SearchIcon width="100%" height="100%" />;
        case "menu": return <fromIcons.MenuIcon width="100%" height="100%" />;
        case "hamburger-menu": return <fromIcons.HamburgerMenuIcon width="100%" height="100%" />;
        case "arrow-ios-back-white": return <fromIcons.ArrowIOSBackWhiteIcon width="100%" height="100%" />;
        case "home": return <fromIcons.HomeIcon width="100%" height="100%" />;
        case "ballet-box": return <fromIcons.BalletBoxIcon width="100%" height="100%" />;
        case "notification": return <fromIcons.NotificationIcon width="100%" height="100%" />;
        case "settings": return <fromIcons.SettingsIcon width="100%" height="100%" />;
        case "file": return <fromIcons.FileIcon width="100%" height="100%" />;
        case "image": return <fromIcons.ImageIcon width="100%" height="100%" />;
        case "users": return <fromIcons.UsersIcon width="100%" height="100%" />;
        case "search-blue": return <fromIcons.SearchBlueIcon width="100%" height="100%" />;
        case "twitter": return <fromIcons.TwitterIcon width="100%" height="100%" />;
        case "google-plus": return <fromIcons.GooglePlusIcon width="100%" height="100%" />;
        case "facebook-share": return <fromIcons.FacebookShareIcon width="100%" height="100%" />;
        case "whats-app": return <fromIcons.WhatsAppIcon width="100%" height="100%" />;
        case "camera": return <fromIcons.CameraIcon width="100%" height="100%" />;
        case "user": return <fromIcons.UserIcon width="100%" height="100%" />;
        case "heart": return <fromIcons.HeartIcon width="100%" height="100%" />;
        case "inbox": return <fromIcons.InboxIcon width="100%" height="100%" />;
        case "settings-s": return <fromIcons.SettingsSIcon width="100%" height="100%" color={'#000'} />;
        case "ios-arrow-forward": return <fromIcons.IOSArrowForwardIcon width="100%" height="100%" />;
        case "star": return <fromIcons.StarIcon width="100%" height="100%" />;
        case "head-phones": return <fromIcons.HeadPhonesIcon width="100%" height="100%" />;
        case "tag": return <fromIcons.TagIcon width="100%" height="100%" />;
        case "groups": return <fromIcons.GroupsIcon width="100%" height="100%" />;
        case "notif-user": return <fromIcons.NotifUserIcon width="100%" height="100%" />;
        case "create-group": return <fromIcons.CreateGroupIcon width="100%" height="100%" />;
        case "relationship": return <fromIcons.RelationShipIcon width="100%" height="100%" />;
        case "clothes": return <fromIcons.ClothsIcon width="100%" height="100%" />;
        case "emotions": return <fromIcons.EmotionsIcon width="100%" height="100%" />;
        case "stopwatch": return <fromIcons.StopWatchIcon width="100%" height="100%" />;
        case "video": return <fromIcons.VideoIcon width="100%" height="100%" />;
        default: return <AiOutlineQuestion width="100%" height="100%" />;
    }
}

function UserAvatar() {
    return (
        <img
            src={fromIcons.UserAvatarIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}
function GoogleIcon() {
    return (
        <img
            src={fromIcons.GoogleImageIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}

function FacebookIcon() {
    return (
        <img
            src={fromIcons.FaceBookIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}

function PubRuleNameIcon() {
    return (
        <img
            src={fromIcons.AppNameLogo}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}

function MobileCamera() {
    return (
        <img
            src={fromIcons.MobileCameraIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}

function GalleryIcon() {
    return (
        <img
            src={fromIcons.GalleryIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}


export function getIconNameByQuestionType(type: string) {
    switch (type) {
        case QUESTION_TYPE.SINGLE_OPTION: return 'relationship';
        case QUESTION_TYPE.MULTIPLE_CHOICE: return 'clothes';
        case QUESTION_TYPE.SHORT_ANSWER: return 'emotions';
        default: return 'relationship';;
    }
}

interface ImageWithSpinnerProps {
    uri: string,
    customStyle: any,
    imgStyle?: any,
    resizeMode?: "fill" | "contain" | "cover" | "none" | "scale-down",
    spinnerSize?: number,
}
export function ImageWithSpinner(props: ImageWithSpinnerProps) {
    const { uri, customStyle, imgStyle, resizeMode, spinnerSize } = props;
    const [loading, setLoading] = useState<boolean>(true)

    if (!uri) {
        return (
            <div style={{
                ...{
                    width: currentWindowWidth() / 2,
                    height: 120
                },
                ...customStyle
            }}>
                <img
                    src={ContactIcon}
                    style={{
                        ...{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            objectFit: resizeMode ? resizeMode : "contain",
                           
                        }, ...imgStyle
                    }}
                    onLoadStart={onLoadStart}
                    onLoad={onLoad}
                />
                {loading && <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                }}>
                    <SpinnerScreen size={spinnerSize} backgroundColor={"transparent"} />
                </div>}
            </div>
        )
    }

    return (
        <div style={{
            ...customStyle,
            maxWidth: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
            position: 'relative'
        }}>
            <img
                src={uri}
                style={{
                    ...{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        objectFit: resizeMode ? resizeMode : 'cover',
                        boxSizing: 'border-box',
                        borderRadius:'80%'
                    },
                    ...imgStyle
                }}
                onLoadStart={onLoadStart}
                onLoad={onLoad}
            />
            {loading && <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                boxSizing: 'border-box'
            }}>
                <SpinnerScreen size={spinnerSize} backgroundColor={"transparent"} />
            </div>}
        </div>
    )

    function onLoadStart(event) {
        setLoading(true);
    }
    function onLoad(event) {
        // console.log(event);
        setLoading(false);
    }
}


const styles: { [key: string]: React.CSSProperties } = ({

});