import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, GroupUsersList, ScreenHolder } from "../components";
import { THEME } from "../enums";
import { AppError, ContactInfo, SharedUser } from "../interfaces";
import {
    ActionDialogHolder,
    ActionDialogHolderType,
    // ActionDialogHolder,
    // ActionDialogHolderType,
    ActionMessage,
    ErrorInfo,
    ErrorMessage,
    FieldForm,
    ImageWithSpinner,
    RefreshWithError,
    SpinnerInfo,
} from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import { checkErrorResponse, extractError } from "../services";
import { MAIN_APP_ROUTES } from "../routes";
import * as ContactService from '../services/contacts.service';
import * as fromStore from '../store';
// import { ModelHolder } from "../utils/model-holder";
import { SpinnerScreen } from "../utils/spinner.screen";

const actionSize = `${100 / 3}%`;
const halfSize = `${100 / 2}%`;
const contactsWindowHeight = '85%';

const groupInput = {
    field: 'name',
    label: '',
    secure: false,
    type: 'none',
    placeholder: 'typeGroupName',
    pattern: ''
}

interface State {
    id?: string,
    name: string,
    image: any,
    imagePath?: string,
    imageUrl?: string | null,
    createdByUser?: SharedUser,
    members: SharedUser[],
    questions?: [],
    createdAt?: any,
    modifiedAt?: any,
    type?: string
}

export function EditGroupScreen(props: any) {
    const { location, match, history } = props;
    const { t, i18n } = useTranslation();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const dispatch = useDispatch();
    // const [visible, setVisible] = useState(false);
    // const [addNewUser, setAddNewUser] = useState(false);
    // const [create, setCreate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const groupState = useSelector(state => fromStore.selectGroupState(state));
    const groupDeleteState = useSelector(state => fromStore.selectGroupDeleteState(state));
    // const groupsEntities = useSelector(state => fromStore.selectGroupsEntites(state));
    // const { height, width, keyboard } = useSelector(state => fromStore.selectScreenProps(state));
    const [selectedContacts, setSelectedContacts] = useState<ContactInfo[]>([]);
    const [state, setState] = useState<State>({
        name: '',
        image: null,
        imageUrl: null,
        members: []
    });
    useEffect(() => {
        // dispatch(fromStore.getContacts());
        if (groupState.response) setState({ ...state, ...groupState.response })
        return () => {

        }
    }, [groupState.response]);

    useEffect(() => {
        // console.log(match);
        if (match.params && match.params.id) getGroupInfo(match.params.id);
        return () => {
            dispatch(fromStore.removeGroupById());
        }
    }, []);

    function getGroupInfo(id: string) {
        dispatch(fromStore.getGroupById(id));
        // if (groupsEntities && groupsEntities[id]) setState({ ...state, ...groupsEntities[id] })
    }

    // const handleImageChange = (response) => {
    //     setState({
    //         ...state,
    //         image: response,
    //     });
    // }

    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }

    // function addContact(item: ContactInfo) {
    //     setSelectedContacts([...selectedContacts, item]);
    // }

    // function removeContact(item: ContactInfo) {
    //     let index = selectedContacts.indexOf(item);
    //     selectedContacts.splice(index, 1);
    //     let newList = [...selectedContacts];
    //     setSelectedContacts(newList);
    // }


    // function getUserIdArray(selectedContacts: ContactInfo[]) {
    //     return selectedContacts.map(doc => {
    //         return {
    //             id: doc.recipientUser.id
    //         }
    //     })
    // }

    // function _isFormValid() {
    //     const { name, image } = state;
    //     if (name == '') return false;
    //     if (!selectedContacts) return false;
    //     // if (nameTheSurvey == '') return false;
    //     if (selectedContacts.length === 0) return false;
    //     return true;
    // }

    if (groupState && groupState.loading) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.screenContainer}>
                    <div style={styles.screenHeader}>
                        <CustomText customStyle={styles.shdtxt}>{t('editGroup')}</CustomText>
                    </div>
                    <div style={styles.screenContent}>
                        <SpinnerScreen />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (groupState && groupState.error) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.screenContainer}>
                    <div style={styles.screenHeader}>
                        <CustomText customStyle={styles.shdtxt}>{t('editGroup')}</CustomText>
                    </div>
                    <div style={styles.screenContent}>
                        <RefreshWithError text={checkErrorResponse(groupState.error)} onRefresh={() => getGroupInfo(location.params.id)} />
                    </div>
                </div>
            </ScreenHolder>
        )
    }


    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('editGroup')}</CustomText>
                </div>
                <div style={styles.screenContent}>
                    <div style={styles.groupForm}>
                        <div
                            style={{
                                ...styles.imageInput,
                                // ...{ backgroundColor: pressed ? 'rgba(244,244,244,0.2)' : THEME.INPUT_BACKGROUND }
                            }}
                            onClick={() => {
                                //ignore
                                // setVisible(true)
                            }}
                        >
                            <GroupImage
                                image={state.image}
                                uri={state.imageUrl}
                            />
                        </div>
                        <div style={{ ...styles.nameInput, }}>
                            <FieldForm
                                key={groupInput.field}
                                id={groupInput.field}
                                onChange={handleFieldChange}
                                secure={groupInput.secure}
                                placeholder={t(groupInput.placeholder)}
                                type={groupInput.type}
                                value={state[groupInput.field]}
                                pattern={groupInput.pattern}
                                label={groupInput.label}
                                editable={false}
                                placeholderTextColor={THEME.PLACEHOLDER}
                            />
                        </div>
                    </div>
                    <div style={{ ...styles.contactsList, height: '70%' }}>
                        <GroupUsersList
                            list={state.members}
                            onAddNew={() => {
                                // setAddNewUser(true) 
                            }}
                        />
                    </div>
                    <div style={styles.action}>
                        <div style={styles.btn}>
                            {/* <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('save')}
                                enable={_isFormValid()}
                                padding={12}
                                onClick={() => { console.log(state); setCreate(true) }}
                            /> */}
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('delete')}
                                enable={true}
                                loading={groupDeleteState.loading}
                                padding={12}
                                onClick={() => { setIsDelete(true) }}
                            />
                        </div>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('cancel')}
                                enable={true}
                                padding={12}
                                backgroundColor={THEME.CANCEL}
                                onClick={() => { history.goBack() }}
                            />
                        </div>
                    </div>
                </div>

            </div>
            {isDelete && <ActionDialogHolder
                ref={actionRef}
                visible={isDelete}
                wrapperWidth={'65%'}
                onClose={onCancelDelete}
                onDissmiss={onCancelDelete}
            >
                <DeleteGroupProgress
                    id={state.id}
                    onClose={onCancelDelete}
                    onSuccess={onSuccessDelete}
                />
            </ActionDialogHolder>}

        </ScreenHolder>
    )

    function onCancelDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => setIsDelete(false))
        // setIsDelete(false);
    }

    function onSuccessDelete(id: string) {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            if (id) {
                // dispatch(fromStore.deleteGroupSuccess(id));
                dispatch(fromStore.toastMessage(t('groupRemovedSuccessfully')));
            }
            setIsDelete(false);
            history.push(MAIN_APP_ROUTES.GROUPS);
        })

    }
}

interface DeleteGroupProps {
    id?: string,
    onClose: () => void,
    onSuccess: (id: string) => void,
}
interface DeleteGroupState {
    loading: boolean,
    error: AppError | null
}

function DeleteGroupProgress(props: DeleteGroupProps) {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState<DeleteGroupState>({
        loading: false,
        error: null
    })
    if (state.loading) return <SpinnerInfo customStyle={{ height: 100 }} />;
    if (state.error) {
        return (
            <ErrorInfo
                error={state.error}
                onRefresh={onConfirmDelete}
            />
        )
    }
    return (
        <ActionMessage
            text={t('areYouSureYouWantRemove')}
            onCancel={props.onClose}
            onAccept={onConfirmDelete}
            headerText={t('attention!')}
            actions={[`${t('yes')}`, `${t('no')}`]}
        />
    )

    async function onConfirmDelete() {
        try {
            if (!props.id) return;
            setState({ ...state, loading: true, error: null });
            const response = await ContactService.deleteGroup(props.id);
            props.onSuccess(props.id);
        } catch (error: any) {
            const newError = extractError(error, MAIN_APP_ROUTES.EDIT_GROUP);
            setState({ ...state, loading: false, error: newError });
        }

    }
}


interface CreateProgressProps {
    visible: boolean,
    doc: {
        name: string,
        members: Array<{ id: string }>,
        image: any
    },
    onClick: () => void,
    onSuccess: (response: any) => void,
    onClose: () => void,
}

function GroupImage(props) {
    if (props.image) {
        return (
            <ImageWithSpinner
                uri={props.image.uri}
                resizeMode="cover"
                customStyle={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    borderRadius: 25
                }}
                imgStyle={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    borderRadius: 25
                }}
                spinnerSize={15}
            />

        )
    }

    return (
        <ImageWithSpinner
            uri={props.uri}
            resizeMode={"cover"}
            customStyle={{
                height: "100%",
                width: "100%",
                borderRadius: 25
            }}
            imgStyle={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                borderRadius: 25
            }}
            spinnerSize={15}

        />
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid',
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        boxSizing: 'border-box'
    },

    groupForm: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20, paddingBottom: 10,
    },
    imageInput: {
        backgroundColor: THEME.INPUT_BACKGROUND,
        width: 40,
        height: 40,
        borderRadius: 40,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10
    },
    nameInput: {
        width: '85%'
    },
    contactsList: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        boxSizing: 'border-box'
    },
    action: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        bottom: 15,
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: THEME.WHITE
    },
    btn: {
        width: `${(100 / 2) - 2}%`,
        height: 40
    },

    //model styles

    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
        // marginTop: 22
    },

    //Activity viewer
    modalView: {
        // margin: 20,
        width: currentWindowWidth() / 2,
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        padding: 15,
        alignItems: "center",
    },



    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },
})