export const LOGIN_SIGNUP = "/LoginSignup";

export enum LOGIN_SIGNUP_ROUTES {
    WELCOME = "/welcome",
    LOGIN = "/login",
    REGISTER = "/register",
    THANKS = "/thanks",
    FORGOT_PASSWORD = "/forgot-password",
    RESET_PASSWORD = "/reset-password",
    FORGOT_PASSWORD_MESSAGE = "/forgot-password-message",
    ACCOUNT_ACTIVATE = "/account-activate"
}