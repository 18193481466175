import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveyService from '../../services/surveys.service';
import { SurveyAnswer, SurveyComment } from '../../interfaces';
import { MAIN_APP_ROUTES } from '../../routes';

const getAnswersEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getAnswers),
    map((action: any) => action.payload),
    switchMap((payload: string) => {
        return SurveyService.getSurveyAnswers(payload)
            .then((docs : SurveyAnswer[]) => fromActions.getAnswersSuccess(docs))
            .catch(error => fromActions.getAnswersError(extractError(error)))
    })
);

const getMyAnswerEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMyAnswer),
    map((action: any) => action.payload),
    switchMap((payload: string) => {
        return SurveyService.getMySurveyAnswer(payload)
            .then((docs : SurveyAnswer) => fromActions.getMyAnswerSuccess(docs))
            .catch(error => fromActions.getMyAnswerError(extractError(error)))
    })
);

const postAnswerEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.postAnswer),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveyService.postMyAnswer(payload)
            .then(response => fromActions.postAnswerSuccess(response))
            .catch(error => fromActions.postAnswerError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

const updateAnswerEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateAnswer),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveyService.updateMyAnswer(payload)
            .then(response => fromActions.updateAnswerSuccess(response))
            .catch(error => fromActions.updateAnswerError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

export const answersEpics = combineEpics(
    getAnswersEpic,
    getMyAnswerEpic,
    postAnswerEpic,
    updateAnswerEpic
);