import * as fromActions from '../actions/global-contacts.actions';
import { AppError, GlobalContactInfo } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<GlobalContactInfo>{
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
}

const globalContactsAdapter: EntityAdapter<GlobalContactInfo> = createEntityAdapter<GlobalContactInfo>({
    selectId: (doc) => doc.id
})

const initialState: State = globalContactsAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getGlobalContacts, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getGlobalContactsSuccess, (state, action) => globalContactsAdapter.setAll({ ...state, loading: false, page: action.payload.page, countAll: action.payload.countAll }, action.payload.result))
        .addCase(fromActions.getGlobalContactsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getMoreGlobalContacts, (state, action) => ({ ...state, nextLoading: true, error: null }))
        .addCase(fromActions.getMoreGlobalContactsSuccess, (state, action) => {
            if (action.payload.result.length === 0) return ({ ...state, nextLoading: false, countAll: state.ids.length });
            return globalContactsAdapter.addMany({ ...state, nextLoading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result);
        })
        .addCase(fromActions.getMoreGlobalContactsError, (state, action) => ({ ...state, error: action.payload, nextLoading: false }))

        .addCase(fromActions.addGlobalContacts, (state, action) => globalContactsAdapter.addMany(state, action.payload))
        .addCase(fromActions.updateOneGlobalContact, (state, action) => globalContactsAdapter.upsertOne(state, action.payload))

        .addCase(fromActions.removeGlobalContacts, (state, action) => globalContactsAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearGlobalContactsError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = globalContactsAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;