import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, THEME } from "../enums";
import { ContactInfo } from "../interfaces";
import { checkErrorResponse, getUserName } from "../services";
import * as fromStore from "../store";
import { RefreshWithError } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { CURRENT_OS, currentWindowWidth } from "../variables";
import { UserContact } from "./contact";
import { SearchBar } from "./search-bar";

interface IndividualListProps {
    selected: any[],
    contactsWindowHeight?: any,
    onRefresh: () => void,
    onSelectAll: (contacts: ContactInfo[]) => void,
    onDeselectAll: () => void,
    onSelect: (doc: ContactInfo) => void,
    onDeselect: (doc: ContactInfo) => void,
}
export function IndividualList(props: IndividualListProps) {
    const { selected, onSelect, onDeselect, onSelectAll, onDeselectAll, onRefresh, contactsWindowHeight } = props;
    const [search, setSearch] = useState<string>('');
    const { t, i18n } = useTranslation();
    const contacts = useSelector((state) => fromStore.selectContacts(state));
    const { loading, error } = useSelector((state) => fromStore.selectContactsState(state));


    function filterUsers() {
        return contacts
            .filter(doc => {
                return doc?.recipientUser.email && doc?.recipientUser.email.toLowerCase().includes(search?.toLowerCase()) ||
                    getUserName(doc.recipientUser) && getUserName(doc.recipientUser)?.toLowerCase().replace(/ /g, '').includes(search?.toLowerCase().replace(/ /g, ''));
            })
    }


    return (
        <div style={{ ...styles.container }}>
            <div style={{ width: '100%' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                />
            </div>
            <div style={{ 
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10, marginLeft: 10
             }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedPeople')}</CustomText>
                <div
                    style={{}}
                    onClick={() => {
                        if (selected.length === 0) onSelectAll(contacts);
                        else onDeselectAll();
                    }}
                >

                    <CustomText
                        customStyle={{ opacity: 0.7, color: THEME.BLACK }}
                    >
                        {selected.length === 0 ? t('selectAll') : t('deselectAll')}
                    </CustomText>

                </div>
            </div>
            <div style={{ ...styles.listHolder }}>
                <ItemsList
                    contacts={contacts}
                    selected={selected}
                    loading={loading}
                    error={error}
                    onRefresh={onRefresh}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    filterUsers={filterUsers}
                />
            </div>

        </div >
    )
}


function ItemsList(props) {
    const { t, i18n } = useTranslation();
    if (props.contacts && props.contacts.length === 0 && props.loading) {
        return (
            <SpinnerScreen />
        )
    }

    if (props.contacts && props.contacts.length === 0 && !props.error) {
        return (
            <RefreshWithError text={t('emptyList')} onRefresh={props.onRefresh} />
        )
    }

    if (props.error && props.contacts && props.contacts.length === 0) {
        return (
            <RefreshWithError text={checkErrorResponse(props.error)} onRefresh={props.onRefresh} />
        )
    }

    return (
        props.filterUsers().map((item, index) => (<UserContact
            key={item.id}
            item={item}
            isSelected={props.selected.find(doc => doc.id === item.id) ? true : false}
            onSelect={props.onSelect}
            onDeselect={props.onDeselect}
        />))
    )
}




const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', boxSizing: 'border-box'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        height: '75%',
        overflowY: 'auto'
    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 12,
    },
})