// CustomText.js    
import React, { PureComponent, useEffect, useState } from 'react';
// import {
//     View,
//     StyleSheet,
//     Dimensions,
// } from 'react-native';
import { ICON_SIZE_PORTRAIT, ICON_SIZE_LANDSCAPE, currentOrientation, moderateScale } from '../variables';
import { ORIENTATION } from '../enums'
function getDefaultStyles(item) {
    if (!item) return [styles.defaultStyle];
    if (currentOrientation() === ORIENTATION.PORTRAIT) return [
        styles.defaultStyle,
        {
            ...item,
            width: item.width ? moderateScale(item.width) : moderateScale(ICON_SIZE_PORTRAIT),
            height: item.height ? moderateScale(item.height) : moderateScale(ICON_SIZE_PORTRAIT),
        },
    ];
    return [
        styles.defaultStyle,
        {
            ...item,
            width: item.width ? moderateScale(item.width) : moderateScale(ICON_SIZE_LANDSCAPE),
            height: item.height ? moderateScale(item.height) : moderateScale(ICON_SIZE_LANDSCAPE),
        },
    ];
}

export default function CustomIcon(props) {
    let checkArray = Array.isArray(props.customStyle);
    const [styleArray, setStyleArray] = useState(checkArray ? props.customStyle : getDefaultStyles(props.customStyle));
    const [orientation, setOrientation] = useState(currentOrientation());

    useEffect(() => {
        window.addEventListener('change', orientationChange);
        if (!props.customStyle) return;
        let arr = Array.isArray(props.customStyle);
        if (arr) return calculateSizeForArray(orientation);
        calculateSizeForObj(orientation);
        return () => {
            window.removeEventListener('change', orientationChange);
        }
    }, [orientation, props.customStyle]);

    function calculateSizeForArray(orientation) {
        let doc = props.customStyle.find(doc => doc && doc.fontSize && doc.fontSize !== null);
        if (orientation === ORIENTATION.PORTRAIT) {
            setStyleArray([
                styles.defaultStyle,
                props.customStyle,
                {
                    width: doc ? moderateScale(doc.width) : moderateScale(ICON_SIZE_PORTRAIT),
                    height: doc ? moderateScale(doc.height) : moderateScale(ICON_SIZE_PORTRAIT)
                },
            ]);
        }
        else {
            setStyleArray([
                styles.defaultStyle,
                props.customStyle,
                {
                    width: doc ? moderateScale(doc.width) : moderateScale(ICON_SIZE_LANDSCAPE),
                    height: doc ? moderateScale(doc.height) : moderateScale(ICON_SIZE_LANDSCAPE)
                },
            ]);
        }
    }

    function calculateSizeForObj(orientation) {
        if (orientation === ORIENTATION.PORTRAIT) {
            setStyleArray([
                styles.defaultStyle,
                props.customStyle,
                {
                    width: props.customStyle && props.customStyle.width ? moderateScale(props.customStyle.width) : moderateScale(ICON_SIZE_PORTRAIT),
                    height: props.customStyle && props.customStyle.height ? moderateScale(props.customStyle.height) : moderateScale(ICON_SIZE_PORTRAIT)
                },
            ]);
        }
        else {
            setStyleArray([
                styles.defaultStyle,
                props.customStyle,
                {
                    width: props.customStyle && props.customStyle.width ? moderateScale(props.customStyle.width) : moderateScale(ICON_SIZE_LANDSCAPE),
                    height: props.customStyle && props.customStyle.height ? moderateScale(props.customStyle.height) : moderateScale(ICON_SIZE_LANDSCAPE)
                },
            ]);
        }
    }

    function orientationChange() {
        setOrientation(currentOrientation())
    }

    return (
        <div
            style={styleArray}
        >
            {props.children}
        </div>
    );

}

const styles: { [key: string]: React.CSSProperties }  = ({
    // ... add your default style here
    defaultStyle: {
        backgroundColor: 'transparent'
    },
});