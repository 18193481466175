import { CircularProgress } from "@material-ui/core";
import React, { Component, useState } from "react";
// import { StyleSheet, View, Text, Image, TextInput, Pressable } from "react-native";
import { FONT, THEME } from "../enums";
// import Svg, { Path } from "react-native-svg";
import CustomText from "../utils/custom-text";

interface Props {
    name: string,
    enable?: boolean,
    backgroundColor?: string,
    padding?: number,
    fontSize?: number,
    fontFamily?: string,
    textStyle?: any,
    customStyle?: any,
    loading?: boolean,
    onClick: () => void;
}
export function CustomButton(props: Props) {
    const {
        name,
        enable,
        backgroundColor,
        padding,
        fontSize,
        fontFamily,
        textStyle,
        customStyle,
        loading,
        onClick
    } = props;

    return (
        <div style={styles.container}>
            <div
                onClick={() => {
                    if (loading) return;
                    if (enable) return onClick();
                }}
                className={`custom-button ${enable ? 'custom-button-enabled' : 'custom-button-disabled'}`}
                style={{
                    // ...styles.button,
                    // ...{
                    //     // backgroundColor: pressed ? 'rgba(0,0,0,0.2)' : (
                    //     //     enable ? (backgroundColor ? backgroundColor : THEME.ORANGE_RED) : 'rgba(0,0,0,0.2)'
                    //     // ),
                    //     backgroundColor: enable ? (backgroundColor ? backgroundColor : THEME.ORANGE_RED) : 'rgba(0,0,0,0.2)',
                    //     padding: padding ? padding : 15,
                    // },

                    ...enable === false && { backgroundColor: 'rgba(0,0,0,0.2)' },
                    ...enable && { backgroundColor: backgroundColor ? backgroundColor : THEME.ORANGE_RED },
                    ...customStyle,

                }}

            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap'
                }}>
                    {loading && <CircularProgress
                        style={{
                            ...{ marginRight: 10, color: THEME.ORANGE_RED },
                            ...loading && { color: THEME.WHITE }
                        }}
                        size={15}
                    />}
                    <CustomText customStyle={{
                        ...styles.text,
                        ...{
                            fontSize: fontSize ? fontSize : 12,
                            fontFamily: fontFamily ? fontFamily : FONT.MANROPE_REGULAR,
                        },
                        ...textStyle,
                    }}>
                        {name}
                    </CustomText>
                </div>
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: '100%',
        position: 'relative',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 50,

    },
    text: {
        fontSize: 12,
        // fontWeight: 'bold',
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.WHITE
    }
}