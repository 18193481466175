import { createAction } from '@reduxjs/toolkit';
import { AppError, CommentForm, SurveyCategory, SurveyComment } from '../../interfaces';

export const getComments = createAction<string>('@comments/get');
export const getCommentsSuccess = createAction<SurveyComment[]>('@comments/get/success');
export const getCommentsError = createAction<AppError | null>('@comments/get/error');

export const postComment = createAction<CommentForm>('@comments/post');
export const postCommentSuccess = createAction<SurveyComment>('@comments/post/success');
export const postCommentError = createAction<AppError | null>('@comments/post/error');

export const deleteComment = createAction<string>('@comments/delete');
export const deleteCommentSuccess = createAction<string>('@comments/delete/success');
export const deleteCommentError = createAction<AppError | null>('@comments/delete/error');

export const removeComments = createAction('@comments/remove');
export const clearCommentsError = createAction('@comments/clear/error');