import { createSelector } from "reselect";
import { isCompleted } from "../../services";
import * as fromReducer from '../reducers/active-surveys-list.reducer';

const selectState = state => state.myActiveSurveysState;

export const selectMyActiveSurveysState = createSelector(selectState, fromReducer.selectState);
export const selectMyActiveSurveys = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectMyActiveSurveysEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);