import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';

const getContactsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getContacts),
    switchMap(() => {
        return ContactsService.getContacts()
            .then(response => fromActions.getContactsSuccess(response))
            .catch(error => fromActions.getContactsError(extractError(error)))
    })
);

export const contactsEpics = combineEpics(
    getContactsEpic
);