import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CommentsSection, MultiChoiceResults, PostCommentModal, PostedToUsersDialog, ScreenHolder, ShortAnswerResults, SingleOptionResults } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { CommentForm } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, isInactiveSurvey } from "../services";
import { BottomDialogHolder, ImageWithSpinner, PreviewImage, RefreshWithError } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowWidth } from "../variables";
import * as fromStore from "../store";
import { PostedTo } from "../components/posted-to";

const actionSize = currentWindowWidth() / 3;

export function OwnerSurveyResultsScreen(props: any) {
    const { history, location, match } = props;
    const { params } = match;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [postedTo, setPostedTo] = useState<boolean>(false);
    const [commentsLength, setCommentsLength] = useState<number>(0);
    const answers = useSelector((state) => fromStore.selectAnswers(state));
    const answersState = useSelector((state) => fromStore.selectAnswersState(state));
    const comments = useSelector((state) => fromStore.selectComments(state));
    const commentsState = useSelector((state) => fromStore.selectCommentsState(state));
    const { error, loading, decision } = useSelector((state) => fromStore.selectDecisionResultsState(state));
console.log('error, loading, decisionerror, loading, decision',error, loading, decision,answers,comments);


    const [state, setState] = useState({
        comment: '',
        mediaLoding: true,
        imgPreview: false,
        imgUri: ''
    });


    useEffect(() => {
        if (params) getSurveyInfo(params);
        // console.log(isInactiveSurvey(survey.endTime));
        return () => {
            removeState();
        }
    }, [])

    useEffect(() => {
        if (comments && comments.length > 0) setCommentsLength(comments.length);
        return () => {
        }
    }, [comments])

    function getSurveyInfo(params) {
        console.log('paramsparamsparams',params);
        
        if (params && params.id) {
            dispatch(fromStore.removeDecisionResults());
            dispatch(fromStore.getDecisionResults(params.id));
            dispatch(fromStore.getAnswers(params.id));
            dispatch(fromStore.getComments(params.id));
        }
    }
    function removeState() {
        dispatch(fromStore.removeDecisionResults());
        dispatch(fromStore.removeAnswers());
        dispatch(fromStore.removeComments());
    }




    if (!decision && answers.length === 0 && (loading || answersState.loading)) {

        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('decisionsInProgress')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        
                        <SpinnerScreen />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (answersState.error) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('decisionsInProgress')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <RefreshWithError text={checkErrorResponse(answersState.error)} onRefresh={() => { getSurveyInfo(params) }} />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (error) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('decisionsInProgress')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <RefreshWithError text={checkErrorResponse(error)} onRefresh={() => { getSurveyInfo(params) }} />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    return (
        <ScreenHolder location={location} history={history} >
            
            <div style={styles.container}>
                <div style={styles.header}> 
                    <CustomText customStyle={styles.shdtxt}>{t('decisionsInProgress')}</CustomText>
                </div>
                
                <div style={styles.content}>
                    <ShowSurveyByType
                        decision={decision}
                        commentsLength={commentsLength}
                        answers={answers}
                        onImage={onImage}
                    />
                    
                    {decision && decision.sharedToUsers.length > 0 && <SurveyPostedTo
                        sharedToUsers={decision?.sharedToUsers}
                        onClick={() => { setPostedTo(true) }}
                    />}
                    <CommentsSection
                        deleteState={commentsState.delete}
                        onDelete={(doc) => { }}
                        onVideoClick={(url) => { history.push(MAIN_APP_ROUTES.VIDEO_PLAYER, { uri: url }) }}
                    />
                </div>
                
            </div>
            {state.imgPreview && <PreviewImage
                uri={state.imgUri}
                visible={state.imgPreview}
                onClose={() => { setState({ ...state, imgPreview: false }) }}
                onDissmiss={() => { setState({ ...state, imgPreview: false }) }}
            />  }
            
            {postedTo && <PostedToUsersDialog
                visible={postedTo}
                sharedUsers={decision?.sharedToUsers}
                title={t("postedTo")}
                onClose={() => { setPostedTo(false) }}
                onAccept={() => { }}
                onDissmiss={() => { setPostedTo(false) }}
            />}

        </ScreenHolder>
    );

    function onImage(uri: string) {
        setState({ ...state, imgPreview: true, imgUri: uri })
    }

    function onRefresh() {
        getSurveyInfo(params);
        dispatch(fromStore.getComments(params.id));
    }

    function submitComment(doc: CommentForm) {
        // dispatch(fromStore.postComment(doc));
        setShowToast(true);
    }

    function cancelComment() {
        setState({ ...state, comment: '' });
    }

    function onError() {
        // console.log('error')
    }

    function onSuccess() {
        // console.log('success');
        setState({ ...state, comment: '' });
        setShowToast(false);
    }
}

function ShowSurveyByType(props) {
console.log('anndnasndasndkejj2323u',props);
let vsalues_to = props?.decision && props?.decision?.type? props?.decision?.type:props?.decision?.type
    if (vsalues_to=== QUESTION_TYPE.SINGLE_OPTION) {
    
        return (
            <SingleOptionResults
                survey={props.decision}
                totalComments={props.commentsLength}
                answers={props.answers}
                onImage={props.onImage}
            />
        )
    }

    if (vsalues_to === QUESTION_TYPE.MULTIPLE_CHOICE) {
        
        return (
            <>
            <MultiChoiceResults
                survey={props.decision}
                totalComments={props.commentsLength}
                answers={props.answers}
                onImage={props.onImage}
            />
            <PostedTo survey={props.decision}  />
            </>
        )
    }

    if (vsalues_to === QUESTION_TYPE.SHORT_ANSWER) {
        return (
            
            <ShortAnswerResults
                survey={props.decision}
                totalComments={props.commentsLength}
                onImage={props.onImage}
            />
        )
    }


    return null;

}


function SurveyPostedTo(props) {
    const { t, i18n } = useTranslation();
    return (
        <div style={{ marginTop: 15, marginBottom: 15, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}>
            <CustomText customStyle={{ fontSize: 15, marginRight: 10 }}>{`${t("postedTo")}:`}</CustomText>
            <div
                style={{
                    ...{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' },
                    // ...pressed && {}
                }}
                onClick={props.onClick}
            >
                {props.sharedToUsers.slice(0, 3).map((doc, index) => (
                    <div key={doc.id} style={{ width: 35, height: 35, borderRadius: 50, marginRight: 3, borderWidth: 0.5, borderColor: THEME.DEFAULT_BORDER }}>
                        <ImageWithSpinner
                            uri={doc.profilePicUrl}
                            customStyle={{ width: '100%', height: '100%', borderRadius: 50, backgroundColor: THEME.WHITE }}
                            imgStyle={{ width: '100%', height: '100%', borderRadius: 50 }}
                            resizeMode={"cover"}
                            spinnerSize={15}
                        />
                    </div>
                ))}
                {props.sharedToUsers.length > 3 && <div style={{
                    width: 35,
                    height: 35,
                    borderRadius: 50,
                    marginRight: 5,
                    borderWidth: 0.5,
                    borderColor: THEME.DEFAULT_BORDER,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: THEME.INPUT_BACKGROUND
                }}>
                    {/* <Icon name={"more-horiz"} type={"material"} size={25} /> */}
                    <CustomText customStyle={{ fontSize: 15, color: 'green' }}>{`+${props.sharedToUsers.length - 3}`}</CustomText>
                </div>}
            </div>
        </div>
    )
}




const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowY: 'auto',
        padding: 20,
    },
})