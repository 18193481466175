import React from "react";
import '../scss/custom-toggle.scss';

export function CustomToggle(props) {

    return (
        // <label className="switch" >
        //     <input
        //         type="checkbox"
        //         checked={props.checked}
        //         onClick={props.onClick}
        //         onChange={() => { }}
        //     />
        //     <span className="slider round"></span>
        // </label>
        <div className="center">
            <input
                type="checkbox"
                name="" id=""
                checked={props.checked}
                onClick={props.onClick}
                onChange={() => { }}
            />
        </div>
    )
}