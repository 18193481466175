import { createSelector } from "reselect";
import * as fromReducer from '../reducers/device.reducer';

const selectState = state => state.deviceState;
export const selectDeviceState = createSelector(selectState, fromReducer.selectState);
export const selectUserMenu = createSelector(
    selectDeviceState,
    fromReducer.userMenu
);
export const selectScreenResolution = createSelector(
    selectDeviceState,
    fromReducer.selectScreenResolution
)