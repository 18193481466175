import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { AppNotification, TokenRegistration } from '../../interfaces';
import { selectNotifications } from '../selectors';
import * as fromServices from '../../services';
import * as fromSelectors from '../selectors';
import * as NotificationService from '../../services/notifications.service';
import { MAIN_APP_ROUTES } from '../../routes';

const getNotificationEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getNotifications),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const notificationState = fromSelectors.selectNotificationState(state);
        return NotificationService.getUserNotifications({ page: 0, limit: notificationState.limit })
        .then(response => fromActions.getNotificationsSuccess(response))
        .catch(error => fromActions.getNotificationsError(extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS)))
    })
);

const getMoreNotificationEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreNotifications),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const notificationState = fromSelectors.selectNotificationState(state);
        return NotificationService.getUserNotifications({ page: notificationState.page + 1, limit: notificationState.limit })
        .then(response => fromActions.getMoreNotificationsSuccess(response))
        .catch(error => fromActions.getMoreNotificationsError(extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS)))
    })
);

const updateNotificationEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateNotifications),
    map(((action: any) => action.payload)),
    switchMap((payload) => {
        return NotificationService.markNotificationAsRead(payload)
            .then((res) => fromActions.updateNotificationsSuccess(res))
            .catch(error => fromActions.updateNotificationsError(extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS)))
    })
);

const deleteNotificationEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.deleteNotification),
    map(((action: any) => action.payload)),
    switchMap((payload) => {
        return NotificationService.deleteNotification(payload)
            .then((res) => fromActions.deleteNotificationSuccess(payload))
            .catch(error => fromActions.deleteNotificationError(extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS)))
    })
);

const registerFCMTokenEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.registerFcmToken),
    map(((action: any) => action.payload)),
    switchMap((payload: TokenRegistration) => {
        // console.log(payload);
        return NotificationService.registerFcmToken(payload)
            .then(res => fromActions.registerFcmTokenSuccess(res))
            .catch(error => {
                const newError = extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS);
                return fromActions.registerFcmTokenError(newError)
            })

    })
);

const deregisterFCMTokenEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.deregisterFcmToken),
    map(((action: any) => action.payload)),
    switchMap((payload: TokenRegistration) => {
        return NotificationService.removeFcmToken(payload)
            .then(res => fromActions.deregisterFcmTokenSuccess())
            .catch(error => {
                const newError = extractError(error, MAIN_APP_ROUTES.NOTIFICATIONS);
                return fromActions.deregisterFcmTokenError(newError)
            })

    })
);

const deregisterFcmTokenSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.deregisterFcmTokenSuccess),
    switchMap(() => [
        fromActions.removeUser(),
        fromActions.removeAppState()
    ])
);
const deregisterFcmTokenErrorEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.deregisterFcmTokenError),
    switchMap(() => [
        fromActions.removeUser(),
        fromActions.removeAppState()
    ])
);

export const notificationEpics = combineEpics(
    getNotificationEpic,
    getMoreNotificationEpic,
    updateNotificationEpic,
    deleteNotificationEpic,
    registerFCMTokenEpic,
    deregisterFCMTokenEpic,
    deregisterFcmTokenSuccessEpic,
    deregisterFcmTokenErrorEpic
);