import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, THEME } from "../enums";
import { ContactInfo } from "../interfaces";
import { checkErrorResponse, getUserName } from "../services";
import * as fromStore from "../store";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { CURRENT_OS, currentWindowWidth } from "../variables";
import { UserFriend } from "./user-friend";
import { SearchBar } from "./search-bar";
import { RefreshWithError } from "../utils";

interface FriendsListProps {
    selected: any[],
    contactsWindowHeight: string,
    onRefresh: () => void,
    onAccept?: (doc: ContactInfo) => void,
    onReject?: (doc: ContactInfo) => void,
    onRemove: (doc: ContactInfo) => void,
}
export function FriendsList(props: FriendsListProps) {
    const { selected, onAccept, onReject, onRemove, onRefresh, contactsWindowHeight } = props;
    const [search, setSearch] = useState<string>('');
    const { t, i18n } = useTranslation();
    const contacts = useSelector((state) => fromStore.selectContacts(state));
    const { loading, error } = useSelector((state) => fromStore.selectContactsState(state));


    function filterUsers() {
        console.log('contactscontacts',contacts);
        
        return contacts
            .filter(doc => doc?.recipientUser?.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
                doc?.recipientUser?.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
                doc?.recipientUser?.email?.toLowerCase().includes(search?.toLowerCase())
            )
    }


    if (contacts.length === 0 && loading) {
        return (
            <div style={styles.container}>
                <SpinnerScreen />
            </div>
        )
    }

    if (contacts && contacts.length === 0 && !error) {
        return (
            <div style={styles.container}>
                <RefreshWithError text={t('emptyList')} onRefresh={onRefresh} />
            </div>
        )
    }


    if (error && contacts && contacts.length === 0) {
        return (
            <div style={styles.container}>
                <RefreshWithError text={checkErrorResponse(error)} onRefresh={onRefresh} />
            </div>
        )
    }

    return (
        <div style={styles.container}>
            <div style={{ width: '100%', boxSizing: 'border-box' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => {
                        console.log('akdnjnjdkfnjdsnjfsd',value,id)
                        setSearch(value)
                    }}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedPeople')}</CustomText>
            </div>
            <div style={{...styles.listHolder, height: '82%' }}>
                {filterUsers().map((item, index) => (<UserFriend
                    key={item.id}
                    item={item}
                    onAccept={() => { }}
                    onReject={() => { }}
                    onRemove={onRemove}
                />))}
            </div>

        </div >
    )
}




const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', 
        flexDirection: 'column',
         height: '100%', 
         width: '100%', 
         boxSizing: 'border-box'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
        boxSizing: 'border-box',
        overflowY: 'auto'
    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER, borderBottomStyle: 'solid'
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 12,
    },
})