
import * as fromActions from '../actions/invite-friend.actions';
import { InviteFriendInfo, AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    response?: InviteFriendInfo | null,
    loading: boolean,
    inviteKey?: string | null,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getInviteLink, (state, action) => ({ ...state, loading: true }))
        .addCase(fromActions.getInviteLinkSuccess, (state, action) => ({ ...state, loading: false, response: action.payload }))
        .addCase(fromActions.getInviteLinkError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.requestInviteLink, (state, action) => ({ ...state, loading: true }))
        .addCase(fromActions.requestInviteLinkSuccess, (state, action) => ({ ...state, loading: false, response: action.payload }))
        .addCase(fromActions.requestInviteLinkError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.acceptInviteLink, (state, action) => ({ ...state, loading: true, inviteKey: action.payload }))
        .addCase(fromActions.acceptInviteLinkSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.acceptInviteLinkError, (state, action) => ({ ...state, error: action.payload, loading: false, inviteKey: null }))

        .addCase(fromActions.removeInviteLink, (state, action) => ({ ...state, error: null, response: null, inviteKey: null }))
        .addCase(fromActions.clearInviteLinkError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
export const selectResponse = (state: State) => state.response;
