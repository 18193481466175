import React, { Component, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { Country, SurveyDuration } from "../interfaces";
import { BottomDialogHoderType, BottomDialogHolder, FieldForm } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth, CURRENT_OS } from "../variables";
import { CustomButton } from "./button";



interface Props {
    selected: any,
    loading: boolean,
    setItem: (doc: SurveyDuration) => void,
    visible: boolean,
    title: string,
    wrapperHeight: any,
    onClose: () => void,
    onDissmiss?: () => void
}

const SearchDoc = {
    field: 'search',
    label: '',
    secure: false,
    type: 'name',
    placeholder: 'Search...',
    pattern: ''

}

const list: SurveyDuration[] = require("../../assets/others/duration.json");

export function SurveyDurationList(props: Props) {
    const {
        loading,
        selected,
        setItem,
        visible,
        title,
        wrapperHeight,
        onClose,
        onDissmiss,
    } = props;
    const [search, setSearch] = useState<string>('');
    const [date, setDate] = useState(selected ? selected : new Date().toISOString());
    const dialogRef = useRef<BottomDialogHoderType>(null);

    useEffect(() => {

        return () => {

        }
    }, []);

    function handleFieldChange(fieldId, value) {
        setSearch(value);
    }

    return (
        <BottomDialogHolder
            ref={dialogRef}
            wrapperHeight={wrapperHeight}
            visible={visible}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: THEME.WHITE,
                width: '100%',
                height: '100%',
                borderTopLeftRadius: 28,
                borderTopRightRadius: 28,
            }}>
                <div style={styles.actionHeader}>
                    <CustomText customStyle={{ color: 'red', fontSize: 18 }}>{title}</CustomText>
                </div>
                <div style={styles.searchContainer}>
                    <FieldForm
                        key={SearchDoc.field}
                        id={SearchDoc.field}
                        onChange={handleFieldChange}
                        secure={SearchDoc.secure}
                        placeholder={SearchDoc.placeholder}
                        type={SearchDoc.type}
                        value={search}
                        pattern={SearchDoc.pattern}
                        label={SearchDoc.label}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box', height: '67%', overflowY: 'auto' }}>
                    {list.filter(doc => doc?.name.toLowerCase().includes(search?.toLowerCase()))
                        .map((item, index) => (
                            <ListItem
                                key={index}
                                item={item}
                                selected={selected}
                                onClick={(doc) => { onOptionChange(doc) }}
                            />
                        ))
                    }

                </div>

            </div>
        </BottomDialogHolder>
    )

    function onOptionChange(item) {
        if (dialogRef && dialogRef.current) dialogRef.current.closeAnimation(() => setItem(item));
    }
}


interface ListItemProps {
    item: any,
    selected: any,
    onClick: (doc: any) => void,
}


function ListItem(props: ListItemProps) {
    const { item, selected, onClick } = props;
    const { t, i18n } = useTranslation();
    return (
        <div
            style={{
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE },
                ...styles.countryItemContainer
            }}
            onClick={() => onClick(item)}
        >

            <div style={{
                display: 'flex',
                flexDirection: 'row',

            }}>
                <div style={{
                    ...styles.radioBtn,
                    ...{ backgroundColor: (selected?.code === item?.code) ? THEME.ORANGE_RED : THEME.WHITE }
                }}></div>
                <div style={styles.innervalue}>
                    {item?.url && <div style={styles.thumbnail}>
                        <img
                            src={item?.url}
                            style={{ width: "100%", height: "100%", borderRadius: 10, objectFit: 'cover' }}
                        />
                    </div>}
                    <CustomText customStyle={{}}>{t(item.name)}</CustomText>
                </div>
            </div>

        </div>
    )
}




const styles: { [key: string]: React.CSSProperties } = ({
    searchContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10,
    },
    actionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        marginBottom: 10
    },
    countryItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 60,

        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 1,
        borderColor: 'rgb(244,244,244)',
        borderStyle: 'solid',
        boxSizing: 'border-box'
    },
    radioBtn: {
        width: 20,
        height: 20,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 3,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid'
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    thumbnail: {
        width: 30, height: 25, marginRight: 10, borderRadius: 10
    },
    action: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
        paddingTop: 10,
        bottom: 0,
        width: '100%'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
    },

    //Date time styles

    durationContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },

    dateHolder: {
        width: '100%', display: 'flex', flexDirection: 'column'
    },

    dateInput: {
        width: '100%',
        padding: 10,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid',
        borderRadius: 10,
        marginBottom: 10
    }
})