
import * as fromActions from '../actions/decision-results.actions';
import { User, AppError, MySurvey } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    decision?: MySurvey | null,
    loading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getDecisionResults, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getDecisionResultsSuccess, (state, action) => ({ ...state, loading: false, decision: action.payload }))
        .addCase(fromActions.getDecisionResultsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.removeDecisionResults, (state, action) => ({ ...state, error: null, decision: null, loading: false }))
        .addCase(fromActions.clearDecisionResultsError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
export const selectDecisionResults = (state: State) => state.decision;
