import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { POST_OPTIONS, QUESTION_TYPE, SHARE_TYPE, THEME } from "../enums";
import { AppError, ImageResponse, MySurvey, SharedToGrupsForm, ShareQuestionByInviteForm, ShareQuestionToEveryoneForm, ShareQuestionToGroupForm, ShareQuestionToPersonForm } from "../interfaces";
import { ErrorMessage } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import * as SurveyService from '../services/surveys.service';
import * as DraftService from '../services/drafts.service';
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, extractError } from "../services";
import * as fromStore from "../store";
import { CircularProgress } from "@material-ui/core";

const optionPageHeight = '75%';

interface Props {
    uploading: boolean,
    doc: any,
    userId: string | undefined,
    shareType: string,
    selectedGroups: SharedToGrupsForm[],
    selectedContacts: Array<{ id: string }>,
    onClick: () => void,
    onSuccess: (response: any) => void,
    onClose: () => void,
    onCreateInviteLinkSuccess: (doc: MySurvey, response: any) => void,
    typeToShare:{sharePost:boolean|null, id:number|null}
}

interface ProgressState {
    status: string,
    error: AppError | null,
}

export function PostQuestionModal(props: Props) {
    const { uploading, doc, shareType, selectedGroups, selectedContacts, onClick, onSuccess, onClose, onCreateInviteLinkSuccess,typeToShare } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState<ProgressState>({
        status: '',
        error: null
    });

    useEffect(() => {
        if (uploading && doc) uploadQuestion(doc);
    }, [])

    function uploadQuestion(doc) {
        if (!doc.type) return;
        switch (doc.type) {
            case QUESTION_TYPE.SINGLE_OPTION: return handleNormalQuestion(doc);
            case QUESTION_TYPE.SHORT_ANSWER: return handleNormalQuestion(doc);
            case QUESTION_TYPE.MULTIPLE_CHOICE: return handleMultipleChoiceQuestion(doc);
        }
    }

    async function handleNormalQuestion(doc) {
        try {
            const { duration } = doc;

            // console.log(startTime.toISOString(), endTime.toISOString());
            setState({ ...state, status: 'Uploading image' });
            const ImageResponse: ImageResponse | null = doc.image ? await SurveyService.uploadQuestionImage(doc.image) : null;
            const startTime = new Date();
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + duration.code);
            setState({ ...state, status: 'Posting' });
            const data = doc.id ? {
                ...SurveyService.getUpdateQuestionObj(doc),
                imagePath: SurveyService.getImagePath(ImageResponse, doc?.imagePath),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                resultpermission: doc.resultpermission,
                inviteLink: doc.InviteLink
            } : {
                type: doc.type,
                text: doc.text,
                code: null,
                imagePath: SurveyService.getImagePath(ImageResponse, doc?.imagePath),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                category: doc.category,
                nameTheSurvey: doc.nameTheSurvey,
                options: SurveyService.getOptions(doc.type),
                resultpermission: doc.resultpermission,
                inviteLink:doc.InviteLink
            }
            console.log(data,'fianldatacheck');
            console.log('it working not',typeToShare?.sharePost==true && typeToShare?.id)
            if(typeToShare?.sharePost==true && typeToShare?.id){
                console.log('it working')
                if (shareType === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length > 0) shareToContacts(doc);
                if (shareType === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length > 0) shareToGroups(doc);
                if (shareType === POST_OPTIONS.EVERYONE) shareToEveryone(doc);
                if (shareType === POST_OPTIONS.INVITE_VIA_LINK) generateInviteLink(doc);
            }else{
                const response = doc.id ? await DraftService.updateDraftAndPostQuestion(data) : await SurveyService.postMySurvey(data);
                if (doc.id) dispatch(fromStore.removeOneDraft(doc.id));
                // setState({ ...state, status: `Success...` });
                // console.log(response);
                // console.log(
                //     (shareType === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length > 0),
                //     (shareType === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length > 0),
                //     (shareType === POST_OPTIONS.EVERYONE)
                // );
                if (shareType === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length > 0) shareToContacts(response);
                if (shareType === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length > 0) shareToGroups(response);
                if (shareType === POST_OPTIONS.EVERYONE) shareToEveryone(response);
                if (shareType === POST_OPTIONS.INVITE_VIA_LINK) generateInviteLink(response);
                // onSuccess(response);
                // dispatch(fromStore.postMySurvey(data));
            }
            


        } catch (error: any) {
            // console.log(error);
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }

    async function handleMultipleChoiceQuestion(doc) {
        const { duration, options } = doc;

        let result: any[] = [];
        let counter = 0;

        async function uploadImage(docs: any[], counter: number) {
            setState({ ...state, status: `Uploading image ${counter + 1}/${docs.length}` });
            const res: ImageResponse | null = docs[counter].image ? await SurveyService.uploadOptionImage(docs[counter].image) : null;
            result.push({
                ...docs[counter],
                imagePath: SurveyService.getImagePath(res, docs[counter].imagePath)
            });
            if (counter === docs.length - 1) return;
            counter = counter + 1;
            return uploadImage(docs, counter);
        }
        try {

            // setState({ ...state, status: 'uploading image' });
            // const response = await SurveyService.uploadQuestionImage(doc.image);
            setState({ ...state, status: 'Uploading Images' });
            const arrayOptions = Object.keys(options).map((key) => options[key]);

            // const resOptions = await uploadMultipleImages(arrayOptions);
            await uploadImage(arrayOptions, counter);
            // console.log("===>",result);

            setState({ ...state, status: `Posting...` });
            const startTime = new Date();
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + duration.code);
            // console.log(startTime.toISOString(), endTime.toISOString());
            const data = doc.id ? {
                ...SurveyService.getUpdateQuestionObj(doc),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                options: result,
                resultpermission: doc.resultpermission,
                inviteLink: doc.InviteLink
            } : {
                type: doc.type,
                text: doc.text,
                code: null,
                imagePath: null,
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                category: doc.category,
                nameTheSurvey: doc.nameTheSurvey,
                options: result,
                resultpermission: doc.resultpermission,
                inviteLink: doc.InviteLink
            }
            if(typeToShare?.sharePost==true && typeToShare?.id){
                if (shareType === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length > 0) shareToContacts(doc);
                if (shareType === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length > 0) shareToGroups(doc);
                if (shareType === POST_OPTIONS.EVERYONE) shareToEveryone(doc);
                if (shareType === POST_OPTIONS.INVITE_VIA_LINK) generateInviteLink(doc);
                // onSuccess(response);
            }else{
                const response = doc.id ? await DraftService.updateDraftAndPostQuestion(data) : await SurveyService.postMySurvey(data);
                console.log('How many time calling funcations',response);
                
                if (doc.id) dispatch(fromStore.removeOneDraft(doc.id));
                // setState({ ...state, status: `Success...` });
                if (shareType === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length > 0) shareToContacts(response);
                if (shareType === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length > 0) shareToGroups(response);
                if (shareType === POST_OPTIONS.EVERYONE) shareToEveryone(response);
                if (shareType === POST_OPTIONS.INVITE_VIA_LINK) generateInviteLink(response);
                // onSuccess(response);
            }
           






        } catch (error: any) {
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }

    async function shareToContacts(doc: MySurvey) {
        console.log('dnfdsjkfnjsdjkfns test shareToContacts',{
            type: SHARE_TYPE.PERSON,
            question: {
                id: doc.id,
                user: {
                    id: doc.user.id as string
                }
            },
            sharedTos: selectedContacts
        });
        
        try {
            const data: ShareQuestionToPersonForm = {
                type: SHARE_TYPE.PERSON,
                question: {
                    id: doc.id,
                    user: {
                        id: doc.user.id as string
                    }
                },
                sharedTos: selectedContacts
            }
           
            // console.log(data);
            const response = await SurveyService.shareMySurvey(data);
            // console.log(response);
            setState({ ...state, status: `Success...` });
            onSuccess(doc);
        } catch (error: any) {
            console.log('dsmfsdnmfnsmdknfmnsdjkrnwejnf',error);
            
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // // console.log(extError);
            setState({ ...state, error: extError });
        }

    }

    async function shareToGroups(doc: MySurvey) {
        console.log('dnfdsjkfnjsdjkfns shareToGroups 1',{
                    type: SHARE_TYPE.GROUP,
                    question: {
                        id: doc.id,
                        user: {
                            id: doc.user.id as string
                        }
                    },
                    sharedToGroups: selectedGroups
                });
        
        try {
            const data: ShareQuestionToGroupForm = {
                type: SHARE_TYPE.GROUP,
                question: {
                    id: doc.id,
                    user: {
                        id: doc.user.id as string
                    }
                },
                sharedToGroups: selectedGroups
            }
            // console.log(data);
            const response = await SurveyService.shareMySurvey(data);
            // console.log(response);
            setState({ ...state, status: `Success...` });
            onSuccess(doc);
        } catch (error: any) {
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }

    }

    async function shareToEveryone(doc: MySurvey) {
        console.log('dnfdsjkfnjsdjkfns test 2,shareToEveryone',{
                    type: SHARE_TYPE.EVERYONE,
                    question: {
                        id: doc.id,
                        user: {
                            id: doc.user.id as string
                        }
                    },
                    sharedTos: selectedContacts,
                    sharedToGroups: selectedGroups,
                });
        try {
            const data: ShareQuestionToEveryoneForm = {
                type: SHARE_TYPE.EVERYONE,
                question: {
                    id: doc.id,
                    user: {
                        id: doc.user.id as string
                    }
                },
                sharedTos: selectedContacts,
                sharedToGroups: selectedGroups,
            }
            console.log(data);
            const response = await SurveyService.shareMySurvey(data);
            // console.log(response);
            setState({ ...state, status: `Success...` });
            onSuccess(doc);
        } catch (error: any) {
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }

    async function generateInviteLink(doc: MySurvey) {
        console.log('dnfdsjkfnjsdjkfns test 3,generateInviteLink',{
                type: SHARE_TYPE.LINK,
                question: {
                    id: doc.id,
                    user: {
                        id: doc.user.id as string
                    }
                }
            });
        try {
            const data: ShareQuestionByInviteForm = {
                type: SHARE_TYPE.LINK,
                question: {
                    id: doc.id,
                    user: {
                        id: doc.user.id as string
                    }
                }
            }
            // console.log(data);
            setState({ ...state, status: `Creating link...` });
            const response = await SurveyService.shareMySurvey(data);
            // console.log(response);
            setState({ ...state, status: `Success...` });
            onCreateInviteLinkSuccess(doc, response);
        } catch (error: any) {
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }


    function onErrorClose() {
        setState({ ...state, error: null });
        onClose();
    }

    if (state.error) {
        return (
            <div style={styles.centeredView}>
                <div style={styles.modalView}>
                    <ErrorMessage
                        text={checkErrorResponse(state.error)}
                        onClose={onErrorClose}
                    />
                </div>
            </div>
        )
    }

    return (
        <div style={styles.centeredView}>
            <div style={{ ...styles.modalView, padding: 15 }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress size={20} style={{ marginBottom: 10 }} />
                    <CustomText customStyle={styles.modalText}>{state.status}</CustomText>
                </div>
            </div>
        </div >
    )
}





const styles: { [key: string]: React.CSSProperties } = ({

    //model styles

    centeredView: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100, 
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        height: '100%'
        // marginTop: 22
    },

    //Activity viewer
    modalView: {
        // margin: 20,
        width: `${(100 / 2)}%`,
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        // padding: 15,
        alignItems: "center",
    },



    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },
})