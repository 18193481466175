

export enum SCREEN_TITLE {
    SUBSCRIPTION = "Subscription",
    UPDATE_PROFILE = 'Update Profile',
    CHANGE_PASSWORD = 'Change Password',
    STANDARD = 'Standard',
    ABOUT_MENTORING_MINDS = 'About Mentoring Minds',
    HELP_AND_SUPPORT = 'Help & Support',
    LOG_OUT = 'Logout',

}

export enum WEB_VIEW_LINKS {
    ABOUT = 'https://www.mentoringminds.com/about/',
    SUPPORT = 'https://www.mentoringminds.com/support/',
    BLOG = 'https://www.mentoringminds.com/learn/blog/',
    TERMS_OF_USE = 'https://www.mentoringminds.com/support/topics/terms-of-use/',
    PRIVACY_POLICY = 'https://www.mentoringminds.com/support/topics/privacy-policy/'
}
