import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as DraftsService from '../../services/drafts.service';
import { Draft } from '../../interfaces';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';


const getDraftsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getDrafts),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const draftsState = fromSelectors.selectDraftsListState(state);
        return DraftsService.getDrafts({ page: 0, limit: draftsState.limit })
            .then(res => fromActions.getDraftsSuccess(res))
            .catch(error => fromActions.getDraftsError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
);

const getMoreDraftsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreDrafts),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const draftsState = fromSelectors.selectDraftsListState(state);
        return DraftsService.getDrafts({ page: draftsState.page + 1, limit: draftsState.limit })
            .then(res => fromActions.getMoreDraftsSuccess(res))
            .catch(error => fromActions.getMoreDraftsError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
);

const saveDraftEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.saveDraft),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return DraftsService.postDraft(payload)
            .then((res) => fromActions.saveDraftSuccess(res))
            .catch(error => fromActions.saveDraftError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
)

const updateDraftEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateDraft),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return DraftsService.updateDraft(payload)
            .then((res) => fromActions.updateDraftSuccess(res))
            .catch(error => fromActions.updateDraftError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
)

const deleteDraftEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteDraft),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return DraftsService.deleteDraft(payload)
            .then((res) => fromActions.deleteDraftSuccess(payload))
            .catch(error => fromActions.deleteDraftError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
)

const deleteDraftSuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteDraftSuccess),
    switchMap(() => [fromActions.toastMessage('Removed successfully')])
);

export const draftsListEpics = combineEpics(
    getDraftsEpic,
    getMoreDraftsEpic,
    saveDraftEpic,
    updateDraftEpic,
    deleteDraftEpic,
    deleteDraftSuccessEpic
);