import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FONT, THEME, WEB_URLS } from "../enums";
import { ActionDialogHolder, ActionDialogHolderType, ActionMessage, CustomToggle, ErrorPopup, IconProvider, ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";
import { DEFAULT_SAFE_AREA_STYLES, FILE_SIZE_LIMIT } from "../variables";
import { checkErrorResponse, formatBytes, getFileSize, getUserName } from "../services";
import { MAIN_APP_ROUTES } from "../routes";
import * as fromStore from "../store";
import * as AuthService from '../services/auth.service';
import { SpinnerScreen } from "../utils/spinner.screen";
import '../scss/settings.scss';

const preferences = [
    {
        icon: 'bell',
        title: 'notifications',
        field: 'enabledNotifications',
    },
    {
        icon: 'globe',
        title: 'newsLetterSubscription',
        field: 'newsletterSubscribed',
    },
    // {
    //     icon: 'doller',
    //     title: 'syncPhoneContacts',
    //     field: 'syncContacts',
    // },
];

const AccountSettings = [
    {
        icon: 'credit-card',
        title: 'editProfile',
        route: MAIN_APP_ROUTES.EDIT_PROFILE,
    },
    {
        icon: 'shield',
        title: 'contactUs',
        route: MAIN_APP_ROUTES.CONTACT_US,
    },
    {
        icon: 'question-circle',
        title: 'privacyPolicy',
        route: MAIN_APP_ROUTES.PRIVACY_POLICY,
    },
];

interface State {
    enabledNotifications: boolean,
    newsletterSubscribed: boolean,
    // syncContacts: boolean,
    image: any
}

export function SettingsScreen(props: any) {
    const { history } = props;
    const [isInitLogOut, setIsInitLogOut] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [state, setState] = useState<State>({
        enabledNotifications: true,
        newsletterSubscribed: true,
        // syncContacts: true,
        image: null
    });
    const { t, i18n } = useTranslation();
    const inputFile = useRef<HTMLInputElement>(null)
    const { user, loading } = useSelector((state) => fromStore.selectUserState(state));
    const authState = useSelector((state) => fromStore.selectAuthState(state));
    const profileState = useSelector((state) => fromStore.selectProfileState(state));
    const dispatch = useDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);

    useEffect(() => {
        if (user) {
            const formState = {
                ...state,
                enabledNotifications: user.enabledNotifications,
                newsletterSubscribed: user.newsletterSubscribed,
                // syncContacts: user,
            };
            setState(formState);
            // setPrevState(formState);
        }
        return () => {

        }

    }, [user])

    useEffect(() => {
        if (profileState.error) displayToastMessage(profileState.error);
        return () => { }
    }, [profileState.error]);


    function onChange(e) {
        if (e.target.files[0]) onMedia(e.target.files[0]);
    }

    function onAddMore() {
        if (inputFile.current) inputFile?.current.click();
    }


    return (
        <div style={{ ...DEFAULT_SAFE_AREA_STYLES, ...{ backgroundColor: THEME.WHITE, height: '100%', width: '100%' } }}>
            <input ref={inputFile} type={'file'} accept={"image/*"} style={{ display: 'none' }} onChange={onChange} />
            <div style={styles.container}>
                <div style={styles.header}>
                    <div
                        style={{
                            ...{ width: 20, height: 20, padding: 5, borderRadius: 10, cursor: 'pointer' },
                            // ...{ backgroundColor: pressed ? 'rgba(210,210,210,0.2)' : THEME.WHITE }
                        }}
                        onClick={() => history.goBack()}
                    >
                        <IconProvider type={"arrow-ios-back"} />
                    </div>
                    <CustomText customStyle={styles.headerTxt}>{t('settings')}</CustomText>
                    <div style={{ width: 24, height: 24 }}></div>
                </div>
                <div className={"settings-content"}>
                    <div style={styles.profile}>
                        <div style={styles.user}>
                            <div
                                className={"on-user-avatar"}
                                style={{
                                    ...styles.userIcon,
                                    // ...{ borderWidth: 1, borderColor: pressed ? 'green' : 'transparent' }
                                }}
                                onClick={onAddMore}
                            >
                                <UserImage
                                    url={user?.profilePicUrl}
                                    loading={profileState.imageLoading}
                                />

                                <div style={styles.active}></div>
                            </div>
                            <div style={styles.userInfo}>
                                <CustomText customStyle={styles.name}>{getUserName(user)}</CustomText>
                                <CustomText customStyle={styles.email}>{`${user?.email}`}</CustomText>
                            </div>
                        </div>
                    </div>

                    <div style={styles.panel}>
                        <div style={styles.labelHeader}>
                            <CustomText customStyle={styles.labelTxt}>{t('preferences')}</CustomText>
                        </div>
                        {preferences.map((doc, index) => (

                            <div
                                key={index}
                                style={{
                                    ...styles.prefOption,
                                    ...index !== (preferences.length - 1) ? { borderBottomWidth: 0.5, borderBottomColor: '#CCCCCC' } : {}
                                }}
                            >
                                <div style={styles.option}>
                                    <div style={styles.icon}>
                                        <IconProvider type={doc.icon} />
                                    </div>
                                    <CustomText customStyle={styles.title}>{t(doc.title)}</CustomText>
                                </div>
                                <div style={styles.toggle}>
                                    <CustomToggle
                                        checked={state[doc.field]}
                                        onClick={() => {
                                            // setState({...state, [doc.field]: !state[doc.field]})
                                            toggleSwitch(doc.field)
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ ...styles.panel, ...{ marginTop: 40 } }}>
                        <div style={styles.labelHeader}>
                            <CustomText customStyle={styles.labelTxt}>{t('accountSettings')}</CustomText>
                        </div>
                        {AccountSettings.map((doc, index) => (

                            <div
                                key={index}
                                className={"on-press-cls"}
                                style={{
                                    // ...{ backgroundColor: pressed ? THEME.WHITE : 'transparent' },
                                    ...index !== (AccountSettings.length - 1) ? { borderBottomWidth: 0.5, borderBottomColor: '#CCCCCC' } : {}
                                }}
                                onClick={() => {
                                    if (doc.route === MAIN_APP_ROUTES.PRIVACY_POLICY) history.push(doc.route, { title: doc.title, uri: WEB_URLS.PRIVACY_POLICY })
                                    else history.push(doc.route);
                                }}
                            >
                                <div style={styles.prefOption}>
                                    <div style={styles.option}>
                                        <div style={styles.icon}>
                                            <IconProvider type={doc.icon} />
                                        </div>
                                        <CustomText customStyle={styles.title}>{t(doc.title)}</CustomText>
                                    </div>
                                    <div style={styles.toggle}>
                                        <div style={styles.iconForward}>
                                            <IconProvider type={'arrow-forward'} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                    <div style={styles.logout}>
                        <div
                            onClick={() => { setIsInitLogOut(true); }}
                            style={{
                                // ...{backgroundColor: pressed ? 'rgba(0,0,0,0.2)' : THEME.ORANGE_RED},
                                ...styles.button
                            }}
                        >
                            <div style={styles.btnHolder}>
                                <div style={styles.logoutIcon}>
                                    <IconProvider type={'logout'} />
                                </div>
                                <CustomText customStyle={styles.text}>{t('logout')}</CustomText>
                            </div>


                        </div>
                    </div>
                </div>
                {isInitLogOut && <ActionDialogHolder
                    ref={actionRef}
                    visible={isInitLogOut}
                    wrapperWidth={'65%'}
                    onClose={() => { }}
                    onDissmiss={() => { }}
                >
                    <ActionMessage
                        text={t('areYouSureYouWantToLogOut')}
                        onCancel={onCancelLogOut}
                        onAccept={onLogout}
                        headerText={t('attention!')}
                        actions={[`${t('yes')}`, `${t('no')}`]}
                    />
                </ActionDialogHolder>}
            </div>

        </div>
    )

    function onCancelLogOut() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => setIsInitLogOut(false));
    }
    function onLogout() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            dispatch(fromStore.logout());
            setIsInitLogOut(false);
        })
    }

    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
    }

    async function onMedia(response: File) {
        if (response && response.size && (getFileSize(response.size) > FILE_SIZE_LIMIT)) {
            dispatch(fromStore.toastMessage(t('exceededFileSizeLimit', { size: formatBytes(response.size), limit: FILE_SIZE_LIMIT })));
            return;
        }
        else dispatch(fromStore.uploadProfileImage(response));
    }
    
    function toggleSwitch(field: string) {
        setState({ ...state, [field]: !state[field] });
        dispatch(fromStore.updateProfileSettings({
            id: user?.id as string,
            enabledNotifications: field === "enabledNotifications" ? !state[field] : state.enabledNotifications,
            newsletterSubscribed: field === "newsletterSubscribed" ? !state[field] : state.newsletterSubscribed
        }))
    }
}


function UserImage(props) {

    if (props.loading) {
        return (
            <SpinnerScreen size={20} backgroundColor={"transparent"} />
        )
    }

    if (props.url) {
        return (
            <ImageWithSpinner
                resizeMode={"cover"}
                customStyle={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",

                }}
                imgStyle={{
                    borderRadius: 30,
                }}
                uri={props.url}
                spinnerSize={20}
            />
        )
    }

    return (
        <IconProvider type={"user-avatar"} />
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '100%',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        height: 55
    },
    headerTxt: {
        fontSize: 25,
        fontFamily: FONT.MANROPE_REGULAR
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 10,
        paddingBottom: 25,
        height: '86%',
        overflowY: 'auto'
    },
    profile: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: '#F8F8F8',
        borderRadius: 8,
        width: '100%',
        boxSizing: 'border-box'
    },
    user: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box'
    },
    userIcon: {
        position: 'relative',
        width: 60,
        height: 60,
        borderRadius: 30,
        marginRight: 20,
        cursor: 'pointer',

    },
    active: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 8,
        backgroundColor: THEME.ORANGE_RED,
        bottom: 2,
        right: 10
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%'
    },
    name: {
        fontFamily: FONT.MANROPE_REGULAR, //Helvatica
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },
    email: {
        fontSize: 10, color: "#AAAAAA",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    },

    //panels

    panel: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: '#F8F8F8',
        borderRadius: 8,
        marginTop: 20
    },
    labelHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 10
    },
    labelTxt: {
        fontSize: 14,
        fontFamily: FONT.MANROPE_REGULAR
    },
    prefOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    option: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
    },
    icon: {
        width: 23,
        height: 23,
        backgroundColor: '#EEEEEE',
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 5
    },
    iconForward: {
        width: 18,
        height: 18,
        marginRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        padding: 5
    },
    title: {},
    toggle: {
        // width: 60,
        height: 30
    },
    logout: {
        width: '100%',
        position: 'relative',
        marginTop: 40
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 50,
        backgroundColor: THEME.ORANGE_RED,
        cursor: 'pointer'

    },
    btnHolder: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoutIcon: {
        width: 24,
        height: 24,
        marginRight: 10,
        padding: 2,
        backgroundColor: '#FFAD66',
        borderRadius: 5,
    },
    text: {
        fontSize: 12,
        fontWeight: 'bold',
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.WHITE
    }




})