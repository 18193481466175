import { createAction } from '@reduxjs/toolkit';
import { AppError, MySurvey, SingleOptionSurveyForm } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: MySurvey[]
}

export const getMyCompletedSurveys = createAction('@my-completed-surveys/get');
export const getMyCompletedSurveysSuccess = createAction<PageNation>('@my-completed-surveys/get/success');
export const getMyCompletedSurveysError = createAction<AppError | null>('@my-completed-surveys/get/error');

export const getMoreMyCompletedSurveys = createAction('@my-completed-surveys/get/more');
export const getMoreMyCompletedSurveysSuccess = createAction<PageNation>('@my-completed-surveys/get/more/success');
export const getMoreMyCompletedSurveysError = createAction<AppError | null>('@my-completed-surveys/get/more/error');

export const postMyCompletedSurvey = createAction<any>('@my-completed-surveys/post');
export const postMyCompletedSurveySuccess = createAction<any>('@my-completed-surveys/post/success');
export const postMyCompletedSurveyError = createAction<AppError | null>('@my-completed-surveys/post/error');

export const updateMyCompletedSurvey = createAction<any>('@my-completed-surveys/update');
export const updateMyCompletedSurveySuccess = createAction<any>('@my-completed-surveys/update/success');
export const updateMyCompletedSurveyError = createAction<AppError | null>('@my-completed-surveys/update/error');

export const deleteMyCompletedSurvey = createAction<any>('@my-completed-surveys/delete');
export const deleteMyCompletedSurveySuccess = createAction<any>('@my-completed-surveys/delete/success');
export const deleteMyCompletedSurveyError = createAction<AppError | null>('@my-completed-surveys/delete/error');

export const removeMyCompletedSurvays = createAction('@my-completed-surveys/remove');
export const clearMyCompletedSurveysError = createAction('@my-completed-surveys/clear/error');