import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';

const checkErrorEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(
        fromActions.getUserError,
        fromActions.updateProfileError,
    ),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        const { code } = payload;
        if (code == 401) return of(fromActions.sessionExpired(payload));
        return of(fromActions.normalError());
    })
);

const sessionExpiredEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.sessionExpired.type),
    switchMap(()=>[
        fromActions.logout(),
        fromActions.clearUserError()
    ])
);

const clearSessionErrorEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.clearSessionError.type),
    switchMap(()=>[
        fromActions.clearAuthError(),
    ])
);

export const errorEpics = combineEpics(checkErrorEpic, sessionExpiredEpic, clearSessionErrorEpic);