import React, { Component, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { CustomButton, ScreenHolder } from "../components";
import { FONT, THEME } from "../enums";
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES } from "../routes";
import CustomText from "../utils/custom-text";
import * as fromStore from '../store';
import { checkErrorResponse } from "../services";
import { RefreshWithError } from "../utils";
import { SpinnerScreen } from "../utils/spinner.screen";
import { useState } from "react";

export function ActivateAccountScreen(props: any) {
    const { history, match } = props;
    const { params } = match;
    const { t, i18n } = useTranslation();
    const { accLoading, accError } = useSelector((state) => fromStore.selectAuthState(state));
    const dispatch = useDispatch();

    useEffect(() => {
        if (params && params.key) activateUserAccount();
        return () => { }
    }, [])

    function activateUserAccount() {
        if (params && params.key) dispatch(fromStore.activateAccount(params.key));
    }

    if (accLoading) {
        return (
            <div style={styles.container}>
                <SpinnerScreen />
            </div>
        );
    }

    if (accError) {
        return (
            <div style={styles.container}>
                <RefreshWithError text={checkErrorResponse(accError)} onRefresh={activateUserAccount} />
            </div>
        )
    }

    return (
        <div style={styles.container}>
            {/* <div style={styles.header}>
                <CustomText customStyle={styles.headerText}>{t('thankYouFor')}</CustomText>
                <CustomText customStyle={styles.headerText}>{t('registeringOnPubrules')}</CustomText>
            </div> */}
            <div style={styles.message}>
                <CustomText customStyle={styles.msgText}>{t('accountAcivatedAknw')}</CustomText>
            </div>
            <div style={styles.action}>
                <CustomButton name={t('login')} enable={true} onClick={() => history.push(LOGIN_SIGNUP_ROUTES.LOGIN)} />
            </div>
        </div>
    )

}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        width: '100%',
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK,
        fontFamily: FONT.MANROPE_REGULAR
    },
    message: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 30,
        paddingBottom: 40,
    },
    msgText: {
        fontSize: 29,
        color: THEME.ORANGE_RED,
        fontFamily: FONT.MANROPE_REGULAR,
        textAlign: 'center',
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        // marginTop: 30,
    },
})