import { createSelector } from "reselect";
import * as fromReducer from '../reducers/invite-friend.reducer';

const selectState = state => state.inviteFriendState;
export const selectInviteFriendState = createSelector(selectState, fromReducer.selectState);
export const selectResponse = createSelector(
    selectInviteFriendState,
    fromReducer.selectResponse
);




