import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, ScreenHolder } from "../components";
import { DRAWER_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { GalleryIcon, NoImageIcon } from "../icons";
import { MultipleChoiceSurveyForm, ShortAnswerSurveyForm, SingleOptionSurveyForm } from "../interfaces";
import { getRouteNameByQuestionType } from "../services";
import { IconProvider, ImageWithSpinner, RadioButton } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";

const actionSize = currentWindowWidth() / 3;

export function PreviewQuestionScreen(props: any) {
    const { history, location } = props;
    const { type, question } = location.state;
    const { t, i18n } = useTranslation();
    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('previewQuestion')}</CustomText>
                </div>
                <div style={styles.contentContainer}>
                    <div style={styles.screenContent}>
                        {type === QUESTION_TYPE.SINGLE_OPTION && <SingleOption doc={question} />}
                        {type === QUESTION_TYPE.SHORT_ANSWER && <ShortAnswer doc={question} />}
                        {type === QUESTION_TYPE.MULTIPLE_CHOICE && <MultipleChoice doc={question} />}
                    </div>
                    <div style={styles.action}>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('cancel')}
                                enable={true}
                                padding={10}
                                backgroundColor={THEME.CANCEL}
                                onClick={() => {
                                    history.push({
                                        pathname: location.state.id ?`${getRouteNameByQuestionType(type)}/${location.state.id}` : getRouteNameByQuestionType(type),
                                        state: {
                                            previewQuestion: question
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ScreenHolder>
    )
}

/*------------- Single Option ----------------------*/

interface SingleOptionProps {
    doc: SingleOptionSurveyForm,
}
function SingleOption(props: SingleOptionProps) {
    const { doc } = props;
    const { text, image, category, nameTheSurvey, duration, imageUrl } = doc;
    const { t } = useTranslation();
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [image])

    function readImageFile() {
        if (image === null) {
            setDataUrl(null);
            return;
        }
        let file = image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
                // console.log(event.target.result)
            }

        };

        reader.readAsDataURL(file);
    }
    return (
        <div style={styles.previewContainer}>
            <div style={styles.previewImage}>
                <ImageViewer
                    dataUrl={dataUrl}
                    imageUrl={imageUrl}
                    image={image}
                />

            </div>
            <div style={styles.previewContent}>
                <div style={styles.icon}>
                    <IconProvider type={"users"} />
                </div>
                <div style={styles.questionContainer}>
                    <div style={{ paddingRight: 20, marginBottom: 15 }}>
                        <CustomText customStyle={{ fontSize: 16, color: THEME.ORANGE_RED }}>{text}</CustomText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                        <RadioButton customStyle={{ marginRight: 60 }} labelStyle={{ fontSize: 14 }} selected={false} label={"yes"} onClick={(value) => { }} />
                        <RadioButton labelStyle={{ fontSize: 14 }} selected={false} label={"no"} onClick={(value) => { }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('selectedCategory')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{category?.name}</CustomText>
                        </CustomText>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('nameTheSurvey')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{nameTheSurvey}</CustomText>
                        </CustomText>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('surveyDuration')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{duration.name}</CustomText>
                        </CustomText>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ImageViewer(props) {
    if (props.dataUrl) {
        return (
            <img
                src={props.dataUrl}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    objectFit: 'contain'
                }}
            />
        )
    }

    if (props.imageUrl) {
        return (
            <ImageWithSpinner
                resizeMode={"contain"}
                customStyle={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent"
                }}
                uri={props.imageUrl}
            />
        )
    }

    return (
        <img
            src={NoImageIcon}
            style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                objectFit: 'contain'
            }}
        />
    )
}

/*------------- Short Answer ----------------------*/

interface ShortAnswerProps {
    doc: ShortAnswerSurveyForm,
}
function ShortAnswer(props: ShortAnswerProps) {
    const { doc } = props;
    const { text, image, category, nameTheSurvey, duration, imageUrl } = doc;
    const { t } = useTranslation();
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [image])

    function readImageFile() {
        if (image === null) {
            setDataUrl(null);
            return;
        }
        let file = image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
                // console.log(event.target.result)
            }

        };

        reader.readAsDataURL(file);
    }

    return (
        <div style={styles.previewContainer}>
            <div style={styles.previewImage}>
                <ImageViewer
                    dataUrl={dataUrl}
                    imageUrl={imageUrl}
                    image={image}
                />
            </div>
            <div style={styles.previewContent}>
                <div style={styles.icon}>
                    <IconProvider type={"users"} />
                </div>
                <div style={styles.questionContainer}>
                    <div style={{ paddingRight: 20, marginBottom: 15 }}>
                        <CustomText customStyle={{ fontSize: 16, color: THEME.ORANGE_RED }}>{text}</CustomText>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('selectedCategory')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{category?.name}</CustomText>
                        </CustomText>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('nameTheSurvey')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{nameTheSurvey}</CustomText>
                        </CustomText>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                            {`${t('surveyDuration')}: `}
                            <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{duration.name}</CustomText>
                        </CustomText>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*------------- Multiple Choice ----------------------*/

interface MultipleChoiceProps {
    doc: MultipleChoiceSurveyForm,
}
function MultipleChoice(props: MultipleChoiceProps) {
    const { doc } = props;
    const { text, category, nameTheSurvey, duration } = doc;
    const [options, setOptions] = useState<Array<{ value: string, image: File | null, imageUrl: string | null }>>(doc.options ? Object.keys(doc.options).map((key) => doc.options[key]) : []);
    const { t } = useTranslation();


    return (
        <div style={styles.previewContainer}>
            <div style={styles.previewContent}>
                <div style={styles.icon}>
                    <IconProvider type={"users"} />
                </div>
                <div style={{ paddingRight: 20, marginBottom: 15 }}>
                    <CustomText customStyle={{ fontSize: 16, color: THEME.ORANGE_RED }}>{text}</CustomText>
                </div>
            </div>
            <div style={styles.questionContainer}>
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                    {
                        options && options.length > 0 ? (
                            options.map((doc, index) => (
                                <OptionInput key={index} doc={doc} index={index} />
                            ))

                        ) : null
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                        {`${t('selectedCategory')}: `}
                        <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{category?.name}</CustomText>
                    </CustomText>
                    <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                        {`${t('nameTheSurvey')}: `}
                        <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{nameTheSurvey}</CustomText>
                    </CustomText>
                    <CustomText customStyle={{ fontSize: 14, marginBottom: 15 }}>
                        {`${t('surveyDuration')}: `}
                        <CustomText customStyle={{ fontWeight: 'bold', fontSize: 14 }}>{duration.name}</CustomText>
                    </CustomText>
                </div>
            </div>
        </div>
    )
}


interface OptionInputProps {
    doc: { value: string, image: File | null, imageUrl: string | null },
    index: number
}

function OptionInput(props: OptionInputProps) {
    const { doc, index } = props;
    const [dataUrl, setDataUrl] = useState<any>();

    useEffect(() => {
        readImageFile();
    }, [doc.image])

    function readImageFile() {
        if (doc.image === null) {
            setDataUrl(null);
            return;
        }
        let file = doc.image;
        let reader = new FileReader();
        reader.onload = (event) => {
            // The file's text will be printed here
            if (event.target) {
                setDataUrl(event.target.result);
                // console.log(event.target.result)
            }

        };

        reader.readAsDataURL(file);
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 15
            }}
        >
            <div style={{}}>
                <CustomText customStyle={{ fontSize: 14, textTransform: 'capitalize' }}>{`${index + 1}. ${doc.value}`}</CustomText>
            </div>
            {doc.image && dataUrl && <div style={styles.multiTumb}>
                <img
                    src={dataUrl}
                    style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent",
                        objectFit: 'contain'
                    }}
                />
            </div>}
            {(doc.imageUrl && !doc.image) && <div style={styles.multiTumb}>
                <ImageWithSpinner
                    resizeMode={"contain"}
                    spinnerSize={10}
                    customStyle={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "transparent"
                    }}
                    uri={doc.imageUrl}
                />
            </div>}
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.BLACK,
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 25,
       
    },
    actionLeft: {
        width: 26,
        height: 26,
        padding: 5,
        borderRadius: 12,
        marginLeft: 10,

    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,

    },
    actionRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: actionSize,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '100%',
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        paddingBottom: 120
    },
    image: {
        width: actionSize,
        height: "100%",
    },
    bottomNav: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        bottom: 0,
        backgroundColor: THEME.WHITE,
        borderTopWidth: 1,
        borderTopColor: 'rgb(244,244,244)'
    },

    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },

    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        height: '77%',
        width: '100%',
        paddingBottom: 30,
        overflowY: 'auto'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },

    //preview question

    previewContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    previewImage: {
        width: '100%',
        height: 180,
        // backgroundColor: THEME.INPUT_BORDER,
        marginBottom: 20
    },

    previewContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'nowrap'
    },
    icon: {
        width: 40, height: 20, marginRight: 10, marginTop: 5
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    action: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },

    multiTumb: {
        width: 20,
        height: 30,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid'
    }

})