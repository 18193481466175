import { createSelector } from "reselect";
import * as fromReducer from '../reducers/search.reducer';

const selectState = state => state.searchState;

export const selectSearchState = createSelector(selectState, fromReducer.selectState);
export const selectSearchList = createSelector(
    selectState,
    fromReducer.selectAll
);



export const selectSearchEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);