import React from 'react';

const notifications = "notifications";
const drafts = "drafts";
const decisions = "decisions";
const idToken = "id_token";
const country = "country";
const bookmarks = 'bookmarks';
const recentlyViewed = 'recently_viewed';
const userState = 'userState';
const firebaseNotificationToken = 'firebaseToken';
const messageId = 'message-id';
const purchase = 'purchase';
const AppStorage = {

    /************Access Token****************/



    //push notifications
    setFirebaseNotificationToken(token) {
        return localStorage.setItem(firebaseNotificationToken, token);
    },

    getFirebaseNotificationToken() {
        return localStorage.getItem(firebaseNotificationToken);
    },

    removeFirebaseNotificationToken() {
        return localStorage.removeItem(firebaseNotificationToken);
    },

    //set message id
    setMessageId(id) {
        return localStorage.setItem(messageId, id);
    },

    getMessageId() {
        return localStorage.getItem(messageId);
    },

    removeMessageId() {
        return localStorage.removeItem(messageId);
    },

    //id_token
    getIdToken() {
        return localStorage.getItem(idToken);
    },

    setIdToken(token: string) {
        return localStorage.setItem(idToken, token);
    },

    removeIdToken() {
        return localStorage.removeItem(idToken);
    },

    //user country state

    getUserCountry() {
        return localStorage.getItem(country);
    },

    setUserCountry(code: string) {
        return localStorage.setItem(country, code);
    },

    removeUserCountry() {
        return localStorage.removeItem(country);
    },


    //bookmarks

    getBookmarks() {
        return localStorage.getItem(bookmarks);
    },

    setBookmarks(value: any) {
        return localStorage.setItem(bookmarks, value);
    },

    removeBookmarks() {
        return localStorage.removeItem(bookmarks)
    },

    //notifications

    getNotifications() {
        return localStorage.getItem(notifications);
    },

    setNotifications(value: any) {
        return localStorage.setItem(notifications, value);
    },

    removeNotifications() {
        return localStorage.removeItem(notifications);
    },

    //decisions

    getDecisions() {
        return localStorage.getItem(decisions);
    },

    setDecisions(value: any) {
        return localStorage.setItem(decisions, value);
    },

    removeDecisions() {
        return localStorage.removeItem(decisions);
    },

    //drafts

    getDrafts() {
        return localStorage.getItem(drafts);
    },

    setDrafts(value: any) {
        return localStorage.setItem(drafts, value);
    },

    removeDrafts() {
        return localStorage.removeItem(drafts);
    },

    //recently viewed

    getRecentlyViewed() {
        return localStorage.getItem(recentlyViewed);
    },

    setRecentlyViewed(value: any) {
        return localStorage.setItem(recentlyViewed, value);
    },

    removeRecentlyViewed() {
        return localStorage.removeItem(recentlyViewed);
    },

    //user purchase
    setPurchaseInfo(userId, data) {
        return localStorage.setItem(userId, data)
    },

    getPurchaseInfo(userId) {
        return localStorage.getItem(userId);
    },

    removePurchaseInfo(userId) {
        return localStorage.removeItem(userId)
    }


}

export default AppStorage;