import * as fromActions from '../actions/search-friend.actions';
import { AppError, GlobalContactInfo } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<GlobalContactInfo> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
}

const searchFriendAdapter: EntityAdapter<GlobalContactInfo> = createEntityAdapter<GlobalContactInfo>({
    selectId: (doc) => doc.id
})

const initialState: State = searchFriendAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.searchFriend, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.searchFriendSuccess, (state, action) => searchFriendAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.searchFriendError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.updateOneSearchFriend, (state, action) => searchFriendAdapter.upsertOne(state, action.payload))

        .addCase(fromActions.removeSearchFriend, (state, action) => searchFriendAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearSearchFriendError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = searchFriendAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;