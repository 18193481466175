import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { div, Pressable, StyleSheet, View } from "react-native";
// import { Icon } from "react-native-elements";
import { THEME } from "../enums";
import { AppError } from "../interfaces";

import { currentWindowWidth } from "../variables";
import CustomText from "./custom-text";
import { SpinnerScreen } from "./spinner.screen";
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { RefreshWithError } from "./refresh.screen";
import { checkErrorResponse } from "../services";

interface ErrorPopup {
    text: string,
    visible: boolean,
    headerText?: string,
    type?: 'error' | 'action',
    actions?: [string, string],
    loading?: boolean,
    onClose: () => void,
    onAccept?: () => void,
    onDissmiss?: () => void
}

export function ErrorPopup(props: ErrorPopup) {
    const {
        text,
        visible,
        type,
        headerText,
        actions,
        loading,
        onClose,
        onAccept,
        onDissmiss
    } = props;
    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     if (visible && doc) uploadQuestion(doc);
    // }, [visible])

    if (loading) {

        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <SpinnerInfo />
                </div>
            </div>
        )
    }
    if (type === 'action') {
        return (
            <div style={styles.modalContainer}>
                <div style={styles.container}>
                    <ActionMessage
                        text={text}
                        onCancel={onClose}
                        onAccept={onAccept}
                        headerText={headerText}
                        actions={actions}
                    />
                </div>
            </div>
        )
    }
    return (
        <div style={styles.modalContainer}>
            <div style={styles.container}>
                <ErrorMessage text={text} onClose={onClose} />
            </div>
        </div>
    )
}

export function SpinnerInfo(props) {

    return (
        <div style={{ ...styles.contentContainer, ...{ padding: 15 } }}>
            <SpinnerScreen customStyle={props.customStyle} />
        </div>
    )
}

export function ErrorInfo(props) {

    return (
        <div style={{ ...styles.contentContainer, padding: 15, height: 200 }}>
            <RefreshWithError text={checkErrorResponse(props.error)} onRefresh={props.onRefresh} />
        </div>
    )
}

export function ActionMessage(props) {
    const { text, headerText, actions, onCancel, onAccept } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={{ ...styles.contentContainer, width: '100%' }}>
            <div style={styles.header}>
                <CustomText customStyle={{ fontSize: 18, color: THEME.ORANGE_RED, fontWeight: 500 }}>{headerText}</CustomText>
            </div>
            <div style={{
                ...styles.messageContainer,
                ...{
                    borderBottomWidth: 1,
                    borderBottomColor: THEME.INPUT_BORDER,
                    borderBottomStyle: 'solid'
                }
            }}>
                <CustomText customStyle={{ ...styles.divText, ...{ fontSize: 18 } }}>{text}</CustomText>
            </div>
            <div style={styles.actionContainer}>

                <div
                    className={"active-btn"}
                    style={{
                        ...styles.actionBtn,
                        ...{ borderRightWidth: 1, borderRightColor: THEME.INPUT_BORDER, borderBottomLeftRadius: 10 },
                        // pressed &&  backgroundColor: THEME.ORANGE_RED, borderBottomLeftRadius: 10 }
                    }}
                    onClick={onAccept}
                >

                    <CustomText customStyle={{
                        ...styles.actionBtnText,
                        // ...{ color: pressed ? THEME.WHITE : THEME.BLACK }
                    }}>{actions ? actions[0] : t('ok')}</CustomText>

                </div>
                <div
                    className={"active-btn"}
                    style={{
                        ...styles.actionBtn,
                        borderBottomRightRadius: 10
                        // pressed && { backgroundColor: THEME.CANCEL, borderBottomRightRadius: 10 }
                    }}
                    onClick={onCancel}
                >

                    <CustomText customStyle={{
                        ...styles.actionBtnText,
                        // ...{ color: pressed ? THEME.WHITE : THEME.BLACK }
                    }}>{actions ? actions[1] : t('cancel')}</CustomText>

                </div>
            </div >
        </div >
    )
}

/*--------------Error ---------*/
export function ErrorMessage(props) {
    const { text, onClose } = props;
    const { t, i18n } = useTranslation();
    return (
        <div style={styles.contentContainer}>
            <div style={styles.header}>
                <CustomText customStyle={{ fontSize: 16, color: THEME.ORANGE_RED, fontWeight: 500 }}>{t("error!")}</CustomText>
            </div>
            <div style={styles.messageContainer}>
                <CustomText customStyle={styles.divText}>{text}</CustomText>
            </div>
            <div
                style={{
                    ...styles.closeIcon,
                    // ...{ backgroundColor: pressed ? THEME.INPUT_BACKGROUND : THEME.RED }
                }}
                onClick={onClose}
            >

                <AiOutlineClose size={20} color={THEME.WHITE} />

            </div>
        </div >
    )
}

interface ToastMessageProps {
    customStyle?: any,
    textStyle?: any,
    animationType?: "fade" | "slide",
    duration?: number,
    text: string,
    visible: boolean,
    position: "top" | "bottom" | "center",
    onClose: () => void
}


export function ToastMessage(props: ToastMessageProps) {
    const { customStyle, textStyle, animationType, duration, text, visible, position, onClose } = props;
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (visible) triggerTimer();

    }, [visible])

    function triggerTimer() {
        setTimeout(() => {
            onClose();
        }, duration ? duration : 3000);
    }

    return (
        <div style={styles.modalContainer}>
            <div
                style={{
                    ...styles.toastContainer,
                    ...customStyle,
                    ...position === "top" && { justifyContent: 'flex-start' },
                    ...position === "center" && { justifyContent: 'center' },
                    ...position === "bottom" && { justifyContent: 'flex-end' },
                }}
                onClick={onClose}
            >
                <div style={styles.toastContentContainer}>
                    <div style={styles.messageContainer}>
                        <CustomText customStyle={{ ...{ textAlign: 'center', color: THEME.WHITE }, textStyle }}>{text}</CustomText>
                    </div>
                </div>
            </div>
        </div >
    )
}




const styles: { [key: string]: React.CSSProperties } = ({
    modalContainer: {
        position: 'absolute', left: 0, right: 0, top: 0, bottom: 0,
        boxSizing: 'border-box'
    },
    container: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
        // marginTop: 22
    },

    contentContainer: {
        position: 'relative',
        width: '100%',
        backgroundColor: THEME.WHITE,
        borderRadius: 10,

        display: 'flex',
        flexDirection: 'column',
        minHeight: 100,
        boxSizing: 'border-box'
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid'
    },

    divText: {
        // marginBottom: 15,
        textAlign: "center"
    },

    messageContainer: {
        // paddingTop: 10, paddingBottom: 10,
        // paddingLeft: 15, paddingRight: 15,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        padding: 5,
        backgroundColor: THEME.RED,
        borderRadius: 50,
        cursor: 'pointer'
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    actionBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: `${100 / 2}%`,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 15,
        paddingBottom: 15,
        cursor: 'pointer'
    },
    actionBtnText: {
        fontWeight: 500,
        fontSize: 16
    },


    //Toast Error styles
    toastContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-end",
        alignItems: "center",
        // backgroundColor: 'rgba(0, 0, 0, 0.2)',
        paddingTop: 60, paddingLeft: 25, paddingRight: 25,
        paddingBottom: 85,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },

    toastContentContainer: {
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: 10,

        display: 'flex',
        flexDirection: 'column',
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 0
        // },
        // shadowOpacity: 0.25,
        // shadowRadius: 4,
        // elevation: 5,

    }

})