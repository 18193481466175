import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';

const getDecisionResultsEpic = (action$, state$): Observable<Action> => action$.pipe(
    ofType(fromActions.getDecisionResults),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveysService.getSurveyById(payload)
            .then(response => {
                console.log('adnkjfbkabfhjadhjfhkadbhfad',response)
             return   fromActions.getDecisionResultsSuccess(response)
            })
            .catch(error => fromActions.getDecisionResultsError(extractError(error, MAIN_APP_ROUTES.SURVEY)))
    })
);

export const decisionResultsEpics = combineEpics(
    getDecisionResultsEpic
);