import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';

const getMySurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMySurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const mySurveyState = fromSelectors.selectMySurveysState(state);
        return SurveysService.getMySurveys({ page: 0, limit: mySurveyState.limit })
            .then(response => fromActions.getMySurveysSuccess(response))
            .catch(error => fromActions.getMySurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const getMoreMySurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreMySurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const mySurveyState = fromSelectors.selectMySurveysState(state);
        return SurveysService.getMySurveys({ page: mySurveyState.page + 1, limit: mySurveyState.limit })
            .then(response => fromActions.getMoreMySurveysSuccess(response))
            .catch(error => fromActions.getMoreMySurveysError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);

const postMySurveyEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.postMySurvey.type),
    map((action: any) => action.payload),
    switchMap((payload) => {
        const question  = SurveysService.getQuestionSendFormat(payload);
        // console.log(question);
        return SurveysService.postMySurvey(question)
            .then(response => {
                // navigate(MAIN_APP_ROUTES.MY_SURVEYS);
                return fromActions.postMySurveySuccess(response);
            })
            .catch(error => fromActions.postMySurveyError(extractError(error, MAIN_APP_ROUTES.MY_SURVEYS)))
    })
);


// const uploadImageEpic = (action$, state$): Observable<Action> => action$.pipe(
//     ofType(fromActions.uploadMySurveyImage),
//     map((action: any) => action.payload),
//     switchMap(async (payload: any) => {
//         try {
//             if (payload.imageType === 'question' && payload.data.imagePath !== null) {
//                 const res = await SurveysService.uploadQuestionImage(payload.data);
//                 return 
//             }
//         } catch (error: any) {
//             return fromActions.postMySurveyError(extractError(error))
//         }
//     })
// )


const updateMySurveyEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateMySurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.updateQuestion(payload)
            .then((res) => fromActions.updateMySurveySuccess(res))
            .catch(error => fromActions.updateMySurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMySurveyEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMySurvey),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveysService.deleteQuestion(payload)
            .then((res) => fromActions.deleteMySurveySuccess(payload))
            .catch(error => fromActions.deleteMySurveyError(extractError(error, MAIN_APP_ROUTES.POST_QUESTION)))
    })
)

const deleteMySurveySuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteMySurveySuccess),
    switchMap(() => [fromActions.toastMessage('Removed successfully')])
);

export const mySurveysEpics = combineEpics(
    getMySurveysEpic,
    getMoreMySurveysEpic,
    postMySurveyEpic,
    updateMySurveyEpic,
    deleteMySurveyEpic,
    deleteMySurveySuccessEpic
);