import React from "react";
import { THEME } from "../enums";
import { GroupInfo } from "../interfaces";
import { ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";


interface GroupContactProps {
    item: GroupInfo,
    onClick: (doc: GroupInfo) => void,
}
export function GroupViewItem(props: GroupContactProps) {
    const { item, onClick } = props;
    return (
        <div
            style={{
                ...styles.contactPressable,
                // ...pressed && {backgroundColor: 'rgba(200,200,200,0.5)'}
            }}
            onClick={() => onClick(item)}
        >
            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={item.imageUrl}
                            resizeMode={"cover"}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                        />
                    </div>
                    <div style={{ width: '75%' }}>
                        <CustomText customStyle={{ fontSize: 14 }} numberOfLines={1} ellipsizeMode={"tail"}>{`${item.name}`}</CustomText>
                    </div>
                </div>
            </div>
        </div>

    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        flex: 1, flexDirection: 'column'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
    },
    contactPressable: {
        paddingTop: 10, 
        paddingBottom: 10, 
        borderBottomWidth: 1, 
        borderBottomColor: THEME.INPUT_BORDER,
        paddingLeft: 20,
        paddingRight: 20,
        // marginLeft: 10,
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 45, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 12,
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '80%',
    }
})