import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { QUESTION_TYPE, THEME } from "../enums";
import { CommentForm, MySurvey, SurveyAnswer, SurveyAnswerForm } from "../interfaces";
import { selectAnswers, selectAnswersState } from "../store/selectors";
import { FormInput, getIconNameByQuestionType, IconProvider, ImageWithSpinner, RadioButton, VideoInput } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import { CustomButton } from "./button";
import { ProgressBar } from './progress-bar';
import { CommentsSection, SurveyCommentItem } from "./survey-comment";
import { SurveyTimer } from "./survey-timer";
import * as fromStore from "../store";
import { getCustomDateFormat, isSurveyActive } from "../services";
import { CommentFormScreen } from "./comment-form";
import { AiOutlineBook } from "react-icons/ai";

const InputDoc = {
    field: 'comment',
    label: '',
    required: true,
    secure: false,
    type: 'none',
    textContentType: 'none',
    placeholder: 'typeYourComment',
    multiline: true,
    numberOfLines: 2,
    backgroundColor: THEME.INPUT_BACKGROUND

};


interface Props {
    survey: MySurvey,
    totalComments: number,
    state: any,
    onImage: (uri: string) => void,
    onStateChange: (state: any) => void,
    onSubmitAnswer: (doc: SurveyAnswerForm) => void,
}

export function SingleOptionSurvey(props: Props) {
    const {
        state,
        survey,
        totalComments,
        onImage,
        onStateChange,
        onSubmitAnswer,
    } = props;

    const { t, i18n } = useTranslation();
    const { type, id, category } = survey;

    return (
        <div style={styles.container}>
            {survey?.imageUrl && <div
                style={{
                    ...styles.prevContainer,
                    // ...pressed && { opacity: 0.6}
                }}
                onClick={() => onImage(survey?.imageUrl)}
            >
                
                <ImageWithSpinner
                    customStyle={styles.prevImage}
                    uri={survey?.imageUrl}
                    resizeMode={"contain"}
                    spinnerSize={30}
                />
            </div>}
            <div style={styles.surveyInfo}>
                <div style={styles.surveyIcon}>
                <ImageWithSpinner
                        resizeMode={"contain"}
                        customStyle={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "transparent",
                            borderRadius:"50%"
                        }}
                        uri={'https://mobile.pubrule.com/favicon.ico'}
                        spinnerSize={20}
                    /> 
                </div>
                <div style={styles.surveyData}>
                    <CustomText customStyle={styles.question}>{survey.text}</CustomText>
                    <div style={styles.contentInfo}>
                        <CustomText customStyle={styles.timeStamp}>{survey.startTime ? getCustomDateFormat(survey.startTime) : ''}</CustomText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {/* <AiOutlineBook style={{ marginRight: 10 }} size={12}/> */}
                            <CustomText customStyle={styles.comments}>{`${totalComments} ${t('comments')}`}</CustomText>
                        </div>
                    </div>
                    <div style={styles.surveyOptionsStatus}>
                        {survey.options.map((doc, index) => (
                            <div key={doc.id} style={{ marginBottom: 10 }}>
                                <RadioButton
                                    labelStyle={{ fontSize: 14 }}
                                    selected={isOptionSelected(doc.id)}
                                    label={doc.value}
                                    onClick={(value) => {
                                        if (!isSurveyActive(survey.endTime)) return;
                                        onStateChange({ ...state, selectedOption: value ? doc.id : '' });
                                        onSubmitAnswer({
                                            question: { id: survey.id, user: { id: survey.user.id } },
                                            option: {
                                                id: doc.id
                                            }
                                        })

                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    
                    <SurveyTimer timeStamp={survey.endTime} />
                </div>

            </div>
        </div>
    );

    function isOptionSelected(id: string) {
        if (state.selectedOption && state.selectedOption === id) return true;
        return false;
    }


}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    prevContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15
    },
    prevImage: {
        width: `${(100/2)}%`,
        height: 120
    },
    surveyInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: 25,
        marginTop: 10,
    },
    surveyIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    surveyData: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    question: {
        color: THEME.ORANGE_RED,
        fontSize: 14
        // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
    },
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    //survey options 
    surveyOptionsStatus: {
        display: 'flex',
        flexDirection: 'column',
    },

    //Comment Form
    commentForm: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100/2) - 5}%`,
        height: 40
    },

    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
    },

})