import React, { PureComponent } from "react";
import { AiOutlineReload } from "react-icons/ai";
// import {
//     Text,
//     View,
//     TouchableOpacity,
//     StyleSheet,
//     Pressable,
// } from "react-native";
// import { Icon } from "react-native-elements";
import { THEME } from "../enums";
import CustomText from '../utils/custom-text';
// import { Icon } from "react-native-elements";


interface Props {
    onRefresh?: () => void,
    text?: any,
    color?: any,
    size?: any,
    textColor?: any,
    fontSize?: any
}

const styles: { [key: string]: React.CSSProperties }  = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', 
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    iconContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        padding: 10, 
        width: 200,
        justifyContent: 'center',
        alignItems: 'center', 
    },
    errorText: {
        textAlign: 'center', color: 'rgb(144,144,144)'
    }
})

export function RefreshWithError(props: Props) {
    const { onRefresh, text, color, size, textColor, fontSize } = props;
    return (
        <div style={styles.container}>
            <div style={styles.iconContainer}>
                <div style={{ padding: 5, cursor: 'pointer' }} onClick={onRefresh}>
                    <AiOutlineReload size={size ? size : 40} color={color ? color : THEME.ORANGE_RED} />
                    {/* <Icon name={"refresh"} type={"material"} size={size ? size : 60} color={color ? color : THEME.ORANGE_RED} /> */}
                </div>
                <CustomText
                    customStyle={{
                        ...styles.errorText,
                        ...textColor ? { color: textColor } : null,
                        ...fontSize ? { fontSize } : null
                    }}
                >
                    {text}
                </CustomText>
            </div>
        </div>
    )
}