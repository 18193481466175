import { createAction } from '@reduxjs/toolkit';
import { SurveyAnswerForm, AppError, MySurvey, SurveyAnswer } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: MySurvey[]
}
export const getSurveys = createAction('@surveys/get');
export const getSurveysSuccess = createAction<PageNation>('@surveys/get/success');
export const getSurveysError = createAction<AppError | null>('@surveys/get/error');

export const getMoreSurveys = createAction('@surveys/get/more');
export const getMoreSurveysSuccess = createAction<PageNation>('@surveys/get/more/success');
export const getMoreSurveysError = createAction<AppError | null>('@surveys/get/more/error');

export const removeSurveys = createAction('@surveys/remove');
export const clearSurveysError = createAction('@surveys/clear/error');