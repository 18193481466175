import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, THEME } from "../enums";
import { GroupInfo } from "../interfaces";
import { selectGroups, selectGroupsState } from "../store/selectors";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { CURRENT_OS, currentWindowWidth } from "../variables";
import { SearchBar } from "./search-bar";
import { CustomScrollView, RefreshWithError } from "../utils";
import { MAIN_APP_ROUTES } from "../routes";
import * as fromStore from "../store";
import { GroupViewItem } from "./group-view-item";
import { checkErrorResponse } from "../services";
import { useHistory } from "react-router-dom";

interface GroupsListProps {
    contactsWindowHeight: string,
    onRefresh: () => void,
    onClick: (doc: GroupInfo) => void,
}
export function GroupsViewList(props: GroupsListProps) {
    const { onClick, onRefresh, contactsWindowHeight } = props;
    const history = useHistory();
    const [search, setSearch] = useState<string>('');
    const { t, i18n } = useTranslation();
    const groups = useSelector((state) => selectGroups(state));
    const { loading, error } = useSelector((state) => selectGroupsState(state));


    function filterUsers() {
        return groups
            .filter(doc => doc?.name.toLowerCase().includes(search?.toLowerCase()))
    }


    return (
        <div style={styles.container}>
            <div style={{ width: '100%' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchGroup")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10, marginLeft: 10
            }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedGroups')}</CustomText>
                <div
                    style={{}}
                    onClick={() => { history.push(MAIN_APP_ROUTES.CREATE_GROUP) }}
                >

                    <CustomText className={"active-text"} customStyle={{ opacity: 0.7 }}>{t('createNewGroup')}</CustomText>

                </div>
            </div>
            <div style={{ ...styles.listHolder, height: '85%' }}>
                <GroupsItemList
                    groups={groups}
                    loading={loading}
                    error={error}
                    onRefresh={onRefresh}
                    onClick={onClick}
                    filterUsers={filterUsers}
                />
            </div>

        </div >
    )
}

function GroupsItemList(props) {
    const { t, i18n } = useTranslation();

    if (props.groups.length === 0 && props.loading) {
        return (
            <SpinnerScreen />
        )
    }

    if (props.groups && props.groups.length === 0 && !props.error) {
        return (
            <RefreshWithError text={t('noGroups')} onRefresh={props.onRefresh} />
        )
    }

    if (props.error && props.groups && props.groups.length === 0) {
        return (
            <RefreshWithError text={checkErrorResponse(props.error)} onRefresh={props.onRefresh} />
        )
    }

    return (
        props.filterUsers().map((item, index) => (<GroupViewItem
                key={item.id}
                item={item}
                onClick={props.onClick}
            />)
        )
    )

}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', flexDirection: 'column', height: '100%', width: '100%', boxSizing: 'border-box'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        // marginTop: 10,
        // marginLeft: 10,
        overflowY: 'auto'
    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 12,
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '80%',
    }
})