import { createSelector } from "reselect";
import * as fromReducer from '../reducers/decision-results.reducer';

const selectState = state => state.decisionResultsState;

export const selectDecisionResultsState = createSelector(selectState, fromReducer.selectState);

export const selectDecisionResults = createSelector(
    selectState,
    fromReducer.selectDecisionResults
);