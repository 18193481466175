import { createAction } from '@reduxjs/toolkit';
import { AddFriendForm, AppError, FriendRequest, GlobalContactInfo } from '../../interfaces';


export const getSentRequests = createAction('@sent-requests/get');
export const getSentRequestsSuccess = createAction<FriendRequest[]>('@sent-requests/get/success');
export const getSentRequestsError = createAction<AppError | null>('@sent-requests/get/error');

export const removeOneSentRequest = createAction<string>('@sent-requests/remove/one');
export const addOneSentRequest = createAction<FriendRequest>('@sent-requests/add/one');

export const removeSentRequests = createAction('@sent-requests/remove');
export const clearSentRequestsError = createAction('@sent-requests/clear/error');