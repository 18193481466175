import * as fromActions from '../actions/completed-surveys-list.actions';
import { AppError, MySurvey } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<MySurvey> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
    delete: {
        loading: boolean,
        error?: AppError | null,
        id: string | null,
    },
}

const myCompletedSurveyAdapter: EntityAdapter<MySurvey> = createEntityAdapter<MySurvey>({
    selectId: (doc) => doc.id
})

const initialState: State = myCompletedSurveyAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
    delete: {
        loading: false,
        id: null,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getMyCompletedSurveys, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getMyCompletedSurveysSuccess, (state, action) => myCompletedSurveyAdapter.setAll({ ...state, loading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result))
        .addCase(fromActions.getMyCompletedSurveysError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getMoreMyCompletedSurveys, (state, action) => ({ ...state, nextLoading: true, error: null }))
        .addCase(fromActions.getMoreMyCompletedSurveysSuccess, (state, action) => {
            if (action.payload.result.length === 0) return ({ ...state, nextLoading: false, countAll: state.ids.length });
            return myCompletedSurveyAdapter.addMany({ ...state, nextLoading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result);
        })
        .addCase(fromActions.getMoreMyCompletedSurveysError, (state, action) => ({ ...state, error: action.payload, nextLoading: false }))

        .addCase(fromActions.postMyCompletedSurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.postMyCompletedSurveySuccess, (state, action) => {
            const { countAll, limit, page, ids, entities } = state;
            let newCountAll = countAll + 1;
            let newLimit = limit + 1;
            const newIds: any[] = [...ids];
            newIds.unshift(action.payload.id);
            return {
                ...state,
                ids: newIds,
                entities: { ...entities, [action.payload.id]: action.payload },
                countAll: newCountAll,
                limit: (countAll < limit && newCountAll <= limit) ? limit : newLimit,
                page: page,
            }
            // return myCompletedSurveyAdapter.addOne({ ...state, loading: false }, action.payload);
        })
        .addCase(fromActions.postMyCompletedSurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.updateMyCompletedSurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.updateMyCompletedSurveySuccess, (state, action) => myCompletedSurveyAdapter.updateOne({ ...state, loading: false }, action.payload))
        .addCase(fromActions.updateMyCompletedSurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.deleteMyCompletedSurvey, (state, action) => ({ ...state, delete: { ...state.delete, id: action.payload, loading: true, error: null } }))
        .addCase(fromActions.deleteMyCompletedSurveySuccess, (state, action) => myCompletedSurveyAdapter.removeOne({ 
            ...state, 
            countAll: state.countAll - 1, 
            delete: { ...state.delete, id: null, loading: false } 
        }, action.payload))
        .addCase(fromActions.deleteMyCompletedSurveyError, (state, action) => ({ ...state, delete: { ...state.delete, loading: false, error: action.payload } }))

        .addCase(fromActions.removeMyCompletedSurvays, (state, action) => myCompletedSurveyAdapter.removeAll({ ...state, error: null, surveyResult: null }))
        .addCase(fromActions.clearMyCompletedSurveysError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = myCompletedSurveyAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;