import React from "react";
import { useTranslation } from "react-i18next";
import { CustomButton, ScreenHolder } from "../components";
import { THEME, FONT } from "../enums";
import { LOGIN_SIGNUP_ROUTES } from "../routes";
import CustomText from "../utils/custom-text";


export function ForgotPasswordMessageScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <CustomText customStyle={styles.headerText}>{t('forgotPassword')}</CustomText>
            </div>
            <div style={styles.infoContainer}>
                <CustomText customStyle={styles.subInfoText}>{t('passwordAckInfo')}</CustomText>
            </div>
            <div style={styles.action}>
                <CustomButton
                    name={t('next')}
                    enable={true}
                    onClick={() => history.push({pathname: LOGIN_SIGNUP_ROUTES.LOGIN, state: location.state})}
                />
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        position: 'relative',
        width: '100%',
        height: '100%',

    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        boxSizing: 'border-box',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    headerText: {
        fontSize: 17,
        color: THEME.BLACK
    },
    infoContainer: {
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: "center",
        width: '100%',
        boxSizing: 'border-box',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25,
        marginBottom: 25,
    },
    subInfoText: {
        fontSize: 13,
        // textAlign: 'auto',
        // fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.BLACK,
    },
    action: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 30,
    },
})