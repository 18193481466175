import React, { useEffect, useRef } from "react";
import CustomText from "../utils/custom-text";
import { currentWindowWidth, CURRENT_OS } from "../variables";
import { SurveyComment } from "../interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectComments, selectCommentsState } from "../store/selectors";
import { SpinnerScreen } from "../utils/spinner.screen";
import { DEVICE_TYPE } from "../enums";
import * as fromStore from "../store";
import { getCustomDateFormat, getUserName } from "../services";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { DeleteIcon } from "./delete-icon";

const thumbSize = currentWindowWidth() / 2 - 50;


interface CommentsSectionProps {
    deleteState: any,
    onVideoClick: (url: string) => void,
    onDelete: (doc: SurveyComment) => void,
    enableDelete?: boolean
}

export function CommentsSection(props: CommentsSectionProps) {
    const { onVideoClick, onDelete, deleteState, enableDelete } = props;
    const dispatch = useDispatch();
    const comments = useSelector((state) => selectComments(state));
    const { loading, error } = useSelector((state) => selectCommentsState(state));


    if (loading) {
        return (
            <div style={styles.commentsSection}>
                <div style={{ height: 250 }}>
                    <SpinnerScreen />
                </div>
            </div>
        )
    }
    return (
        <div style={styles.commentsSection}>
            {comments.map((item, index) => (
                <SurveyCommentItem
                    key={item.id}
                    index={index}
                    item={item}
                    deleteState={deleteState}
                    onVideo={onVideoClick}
                    onClick={() => { }}
                    onDelete={onDelete}
                    enableDelete={enableDelete}
                />
            ))}
        </div>
    )
}




interface Props {
    index: number,
    item: SurveyComment,
    onClick: () => void,
    onVideo: (url: string) => void,
    onDelete: (doc: SurveyComment) => void,
    enableDelete?: boolean,
    deleteState: any,
}
export function SurveyCommentItem(props: Props) {
    const { item, index, onClick, onVideo, onDelete, enableDelete, deleteState } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const onLoad = (event) => { //: React.ReactEventHandler<HTMLVideoElement> | undefined
        // if(videoRef.current) videoRef.current
    }
    if (item?.mediaUrl) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderBottomWidth: 1,
                    borderBottomColor: '#E1E1E1',
                    borderBottomStyle: 'solid'
                }}
            >

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                }}>
                    <div
                        style={{
                            ...{
                                position: 'relative',
                                width: thumbSize,
                                maxHeight: 250,
                                marginRight: 20,
                                backgroundColor: 'rgba(0,0,0,0.05)',
                                cursor: 'pointer'
                            }
                        }}
                        onClick={() => onVideo(item.mediaUrl)}
                    >
                        <video
                            ref={videoRef}
                            src={item?.mediaUrl}   // Can be a URL or a local file. { uri: state.videoUrl }
                            style={{ width: '100%', height: '100%' }}
                            // fullScreen={true}
                            // ignoreSilentSwitch={"ignore"}
                            // posterResizeMode={"stretch"}
                            controls={false}
                            // resizeMode={'stretch'}
                            // paused={true}
                            onLoad={onLoad}

                        />
                        <div style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%', height: '100%',
                            top: 0, left: 0, right: 0, bottom: 0
                        }}>
                            {/* <Icon type={"antdesign"} name={"playcircleo"} size={25} color={'#fff'} /> */}
                            <AiOutlinePlayCircle size={25} color={'#fff'} />
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '54%',

                    }}>
                        <CustomText customStyle={{ fontSize: 14, marginBottom: 5 }}>{getUserName(item.user)}</CustomText>
                        <div style={{
                            marginBottom: 5,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            {item.modifiedAt && <CustomText customStyle={{ color: '#777777', fontSize: 10, }}>{getCustomDateFormat(item.modifiedAt)}</CustomText>}
                            {enableDelete && <DeleteIcon
                                customStyle={{ padding: 10, borderRadius: 50, marginLeft: 10, }}
                                state={deleteState}
                                item={item}
                                onClick={onDelete}
                            />}
                        </div>
                    </div>
                </div>
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <CustomText customStyle={{ fontSize: 12 }}>{item.text}</CustomText>

                </div>
            </div>
        )
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 15,
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: '#E1E1E1',
                borderBottomStyle: 'solid'
            }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: enableDelete ? 0 : 10,
            }}>
                <CustomText customStyle={{ fontSize: 14 }}>{getUserName(item.user)}</CustomText>
                <div style={{
                    marginBottom: enableDelete ? 0 : 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                    {item.modifiedAt && <CustomText customStyle={{ color: '#777777', fontSize: 10, }}>{getCustomDateFormat(item.modifiedAt)}</CustomText>}
                    {enableDelete && <DeleteIcon
                        customStyle={{ padding: 10, borderRadius: 50, marginLeft: 10, }}
                        state={deleteState}
                        item={item}
                        onClick={onDelete}
                    />}
                </div>
            </div>
            <div style={{}}>
                <CustomText customStyle={{ fontSize: 12 }}>{item.text}</CustomText>
            </div>
        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    submitComment: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
})