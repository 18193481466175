import { createAction } from '@reduxjs/toolkit';
import { AppError, GroupInfo } from '../../interfaces';

export const getGroups = createAction('@groups/get');
export const getGroupsSuccess = createAction<GroupInfo[]>('@groups/get/success');
export const getGroupsError = createAction<AppError | null>('@groups/get/error');

export const getGroupById = createAction<string>('@groups/get-by-id');
export const getGroupByIdSuccess = createAction<GroupInfo>('@groups/get-by-id/success');
export const getGroupByIdError = createAction<AppError | null>('@groups/get-by-id/error');

export const deleteGroup = createAction<string>('@groups/delete');
export const deleteGroupSuccess = createAction<string>('@groups/delete/success');
export const deleteGroupError = createAction<AppError | null>('@groups/delete/error');

export const addGroup = createAction<GroupInfo>('@groups/add');

export const removeGroupById = createAction('@groups/remove/by-id');
export const clearGroupByIdError = createAction('@group/clear/by-id-error');
export const removeGroups = createAction('@groups/remove');
export const clearGroupsError = createAction('@groups/clear/error');