import * as fromActions from '../actions/notification.actions';
import { AppError, AppNotification, TokenRegistrationResponse } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<AppNotification> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    fcm?: TokenRegistrationResponse,
    error?: AppError | null,
    delete: {
        loading: boolean,
        error?: AppError | null,
        id: string | null,
    },
}

const notificationAdapter: EntityAdapter<AppNotification> = createEntityAdapter<AppNotification>({
    selectId: (doc) => doc.id
})

const initialState: State = notificationAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
    delete: {
        loading: false,
        id: null,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getNotifications, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getNotificationsSuccess, (state, action) => notificationAdapter.setAll({ ...state, loading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result))
        .addCase(fromActions.getNotificationsError, (state, action) => notificationAdapter.setAll({ ...state, error: action.payload, loading: false }, []))

        .addCase(fromActions.getMoreNotifications, (state, action) => ({ ...state, nextLoading: true, error: null }))
        .addCase(fromActions.getMoreNotificationsSuccess, (state, action) => {
            if (action.payload.result.length === 0) return ({ ...state, nextLoading: false, countAll: state.ids.length  });
            return notificationAdapter.addMany({ ...state, nextLoading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result);
        })
        .addCase(fromActions.getMoreNotificationsError, (state, action) => ({ ...state, error: action.payload, nextLoading: false }))

        .addCase(fromActions.updateNotifications, (state, action) => ({ ...state, error: null }))
        .addCase(fromActions.updateNotificationsSuccess, (state, action) => notificationAdapter.upsertOne({ ...state, error: null }, action.payload))
        .addCase(fromActions.updateNotificationsError, (state, action) => ({ ...state, error: action.payload }))

        .addCase(fromActions.deleteNotification, (state, action) => ({ ...state, delete: { ...state.delete, id: action.payload, loading: true, error: null } }))
        .addCase(fromActions.deleteNotificationSuccess, (state, action) => notificationAdapter.removeOne({ 
            ...state, 
            countAll: state.countAll - 1, 
            delete: { ...state.delete, id: null, loading: false } 
        }, action.payload))
        .addCase(fromActions.deleteNotificationError, (state, action) => ({ ...state, delete: { ...state.delete, loading: false, error: action.payload } }))

        .addCase(fromActions.registerFcmToken, (state, action) => notificationAdapter.setAll({ ...state, error: null }, []))
        .addCase(fromActions.registerFcmTokenSuccess, (state, action) => ({ ...state, fcm: action.payload, error: null }))
        .addCase(fromActions.registerFcmTokenError, (state, action) => ({ ...state, error: action.payload }))

        .addCase(fromActions.deregisterFcmToken, (state, action) => notificationAdapter.setAll({ ...state, error: null }, []))
        .addCase(fromActions.deregisterFcmTokenSuccess, (state, action) => ({ ...state, fcm: undefined, error: null }))
        .addCase(fromActions.deregisterFcmTokenError, (state, action) => ({ ...state, error: action.payload, fcm: undefined, }))

        .addCase(fromActions.addNotification, (state, action) => {
            const { ids, entities, countAll } = state;
            const newIds: any[] = [...ids];
            const newCount = countAll + 1;
            newIds.unshift(action.payload.id);
            return {
                ...state,
                ids: newIds,
                entities: { ...entities, [action.payload.id]: action.payload },
                countAll: newCount,
            }
            // return notificationAdapter.upsertOne({ ...state, loading: false }, action.payload);
        })
        .addCase(fromActions.addNotifications, (state, action) => notificationAdapter.setAll({ ...state, loading: false }, action.payload))

        .addCase(fromActions.removeNotification, (state, action) => notificationAdapter.removeOne({ ...state, loading: false }, action.payload))
        .addCase(fromActions.clearNotifications, (state, action) => notificationAdapter.removeAll(state))

        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = notificationAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;

