import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable, } from 'redux-observable';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import AppStorage from '../../services/storage.service';
import * as AuthService from '../../services/auth.service';
import { Observable, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { extractError } from '../../services';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';
import { User } from '../../interfaces';

const updateProfileEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateProfile),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.updateUserProfile(payload.id, payload)
            .then(res => fromActions.updateProfileSuccess(res))
            .catch(error => fromActions.updateProfileError(extractError(error, MAIN_APP_ROUTES.EDIT_PROFILE)))
    })
);

const updateProfileSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateProfileSuccess),
    map((action: any) => action.payload),
    switchMap((payload) => [fromActions.addUser(payload)])
);

const uploadProfileImageEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.uploadProfileImage),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.uploadUserProfilePicture(payload)
            .then(res => fromActions.uploadProfileImageSuccess(res))
            .catch(error => fromActions.uploadProfileImageError(extractError(error, MAIN_APP_ROUTES.EDIT_PROFILE)))
    })
);

const uploadProfileImageSuccessEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.uploadProfileImageSuccess),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        const userState = fromSelectors.selectUserState(state);
        return [
            fromActions.updateProfile({ ...userState.user, profilePicPath: payload.path } as User),
            // fromActions.addUser({ ...userState.user, profilePicPath: payload.path, profilePicUrl: payload.signedUrl } as User)
        ]
    })
);

const updateProfileSettingsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateProfileSettings),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.updateProfileSettings(payload)
            .then(res => fromActions.updateProfileSettingsSuccess(res))
            .catch(error => fromActions.updateProfileSettingsError(extractError(error, MAIN_APP_ROUTES.EDIT_PROFILE)))
    })
);

const updateProfileSettingsSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.updateProfileSettingsSuccess),
    map((action: any) => action.payload),
    switchMap((payload) => [fromActions.addUser(payload)])
);


export const profileEpics = combineEpics(
    updateProfileEpic,
    updateProfileSuccessEpic,
    uploadProfileImageEpic,
    uploadProfileImageSuccessEpic,
    updateProfileSettingsEpic,
    updateProfileSettingsSuccessEpic
);