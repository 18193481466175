import { createAction } from '@reduxjs/toolkit';
import { AppError, ContactInfo } from '../../interfaces';

export const getContacts = createAction('@contacts/get');
export const getContactsSuccess = createAction<ContactInfo[]>('@contacts/get/success');
export const getContactsError = createAction<AppError | null>('@contacts/get/error');

export const addContact =  createAction<ContactInfo>('@contacts/add');

export const removeContacts = createAction('@contacts/remove');
export const clearContactsError = createAction('@contacts/clear/error');