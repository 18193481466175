import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';
import { MAIN_APP_ROUTES } from '../../routes';

const searchFriendEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.searchFriend),
    map((action: any) => action.payload),
    switchMap((payload: string) => {
        return ContactsService.searchAppUsers(payload)
            .then(response => fromActions.searchFriendSuccess(response))
            .catch(error => fromActions.searchFriendError(extractError(error, MAIN_APP_ROUTES.SEARCH)))
    })
);

export const searchFriendEpics = combineEpics(
    searchFriendEpic
);