import * as fromActions from '../actions/groups.actions';
import { AppError, GroupInfo } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<GroupInfo>{
    loading: boolean,
    error?: AppError | null,
    group:{
        loading: boolean,
        error?: AppError | null,
        response: GroupInfo | null
    },
    delete: {
        loading: boolean,
        error?: AppError | null,
        id: string | null,
    },
}

const groupsAdapter: EntityAdapter<GroupInfo> = createEntityAdapter<GroupInfo>({
    selectId: (doc) => doc.id
})

const initialState: State = groupsAdapter.getInitialState({
    loading: false,
    group:{
        loading: false,
        response: null,
    },
    delete: {
        loading: false,
        id: null,
    }
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getGroups, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getGroupsSuccess, (state, action) => groupsAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.getGroupsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getGroupById, (state, action) => ({ ...state, group: { ...state.group, loading: true, error: null } }))
        .addCase(fromActions.getGroupByIdSuccess, (state, action) => ({ ...state, group: { ...state.group, loading: false, response: action.payload } }))
        .addCase(fromActions.getGroupByIdError, (state, action) => ({ ...state, group: { ...state.group, loading: false, error: action.payload } }))

        .addCase(fromActions.deleteGroup, (state, action) => ({ ...state, delete: { ...state.delete, id: action.payload, loading: true, error: null } }))
        .addCase(fromActions.deleteGroupSuccess, (state, action) => groupsAdapter.removeOne({ ...state, delete: { ...state.delete, id: null, loading: false, } }, action.payload))
        .addCase(fromActions.deleteGroupError, (state, action) => ({ ...state, delete: { ...state.delete, loading: false, error: action.payload } }))

        .addCase(fromActions.addGroup, (state, action) => groupsAdapter.addOne({ ...state, loading: false }, action.payload))
        .addCase(fromActions.removeGroups, (state, action) => groupsAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.removeGroupById, (state, action) => ({ ...state, group: { ...state.group, response: null, error: null } }))
        .addCase(fromActions.clearGroupsError, (state, action) => ({ ...state, error: null }))
        .addCase(fromActions.clearGroupByIdError, (state, action) => ({ ...state, group: { ...state.group, error: null } }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = groupsAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectGroup = (state: State) => state.group;
export const selectDelete = (state: State) => state.delete;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;