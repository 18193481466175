//const axios = require('axios');
import axios, { AxiosInstance } from 'axios';
import { environment } from '../app.config';
import AppStorage from './storage.service';

const axiosPatchInstance: AxiosInstance = axios.create({
    baseURL: environment.BASE_API_URL,
    headers: { 'Content-Type': 'application/merge-patch+json', 'Authorization': '' }
});

// request interceptor 
axiosPatchInstance.interceptors.request.use((config) => {
    // Do something before request is sent 
    //If the header does not contain the token and the url not public, redirect to login  
    const idToken = AppStorage.getIdToken();
    if (idToken && config.method !== 'OPTIONS') {
        config.headers.Authorization = 'Bearer ' + idToken;
        // console.log(config.headers.access_token)
        return config;
    };
    //console.log(config)
    return config;

}, (error) => {
    // AppLogger.error("request interceptor error\n", error);
    // Do something with request error 
    //console.log(error);
    return error;
});

export default axiosPatchInstance;