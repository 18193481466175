import { createSelector } from "reselect";
import * as fromReducer from '../reducers/global-contacts.reducer';

const selectState = state => state.globalContactsState;

export const selectGlobalContactsState = createSelector(selectState, fromReducer.selectState);
export const selectGlobalContacts = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectGlobalContactsEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);
