import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
// import {
//   AccessMenu,
//   BottomNav,
//   CustomButton,
//   RightSideMenu,
// } from '../components';
import { DEVICE_TYPE, QUESTION_TYPE, THEME } from '../enums';
import { Draft } from '../interfaces';
import * as fromStore from '../store';
import CustomText from '../utils/custom-text';
import { SpinnerScreen } from '../utils/spinner.screen';
import { CURRENT_OS, DEFAULT_SAFE_AREA_STYLES } from '../variables';
import { IconProvider, getIconNameByQuestionType, RefreshWithError, ToastMessage, CustomScrollView, ActionMessage, ActionDialogHolder, ActionDialogHolderType } from '../utils';
import { MAIN_APP_ROUTES } from '../routes';
import { checkErrorResponse, getCustomDateFormat, getRouteNameByQuestionType } from '../services';
import { CustomButton, ScreenHolder } from '../components';
import { DeleteIcon } from '../components/delete-icon';

export function DraftsScreen(props: any) {
  const { history, location } = props;
  const drafts = useSelector(state => fromStore.selectDraftsList(state));
  const draftsListState = useSelector(state => fromStore.selectDraftsListState(state));
  // const userMenu = useSelector((state) => selectUserMenu(state));
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [state, setState] = useState({});
  const actionRef = useRef<ActionDialogHolderType>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [delId, setDelId] = useState<string | null>(null);

  useEffect(() => {
    if (drafts.length === 0 && !draftsListState.error) getMyDrafts();
    return () => {
      // dispatch(fromStore.saveDrafts());
    }
  }, [])

  function displayToastMessage(error) {
    if (!error) return;
    setShowToast(true);
  }

  function getMyDrafts() {
    dispatch(fromStore.getDrafts());
  }

  return (
    <ScreenHolder location={location} history={history}>
      <div style={styles.screenContainer}>
        <div style={styles.screenHeader}>
          <CustomText customStyle={styles.shdtxt}>{t('drafts')}</CustomText>
        </div>
        <div
          style={{
            backgroundColor: THEME.WHITE,
            paddingTop: 20,
            paddingBottom: 50,
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            overflowY: 'auto'
          }}
        >
          <CustomScrollView
            customStyle={{}}
            listLength={drafts.length}
            countAll={draftsListState.countAll}
            refreshing={draftsListState.loading}
            emptyTitle={t('noDrafts')}
            error={draftsListState.error}
            onToast={displayToastMessage}
            onRefresh={getMyDrafts}
            fetchMore={() => { dispatch(fromStore.getMoreMySurveys()) }}
          >
            {drafts.map((item, index) => (
              <DraftItem
                key={item.id}
                item={item}
                state={draftsListState.delete}
                onClick={doc => {
                  history.push({
                    pathname: `${getRouteNameByQuestionType(doc.type)}/${doc.id}`,
                    state: {
                      id: doc.id
                    }
                  });
                }}
                onDelete={(item) => { setVisible(true); setDelId(item.id); }}
              />
            ))}

          </CustomScrollView>


          {visible && <ActionDialogHolder
            ref={actionRef}
            visible={visible}
            wrapperWidth={'65%'}
            onClose={() => { }}
            onDissmiss={() => { }}
          >
            <ActionMessage
              text={t('areYouSureYouWantRemove')}
              onCancel={onCancelDelete}
              onAccept={onConfirmDelete}
              headerText={t('attention!')}
              actions={[`${t('yes')}`, `${t('no')}`]}
            />
          </ActionDialogHolder>}
        </div>
      </div>
    </ScreenHolder>


  );

  function onCancelDelete() {
    if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
      setVisible(false);
      setDelId(null);
    })

  }

  function onConfirmDelete() {
    if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
      setVisible(false);
      if (delId) dispatch(fromStore.deleteDraft(delId));
    })

  }
}

interface DraftItemProps {
  item: Draft,
  state: any,
  onClick: (doc: Draft) => void,
  onDelete: (doc: Draft) => void
}

function DraftItem(props: DraftItemProps) {
  const { item, state, onClick, onDelete } = props;
  const { type, id, category } = item;
  return (
    <div
      className={"on-press-cls"}
      style={{
        ...styles.draftContainer,
        // ...{ backgroundColor: pressed ? 'rgba(220,220,220,0.2)' : THEME.WHITE },
      }}
    // onClick={() => onClick(item)}
    >

      <div style={styles.draft} onClick={() => onClick(item)}>
        <div style={styles.prevIcon}>
          <IconProvider type={getIconNameByQuestionType(type)} />
        </div>
        <div style={styles.content}>
          <CustomText customStyle={styles.question}>{item.text}</CustomText>
          <div style={styles.contentInfo}>
            <CustomText customStyle={styles.timeStamp}>
              {item?.startTime ? getCustomDateFormat(item?.startTime) : ''}
            </CustomText>
          </div>
        </div>

      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative'
        }}>
        <DeleteIcon
          customStyle={{ padding: 10, borderRadius: 50, marginLeft: 10, }}
          item={item}
          state={state}
          onClick={onDelete}
        />
      </div>

    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = ({
  // Screen container styles

  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  screenHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: THEME.DEFAULT_BORDER,
    borderBottomStyle: 'solid',
  },
  shdtxt: {
    fontSize: 17,
    color: THEME.ORANGE_RED,
    textTransform: 'uppercase',
  },
  screenContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
  },
  draftContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 15,
    cursor: 'pointer',
    boxSizing: 'border-box'
  },
  draft: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    // width: '90%',
  },
  prevIcon: {
    minWidth: 50,
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 15,
    backgroundColor: '#F7B500',
    padding: 8,
    boxSizing: 'border-box'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    // width: '60%',
  },
  question: {
    // marginRight: CURRENT_OS === DEVICE_TYPE.IOS ? 43 : 60,
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    // marginRight: 43,
  },
  timeStamp: {
    fontSize: 11,
    color: '#777777',
  },
  comments: {
    fontSize: 11,
    color: 'red',
  },

  loadMoreContainer: {
    // height: 60,
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomNav: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    bottom: 0,
    backgroundColor: THEME.WHITE,
    borderTopWidth: 1,
    zIndex: 5,
    borderTopColor: 'rgb(244,244,244)',
  },

  refreshError: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
});
