import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, } from "react-redux";
import { CustomButton, EveryoneList, GroupsList, IndividualList, InviteLink, PostQuestionModal, ScreenHolder } from "../components";
import { CONTACT_TYPE, DEVICE_TYPE, POST_OPTIONS, THEME } from "../enums";
import { ContactInfo, GroupInfo, MySurvey, SharedSurvey } from "../interfaces";
import { RadioButton } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import * as SurveyService from '../services/surveys.service';
import { MAIN_APP_ROUTES } from "../routes";
import * as fromStore from "../store";
import { environment } from "../app.config";
import { async } from "rxjs";

const actionSize = currentWindowWidth() / 3;
const optionSize = (100 / 2) - 10;
const contactsWindowHeight = currentWindowHeight() / 2;

const optionPageHeight = `${(100 / 3) * 2 - 7}%`;

// let OPTIONS = [
//     {
//         field: POST_OPTIONS.PERSON,
//         label: "person",

//     },
//     {
//         field: POST_OPTIONS.GROUP,
//         label: "group",

//     },
//     {
//         field: POST_OPTIONS.EVERYONE,
//         label: "everyone",

//     },
//     // {
//     //     field: POST_OPTIONS.INVITE_VIA_LINK,
//     //     label: "inviteViaLink",

//     // }
// ];

const link = environment.WEB_APP_DOMAIN;

export function PostQuestionScreen(props: any) {
    // console.log(props,'multichoice ffff');
    const { history, location, match } = props;
    const { question,sharePost,id } = location.state;
    console.log( location.state,'question log');
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    // const mySurveysState = useSelector((state) => selectMySurveysState(state));
    const user = useSelector((state) => fromStore.selectUser(state));
    const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
    const [state, setState] = useState({
        option: POST_OPTIONS.PERSON,
        inviteLink: ''
    });
    const [upload, setUpload] = useState<boolean>(false);
    const [hideKeyboard, setHideKeyboard] = useState(false);
    const [ShareList, setShareList] = useState([])
    const [ShareListType, setShareListType] = useState()
    const [OPTIONS, setOPTIONS] = useState([
        {
            field: POST_OPTIONS.PERSON,
            label: "person",
    
        },
        {
            field: POST_OPTIONS.GROUP,
            label: "group",
    
        },
        {
            field: POST_OPTIONS.EVERYONE,
            label: "everyone",
    
        },
    ]
    )
const sharePostDetails ={sharePost:sharePost?sharePost:null,id:location?.state?.id?location?.state?.id:null}


    useEffect(() => {
        getMyShareListCallback();
        getMyContacts();
        getMyGroups();
        // Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
        // Keyboard.addListener('keyboardDidHide', _keyboardDidHide);
        return () => {
            // Keyboard.removeListener('keyboardDidShow', _keyboardDidShow);
            // Keyboard.removeListener('keyboardDidHide', _keyboardDidHide);
        }
    }, []);
    async function  getMyShareListCallback() {
        const response = await SurveyService.shareList({size:10000,limit:1000,page:1});
       console.log('responseresponse',response)
       setShareList(response?.result)
       if(response?.result && response?.result?.length>0){
        let a =[...response?.result];
        let b:any =[]
        a.filter((v:any,i)=>{
           console.log('adkfjkdabhjfbjnabdfjabds',v);
           
            
            if(v &&v?.question &&v?.question?.id ==id){
                b.push(v)
            }
        })
        console.log('sdfjhsdfbncv nsb[0]?.typedfd',b[0]);
        
        setShareListType(b[0].type)
        if(b &&b[0] && b[0]?.type){
            if (b[0]?.type=="PERSON") {
                setOPTIONS(  [
                    {
                        field: POST_OPTIONS.PERSON,
                        label: "person",
                
                    }])
                    setState({
                        option: POST_OPTIONS.PERSON,
                        inviteLink: ''
                    })
            }else if (b[0]?.type=="GROUP") {
                setOPTIONS(  [
                     
                      {
                          field: POST_OPTIONS.GROUP,
                          label: "group",
                  
                      }])
                      setState({
                        option: POST_OPTIONS.GROUP,
                        inviteLink: ''
                    })
              }else if (b[0]?.type=="EVERYONE") {
                setOPTIONS( [
                     
                      {
                          field: POST_OPTIONS.EVERYONE,
                          label: "everyone",
                  
                      }])
                      setState({
                        option: POST_OPTIONS.EVERYONE,
                        inviteLink: ''
                    })
              }else if (b[0]?.type=="LINK") {
                setOPTIONS( [])
                setState({
                    option: POST_OPTIONS.INVITE_VIA_LINK,
                    inviteLink:link + `${MAIN_APP_ROUTES.INVITED_SURVEY}/${b[0]?.inviteLink}`
                })
              }
        }
      }
    }
    function getMyContacts() {
        dispatch(fromStore.getContacts());
    }
    function getMyGroups() {
        dispatch(fromStore.getGroups());
    }

    function _keyboardDidShow() {
        setHideKeyboard(true)
    }

    function _keyboardDidHide() {
        setHideKeyboard(false);
    }

    function addContact(item: ContactInfo) {
        setSelectedContacts([...selectedContacts, item]);
    }

    function removeContact(item: ContactInfo) {
        let index = selectedContacts.indexOf(item);
        selectedContacts.splice(index, 1);
        let newList = [...selectedContacts];
        setSelectedContacts(newList);
    }

    function addGroup(item: GroupInfo) {
        setSelectedGroups([...selectedGroups, item]);
    }

    function removeGroup(item: GroupInfo) {
        let index = selectedGroups.indexOf(item);
        selectedGroups.splice(index, 1);
        let newList = [...selectedGroups];
        setSelectedGroups(newList);
    }

    const onShare = async () => {
        // try {
        //     const result = await Share.share({
        //         message: link,
        //     });
        //     if (result.action === Share.sharedAction) {
        //         if (result.activityType) {
        //             // shared with activity type of result.activityType
        //         } else {
        //             // shared
        //         }
        //     } else if (result.action === Share.dismissedAction) {
        //         // dismissed
        //     }
        // } catch (error: any) {
        //     // console.log(error.message);
        // }
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(state.inviteLink)
        displayToast(t('copied'));
    }

    function _isValid() {
        const { option } = state;
        if (option === POST_OPTIONS.GROUP && selectedGroups && selectedGroups.length === 0) return false;
        if (option === POST_OPTIONS.PERSON && selectedContacts && selectedContacts.length === 0) return false;
        if (option === POST_OPTIONS.EVERYONE) {
            if (selectedContacts && selectedContacts.length !== 0) return true;
            if (selectedGroups && selectedGroups.length !== 0) return true;
            return false;
        }
        return true;
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.shdtxt}>{t('postQuestion')}</CustomText>
                </div>
                { (ShareListType =='' ||!ShareListType || ShareListType =='PERSON' || ShareListType =='LINK' ) && <div style={styles.screenContent}>
                    <div style={styles.avialableOptions}>
                        {OPTIONS.map((doc, index) => (
                            <RadioButton
                                key={index}
                                customStyle={{
                                    width: `${optionSize}%`,
                                    marginBottom: 15,
                                    // backgroundColor: 'green' 
                                }}
                                disabled={  (state.inviteLink !== '' && (state["option"] === POST_OPTIONS.INVITE_VIA_LINK))}
                                labelStyle={{ fontSize: 14 }}
                                selected={state["option"] === doc.field}
                                label={t(doc.label)}
                                onClick={(value) => { 
                                    setSelectedContacts([]);
                                    setSelectedGroups([]);
                                    setState({ ...state, option: doc.field, }); 
                                }}
                            />
                        ))}
                        {ShareListType? ShareListType=="LINK" ?<RadioButton
                            customStyle={{
                                width: `${optionSize}%`,
                                marginBottom: 15,
                                // backgroundColor: 'green' 
                            }}
                            labelStyle={{ fontSize: 14 }}
                            selected={state["option"] === POST_OPTIONS.INVITE_VIA_LINK}
                            label={t("inviteViaLink")}
                            onClick={(value) => { setState({ ...state, option: POST_OPTIONS.INVITE_VIA_LINK, }) }}
                        />:null:<RadioButton
                        customStyle={{
                            width: `${optionSize}%`,
                            marginBottom: 15,
                            // backgroundColor: 'green' 
                        }}
                        labelStyle={{ fontSize: 14 }}
                        selected={state["option"] === POST_OPTIONS.INVITE_VIA_LINK}
                        label={t("inviteViaLink")}
                        onClick={(value) => { setState({ ...state, option: POST_OPTIONS.INVITE_VIA_LINK, }) }}
                    />}
                    </div>

                    {state["option"] === POST_OPTIONS.PERSON && <div style={styles.optionPage}>
                        <IndividualList
                            selected={selectedContacts}
                            onRefresh={getMyContacts}
                            onSelect={addContact}
                            onDeselect={removeContact}
                            onSelectAll={selectAllContacts}
                            onDeselectAll={deselectAllContacts}
                        />
                    </div>}
                    {state["option"] === POST_OPTIONS.GROUP && <div style={styles.optionPage}>
                        <GroupsList
                            selected={selectedGroups}
                            contactsWindowHeight={optionPageHeight}
                            onRefresh={getMyGroups}
                            onSelect={addGroup}
                            onDeselect={removeGroup}
                        />
                    </div>}
                    {state["option"] === POST_OPTIONS.EVERYONE && <div style={styles.optionPage}>
                        <EveryoneList
                            selectedContacts={selectedContacts}
                            selectedGroups={selectedGroups}
                            onRefresh={() => {
                                // getMyContacts();
                                // getMyGroups();
                            }}
                            onSelect={(doc) => {
                                (doc.type === CONTACT_TYPE.GROUP) ? addGroup(doc as GroupInfo) : addContact(doc as ContactInfo);
                            }}
                            onDeselect={(doc) => {
                                (doc.type === CONTACT_TYPE.GROUP) ? removeGroup(doc as GroupInfo) : removeContact(doc as ContactInfo);
                            }}
                            onSelectAll={selectEveryone}
                            onDeselectAll={deselectEveryone}
                        />
                    </div>}
                    {state["option"] === POST_OPTIONS.INVITE_VIA_LINK && <div style={{ ...styles.optionPage, height: '75%', }}>
                        <GenerateInviteLink />
                    </div>}
                    {state["option"] !== POST_OPTIONS.INVITE_VIA_LINK && <div style={styles.action}>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('post')}
                                enable={_isValid()}
                                padding={12}
                                onClick={() => {sharePost?postShare(): postUserQuestion() }}
                            />
                        </div>
                        <div style={styles.btn}>
                            <CustomButton
                                textStyle={{ textTransform: 'uppercase' }}
                                name={t('cancel')}
                                enable={true}
                                padding={12}
                                backgroundColor={THEME.CANCEL} onClick={() => { history.goBack() }}
                            />
                        </div>
                    </div>}
                </div>}


            </div>
            { !sharePost &&
                upload && <PostQuestionModal
                    uploading={upload}
                    shareType={state.option}
                    doc={id &&sharePostDetails?.sharePost==true?{...question,id}:question}
                    typeToShare={sharePostDetails}
                    userId={user?.id}
                    selectedGroups={SurveyService.getSharedGroupIds(selectedGroups)}
                    selectedContacts={SurveyService.getContactsIds(selectedContacts)}
                    onClick={() => { }}
                    onClose={() => { setUpload(!upload) }}
                    onSuccess={onPostSuccess}
                    onCreateInviteLinkSuccess={onLinkCreated}
                />
            }
        </ScreenHolder >
    )

    function selectAllContacts(contacts: ContactInfo[]) {
        setSelectedContacts([...contacts]);
    }

    function deselectAllContacts() {
        setSelectedContacts([]);
    }

    function selectEveryone(contacts: ContactInfo[], groups: GroupInfo[]) {
        setSelectedGroups([...groups]);
        setSelectedContacts([...contacts]);
    }

    function deselectEveryone() {
        setSelectedContacts([]);
        setSelectedGroups([]);
    }


    function postUserQuestion() {
        // console.log(question,'uuuuyyyy');
        if (!question) return;
        // console.log(upload);
        setUpload(!upload);
        console.log(question,'post question jitendra');
        if(!sharePostDetails?.id && sharePostDetails?.sharePost==null){
            console.log('test,test');
            
            // dispatch(fromStore.postMySurvey(question));
        }
       
    }

    async function postShare(){
        console.log('ShareListShareListShareList',id);
if(ShareList && ShareList?.length>0){
    let a =[...ShareList];
    let b:any =[]
    a.filter((v:any,i)=>{
       console.log('adkfjkdabhjfbjnabdfjabds',v);
       
        
        if(v &&v?.question &&v?.question?.id ==id){
            b.push(v)
        }
    })
let c:any =[]
let finalDetails:any ={}
if(ShareListType=="PERSON"){
    
if(selectedContacts && selectedContacts?.length>0 ){
    selectedContacts.map((v,i)=>{
        c.push(v?.recipientUser)
    })
}
let finalsharedTos =[...b[0]?.sharedTos, ...c]
let d:any =[]
finalsharedTos.map((v,i)=>{
d.push({
    "id":v.id,
"login": null,
"firstName": null,
"lastName": null,
"email": null
})
 })

finalDetails ={...b[0]}
let q ={"id":finalDetails?.question?.id,"type":null,"text":null,"imagePath":null,"imageUrl":null,"startTime":null,"endTime":null,"isDraft":null,"resultpermission":false,"createdAt":null,"modifiedAt":null,"nameTheSurvey":null,
"category":null,"user":{"id":finalDetails?.sharedBy?.id,"login":null,"firstName":null,"lastName":null,"email":null},"options":[],"commentsCount":0,"sharedToUsers":[]}

finalDetails['sharedTos']=d
finalDetails['sharedToIndividuals']=d
finalDetails['question']=q


    
}else if(ShareListType=="GROUP"){
console.log('dsfscvwer34r34534',selectedGroups);
let member:any =[...b[0]?.sharedToIndividuals]
if(selectedGroups && selectedGroups?.length>0 ){
    selectedGroups.map((v,i)=>{
        c.push({
            id:v.id,
            name:v.name,
            imagePath:v.imagePath,
            imageUrl:v.imageUrl,
        })
        if(v && v?.members &&v?.members?.length>0 ){
            v?.members.map((res,data)=>{
                member.push({
                    "id":res.id,
                    "login": null,
                    "firstName": null,
                    "lastName": null,
                    "email": null
                })
            })
        }

    })
}
let finalsharedTos =[...b[0]?.sharedToGroups, ...c]
let d:any =[]
let e:any =[]
finalsharedTos.map((v,i)=>{
d.push({
    id:v.id,
            name:v.name,
            imagePath:v.imagePath,
            imageUrl:v.imageUrl,
})
 })
 member.map((res,data)=>{
    e.push({
        "id":res.id,
        "login": null,
        "firstName": null,
        "lastName": null,
        "email": null
    })
})
finalDetails ={...b[0]}
let q ={"id":finalDetails?.question?.id,"type":null,"text":null,"imagePath":null,"imageUrl":null,"startTime":null,"endTime":null,"isDraft":null,"resultpermission":false,"createdAt":null,"modifiedAt":null,"nameTheSurvey":null,
"category":null,"user":{"id":finalDetails?.sharedBy?.id,"login":null,"firstName":null,"lastName":null,"email":null},"options":[],"commentsCount":0,"sharedToUsers":[]}

finalDetails['sharedToGroups']=d
finalDetails['question']=q
finalDetails['sharedToIndividuals']=e


}
    
console.log('amdkcmxncvmcnmas',finalDetails);

 let details =  await SurveyService.shareMyPost(finalDetails)
 console.log('response of google',details);
 
 if(details){
    history.push(MAIN_APP_ROUTES.LIVE_SURVEY);
 }
}
        
        
        
    }

    function onPostSuccess(response) {
        dispatch(fromStore.postMyActiveSurveySuccess(response));
        setUpload(!upload);
        history.push(MAIN_APP_ROUTES.LIVE_SURVEY);
    }

    function onLinkCreated(doc: MySurvey, response: SharedSurvey) {
        console.log(doc,'testpostdata');
        dispatch(fromStore.postMyActiveSurveySuccess(doc));
        setUpload(!upload);
        setState({ ...state, inviteLink: link + `${MAIN_APP_ROUTES.INVITED_SURVEY}/${response.inviteLink}` })
    }

    function displayToast(text) {
        dispatch(fromStore.toastMessage(text));
    }


    function GenerateInviteLink() {

        if (state.inviteLink) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                    <InviteLink
                        inviteLink={state.inviteLink}
                        onCopy={copyToClipboard}
                        onShare={onShare}
                    />
                    <CustomButton
                        customStyle={{ marginBottom: 10 }}
                        textStyle={{ textTransform: 'uppercase' }}
                        name={"DONE"}
                        enable={true}
                        padding={12}
                        onClick={() => { history.push(MAIN_APP_ROUTES.PUBLIC_CHOICE, {}) }}
                    />
                </div>

            )
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-end' }}>
                <CustomButton
                    customStyle={{ marginBottom: 10 }}
                    textStyle={{ textTransform: 'uppercase' }}
                    name={"POST & CREATE LINK"}
                    enable={true}
                    padding={12}
                    onClick={() => { postUserQuestion() }}
                />
            </div>
        )
    }
}



const styles: { [key: string]: React.CSSProperties } = ({
    // bottomNav: {
    //     position: 'absolute',
    //     display: 'flex',
    //     width: '100%',
    //     bottom: 0,
    //     backgroundColor: THEME.WHITE,
    //     borderTopWidth: 1,
    //     borderTopColor: 'rgb(244,244,244)'
    // },

    // Screen container styles

    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxSizing: 'border-box'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        height: '92%',
        overflowY: 'auto',
        boxSizing: 'border-box'
    },

    avialableOptions: {
        paddingTop: 15,
        paddingBottom: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },

    optionPage: {
        width: '100%',
        boxSizing: 'border-box',
        // marginBottom: 10,
        height: '70%',
    },

    action: {
        // position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        // paddingLeft: 20,
        // paddingRight: 20,
        // paddingTop: 10,
        // paddingBottom: 15,
        // bottom: 10,
        width: '100%',
        backgroundColor: THEME.WHITE,
        boxSizing: 'border-box'
        // zIndex: 1
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
})