import { createSelector } from "reselect";
import * as fromReducer from '../reducers/user.reducer';

const selectState = state => state.userState;
export const selectUserState = createSelector(selectState, fromReducer.selectState);
export const selectUser = createSelector(
    selectUserState,
    fromReducer.selectUser
);




