
import * as fromActions from '../actions/draft.actions';
import { User, AppError, MySurvey } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    draft?: MySurvey | null,
    loading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getDraft, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getDraftSuccess, (state, action) => ({ ...state, loading: false, draft: action.payload }))
        .addCase(fromActions.getDraftError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.removeDraft, (state, action) => ({ ...state, error: null, draft: null, loading: false }))
        .addCase(fromActions.clearDraftError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
export const selectDraft = (state: State) => state.draft;
