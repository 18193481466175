import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FONT, THEME } from "../enums";
import { IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import { APP_THEME_COLOR } from "../variables";

interface Props {
    value: any,
    label?: string,
    secure?: any,
    placeholder?: string,
    keyboardtype?: any,
    type?: any,
    id: string,
    pattern?: any,
    password?: string,
    fieldStyles?: any,
    clearButtonMode?: any,
    editable?: any,
    placeholderTextColor?: string,
    currentPassword?: string,
    onChange: (id: string, value: any) => void,
    onFocus?: (event: any) => void,
    onBlur?: (event: any) => void,
}

export function SearchBar(props: Props) {
    const [error, setError] = useState<string | null>('');
    // const { t } = useTranslation();
    const {
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        password,
        fieldStyles,
        clearButtonMode,
        editable,
        placeholderTextColor,
        label,
        onChange,
        onFocus,
        onBlur
    } = props;


    const handleChange = (text: any) => {
        onChange(props.id, text);
    }
    return (
        <div style={{ ...styles.container, ...fieldStyles ? fieldStyles : null }}>
            {label ? <CustomText customStyle={styles.lableHeader}>{label}</CustomText> : null}
            <div style={{ ...styles.inputContainer, ...editable == false ? { opacity: 0.4, } : null }}>
                <div style={{ width: 20, height: 20, marginLeft: 15, marginRight: 10 }}>
                    <IconProvider type={"search-blue"} />
                </div>
                <div style={{ width: '85%' }}>
                    <input
                        style={styles.input}
                        placeholder={placeholder}
                        // placeholderTextColor={placeholderTextColor ? placeholderTextColor : "#000000"}
                        // textContentType={type}
                        // secureTextEntry={secure}
                        // keyboardType={keyboardtype}
                        onChange={event => handleChange(event.target.value)}
                        value={value}
                        autoCapitalize={"none"}
                        // autoCompleteType={"off"}
                        // autoFocus={false}
                        // caretHidden={false}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        // clearButtonMode={clearButtonMode ? clearButtonMode : "while-editing"}
                        // contextMenuHidden={false}
                        disabled={editable === false}
                    //onBlur={Keyboard.dismiss} 
                    />
                </div>
            </div>
            {error ? <CustomText customStyle={{ color: 'red', padding: 5 }}>{error}</CustomText> : null}
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    lableHeader: {
        fontSize: 14,
        fontFamily: FONT.MANROPE_700,
        marginBottom: 10,
        marginTop: 10,
        color: APP_THEME_COLOR
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 40,
        backgroundColor: "#EFEFEF",
        borderRadius: 10,
        width: '100%',
    },
    input: {
        color: THEME.BLACK,
        width: '100%',
        fontFamily: FONT.MANROPE_REGULAR,
        border: 'none',
        backgroundColor: 'transparent',
        minHeight : 35
    },
});