import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';

const searchQueryEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.searchQuery),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
        return SurveysService.searchSurveys(payload)
            .then(response => fromActions.searchQuerySuccess(response))
            .catch(error => fromActions.searchQueryError(extractError(error, MAIN_APP_ROUTES.SEARCH)))
    })
);

export const searchEpics = combineEpics(
    searchQueryEpic
);