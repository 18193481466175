import React from "react";
import { THEME } from "../enums";
import { ContactIcon } from "../icons";
import { GroupInfo } from "../interfaces";
import CustomText from "../utils/custom-text";


interface GroupContactProps {
    item: GroupInfo,
    isSelected: boolean,
    onSelect: (doc: GroupInfo) => void,
    onDeselect: (doc: GroupInfo) => void
}
export function GroupContact(props: GroupContactProps) {
    const { item, isSelected, onSelect, onDeselect } = props;
    return (
        <div
            className={"on-press-cls"}
            style={{
                ...styles.contactPressable,
                // ...{ opacity: pressed ? 0.7 : 1 }
            }}
            onClick={() => { isSelected ? onDeselect(item) : onSelect(item) }}
        >

            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <img
                            src={item.imageUrl ? item.imageUrl : ContactIcon}
                            style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 25,
                                objectFit: 'cover'
                            }}
                        />
                    </div>
                    <div style={{}}>
                        <CustomText
                            customStyle={{
                                fontSize: 14,
                                whiteSpace: "nowrap",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                            }}>{`${item.name}`}</CustomText>
                    </div>
                </div>
                <div style={{
                    ...styles.indicator,
                    ...{ backgroundColor: isSelected ? THEME.ORANGE_RED : THEME.WHITE }
                }}
                >

                </div>
            </div>

        </div>

    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', flexDirection: 'column'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,
    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 24,
        borderStyle: 'solid'
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '80%',
    }
})