import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ScreenHolder } from "../components";
import { DRAWER_TYPE, THEME } from "../enums";
import { CustomScrollView, RefreshWithError } from "../utils";
import CustomText from "../utils/custom-text";
import * as fromStore from '../store';
import { MAIN_APP_ROUTES } from "../routes";
import { MySurvey } from "../interfaces";
import { SpinnerScreen } from "../utils/spinner.screen";
import { checkErrorResponse, getRouteNameByQuestionType } from "../services";


export function SearchResultsScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector(state => fromStore.selectUserState(state));
    const searchList = useSelector(state => fromStore.selectSearchList(state));
    const { loading, error } = useSelector(state => fromStore.selectSearchState(state));
    const [search, setSearch] = useState<string>('');
    let delayTimer;

    useEffect(() => {
        dispatch(fromStore.removeSearchData());
        return () => {

        }
    }, [])

    function handleSearch(id: string, value: string) {
        setSearch(value);
        if (delayTimer) clearTimeout(delayTimer);
        if (!value || value === '') {
            dispatch(fromStore.removeSearchData());
            // if (delayTimer) clearTimeout(delayTimer);
            // setPrevSearch('');
            return;
        }
        delayTimer = setTimeout(() => {
            // Do the ajax stuff
            if (value) dispatch(fromStore.searchQuery(value));
        }, 1500); // Will do the ajax stuff after 1000 ms, or 1 s

    }

    return (
        <ScreenHolder
            location={location}
            history={history}
            drawerType={DRAWER_TYPE.SEARCH}
            onChange={handleSearch}
        >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.shdtxt}>{t('searchResults')}</CustomText>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '90%',
                }}>
                    <SearchList
                        list={search ? searchList.filter(doc => doc.text.toLowerCase().includes(search.toLowerCase())) : []}
                        search={search}
                        onClick={onQuestionClick}
                        refreshing={loading}
                        emptyTitle={t('noResults')}
                        notFoundTitle={'Not found'}
                        onRefresh={() => { dispatch(fromStore.searchQuery(search)) }}
                    />
                </div>

            </div>
        </ScreenHolder>
    )

    function onQuestionClick(item: MySurvey) {
        if (item.isDraft) {
            history.push({
                pathname: `${getRouteNameByQuestionType(item.type)}/${item.id}`,
                state: {
                    id: item.id
                }
            });
        }
        else if (user && (item.user.id === user.id)) {
            history.push({ pathname: `${MAIN_APP_ROUTES.SURVEY_RESULTS}/${item.id}`, state: { id: item.id } })
        }
        else if (user && (item.user.id !== user.id)) {
            history.push({ pathname: `${MAIN_APP_ROUTES.SURVEY}/${item.id}`, state: { id: item.id } })
        }
        else {
            //do nothing
        }
    }
}


function SearchList(props) {

    if (props.refreshing && props.list.length === 0 && !props.error) {
        return (
            <SpinnerScreen />
        )
    }

    if (props.list && props.list.length === 0 && props.search && !props.error && !props.refreshing) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',

            }}>
                <CustomText customStyle={{ fontSize: 18, color: THEME.PLACEHOLDER }}>{props.notFoundTitle}</CustomText>
            </div>
        )
    }

    if (props.list && props.list.length === 0 && !props.error && !props.refreshing) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',

            }}>
                <CustomText customStyle={{ fontSize: 18, color: THEME.PLACEHOLDER }}>{props.emptyTitle}</CustomText>
            </div>
        )
    }

    if (props.error && props.list && props.list.length === 0) {
        return (
            <RefreshWithError text={checkErrorResponse(props.error)} onRefresh={props.onRefresh} />
        )
    }

    return (
        <CustomScrollView
            listLength={props.list}
            countAll={props.list}
            refreshing={props.refreshing}
            onRefresh={props.onRefresh}
            fetchMore={() => { }}
        // ListFooterComponent={() => <View style={{ height: 60, padding: 10 }}></View>}
        >
            {props.list.map((item, index) => (<SearchItem
                key={item.id}
                item={item}
                onClick={props.onClick}

            />))}
        </CustomScrollView>
    )
}


/* -------------- Search item ------------- */

interface SearchItemProps {
    item: MySurvey,
    onClick: (item: any) => void;
}

function SearchItem(props: SearchItemProps) {
    const { item, onClick } = props;
    return (
        <div
            className={"on-press-cls"}
            style={{
                ...{
                    paddingLeft: 20,
                    paddingRight: 20
                },
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
            }}
            onClick={() => onClick(item)}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingTop: 15,
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: THEME.INPUT_BORDER,
                borderBottomStyle: 'solid'
            }}>
                <CustomText customStyle={{ fontSize: 14, fontWeight: '400' }}>{item.text}</CustomText>
            </div>

        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({

    // Screen container styles

    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20
    },
})