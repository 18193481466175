import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as CountriesService from '../../services/countries.service';

const getCountriesEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getCountries),
    switchMap(() => {
        return CountriesService.getCountries()
            .then(docs => fromActions.getCountriesSuccess(docs))
            .catch(error => fromActions.getCountriesError(extractError(error)))
    })
);

export const countriesEpics = combineEpics(
    getCountriesEpic
);