import { createSelector } from "reselect";
import { CONTACT_TYPE } from "../../enums";
import * as fromReducer from '../reducers/groups.reducer';

const selectState = state => state.groupsState;

export const selectGroupsState = createSelector(selectState, fromReducer.selectState);
export const selectGroupsEntites = createSelector(
    selectState,
    fromReducer.selectEntities
);

export const selectGroups = createSelector(
    selectState,
    fromReducer.selectAll
);

export const selectGroupState = createSelector(selectState, fromReducer.selectGroup);
export const selectGroupDeleteState = createSelector(selectState, fromReducer.selectDelete);
export const selectGroupsWithType = createSelector(
    selectGroups,
    groups => groups.map(doc => ({ ...doc, type: CONTACT_TYPE.GROUP }))
);