import React, { useState } from "react";

import { DRAWER_TYPE, THEME } from "../enums";
import { useTranslation } from 'react-i18next';
import { currentWindowHeight, currentWindowWidth, FULL_SCREEN_HEADER_HEIGHT } from "../variables";

import { FieldForm, IconProvider } from "../utils";
import { MAIN_APP_ROUTES } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { selectScreenResolution } from "../store/selectors";

import '../scss/screen-holder.scss';
import { AccessMenu } from "./access-menu";

const headerColumnSize = `${100 / 3}%`;
const smallScreen = currentWindowHeight() / 3;

const SearchDoc = {
    field: 'search',
    label: '',
    secure: false,
    type: 'name',
    placeholder: 'searchKeyWord',
    pattern: ''

};


interface Props {
    history?: any,
    location?: any,
    children: any,
    drawerType?: DRAWER_TYPE.FULL | DRAWER_TYPE.HALF | DRAWER_TYPE.SEARCH,
    search?: any,
    isHome?: boolean,
    onChange?: (id: string, value: any) => void,
};
export function ScreenHolder(props: Props) {
    const { drawerType } = props;

    if (drawerType === DRAWER_TYPE.HALF) return (
        <HalfHolder {...props} />
    );
    if (drawerType === DRAWER_TYPE.SEARCH) return (
        <SearchHolder {...props} />
    );
    return (
        <FullHolder {...props} />
    )
}




function HalfHolder(props) {
    const { history, location, drawerType, children, search, onChange } = props;
    const { t, i18n } = useTranslation();
    return (
        <div className={"screen-holder-container"}>
            <div className={"screen-holder-small-header"}>
                <div className={"center-logo"}>
                    <IconProvider type={"app-name-icon"} />
                </div>
            </div>
            <div className={"screen-holder-small-drawer"}>
                {children}
            </div>
        </div>
    )
}


function SearchHolder(props) {
    const { isHome, history, location, drawerType, children, search, onChange } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const screenResolution = useSelector((state) => selectScreenResolution(state));
    const [toggleMenu, setToggleMenu] = useState<boolean>(false);
    return (
        <div className={"screen-holder-container"}>
            <div style={styles.header}>
                <div style={{}}>
                    <div
                        className={"on-hover-icon"}
                        style={{
                            ...styles.actionLeft,
                            // ...{ backgroundColor: pressed ? 'rgba(244,244,244,0.2)' : 'transparent' }
                        }}
                        onClick={() => {
                            if (isHome) history.push(MAIN_APP_ROUTES.MENU);
                            else {
                                history.goBack();
                            }
                        }}
                    >
                        <IconProvider type={location.pathname === MAIN_APP_ROUTES.HOME ? "hamburger-menu" : "arrow-ios-back-white"} />
                    </div>
                </div>

                <div className={"search-width"} style={styles.searchContainer}>
                    <FieldForm
                        key={SearchDoc.field}
                        id={SearchDoc.field}
                        onChange={onChange ? onChange : () => { }}
                        secure={SearchDoc.secure}
                        placeholder={t(SearchDoc.placeholder)}
                        type={SearchDoc.type}
                        value={search}
                        pattern={SearchDoc.pattern}
                        label={SearchDoc.label}
                    // inputStyle={{textAlign: 'center'}}
                    />
                </div>
                <div style={{}}>
                    <div
                        style={{
                            ...styles.actionLeft,
                            ...{
                                backgroundColor: 'rgba(244,244,244,0.2)',
                                marginRight: 5
                            }
                        }}
                        onClick={() => setToggleMenu(!toggleMenu)}

                    >
                        <IconProvider type={"menu"} />
                    </div>
                </div>
                <div style={{ marginRight: 10 }}>
                </div>
            </div>
            <div className={"screen-holder-wrapper"}>
                {children}
            </div>

            {toggleMenu && <AccessMenu
                style={{}}
                onClick={(route) => {
                    history.push(route);
                    setToggleMenu(!toggleMenu);
                }}
                onClose={() => setToggleMenu(false)}
            />}
        </div>
    )
}



function FullHolder(props) {
    const { isHome, history, location, drawerType, children, search, onChange } = props;
    const { t, i18n } = useTranslation();
    const [toggleMenu, setToggleMenu] = useState<boolean>(false);

    function onNavigationChange(e) {
        setToggleMenu(false);
    }

    return (

        <div className={"app-screen-holder-container"}>
            <div className={"screen-holder-header"}>
                <div style={{ width: headerColumnSize }}>
                    <div
                        className={"on-hover-icon"}
                        style={{
                            ...styles.actionLeft,
                            // ...{ backgroundColor: pressed ? 'rgba(244,244,244,0.2)' : 'transparent' }
                        }}
                        onClick={() => {
                            if (isHome) {
                                history.push(MAIN_APP_ROUTES.MENU);
                            }
                            else {
                                history.goBack();
                            }
                        }}
                    >
                        <IconProvider
                            type={isHome ? "hamburger-menu" : "arrow-ios-back-white"}
                        />
                    </div>
                </div>

                <div style={{ ...styles.logo, width: headerColumnSize }}>
                    <IconProvider type={"app-name-icon"} />
                </div>
                <div style={styles.actionRight}>
                    <div
                        className={"on-hover-icon"}
                        style={{
                            ...styles.actionLeft,
                            ...{
                                // backgroundColor: pressed ? 'rgba(244,244,244,0.2)' : 'transparent',
                                // marginRight: 15
                            }
                        }}
                        onClick={() => history.push(MAIN_APP_ROUTES.SEARCH)}
                    >
                        <IconProvider type={"search"} />
                    </div>
                    <div
                        style={{
                            ...styles.actionLeft,
                            ...{
                                backgroundColor: 'rgba(244,244,244,0.2)',
                                marginRight: 5
                            }
                        }}
                        onClick={() => setToggleMenu(!toggleMenu)}

                    >
                        <IconProvider type={"menu"} />
                    </div>
                </div>
            </div>

            <div className={"screen-holder-wrapper"}>
                {children}
            </div>

            {toggleMenu && <AccessMenu
                style={{}}
                onClick={(route) => {
                    history.push(route);
                    setToggleMenu(!toggleMenu);
                }}
                onClose={() => setToggleMenu(false)}
            />}
        </div>
    )
}








const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.BLACK,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'
    },
    header: {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        // right: 0,
        // zIndex: 100,
        backgroundColor: THEME.BLACK,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // marginBottom: 10,
        width: '100%',
        height: FULL_SCREEN_HEADER_HEIGHT
    },
    actionLeft: {
        width: 35,
        height: 35,
        padding: 7,
        borderRadius: 35,
        marginLeft: 10,
        cursor: 'pointer',
        boxSizing: 'border-box'
    },

    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
    },
    actionRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: headerColumnSize,
    },
    wrapper: {
        // position: 'absolute',
        // bottom: 0,
        // paddingBottom: 50,
        // top: FULL_SCREEN_HEADER_HEIGHT + 10,
        width: '100%',
        height: ``,
        // height: `calc(100% - ${FULL_SCREEN_HEADER_HEIGHT}px)`,
        backgroundColor: THEME.WHITE,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        overflow: 'hidden'
    },

    image: {
        width: headerColumnSize,
        height: "100%",
    },

    //Small screen holder styles

    smallHeader: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '33%',
        backgroundColor: THEME.BLACK

    },
    centerLogo: {
        width: '70%',
        // backgroundColor: 'green'
        // height: 
    },
    smallWrapper: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        // top: HALF_SCREEN_HEADER_HEIGHT,
        backgroundColor: THEME.WHITE,
        height: '66%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        paddingTop: 28,
        overflow: 'hidden'
        // overflow: 'auto'
        // paddingBottom: 20
    },
    bottomNav: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        bottom: 0,
        backgroundColor: THEME.WHITE,
        borderTopWidth: 1,
        borderTopColor: 'rgb(244,244,244)'
    },
})
