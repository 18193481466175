import * as fromActions from '../actions/surveys-list.actions';
import { AppError, MySurvey } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<MySurvey> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
}

const surveyAdapter: EntityAdapter<MySurvey> = createEntityAdapter<MySurvey>({
    selectId: (doc) => doc.id
})

const initialState: State = surveyAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getSurveys, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getSurveysSuccess, (state, action) => surveyAdapter.setAll({ ...state, loading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result))
        .addCase(fromActions.getSurveysError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getMoreSurveys, (state, action) => ({ ...state, nextLoading: true, error: null }))
        .addCase(fromActions.getMoreSurveysSuccess, (state, action) => {
            if (action.payload.result.length === 0) return ({ ...state, nextLoading: false, countAll: state.ids.length });
            return surveyAdapter.addMany({ ...state, nextLoading: false, countAll: action.payload.countAll, page: action.payload.page }, action.payload.result);
        })
        .addCase(fromActions.getMoreSurveysError, (state, action) => ({ ...state, error: action.payload, nextLoading: false }))

        .addCase(fromActions.removeSurveys, (state, action) => surveyAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearSurveysError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = surveyAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;