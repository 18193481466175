import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { POST_OPTIONS, QUESTION_TYPE, SHARE_TYPE, THEME } from "../enums";
import { AppError, ImageResponse, MySurvey, SharedToGrupsForm, ShareQuestionToEveryoneForm, ShareQuestionToGroupForm, ShareQuestionToPersonForm } from "../interfaces";
import { ErrorMessage } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowWidth } from "../variables";
import * as SurveyService from '../services/surveys.service';
import * as DraftService from '../services/drafts.service';
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, extractError } from "../services";
import * as fromStore from "../store";
import { CircularProgress } from "@material-ui/core";

const optionPageHeight = '75%';

interface Props {
    uploading: boolean,
    doc: any,
    onClick: () => void,
    onSuccess: (response: any) => void,
    onClose: () => void,
}

interface State {
    status: string,
    error: AppError | null,
}

export function SaveQuestionModal(props: Props) {
    const {
        uploading,
        doc,
        onClick,
        onSuccess,
        onClose
    } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useState<State>({
        status: '',
        error: null
    });

    useEffect(() => {
        if (uploading && doc) uploadQuestion(doc);
    }, [uploading])

    function uploadQuestion(doc) {
        if (!doc.type) return;
        switch (doc.type) {
            case QUESTION_TYPE.SINGLE_OPTION: return handleNormalQuestion(doc);
            case QUESTION_TYPE.SHORT_ANSWER: return handleNormalQuestion(doc);
            case QUESTION_TYPE.MULTIPLE_CHOICE: return handleMultipleChoiceQuestion(doc);
        }
    }

    async function handleNormalQuestion(doc) {
        try {
            const { duration } = doc;

            // console.log(startTime.toISOString(), endTime.toISOString());
            setState({ ...state, status: 'Uploading image' });
            const ImageResponse: ImageResponse | null = doc.image ? await SurveyService.uploadQuestionImage(doc.image) : null;
            const startTime = new Date();
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + duration.code);
            setState({ ...state, status: 'Saving...' });
            const data = doc.id ? {
                ...SurveyService.getUpdateQuestionObj(doc),
                imagePath: SurveyService.getImagePath(ImageResponse, doc?.imagePath),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
            } : {
                type: doc.type,
                text: doc.text,
                code: null,
                imagePath: SurveyService.getImagePath(ImageResponse, doc?.imagePath),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                category: doc.category,
                nameTheSurvey: doc.nameTheSurvey,
                options: doc.options ? doc.options : SurveyService.getOptions(doc.type),
                resultpermission: doc?.resultpermission
            }

            // console.log(data);

            const response = doc.id ? await DraftService.updateDraft(data) : await DraftService.postDraft(data);
            setState({ ...state, status: `Success...` });
            // console.log(response);
            onSuccess(response);


        } catch (error: any) {

            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }

    async function handleMultipleChoiceQuestion(doc) {
        const { duration, options } = doc;
        let result: any[] = [];
        let counter = 0;

        async function uploadImage(docs: any[], counter: number) {
            // console.log(counter);
            setState({ ...state, status: `Uploading image ${counter + 1}/${docs.length}` });
            const res: ImageResponse | null = docs[counter].image ? await SurveyService.uploadOptionImage(docs[counter].image) : null;
            result.push({
                ...docs[counter],
                imagePath: SurveyService.getImagePath(res, docs[counter].imagePath)
            });
            if (counter === docs.length - 1) return;
            counter = counter + 1;
            return uploadImage(docs, counter);
        }

        try {
            // setState({ ...state, status: 'uploading image' });
            // const response = await SurveyService.uploadQuestionImage(doc.image);
            setState({ ...state, status: 'Uploading Images' });
            const arrayOptions = Object.keys(options).map((key) => options[key]);
            // const resOptions = await uploadMultipleImages(arrayOptions);
            await uploadImage(arrayOptions, counter);

            setState({ ...state, status: `Saving...` });
            const startTime = new Date();
            const endTime = new Date();
            endTime.setMinutes(endTime.getMinutes() + duration.code);
            // console.log(startTime.toISOString(), endTime.toISOString());
            const data = doc.id ? {
                ...SurveyService.getUpdateQuestionObj(doc),
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                options: result
            } : {
                type: doc.type,
                text: doc.text,
                code: null,
                imagePath: null,
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                category: doc.category,
                options: result,
                resultpermission: doc?.resultpermission
            }
            // console.log(data);
            const response = doc.id ? await DraftService.updateDraft(data) : await DraftService.postDraft(data);
            setState({ ...state, status: `Success...` });
            // console.log(response);
            onSuccess(response);
        } catch (error: any) {
            const extError = extractError(error, MAIN_APP_ROUTES.POST_QUESTION);
            // console.log(extError);
            setState({ ...state, error: extError });
        }
    }




    function onErrorClose() {
        setState({ ...state, error: null });
        onClose();
    }


    return (
        <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
            <div style={styles.centeredView}>
                {state.error ?
                    <ErrorMessage
                        text={checkErrorResponse(state.error)}
                        onClose={onErrorClose}
                    /> :

                    <ActivityViewer text={state.status} />
                }

            </div>
        </div>
    )
}


function ActivityViewer(props) {
    const { text } = props;
    return (
        <div style={styles.modalView}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress size={20} style={{ marginBottom: 10 }} />
                <CustomText customStyle={styles.modalText}>{text}</CustomText>
            </div>
        </div>
    )
}



const styles: { [key: string]: React.CSSProperties } = {

    //model styles

    centeredView: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        height: '100%'
        // marginTop: 22
    },

    //Activity viewer
    modalView: {
        // margin: 20,
        width: '50%',
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        padding: 15,
        alignItems: "center",
    },



    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },
}