import React, { useImperativeHandle, useRef, useEffect } from 'react';
import { THEME } from '../enums';
import { currentWindowHeight } from '../variables';
import { useSpring, animated } from '@react-spring/web';

interface Props {
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void,
    onShow?: () => void,
    wrapperHeight: number,
    children: any
}

interface RefHandlers {
    closeAnimation: (onConfirm?: () => void) => void
}

export const BottomDialogHolder = React.forwardRef((props: Props, ref: React.Ref<RefHandlers>) => {
    const {
        visible,
        wrapperHeight,
        onClose,
        onDissmiss,
        onShow
    } = props;

    const wrapperRef = useRef<HTMLDivElement | null>();
    const wrapPostion = useRef<number>(0);
    const [style, animate] = useSpring(() => ({ x: 0, y: wrapperHeight }));

    useEffect(() => {
        animate({ x: 0, y: wrapPostion.current });
        wrapPostion.current = wrapperHeight;
        return () => { }
    }, [])

    useImperativeHandle(ref, () => ({
        closeAnimation: (onConfirm?: () => void) => closeAnimation(onConfirm),
    }));

    function closeAnimation(onConfirm?: () => void) {
        animate({ x: 0, y: wrapperHeight });
        wrapPostion.current = wrapperHeight;
        setTimeout(() => {
            if (onConfirm) onConfirm();
            else onClose();
        }, 300)
    }

    function onBackgroundPress() {
        closeAnimation();
    }


    return (
        <div style={styles.container}>
            <div style={{ ...styles.backgroundContainer, height: currentWindowHeight() - wrapperHeight }}
                onClick={onBackgroundPress} //background click
            >
            </div>
            <animated.div
                style={{ ...styles.mediaWrapper, height: wrapperHeight, ...style }}
            >
                {props.children}
            </animated.div>
        </div>
    )

})

export type BottomDialogHoderType = React.ElementRef<typeof BottomDialogHolder>;


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
        // marginTop: 22
    },
    backgroundContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    mediaWrapper: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
    },
})