import { createSelector } from "reselect";
import * as fromReducer from '../reducers/categories.reducer';

const selectState = state => state.categoriesState;

export const selectCategoriesState = createSelector(selectState, fromReducer.selectState);
export const selectCategories = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectCategoriesEntities = createSelector(selectState, fromReducer.selectEntities);
