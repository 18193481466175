import React from "react";
import { THEME } from "../enums";
import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { MdDelete, MdSync } from "react-icons/md";

interface Props {
    item: any,
    state: any,
    customStyle?: any,
    onClick: (doc: any) => void,

}

export function DeleteIcon(props: Props) {
    const { state, item, customStyle, onClick } = props;
    const rotation = useSpring({
        loop: true,
        from: { rotateZ: 0 },
        to: { rotateZ: -180 }
    });

    useEffect(() => {

        return () => {
        }
    }, [])

    if (state.id && state.loading && state.id === item.id) {

        return (
            <div
                style={{
                    ...customStyle, //width: 40, height:40,bottom: 0,position: 'absolute', right: 0,
                    // ...pressed && { backgroundColor: THEME.INPUT_BORDER }
                }}
                onClick={() => {
                    //ignore
                }}
            >
                <animated.div style={{ width: 20, height: 20, ...rotation }}>
                    <MdSync style={{}} color={THEME.ORANGE_RED} size={20} />
                </animated.div>
            </div>
        )
    }

    return (
        <div
            style={{
                ...customStyle, //width: 40, height:40,bottom: 0,position: 'absolute', right: 0,
                // ...pressed && { backgroundColor: THEME.INPUT_BORDER }
            }}
            onClick={() => onClick(item)}
        >
            <MdDelete style={{}} color={THEME.CANCEL} size={20} />
        </div>

    )
}