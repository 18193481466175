import React from "react";
import axios, { AxiosInstance } from 'axios';
import { environment } from '../app.config';
import AppStorage from './storage.service';

export async function mediaUpload(url: string, doc, onProgress?: (event: any) => void) {
    const token = await AppStorage.getIdToken();
    return axios.post(environment.BASE_API_URL + url, doc, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        },
        onUploadProgress: onProgress
    })
}

export function getFileExtention(fileName: string | undefined) {
    if(!fileName) return "mp4";
    const extension =  fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    return extension !== fileName ? extension : "mp4";
}