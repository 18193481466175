import { createAction } from '@reduxjs/toolkit';
import { AppError, GlobalContactInfo } from '../../interfaces';

export const searchFriend = createAction<string>('@search-friend/get');
export const searchFriendSuccess = createAction<GlobalContactInfo[]>('@search-friend/get/success');
export const searchFriendError = createAction<AppError | null>('@search-friend/get/error');


export const updateOneSearchFriend = createAction<GlobalContactInfo>('@search-friend/update/one');

export const removeSearchFriend = createAction('@search-friend/remove');
export const clearSearchFriendError = createAction('@search-friend/clear/error');