import { createAction } from '@reduxjs/toolkit';
import { AppError, AppNotification, UpdateNotification, TokenRegistrationResponse, TokenRegistration } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: AppNotification[]
}

export const getNotifications = createAction('@notification/get');
export const getNotificationsSuccess = createAction<PageNation>('@notification/get/success');
export const getNotificationsError = createAction<AppError | null>('@notification/get/error');

export const getMoreNotifications = createAction('@notification/get/more');
export const getMoreNotificationsSuccess = createAction<PageNation>('@notification/get/more/success');
export const getMoreNotificationsError = createAction<AppError | null>('@notification/get/more/error');

export const updateNotifications = createAction<UpdateNotification>('@notification/update');
export const updateNotificationsSuccess = createAction<AppNotification>('@notification/update/success');
export const updateNotificationsError = createAction<AppError | null>('@notification/update/error');

export const deleteNotification = createAction<string>('@notification/delete');
export const deleteNotificationSuccess = createAction<string>('@notification/delete/success');
export const deleteNotificationError = createAction<AppError | null>('@notification/delete/error');

export const registerFcmToken = createAction<TokenRegistration>('@notification/register');
export const registerFcmTokenSuccess = createAction<TokenRegistrationResponse>('@notification/register/success');
export const registerFcmTokenError = createAction<AppError | null>('@notification/register/error');

export const deregisterFcmToken = createAction<TokenRegistration>('@notification/deregister');
export const deregisterFcmTokenSuccess = createAction('@notification/deregister/success');
export const deregisterFcmTokenError = createAction<AppError | null>('@notification/deregister/error');

export const addNotification = createAction<AppNotification>('@notification/add');
export const addNotifications = createAction<AppNotification[]>('@notification/addmore');
export const removeNotification = createAction<string>('@notification/remove');
export const clearNotifications = createAction('@notification/clear');

