import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';
import { getUserName } from '../../services';

const getFriendRequestsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getFriendRequests),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        // const surveyState = fromSelectors.selectSurveysListState(state);
        return ContactsService.getFriendRquests()
            .then(response => fromActions.getFriendRequestsSuccess(response))
            .catch(error => fromActions.getFriendRequestsError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

// const getFriendRequestsSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.getFriendRequestsSuccess),
//     map((action: any) => action.payload),
//     switchMap((payload) => [fromActions.addGlobalContacts(ContactsService.getUserInfoFromFriendRequests(payload))])
// );

const addFriendEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.addFriend),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        // const surveyState = fromSelectors.selectSurveysListState(state);
        return ContactsService.addFriend(payload)
            .then(response => fromActions.addFriendSuccess(response))
            .catch(error => fromActions.addFriendError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

const addFriendSuccessEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.addFriendSuccess),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        const globalContacts = fromSelectors.selectGlobalContactsEntities(state);
        const searchedContacts = fromSelectors.selectSearchFriendEntities(state);
        const contact = globalContacts[payload.recipientUser.id];
        const searchContact = searchedContacts[payload.recipientUser.id];
        return [
            fromActions.toastMessage(`Friend request sent`),
            fromActions.addOneSentRequest(payload),
            contact ? fromActions.updateOneGlobalContact({ ...contact, request: "SENT" }) : fromActions.disgardAction(),
            searchContact ? fromActions.updateOneSearchFriend({ ...searchContact, request: "SENT" }) : fromActions.disgardAction()
        ];
    })
);

const acceptFriendRequestEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.acceptFriend),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        // const surveyState = fromSelectors.selectSurveysListState(state);
        return ContactsService.acceptFriendRequest(payload)
            .then(response => fromActions.acceptFriendSuccess(response))
            .catch(error => fromActions.acceptFriendError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

const acceptFriendRequestSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.acceptFriendSuccess),
    map((action: any) => action.payload),
    switchMap((payload) => [fromActions.toastMessage(`${getUserName(payload?.requestedUser)} added to friends`), fromActions.addContact(payload)])
);


const rejectFriendRequestEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.rejectFriend),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        // const surveyState = fromSelectors.selectSurveysListState(state);
        return ContactsService.rejectFriendRequest(payload)
            .then(response => fromActions.rejectFriendSuccess(response))
            .catch(error => fromActions.rejectFriendError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

export const friendRequestsEpics = combineEpics(
    getFriendRequestsEpic,
    // getFriendRequestsSuccessEpic,
    addFriendEpic,
    addFriendSuccessEpic,
    acceptFriendRequestEpic,
    acceptFriendRequestSuccessEpic,
    rejectFriendRequestEpic
)