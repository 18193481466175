
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { InviteLink, ScreenHolder } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, THEME } from "../enums";
import { checkErrorResponse } from "../services";
import { selectInviteFriendState } from "../store/selectors";
import { RefreshWithError, ToastMessage } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS, } from "../variables";
import * as fromStore from "../store";
import { environment } from "../app.config";
import { MAIN_APP_ROUTES } from "../routes";

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = halfSize + halfSize / 2 - 10;
const link = environment.WEB_APP_DOMAIN +  MAIN_APP_ROUTES.MY_FRIENDS;
export function InviteFriendScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastText, setToastText] = useState<string>('');
    const [inviteLink, setInviteLink] = useState<string>(link);
    const dispatch = useDispatch();
    const { loading, error, response } = useSelector((state) => selectInviteFriendState(state));

    useEffect(() => {
        dispatch(fromStore.requestInviteLink());
        return () => {
            dispatch(fromStore.removeInviteLink());
        }
    }, []);

    useEffect(() => {
        if (response) setInviteLink(`${link}/${response?.inviteKey}`);
        return () => {
        }
    }, [response]);

    const onShare = async () => {
        
    };

    function copyToClipboard() {
        navigator.clipboard.writeText(inviteLink)
        displayToast(t('copied'));
        //   <key>LSApplicationQueriesSchemes</key>
        //   <array>
        //     <string>whatsapp</string>
        //     <string>mailto</string>
        //   </array>
    }

    function displayToast(text) {
        dispatch(fromStore.toastMessage(text));
    }

    if (loading) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.screenContainer}>
                    <div style={styles.screenHeader}>
                        <CustomText customStyle={styles.shdtxt}>{t('inviteAFriend')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <SpinnerScreen />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (error) {
        return (
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('inviteAFriend')}</CustomText>
                </div>
                <div style={styles.content}>
                    <RefreshWithError
                        text={checkErrorResponse(error)}
                        onRefresh={() => { dispatch(fromStore.requestInviteLink()); }}
                    />
                </div>
            </div>
        )
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('inviteAFriend')}</CustomText>
                </div>
                <div style={styles.content}>
                    <InviteLink
                        inviteLink={inviteLink}
                        onCopy={copyToClipboard}
                        onShare={onShare}
                    />
                </div>
            </div>
            {/* {showToast && <ToastMessage
                position={"bottom"}
                text={toastText}
                visible={showToast}
                onClose={() => { setShowToast(false) }}
            />} */}
        </ScreenHolder>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box'
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    content: {
        width: '100%',
        height: '92%',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        boxSizing: 'border-box'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20
    },
})