import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CommentFormScreen, CommentsSection, MultiChoiceSurvey, PostCommentModal, ScreenHolder, ShortAnswerSurvey, SingleOptionSurvey } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, QUESTION_TYPE, THEME } from "../enums";
import { AppError, CommentForm, SurveyAnswer, SurveyAnswerForm } from "../interfaces";
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, isSurveyActive } from "../services";
import { ActionDialogHolder, ActionDialogHolderType, ActionMessage, PreviewImage, RefreshWithError } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowWidth } from "../variables";
import * as fromStore from "../store";
import { UploadInfoModel } from "../components/upload-info-model";

export function SurveyScreen(props: any) {
    const { history, location, match } = props;
    const { params } = match;
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => fromStore.selectUser(state));
    //remove these after bug fix
    // const entities = useSelector((state) => selectSurveysEntities(state));
    // const [tempSurvey, setTempSurvey] = useState<any>();
    const [showToast, setShowToast] = useState<boolean>(false);
    const [mediaInfo, setMediaInfo] = useState<boolean>(false);
    const [state, setState] = useState({
        selectedOption: '',
        comment: '',
        video: null,
        mediaLoding: true,
        imgPreview: false,
        imgUri: ''
    });

    const answersState = useSelector((state) => fromStore.selectAnswersState(state));
    const comments = useSelector((state) => fromStore.selectComments(state));
    const commentsState = useSelector((state) => fromStore.selectCommentsState(state));
    const { error, loading, survey } = useSelector((state) => fromStore.selectSurveyState(state));
    const dispatch = useDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const [deleteComment, setDeleteComment] = useState<boolean>(false);
    const [delId, setDelId] = useState<string | null>(null);

    useEffect(() => {
        // console.log(params);
        if (params) getSurveyInfo(params);

        return () => {
            removeState();
            // dispatch(fromStore.removeAnswers());
        }
    }, [])

    useEffect(() => {
        if (answersState.selectedAnswer) selectedAnswer(answersState.selectedAnswer);
    }, [answersState.selectedAnswer])

    function getSurveyInfo(params) {
        // if (params && params.id && entities) setTempSurvey(entities[params.id]);

        if (params && params.id) {
            dispatch(fromStore.removeSurvay());
            dispatch(fromStore.removeSelectedAnswer());
            dispatch(fromStore.getSurvey(params.id));
            dispatch(fromStore.getMyAnswer(params.id));
            dispatch(fromStore.getComments(params.id));
        }
    }
    function removeState() {
        dispatch(fromStore.removeSurvay());
        dispatch(fromStore.removeSelectedAnswer());
        dispatch(fromStore.removeComments());
    }

    function selectedAnswer(doc: SurveyAnswer) {
        if (!doc.option) return;
        setState({ ...state, selectedOption: doc.option.id })
    }


    if (!survey && loading) {

        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <SpinnerScreen />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    if (error) {
        return (
            <ScreenHolder location={location} history={history} >
                <div style={styles.container}>
                    <div style={styles.header}>
                        <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                    </div>
                    <div style={styles.content}>
                        <RefreshWithError text={checkErrorResponse(error)} onRefresh={() => { getSurveyInfo(params) }} />
                    </div>
                </div>
            </ScreenHolder>
        )
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                </div>
                <div style={styles.content}>
                    <ShowSurveyByType
                        survey={survey}
                        state={state}
                        onStateChange={(doc) => setState(doc)}
                        onSubmitAnswer={submitAnswer}
                        onImage={onImage}
                        totalComments={comments.length}
                    />
                    {survey && isSurveyActive(survey.endTime) && <CommentFormScreen
                        state={state}
                        onState={(doc) => setState(doc)}
                        onChange={handleFieldChange}
                        onSubmit={submitComment}
                        onCancel={cancelComment}
                        onInfo={() => { setMediaInfo(true) }}
                    />}
                    <CommentsSection
                        deleteState={commentsState.delete}
                        onDelete={(doc) => { setDeleteComment(true); setDelId(doc.id) }}
                        enableDelete={true}
                        onVideoClick={(url) => { history.push(MAIN_APP_ROUTES.VIDEO_PLAYER, { uri: url }) }}
                    />
                   
                </div>
            </div>
            {survey && showToast && <PostCommentModal
                doc={{
                    text: state.comment,
                    video: state.video,
                    userId: survey.user?.id,
                    questionId: survey.id
                }}
                visible={showToast}
                onSuccess={onCommentSuccess}
                onClose={() => { dispatch(fromStore.clearCommentsError()); setShowToast(false); }}
            />}
            {state.imgPreview && <PreviewImage
                uri={state.imgUri}
                visible={state.imgPreview}
                onClose={() => { setState({ ...state, imgPreview: false }) }}
                onDissmiss={() => { setState({ ...state, imgPreview: false }) }}
            />}
            {/* <UserPermissionPopup
                    visible={deleteComment}
                    title={t('attention!')}
                    text={t('areYouSureYouWantRemove')}
                    actions={[`${t('yes')}`, `${t('no')}`]}
                    onAccept={onConfirmDelete}
                    onClose={onCancelDelete}
                    onDissmiss={onCancelDelete}
                /> */}
            {deleteComment && <ActionDialogHolder
                ref={actionRef}
                visible={deleteComment}
                wrapperWidth={'65%'}
                onClose={() => { }}
                onDissmiss={() => { }}
            >
                <ActionMessage
                    text={t('areYouSureYouWantRemove')}
                    onCancel={onCancelDelete}
                    onAccept={onConfirmDelete}
                    headerText={t('attention!')}
                    actions={[`${t('yes')}`, `${t('no')}`]}
                />
            </ActionDialogHolder>}
            {mediaInfo && <UploadInfoModel
                type={"video"}
                visible={mediaInfo}
                onClose={() => { setMediaInfo(false) }}
                onDissmiss={() => { setMediaInfo(false) }}
            />}
        </ScreenHolder>
    );

    function onCancelDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            setDeleteComment(false);
            setDelId(null);
        })

    }

    function onConfirmDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            setDeleteComment(false);
            if (delId) dispatch(fromStore.deleteComment(delId));
        })

    }

    function onImage(uri: string) {
        setState({ ...state, imgPreview: true, imgUri: uri })
    }

    function onRefresh() {
        getSurveyInfo(params);
        dispatch(fromStore.getComments(params.id));
    }

    function submitComment() {
        // dispatch(fromStore.postComment(doc));
        setShowToast(true);
    }

    function cancelComment() {
        setState({ ...state, comment: '', video: null });
    }

    function submitAnswer(doc: SurveyAnswerForm) {
        if (answersState.loading) return;
        if (answersState.selectedAnswer) {
            dispatch(fromStore.updateAnswer({ ...doc, id: answersState.selectedAnswer.id }))
        } else {
            dispatch(fromStore.postAnswer(doc));
        }
    }

    function onError() {
        // console.log('error');
        setShowToast(true);
    }

    function onCommentSuccess() {
        // console.log('success');
        setState({ ...state, comment: '', video: null });
        dispatch(fromStore.toastMessage(t('commentPosted')));
        setShowToast(false);
    }



    function handleFieldChange(fieldId, value) {
        setState({ ...state, [fieldId]: value });
    }
}

function ShowSurveyByType(props) {

    if (props.survey && props.survey.type === QUESTION_TYPE.SINGLE_OPTION) {
        return (
            <SingleOptionSurvey
                survey={props.survey}
                totalComments={props.totalComments}
                state={props.state}
                onImage={props.onImage}
                onStateChange={props.onStateChange}
                onSubmitAnswer={props.onSubmitAnswer}
            />
        )
    }

    if (props.survey && props.survey.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
        return (
            <MultiChoiceSurvey
                survey={props.survey}
                totalComments={props.totalComments}
                state={props.state}
                onImage={props.onImage}
                onStateChange={props.onStateChange}
                onSubmitAnswer={props.onSubmitAnswer}
            />
        )
    }

    if (props.survey && props.survey.type === QUESTION_TYPE.SHORT_ANSWER) {
        return (
            <ShortAnswerSurvey
                survey={props.survey}
                totalComments={props.totalComments}
                onImage={props.onImage}
            />
        )
    }


    return null;

}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowY: 'auto',
        padding: 20,
    },
})