import * as fromActions from '../actions/contacts.actions';
import { AppError, ContactInfo } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<ContactInfo>{
    loading: boolean,
    error?: AppError | null,
}

const contactsAdapter: EntityAdapter<ContactInfo> = createEntityAdapter<ContactInfo>({
    selectId: (doc) => doc.id
})

const initialState: State = contactsAdapter.getInitialState({
    loading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getContacts, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getContactsSuccess, (state, action) => contactsAdapter.setAll({ ...state, loading: false }, action.payload))
        .addCase(fromActions.getContactsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.addContact, (state, action) => contactsAdapter.addOne({ ...state, loading: false }, action.payload))
        .addCase(fromActions.removeContacts, (state, action) => contactsAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearContactsError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = contactsAdapter.getSelectors();
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;