import { createAction } from '@reduxjs/toolkit';
import { SurveyAnswerForm, AppError, MySurvey, SurveyAnswer } from '../../interfaces';

export const getSurvey = createAction<string>('@survey/get');
export const getSurveySuccess = createAction<MySurvey>('@survey/get/success');
export const getSurveyError = createAction<AppError | null>('@survey/get/error');

export const getInvitedSurvey = createAction<string>('@survey/invited/get');
export const getInvitedSurveySuccess = createAction<MySurvey>('@survey/invited/get/success');
export const getInvitedSurveyError = createAction<AppError | null>('@survey/invited/get/error');

export const clearSurveyError = createAction('@survey/clear/error');
export const removeSurvay = createAction('@survey/remove');