import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import { DEVICE_TYPE, DRAWER_TYPE, THEME } from './enums';
import { useDispatch, useSelector } from 'react-redux';
import * as fromScreens from './screens';
import { BottomNav, FriendsScreenHolder, ScreenHolder } from './components';
import { ActionDialogHolder, ActionDialogHolderType, ErrorPopup, SpinnerScreen, ToastMessage } from './utils';
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES } from './routes';
import { checkErrorResponse } from './services';
import { history as RouterHistory } from './services/navigation.service';
import CustomText from './utils/custom-text';
import * as fromStore from './store';
import AppStorage from './services/storage.service';
import './scss/nav.scss';
import { CircularProgress } from '@material-ui/core';
import { LocationState } from './interfaces';

// import { animated, useSpringRef, useTransition } from '@react-spring/web';

const decisionTab = [
    {
        name: 'LIVE SURVEY',
        route: MAIN_APP_ROUTES.LIVE_SURVEY
    },
    {
        name: 'PUBLIC CHOICE',
        route: MAIN_APP_ROUTES.PUBLIC_CHOICE
    },
]

export function DecisionsNavigation(props) {
    return (
        <Switch>
            <Route exact path={`${MAIN_APP_ROUTES.SINGLE_OPTION}/:id?`} component={fromScreens.SingleOptionScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.SHORT_ANSWER}/:id?`} component={fromScreens.ShortAnswerScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.MULTIPLE_CHOICE}/:id?`} component={fromScreens.MultipleChoiceScreen} />
            <Route exact path={MAIN_APP_ROUTES.MY_SURVEYS} component={fromScreens.MySurveysScreen} />
            <Route exact path={MAIN_APP_ROUTES.CREATE_QUESTION} component={fromScreens.CreateQuestionScreen} />
            <Route exact path={MAIN_APP_ROUTES.PUBLIC_CHOICE} component={fromScreens.PublicChoiceScreen} />
            <Route exact path={'/'} render={({ location }) => (
                <Redirect
                    to={{
                        pathname: MAIN_APP_ROUTES.PUBLIC_CHOICE,
                        state: { from: location }
                    }}
                />
            )} />
            <Route component={fromScreens.NotFoundScreen} />
        </Switch>
    )
}


export function HomeTabNavigation(props) {
    const history = useHistory();
    const location = useLocation();
    return (
        <ScreenHolder isHome={true} history={history} location={location} >
            <div style={styles.container}>
                <div className={"top-tab-container"}>
                    {decisionTab.map((doc, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.tabBtn,

                                ...(doc.name === 'LIVE SURVEY') ? {
                                    borderRightWidth: 1,
                                    borderRightColor: THEME.INPUT_BORDER,
                                    borderRightStyle: 'solid',
                                    borderTopLeftRadius: 28
                                } : {
                                    borderTopRightRadius: 28
                                },

                                // ...(location && location?.pathname === doc.route) ? {
                                //     backgroundColor: THEME.ORANGE_RED,
                                // } : {},

                            }}
                            onClick={() => { history ? history.push(doc.route) : null }}
                        >
                            <CustomText customStyle={{
                                ...(location && (location?.pathname === doc.route || location?.pathname.includes(doc.route))) ? {
                                    color: THEME.ORANGE_RED,
                                } : {},
                            }}>{doc.name}</CustomText>
                        </div>
                    ))}
                </div>
                <div className={"top-tab-content-container"}>
                    <Switch>
                        <Route exact path={MAIN_APP_ROUTES.LIVE_SURVEY} component={fromScreens.LiveSurveyScreen} />
                        <Route path={'/'} component={DecisionsNavigation} />
                    </Switch>
                </div>
            </div>
        </ScreenHolder>
    );
}

export function FriendsTabNavigation(props) {
    const { history, location } = props;
    const { path, url } = useRouteMatch();
    return (
        <FriendsScreenHolder history={history} location={location} >
            <Switch>
                <Route exact path={`${MAIN_APP_ROUTES.MY_FRIENDS}/:inviteKey?`} component={fromScreens.FriendsScreen} />
                <Route exact path={MAIN_APP_ROUTES.RECIEVED} component={fromScreens.FriendsRecievedScreen} />
                <Route exact path={MAIN_APP_ROUTES.SENT} component={fromScreens.SentRequestScreen} />
                <Route exact path={MAIN_APP_ROUTES.ADD_FRIENDS} component={fromScreens.AddFriendsScreen} />
                <Route exact path={MAIN_APP_ROUTES.FRIENDS} >
                    <Redirect to={MAIN_APP_ROUTES.MY_FRIENDS} />
                </Route>
            </Switch>
        </FriendsScreenHolder>
    );
}


export function HomeStackNavigaton(props) {
    return (
        <Switch>
            <Route exact path={`${MAIN_APP_ROUTES.EDIT_GROUP}/:id`} component={fromScreens.EditGroupScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.SURVEY_RESULTS}/:id`} component={fromScreens.SurveyResultsScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.OWNER_SURVEY_RESULTS}/:id`} component={fromScreens.OwnerSurveyResultsScreen} />
            <Route exact path={MAIN_APP_ROUTES.MENU} component={fromScreens.MenuScreen} />
            <Route exact path={MAIN_APP_ROUTES.DRAFTS} component={fromScreens.DraftsScreen} />
            <Route exact path={MAIN_APP_ROUTES.PREVIEW_QUESTION} component={fromScreens.PreviewQuestionScreen} />
            <Route exact path={MAIN_APP_ROUTES.POST_QUESTION} component={fromScreens.PostQuestionScreen} />
            <Route exact path={MAIN_APP_ROUTES.GROUPS} component={fromScreens.GroupsScreen} />
            <Route exact path={MAIN_APP_ROUTES.CREATE_GROUP} component={fromScreens.NewGroupScreen} />
            <Route exact path={MAIN_APP_ROUTES.QUESTION_BANK} component={fromScreens.QuestionBankScreen} />
            <Route exact path={MAIN_APP_ROUTES.SEARCH} component={fromScreens.SearchResultsScreen} />
            <Route exact path={MAIN_APP_ROUTES.INVITE_FRIENDS} component={fromScreens.InviteFriendScreen} />
            <Route path={MAIN_APP_ROUTES.FRIENDS} component={FriendsTabNavigation} />
            <Route path={'/'} component={HomeTabNavigation} />
        </Switch>
    )
}

export function HelpOthersNavigation() {
    const { path, url } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST} component={fromScreens.HelpOthersByVotingScreen} />
            <Route exact path={MAIN_APP_ROUTES.COMPLETED_SURVEYS_LIST} component={fromScreens.AnsweredSurveysScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.SURVEY}/:id`} component={fromScreens.SurveyScreen} />
            <Route exact path={`${MAIN_APP_ROUTES.INVITED_SURVEY}/:id`} component={fromScreens.InvitedSurveyScreen} />
            <Route exact path={path}>
                <Redirect to={MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST} />
            </Route>
        </Switch>
    )
}

//Bottom Tab Bar with BottomTabBarNavigator
export function AppBottomTabNaviagtion(props) {
    const history = useHistory();
    const location = useLocation();
    const { path, url } = useRouteMatch();
    const [notifLength, setNotifLenght] = useState<number>(0);
    const notifications = useSelector((state) => fromStore.selectUnViewedNotifications(state));

    useEffect(() => {
        if (notifications && notifLength != notifications.length) setNotifLenght(notifications.length);
        return () => {

        }
    }, [notifications])

    return (
        <div className={"nav-container"}>
            <div className={"app-content"}>
                <Switch>
                    <Route exact path={MAIN_APP_ROUTES.NOTIFICATIONS} component={fromScreens.NotificationScreen} />
                    <Route exact path={MAIN_APP_ROUTES.SETTINGS} component={fromScreens.SettingsScreen} />
                    <Route path={MAIN_APP_ROUTES.YOUR_HELP_MATTERS} component={HelpOthersNavigation} />
                    <Route path={'/'} component={HomeStackNavigaton} />
                </Switch>
            </div>
            <div className={"bottom-nav"}>
                <BottomNav
                    style={styles.bottomNav}
                    history={history}
                    location={location}
                    notifications={notifLength}
                />
            </div>

        </div>
    )
}


export function AppNavigation() {
    const dispatch = useDispatch();
    const history = useHistory();
    // const { path, url } = useRouteMatch();
    const location = useLocation<LocationState>();
    // const { from } = location.state || { from: { pathname: "/", hash: "", search: "", state: undefined, } };
    const { idToken } = useSelector((state) => fromStore.selectUserState(state));
    const [userToken, setUserToken] = useState<string | null>(AppStorage.getIdToken());

    useEffect(() => {
        dispatch(fromStore.checkUserExist());
        return () => { }
    }, []);

    useEffect(() => {
        setUserToken(AppStorage.getIdToken());
        return () => { }
    }, [idToken]);

    useEffect(() => {
        if (userToken != null && location.state && location.state.from) {
            // console.log(from);
            history.replace(location.state.from);
        }
        return () => { }
    }, [userToken]);



    return (
        <Switch>
            {/* <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.WELCOME} component={fromScreens.WelcomScreen} /> */}
            <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.LOGIN} component={fromScreens.LoginScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.REGISTER} component={fromScreens.RegisterScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.THANKS} component={fromScreens.RegistrationAKScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD_MESSAGE} component={fromScreens.ForgotPasswordMessageScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD} component={fromScreens.ForgotPasswordScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={`${LOGIN_SIGNUP_ROUTES.RESET_PASSWORD}/:key`} component={fromScreens.ResetPasswordScreen} />
            <AuthRoute exact isAutherised={userToken != null} path={`${LOGIN_SIGNUP_ROUTES.ACCOUNT_ACTIVATE}/:key`} component={fromScreens.ActivateAccountScreen} />

            <ProtectedRoute exact isAutherised={userToken != null} path={MAIN_APP_ROUTES.CONTACT_US} component={fromScreens.ContactUsScreen} />
            <ProtectedRoute exact isAutherised={userToken != null} path={MAIN_APP_ROUTES.EDIT_PROFILE} component={fromScreens.EditProfileScreen} />
            <ProtectedRoute exact isAutherised={userToken != null} path={MAIN_APP_ROUTES.PRIVACY_POLICY} component={fromScreens.PrivacyPolicyScreen} />
            <ProtectedRoute exact isAutherised={userToken != null} path={MAIN_APP_ROUTES.ABOUT_US} component={fromScreens.AboutUsScreen} />
            <ProtectedRoute exact isAutherised={userToken != null} path={MAIN_APP_ROUTES.VIDEO_PLAYER} component={fromScreens.VideoPlayerScreen} />
            <ProtectedRoute isAutherised={userToken != null} path={'/'} component={AppBottomTabNaviagtion} />

        </Switch>
    )
}


const ProtectedRoute = ({ component: Component, isAutherised, ...rest }) => {
    const dispatch = useDispatch();
    const deviceState = useSelector((state) => fromStore.selectDeviceState(state));

    return (
        <Route
            {...rest}
            render={props => {
                // console.log(props.location.pathname);
                const location = (props.location && props.location.pathname === MAIN_APP_ROUTES.SETTINGS) ? { pathname: "/", hash: "", search: "", state: undefined, } : props.location;
                const returnUrl = (location && location.pathname === '/') ? '' : `returnURL=${location.pathname}`;
                if (isAutherised) return (
                    <>
                        <Component {...props} />
                        {deviceState.toast.show && <ToastMessage
                            position={"bottom"}
                            text={deviceState?.toast?.text as string}
                            visible={deviceState.toast.show}
                            onClose={() => { dispatch(fromStore.clearToastMessage()) }}
                        />}
                    </>
                );

                return (
                    <Redirect
                        to={{
                            pathname: LOGIN_SIGNUP_ROUTES.LOGIN,
                            state: { from: location },
                            search: returnUrl,
                        }}

                    />
                )
            }}
        />
    );
};


const AuthRoute = ({ component: Component, isAutherised, ...rest }) => {
    const actionRef = useRef<ActionDialogHolderType>(null);
    const dispatch = useDispatch();
    const { error, country, ssError } = useSelector((state) => fromStore.selectAuthState(state));
    const deviceState = useSelector((state) => fromStore.selectDeviceState(state));

    return (
        <Route
            {...rest}
            render={props =>
                isAutherised ? (<Redirect to={'/'} />) : (
                    <>
                        <ScreenHolder drawerType={DRAWER_TYPE.HALF} location={props.location} history={props.history}>
                            <Component {...props} />
                        </ScreenHolder>
                        {isErrorExist() && <ActionDialogHolder
                            ref={actionRef}
                            visible={isErrorExist()}
                            wrapperWidth={'65%'}
                            onClose={() => { }}
                            onDissmiss={() => { }}
                        >
                            <ErrorPopup
                                text={getAuthErrors()}
                                visible={isErrorExist()}
                                onClose={() => {
                                    if (actionRef && actionRef.current) actionRef.current.closeAction(() => dispatch(fromStore.clearAuthError()));
                                }}
                            />
                        </ActionDialogHolder>}
                        {deviceState.toast.show && <ToastMessage
                            position={"bottom"}
                            text={deviceState?.toast?.text as string}
                            visible={deviceState.toast.show}
                            onClose={() => { dispatch(fromStore.clearToastMessage()) }}
                        />}
                    </>

                )
            }
        />
    );

    function isErrorExist(): boolean {
        if (error) return true;
        if (ssError) return true;
        return false;
    }
    function getAuthErrors() {
        if (error) return checkErrorResponse(error);
        if (ssError) return checkErrorResponse(ssError);
        return null;
    }
}


function LandingScreen() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: THEME.BLACK,
            height: '100%',
            width: '100%',
            padding: 20,
            boxSizing: 'border-box',
        }}>
            <CircularProgress style={{ color: THEME.ORANGE_RED }} size={35} />
        </div>

    )
}



const styles: { [key: string]: React.CSSProperties } = ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        minWidth: 300,
        // backgroundColor: THEME.BLACK
    },
    bottomNav: {
        // position: 'fixed',
        display: 'flex',
        width: '100%',
        // bottom: 0,
        backgroundColor: THEME.WHITE,
        borderTopWidth: 1,
        borderTopColor: 'rgb(244,244,244)',
        borderTopStyle: 'solid',
        // zIndex: 10,

    },

    //Drafts decision nav
    tabContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid'

    },

    tabBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        width: '45%',
        // backgroundColor: 'green',
        cursor: 'pointer',


    }
})



// export function LoginSignupNavigation() {
//     const actionRef = useRef<ActionDialogHolderType>(null);
//     const history = useHistory();
//     const location = useLocation();
//     const { path, url } = useRouteMatch();
//     const dispatch = useDispatch();
//     const { error, country, ssError } = useSelector((state) => fromStore.selectAuthState(state))
//     const { loading } = useSelector((state) => fromStore.selectUserState(state));
//     const deviceState = useSelector((state) => fromStore.selectDeviceState(state));

//     return (
//         <>
//             <ScreenHolder drawerType={DRAWER_TYPE.HALF} location={location} history={history}>
//                 <Switch>
//                     {/* <Route path={LOGIN_SIGNUP_ROUTES.WELCOME} exact component={fromScreens.WelcomScreen} /> */}
//                     <Route path={LOGIN_SIGNUP_ROUTES.LOGIN} exact component={fromScreens.LoginScreen} />
//                     <Route path={LOGIN_SIGNUP_ROUTES.REGISTER} exact component={fromScreens.RegisterScreen} />
//                     <Route path={LOGIN_SIGNUP_ROUTES.THANKS} exact component={fromScreens.RegistrationAKScreen} />
//                     <Route path={LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD_MESSAGE} exact component={fromScreens.ForgotPasswordMessageScreen} />
//                     <Route path={LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD} exact component={fromScreens.ForgotPasswordScreen} />
//                     <Route path={`${LOGIN_SIGNUP_ROUTES.RESET_PASSWORD}/:key`} exact component={fromScreens.ResetPasswordScreen} />
//                     <Route path={`${LOGIN_SIGNUP_ROUTES.ACCOUNT_ACTIVATE}/:key`} exact component={fromScreens.ActivateAccountScreen} />
//                     <Route path={'/'}>
//                         <Redirect to={LOGIN_SIGNUP_ROUTES.LOGIN} />
//                     </Route>
//                 </Switch>

//             </ScreenHolder>

//             {isErrorExist() && <ActionDialogHolder
//                 ref={actionRef}
//                 visible={isErrorExist()}
//                 wrapperWidth={'65%'}
//                 onClose={() => { }}
//                 onDissmiss={() => { }}
//             >
//                 <ErrorPopup
//                     text={getAuthErrors()}
//                     visible={isErrorExist()}
//                     onClose={() => {
//                         if (actionRef && actionRef.current) actionRef.current.closeAction(() => dispatch(fromStore.clearAuthError()));
//                     }}
//                 />
//             </ActionDialogHolder>}
//             {deviceState.toast.show && <ToastMessage
//                 position={"bottom"}
//                 text={deviceState?.toast?.text as string}
//                 visible={deviceState.toast.show}
//                 onClose={() => { dispatch(fromStore.clearToastMessage()) }}
//             />}
//         </>
//     );

//     function isErrorExist(): boolean {
//         if (error) return true;
//         if (ssError) return true;
//         return false;
//     }
//     function getAuthErrors() {
//         if (error) return checkErrorResponse(error);
//         if (ssError) return checkErrorResponse(ssError);
//         return null;
//     }
// }

// export function MainAppNavigation(props) {
//     const dispatch = useDispatch();
//     const history = useHistory();
//     const location = useLocation();
//     const { path, url } = useRouteMatch();
//     const { user, loading, idToken, userExist } = useSelector((state) => fromStore.selectUserState(state));
//     const deviceState = useSelector((state) => fromStore.selectDeviceState(state));
//     useEffect(() => {
//         // console.log(location);
//         // console.log(path, url);
//         // if(location?.state && location?.state?.returnURL) history.push(location?.state?.returnURL);

//         return () => { }
//     }, [])

//     if (loading) {
//         return (
//             <ScreenHolder drawerType={DRAWER_TYPE.HALF} location={location} history={history}>
//                 <SpinnerScreen />
//             </ScreenHolder>
//         )
//     }

//     return (
//         <>
//             <Switch>
//                 <Route path={MAIN_APP_ROUTES.CONTACT_US} exact component={fromScreens.ContactUsScreen} />
//                 <Route path={MAIN_APP_ROUTES.EDIT_PROFILE} exact component={fromScreens.EditProfileScreen} />
//                 <Route path={MAIN_APP_ROUTES.PRIVACY_POLICY} exact component={fromScreens.PrivacyPolicyScreen} />
//                 <Route path={MAIN_APP_ROUTES.VIDEO_PLAYER} exact component={fromScreens.VideoPlayerScreen} />
//                 <Route path={LOGIN_SIGNUP_ROUTES.LOGIN} exact>
//                     <Redirect to={'/'} />
//                 </Route>
//                 <Route path={'/'} component={AppBottomTabNaviagtion} />
//             </Switch>
//             {deviceState.toast.show && <ToastMessage
//                 position={"bottom"}
//                 text={deviceState?.toast?.text as string}
//                 visible={deviceState.toast.show}
//                 onClose={() => { dispatch(fromStore.clearToastMessage()) }}
//             />}
//         </>
//     )
// }