import React, { } from "react";
import { useTranslation } from "react-i18next";
import { DEVICE_TYPE, THEME } from "../enums";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import CustomText from "../utils/custom-text";

import { BottomDialogHoderType, BottomDialogHolder } from "../utils";
import { useRef } from "react";


const wrapperHeight = currentWindowHeight() / 2;

interface Props {
    visible: boolean,
    list: any[] | undefined,
    title: string,
    selected: any,
    onClose: () => void,
    onSelectOption: (item: any) => void,
    onDissmiss?: () => void
}


export function OptionsDialog(props: Props) {
    const {
        visible,
        title,
        list,
        selected,
        onClose,
        onDissmiss,
        onSelectOption
    } = props;
    const { t, i18n } = useTranslation();
    const dialogRef = useRef<BottomDialogHoderType>(null);

    return (
        <BottomDialogHolder
            ref={dialogRef}
            wrapperHeight={wrapperHeight}
            visible={visible}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div style={styles.actionContainer}>
                <div style={styles.actionHeader}>
                    <CustomText customStyle={{ color: 'red', fontSize: 18 }}>{title}</CustomText>
                </div>
                <div style={{ height: wrapperHeight - 60 }}>
                    {list?.map((item, index) => (
                        <ScreenOptionItem
                            key={index + '.key'}
                            item={item}
                            selected={selected}
                            onClick={onOptionChange}
                        />
                    ))}
                </div>
            </div>
        </BottomDialogHolder>
    );

    function onOptionChange(item) {
        if (dialogRef && dialogRef.current) dialogRef.current.closeAnimation(() => onSelectOption(item));
        // onSelectOption(item);
    }
}


function ScreenOptionItem(props) {
    const { t, i18n } = useTranslation();
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderBottomColor: THEME.INPUT_BORDER,
                borderBottomStyle: 'solid'
                    // backgroundColor: 'green'
                // ...pressed && { backgroundColor: 'rgba(200,200,200,0.5)' }
            }}
            onClick={() => props.onClick(props.item)}
        >
            <div style={{}}>
                <CustomText>{t(props.item.name)}</CustomText>
            </div>
            <div style={{
                ...styles.radioBtn,
                ...{ backgroundColor: (props.selected?.code === props.item?.code) ? THEME.ORANGE_RED : THEME.WHITE }
            }}></div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    actionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid'
    },
    actionContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,

    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10
    },
    option: { width: 70, height: 70, marginRight: 25, padding: 5, borderRadius: 5 },

    radioBtn: {
        width: 20,
        height: 20,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 3,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderStyle: 'solid'
    },
})