import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { StyleSheet, View, Text, ImageBackground, TextInput, FlatList, Pressable, Image } from "react-native";
import { DEVICE_TYPE, FONT, THEME } from "../enums";
import { Country } from "../interfaces";
import { FieldForm } from "../utils";
import CustomText from "../utils/custom-text";
import { countries } from "../countries";
import { CURRENT_OS } from "../variables";

const boxHeight = 25;
interface Props {
    countries: Country[];
    selected?: Country,
    loading: boolean,
    setCountry: (doc: Country) => void,
}

const SearchDoc = {
    field: 'search',
    label: '',
    secure: false,
    type: 'name',
    placeholder: 'Search...',
    pattern: ''

}

export function CountriesList(props: Props) {
    const { countries, loading, selected, setCountry } = props;
    const [search, setSearch] = useState<string>('');

    function handleFieldChange(fieldId, value) {
        setSearch(value);
    }

    return (
        <div style={{...styles.container, paddingTop: 20}}>
            <div style={styles.searchContainer}>
                <FieldForm
                    key={SearchDoc.field}
                    id={SearchDoc.field}
                    onChange={handleFieldChange}
                    secure={SearchDoc.secure}
                    placeholder={SearchDoc.placeholder}
                    type={SearchDoc.type}
                    value={search}
                    pattern={SearchDoc.pattern}
                    label={SearchDoc.label}
                ></FieldForm>
            </div>

            <div style={styles.listContainer}>

                {/* <FlatList
                    data={countries.filter(doc =>
                        doc?.code.toLowerCase().includes(search?.toLowerCase()) ||
                        doc?.name.toLowerCase().includes(search?.toLowerCase())
                    )}
                    showsVerticalScrollIndicator={false}
                    removeClippedSubviews={CURRENT_OS === DEVICE_TYPE.ANDROID ? true : false}
                    renderItem={({ item, index }) =>
                        <CountryListItem
                            item={item}
                            selected={selected}
                            listLength={countries.length}
                            onClick={(doc) => { setCountry(doc) }}

                        />}
                    keyExtractor={(item, index) => item.code}
                    refreshing={loading}
                    onRefresh={() => { }}
                    ListFooterComponent={() => <div style={{ height: 60, padding: 10 }}></div>}
                /> */}
                {
                    countries
                        .filter(doc =>
                            doc?.code.toLowerCase().includes(search?.toLowerCase()) ||
                            doc?.name.toLowerCase().includes(search?.toLowerCase())
                        )
                        .map((item, index) => (
                            <CountryListItem
                                key={index}
                                item={item}
                                selected={selected}
                                listLength={countries.length}
                                onClick={(doc) => { setCountry(doc) }}

                            />
                        ))
                }
            </div>

        </div>
    )
}


interface CountryListItemProps {
    item: Country,
    selected?: Country,
    listLength: number,
    onClick: (doc: Country) => void,
}


function CountryListItem(props: CountryListItemProps) {
    const { item, selected, listLength, onClick } = props;

    return (
        <div
            style={{
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE },
                ...styles.countryItemContainer
            }}
            onClick={() => onClick(item)}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',

            }}>
                <div style={{
                    ...styles.radioBtn,
                    ...{ backgroundColor: (selected?.code === item?.code) ? THEME.ORANGE_RED : THEME.WHITE }
                }}></div>
                <div style={styles.innervalue}>
                    <div style={styles.thumbnail}>
                        <img
                            src={countries[item.code.toLocaleLowerCase()]}
                            // resizeMode={"cover"}
                            style={{
                                height: '100%',
                                width: '100%',
                                borderRadius: 5,
                                objectFit: 'cover'
                            }}
                        />
                        {/* <ImageBackground
                                source={require('../../assets/countries/countries.png')}
                                style={{ display: 'flex', overflow: 'hidden', borderRadius: 5 }}
                                imageStyle={{
                                    resizeMode: 'cover',
                                    transform: [{ translateX: 0 }, { translateY: -((item?.SeqID) * boxHeight) }],
                                    height: (listLength * boxHeight)
                                }}
                            /> */}
                    </div>
                    <CustomText>{item.name}</CustomText>
                </div>
            </div>

        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    //list styles
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: THEME.WHITE,
        width: '100%',
        height: '100%',
    },
    searchContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10,
    },
    listContainer: {
        height: '100%',
        overflowY: 'auto'
    },
    countryItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // width: '100%',
        height: 60,

        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: 'rgb(244,244,244)'
    },
    radioBtn: {
        width: 20,
        height: 20,
        borderRadius: 10,
        marginRight: 10,
        marginTop: 3,
        borderWidth: 1,
        borderBlockStyle: 'solid',
        borderColor: THEME.INPUT_BORDER
    },
    innervalue: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    thumbnail: {
        width: 35, height: boxHeight, marginRight: 10, borderRadius: 5
    },
})