import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, THEME } from "../enums";
import { getUserName } from "../services";
import { CustomScrollView } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { CURRENT_OS, currentWindowWidth } from "../variables";
import { GroupUserContact, UserContact } from "./contact";
import { SearchBar } from "./search-bar";

interface GroupUsersListProps {
    list: any[],
    onAddNew: () => void
}
export function GroupUsersList(props: GroupUsersListProps) {
    const { list, onAddNew } = props;
    const [search, setSearch] = useState<string>('');
    const { t, i18n } = useTranslation();


    function filterUsers() {
        return list
            .filter(doc => {
                return doc.email && doc?.email.toLowerCase().includes(search?.toLowerCase()) ||
                    getUserName(doc) && getUserName(doc)?.toLowerCase().replace(/ /g, '').includes(search?.toLowerCase().replace(/ /g, ''));
            })
    }


    return (
        <div style={styles.container}>
            <div style={{ width: '100%' }}>
                <SearchBar
                    id={"search"}
                    type={"none"}
                    value={search}
                    placeholder={t("searchPeople")}
                    placeholderTextColor={THEME.PLACEHOLDER}
                    onChange={(id, value) => setSearch(value)}
                />
            </div>
            {/* <div style={{ marginTop: 10, marginLeft: 10 }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedPeople')}</CustomText>
            </div> */}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10, marginLeft: 10
            }}>
                <CustomText customStyle={{ opacity: 0.7 }}>{t('suggestedPeople')}</CustomText>
                {/* <div
                    style={{}}
                    onClick={onAddNew}
                >
                    <CustomText customStyle={{ opacity: 0.7, color: pressed ? THEME.ORANGE_RED : THEME.BLACK }}>{t('addNewUser')}</CustomText>
                </div> */}
            </div>
            <div style={{ ...styles.listHolder, height: '82%' }}>
                <ItemsList
                    list={list}
                    filterUsers={filterUsers}
                />
            </div>

        </div>
    )



}


function ItemsList(props) {
    const { t, i18n } = useTranslation();

    if (props.list && props.list.length === 0 && props.loading) {
        return (
            <SpinnerScreen />
        )
    }

    return (
        <CustomScrollView
            listLength={props.list.length}
            countAll={props.list.length}
            refreshing={props.loading}
            onRefresh={() => { }}
            fetchMore={() => { }}
        // refreshing={props.loading}
        // onRefresh={props.onRefresh}
        // ListFooterComponent={() => <div style={{ height: 20, padding: 10 }}></div>}
        >
            {props.filterUsers().map((item, index) => (<GroupUserContact key={item.id} item={item} />))}

        </CustomScrollView>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', boxSizing: 'border-box'
    },
    listHolder: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        marginLeft: 10,

    },
    contactPressable: {
        paddingTop: 10, paddingBottom: 10, borderBottomWidth: 1, borderBottomColor: THEME.INPUT_BORDER
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 25, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 12,
    },
})