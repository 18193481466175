
import * as fromActions from '../actions/profile.actions';
import { User, AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    loading: boolean,
    imageLoading: boolean,
    settingsLoading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
    imageLoading: false,
    settingsLoading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.updateProfile, (state, action) => ({ ...state, loading: true }))
        .addCase(fromActions.updateProfileSuccess, (state, action) => ({ ...state, loading: false, imageLoading: false }))
        .addCase(fromActions.updateProfileError, (state, action) => ({ ...state, loading: false, imageLoading: false, error: action.payload }))

        .addCase(fromActions.updateProfileSettings, (state, action) => ({ ...state, settingsLoading: true }))
        .addCase(fromActions.updateProfileSettingsSuccess, (state, action) => ({ ...state, settingsLoading: false, }))
        .addCase(fromActions.updateProfileSettingsError, (state, action) => ({ ...state, settingsLoading: false, error: action.payload }))

        .addCase(fromActions.uploadProfileImage, (state, action) => ({ ...state, imageLoading: true }))
        .addCase(fromActions.uploadProfileImageSuccess, (state, action) => ({ ...state, imageLoading: true, }))
        .addCase(fromActions.uploadProfileImageError, (state, action) => ({ ...state, imageLoading: false, error: action.payload }))

        .addCase(fromActions.clearProfileError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
