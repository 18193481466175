import React, { PureComponent, useState } from 'react';
// import { TextInput, View, Keyboard, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import CustomText from '../utils/custom-text';
import { APP_THEME_COLOR } from '../variables';
import { FONT, THEME } from '../enums';
import { formValidators } from '../services';

interface Props {
    value: any,
    secure?: any,
    placeholder: string,
    keyboardtype?: any,
    type?: any,
    id: string,
    pattern?: any,
    label: string,
    password?: string,
    fieldStyles?: any,
    clearButtonMode?: any,
    editable?: any,
    placeholderTextColor?: string,
    currentPassword?: string,
    height?: number,
    inputStyle?: any,
    style?: any,
    onChange: (id: string, value: any) => void
}

export function FieldForm(props: Props) {
    const {
        height,
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        label,
        password,
        fieldStyles,
        clearButtonMode,
        editable,
        inputStyle,
        style,
        placeholderTextColor
    } = props;
    const [error, setError] = useState<string | null>('');
    const { t } = useTranslation();




    const handleChange = (value: any) => {
        const text = value;
        props.onChange(props.id, text);
    }

    const handleValidation = (text: any) => {
        const { id, pattern, password, currentPassword, } = props;
        setError(formValidators(t, text, id, pattern, password));
    }



    return (
        <div style={{ ...{ display: 'flex', flexDirection: 'column' }, ...fieldStyles }}>
            {label ? <CustomText customStyle={styles.lableHeader}>{label}</CustomText> : null}
            <div style={{ ...styles.inputIconView, ...style, ...(editable === false ? { opacity: 0.4, } : null) }}>
                <input
                    className={"form-input"}
                    style={{ ...inputStyle }}
                    placeholder={placeholder}
                    // placeholderTextColor={placeholderTextColor ? placeholderTextColor : "#000000"}
                    // textContentType={type}
                    // secureTextEntry={secure}
                    // keyboardType={keyboardtype}
                    onChange={event => {
                        handleChange(event.target.value);
                        handleValidation(event.target.value);
                    }}
                    value={value}
                    autoCapitalize={"none"}
                    // autoCompleteType={"off"}
                    autoFocus={false}
                // caretHidden={false}
                // clearButtonMode={clearButtonMode ? clearButtonMode : "while-editing"}
                // contextMenuHidden={false}
                // editable={editable}
                //onBlur={Keyboard.dismiss} 
                />
                {error ? <CustomText customStyle={{ color: 'red', padding: 5 }}>{error}</CustomText> : null}
            </div>

        </div>
    )

}

const styles: { [key: string]: React.CSSProperties } = ({
    lableHeader: {
        fontSize: 14,
        fontFamily: FONT.MANROPE_700,
        marginBottom: 10,
        marginTop: 10,
        color: APP_THEME_COLOR
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        minHeight: 35,
        backgroundColor: "rgb(244,244,244)",
        color: THEME.BLACK,
        // width: '100%',
        borderRadius: 10,
        paddingLeft: 15,
        fontFamily: FONT.MANROPE_REGULAR
    },
});

// export default withTranslation()(FieldForm) ;