import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable, of } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as InviteService from '../../services/invite.service';
import { MAIN_APP_ROUTES } from '../../routes';
import { ContactInfo } from '../../interfaces';
import { getUserName } from '../../services';

const getInviteLinkEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getInviteLink),
    switchMap(() => {
        return InviteService.getInviteLink()
            .then(response => fromActions.getInviteLinkSuccess(response))
            .catch(error => fromActions.getInviteLinkError(extractError(error, MAIN_APP_ROUTES.INVITE_FRIENDS)))
    })
);

const requestInviteLinkEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.requestInviteLink),
    switchMap(() => {
        return InviteService.requestNewInviteLink()
            .then(response => fromActions.requestInviteLinkSuccess(response))
            .catch(error => fromActions.requestInviteLinkError(extractError(error, MAIN_APP_ROUTES.INVITE_FRIENDS)))
    })
);

const acceptInviteLinkEpic = (action$, state$): Observable<Action> => action$.pipe(
    ofType(fromActions.acceptInviteLink),
    map((action: any) => action.payload),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
        // if(state && state.inviteFriendState && state.inviteFriendState.inviteKey && state.inviteFriendState.inviteKey === payload) {
        //     return of(fromActions.disgardAction());
        // }
        return InviteService.acceptInvitationRequest(payload)
            .then(response => fromActions.acceptInviteLinkSuccess(response))
            .catch(error => fromActions.acceptInviteLinkError(extractError(error, MAIN_APP_ROUTES.INVITE_FRIENDS)))
    })
);

const acceptInviteLinkSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.acceptInviteLinkSuccess),
    map((action: any) => action.payload),
    switchMap((payload: ContactInfo) => {
        // console.log(payload);
        if(payload) return [fromActions.addContact(payload), fromActions.toastMessage(`Added ${getUserName(payload.requestedUser)} as friend`)]
        return of(fromActions.disgardAction());
    })
);

export const inviteFriendsEpics = combineEpics(
    getInviteLinkEpic,
    requestInviteLinkEpic,
    acceptInviteLinkEpic,
    acceptInviteLinkSuccessEpic
);