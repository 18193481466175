
import * as fromActions from '../actions/survey.actions';
import { User, AppError, MySurvey } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    survey?: MySurvey | null,
    loading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getSurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getSurveySuccess, (state, action) => ({ ...state, loading: false, survey: action.payload }))
        .addCase(fromActions.getSurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.getInvitedSurvey, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getInvitedSurveySuccess, (state, action) => ({ ...state, loading: false, survey: action.payload }))
        .addCase(fromActions.getInvitedSurveyError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.removeSurvay, (state, action) => ({ ...state, error: null, survey: null, loading: false }))
        .addCase(fromActions.clearSurveyError, (state, action) => ({ ...state, error: null }))

        .addDefaultCase(state => ({ ...state }));
})

export const selectState = (state: State) => state;
export const selectSurvey = (state: State) => state.survey;
