import * as fromActions from '../actions';
import { ofType, combineEpics, } from 'redux-observable';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import AppStorage from '../../services/storage.service';
import * as AuthService from '../../services/auth.service';
import { Observable, of } from 'rxjs';
import { extractError } from '../../services/error-handler';
import { IdToken, User } from '../../interfaces';
import { Action } from '@reduxjs/toolkit';
import { LOGIN_SIGNUP_ROUTES } from '../../routes';



const checkUserExistEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.checkUserExist),
    switchMap(async () => {
        const idToken = AppStorage.getIdToken();
        // const country = AppStorage.getUserCountry();

        if (idToken) {
            return fromActions.userExist(idToken);
        } else {
            return fromActions.newUser(null);
        }
        // return AppStorage.getIdToken()
        //     .then((res: string | null) => {
        //         if (res) {
        //             return fromActions.userExist();
        //         };
        //         return fromActions.newUser(null);
        //     })
        //     .catch(error => {
        //         return fromActions.newUser(extractError(error));
        //     });
    })
);

const newUserEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.newUser),
    switchMap(() => [fromActions.getUserCountry()])
);

const userExistEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.userExist),
    switchMap(() => [fromActions.getUserCountry(), fromActions.initializeApp(), fromActions.getUser()])
);

const initializeAppEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.initializeApp),
    switchMap(() => [
        // fromActions.checkInitialNotification(),
        fromActions.getNotifications()
    ])
);

const getUserEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getUser),
    switchMap(() => {
        return AuthService.getUserDetails()
            .then((user) => fromActions.getUserSuccess(user))
            .catch(error => fromActions.getUserError(extractError(error)))
    })
);


// const addUserEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.addUser),
//     map((action: any) => action.payload),
//     switchMap((user: User) => {
//         return AppStorage.setIdToken(idToken)
//             .then(() => fromActions.addUserSuccess())
//             .catch(error => fromActions.addUserError(extractError(error)))
//     })
// );

// const addUserSuccessEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
//     ofType(fromActions.addUserSuccess),
//     switchMap(() => [])
// );


const removeUserEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.removeUser),
    switchMap(() => {
        AppStorage.removeIdToken();
        return of(fromActions.removeUserSuccess());
        // .catch(error => fromActions.removeUserError(extractError(error)))
    })
);


const saveUserIdTokenEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.saveUserIdToken),
    map((action: any) => action.payload),
    switchMap((response: IdToken) => {
        // console.log("response idtoken", response);
        AppStorage.setIdToken(response.id_token)
        return of(fromActions.checkUserExist());

        // .catch(error => fromActions.saveUserIdTokenError(extractError(error)))
    })
);




export const userEpics = combineEpics(
    initializeAppEpic,
    userExistEpic,
    checkUserExistEpic,
    getUserEpic,
    // addUserEpic,
    // addUserSuccessEpic,
    removeUserEpic,
    saveUserIdTokenEpic,
    newUserEpic,

    //checkUserStandardEpic
);
