import React from "react"
import { useTranslation } from "react-i18next"
import { CustomButton } from "."
import { THEME } from "../enums"
import { getFileSize } from "../services"
import { FormInput, VideoInput } from "../utils"
import { currentWindowWidth, FILE_SIZE_LIMIT } from "../variables"
import { VideoErrorMessage } from "./file-size-error"


const InputDoc = {
    field: 'comment',
    label: '',
    required: true,
    secure: false,
    type: 'none',
    textContentType: 'none',
    placeholder: 'typeYourComment',
    multiline: true,
    numberOfLines: 2,
    backgroundColor: THEME.INPUT_BACKGROUND

};

interface Props {
    state: any,
    onState: (doc: any) => void,
    onChange: (id: string, value: string) => void,
    onSubmit: () => void,
    onCancel: () => void,
    onInfo?: () => void,
}

export function CommentFormScreen(props: Props) {
    const {
        state,
        onState,
        onCancel,
        onChange,
        onSubmit,
        onInfo
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.commentForm}>
            <div style={{ marginBottom: 10 }}>
                <FormInput
                    id={InputDoc.field}
                    onChange={onChange}
                    secure={InputDoc.secure}
                    placeholder={t(InputDoc.placeholder)}
                    type={InputDoc.type}
                    value={state[InputDoc.field]}
                    multiline={InputDoc.multiline}
                    numberOfLines={InputDoc.numberOfLines}
                    required={InputDoc.required}
                    textContentType={InputDoc.textContentType}
                    backgroundColor={InputDoc.backgroundColor}
                />
            </div>
            <div style={{ marginBottom: 15, width: '100%', boxSizing: 'border-box' }}>
                <VideoInput
                    label={t('uploadVideo')}
                    placeholder={t('dragFilesHere')}
                    video={state['video']}
                    onClick={(doc) => {
                        // console.log(doc);
                        onState({ ...state, ['video']: doc });
                    }}
                    onRemove={() => {
                        onState({ ...state, ['video']: null })
                    }}
                    onVideo={(doc) => { }}
                    onInfo={onInfo}
                />
            </div>
            {(state.video && state.video.size) && <VideoErrorMessage bytes={state.video.size} />}
            <div style={styles.actions}>
                <div style={styles.btn}>
                    <CustomButton
                        customStyle={{}}
                        textStyle={{ textTransform: 'uppercase' }}
                        name={t('submit')}
                        enable={_isValid()} //|| state.video !== null
                        onClick={onSubmit}
                    />
                </div>
                <div style={styles.btn}>
                    <CustomButton
                        customStyle={{}}
                        textStyle={{ textTransform: 'uppercase' }}
                        name={t('cancel')}
                        enable={true}
                        backgroundColor={THEME.CANCEL}
                        onClick={onCancel}
                    />
                </div>
            </div>

        </div>
    );

    function _isValid() {
        const { comment, video } = state;
        if (comment && comment !== "") return true;
        if (video && video.size && (getFileSize(video.size) <= FILE_SIZE_LIMIT)) return true;
        return false;
    }
}

const styles: { [key: string]: React.CSSProperties } = ({

    //Comment Form
    commentForm: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        // height: 40
    },

    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
    }
})