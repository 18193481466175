import { createAction } from '@reduxjs/toolkit';
import { AppError, EmailLoginCredentials, EmailSignupCredentials, FacebookLoginForm, GoogleLoginForm, IdToken, ResetPasswordForm, User } from '../../interfaces';



export const emailLogin = createAction<EmailLoginCredentials>('@auth/email/login');
export const emailLoginSuccess = createAction<IdToken>('@auth/email/login/success');
export const emailLoginError = createAction<AppError | null>('@auth/email/login/error');

export const emailSignup = createAction<EmailSignupCredentials>('@auth/email/signup');
export const emailSignupSuccess = createAction('@auth/email/signup/success');
export const emailSignupError = createAction<AppError | null>('@auth/email/signup/error');

export const facebookLogin = createAction<FacebookLoginForm>('@auth/facebook/login');
export const facebookLoginSuccess = createAction<IdToken>('@auth/facebook/login/success');
export const facebookLoginError = createAction<AppError | null>('@auth/facebook/login/error');
export const getFacebookUserDetails = createAction('@auth/facebook/user/details');

export const googleLogin = createAction<GoogleLoginForm>('@auth/google/login');
export const googleLoginSuccess = createAction<IdToken>('@auth/google/login/success');
export const googleLoginError = createAction<AppError | null>('@auth/google/login/error');

export const appleLogin = createAction('@auth/apple/login');
export const appleLoginSuccess = createAction<IdToken>('@auth/apple/login/success');
export const appleLoginError = createAction<AppError | null>('@auth/apple/login/error');

export const logout = createAction('@auth/logout');
export const logoutSuccess = createAction('@auth/logout/sucess');
export const logoutError = createAction<AppError | null>('@auth/logout/error');
export const logoutCancel = createAction('@auth/logout/cancel');

export const updateUserProfile = createAction<any>('@auth/user/update/profile');
export const updateUserProfileSuccess = createAction<any>('@auth/user/update/profile/success');
export const updateUserProfileError = createAction<AppError | null>('@auth/user/update/profile/error');

export const changeUserPassword = createAction<ResetPasswordForm>('@auth/user/password/change');
export const changeUserPasswordSuccess = createAction('@auth/user/password/change/success');
export const changeUserPasswordError = createAction<AppError | null>('@auth/user/password/change/error');

export const resetUserPassword = createAction<string>('@auth/user/password/reset');
export const resetUserPasswordSuccess = createAction<any>('@auth/user/password/reset/success');
export const resetUserPasswordError = createAction<AppError | null>('@auth/user/password/reset/error');

export const activateAccount = createAction<string>('@auth/account/activate');
export const activateAccountSuccess = createAction<any>('@auth/account/activate/success');
export const activateAccountError = createAction<AppError | null>('@auth/account/activate/error');

export const saveUserCountry = createAction<string>('@auth/user/save-country');
export const saveUserCountrySuccess = createAction<string>('@auth/user/save-country/success');
export const saveUserCountryError = createAction<AppError | null>('@auth/user/save-country/error');

export const getUserCountry = createAction('@auth/user/get-saved-country');
export const getUserCountrySuccess = createAction<string>('@auth/user/get-saved-country/success');
export const getUserCountryError = createAction<AppError | null>('@auth/user/get-saved-country/error');

export const clearAuthError = createAction('@auth/clear/error');

