import { createAction } from '@reduxjs/toolkit';
import { AppError, MySurvey, SingleOptionSurveyForm } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: MySurvey[]
}

export const getMySurveys = createAction('@my-surveys/get');
export const getMySurveysSuccess = createAction<PageNation>('@my-surveys/get/success');
export const getMySurveysError = createAction<AppError | null>('@my-surveys/get/error');

export const getMoreMySurveys = createAction('@my-surveys/get/more');
export const getMoreMySurveysSuccess = createAction<PageNation>('@my-surveys/get/more/success');
export const getMoreMySurveysError = createAction<AppError | null>('@my-surveys/get/more/error');

// export const uploadMySurveyImage = createAction<any>('@my-survey/question-image/upload');
// export const uploadMySurveyImageSuccess = createAction<any>('@my-survey/question-image/upload/success');
// export const uploadMySurveyImageError = createAction<AppError | null>('@my-survey/question-image/upload/error');

// export const uploadMySurveyOptionImage = createAction<any>('@my-survey/option-image/upload');
// export const uploadMySurveyOptionImageSuccess = createAction<any>('@my-survey/option-image/upload/success');
// export const uploadMySurveyOptionImageError = createAction<AppError | null>('@my-survey/option-image/upload/error');

export const postMySurvey = createAction<any>('@my-surveys/post');
export const postMySurveySuccess = createAction<any>('@my-surveys/post/success');
export const postMySurveyError = createAction<AppError | null>('@my-surveys/post/error');

export const updateMySurvey = createAction<any>('@my-surveys/update');
export const updateMySurveySuccess = createAction<any>('@my-surveys/update/success');
export const updateMySurveyError = createAction<AppError | null>('@my-surveys/update/error');

export const deleteMySurvey = createAction<any>('@my-surveys/delete');
export const deleteMySurveySuccess = createAction<any>('@my-surveys/delete/success');
export const deleteMySurveyError = createAction<AppError | null>('@my-surveys/delete/error');

export const removeMySurvays = createAction('@my-surveys/remove');
export const clearMySurveysError = createAction('@my-surveys/clear/error');