import { createAction } from '@reduxjs/toolkit';
import { AppError, SurveyAnswer, SurveyAnswerForm, SurveyCategory, SurveyComment } from '../../interfaces';

export const getAnswers = createAction<string>('@answers/get');
export const getAnswersSuccess = createAction<SurveyAnswer[]>('@answers/get/success');
export const getAnswersError = createAction<AppError | null>('@answers/get/error');

export const getMyAnswer = createAction<string>('@answers/get/my-answer');
export const getMyAnswerSuccess = createAction<SurveyAnswer>('@answers/get/my-answer/success');
export const getMyAnswerError = createAction<AppError | null>('@answers/get/my-answer/error');

export const postAnswer = createAction<SurveyAnswerForm>('@answers/post');
export const postAnswerSuccess = createAction<SurveyAnswer>('@answers/post/success');
export const postAnswerError = createAction<AppError | null>('@answers/post/error');

export const updateAnswer = createAction<any>('@answers/update');
export const updateAnswerSuccess = createAction<SurveyAnswer>('@answers/update/success');
export const updateAnswerError = createAction<AppError | null>('@answers/update/error');

export const removeAnswers = createAction('@answers/remove');
export const removeSelectedAnswer = createAction('@answers/remove/selected');
export const clearAnswersError = createAction('@answers/clear/error');
export const clearSelectedAnswerError = createAction('@answers/clear/error/selected');