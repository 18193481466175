import { createSelector } from "reselect";
import * as fromReducer from '../reducers/drafts-list.reducer';

const selectState = state => state.draftsListState;

export const selectDraftsListState = createSelector(selectState, fromReducer.selectState);
export const selectDraftsList = createSelector(
    selectState,
    fromReducer.selectAll
);

export const selectDraftsListEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);