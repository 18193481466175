import React, { useState } from "react";
import { CommentForm, MySurvey, SurveyAnswer, SurveyOption } from "../interfaces";
import CustomText from "../utils/custom-text";
import UserAvatarIcon from '../../assets/images/user-avatar.png';
import { getCustomDateFormat, isSurveyActive } from "../services";
interface Props {
    survey: MySurvey
}
export function PostedTo(props: Props) {
    const {
        survey,

    } = props;
    return (
        <div>
        <div style={{}}>
            <CustomText> Posted to: </CustomText>
            <CustomText><img
                src={UserAvatarIcon}
                style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: "transparent",
                    objectFit: 'cover',
                    borderRadius: "50%"
                }}
            /></CustomText>
        </div>
        <div style={styles.contentInfo}>
                <CustomText customStyle={styles.timeStamp}>{survey.startTime ? getCustomDateFormat(survey.startTime) : ''}</CustomText>
              
            </div>
        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    surveyInfo: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',

    },
    surveyIcon: {
        width: 50,
        height: 50,
        borderRadius: 50,
        marginRight: 15,
        backgroundColor: '#F7B500',
        padding: 8,
        boxSizing: 'border-box'
    },
    surveyData: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
    },
    
    contentInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 15
        // marginRight: 43,
    },
    timeStamp: {
        fontSize: 11,
        color: "#777777"
    },
    comments: {
        fontSize: 11,
        color: 'red'
    },

    //survey options 
    surveyOptionsStatus: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#E1E1E1'
    },


    //Comments sections
    commentsSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 15
    },
    submitComment: {
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    btn: {
        width: `${(100 / 2) - 5}%`,
        height: 40
    },
})