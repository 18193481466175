import { createAction } from '@reduxjs/toolkit';
import { AppError, GlobalContactInfo } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: GlobalContactInfo[]
}

export const getGlobalContacts = createAction('@global-contacts/get');
export const getGlobalContactsSuccess = createAction<PageNation>('@global-contacts/get/success');
export const getGlobalContactsError = createAction<AppError | null>('@global-contacts/get/error');

export const getMoreGlobalContacts = createAction('@global-contacts/get/more');
export const getMoreGlobalContactsSuccess = createAction<PageNation>('@global-contacts/get/more/success');
export const getMoreGlobalContactsError = createAction<AppError | null>('@global-contacts/get/more/error');

export const addGlobalContacts = createAction<GlobalContactInfo[]>('@global-contacts/add');

export const updateOneGlobalContact = createAction<GlobalContactInfo>('@global-contacts/update/one');

export const removeGlobalContacts = createAction('@global-contacts/remove');
export const clearGlobalContactsError = createAction('@global-contacts/clear/error');