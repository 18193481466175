import React, { useState } from "react";
import { ScreenHolder } from "./screen-holder";
import CustomText from "../utils/custom-text";
import { THEME } from "../enums";
import { useTranslation } from "react-i18next";
import { FormSelector } from "../utils";
import { MAIN_APP_ROUTES } from "../routes";
import { OptionsDialog } from "./options-selector-modal";
import { useEffect } from "react";
// import { navigationRef, getCurrentRoute } from "../services";

const OPTIONS_LIST = [
    { name: 'myFriends', code: MAIN_APP_ROUTES.MY_FRIENDS },
    { name: 'addFriends', code: MAIN_APP_ROUTES.ADD_FRIENDS },
    { name: 'friendRequestSent', code: MAIN_APP_ROUTES.SENT },
    { name: 'friendRequestReceived', code: MAIN_APP_ROUTES.RECIEVED },
];


export function FriendsScreenHolder(props) {
    const { t, i18n } = useTranslation();
    const [screen, setScreen] = useState<{ name: string, code: string }>(OPTIONS_LIST[0]);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        if(props.location) onNavigationChange(props.location.pathname);
        return () => {
        }
    }, [])

    function onNavigationChange(path: string) {
        const activeOption = OPTIONS_LIST.find(doc => doc.code === path);
        if(!activeOption) return;
        setScreen(activeOption);
    }

    return (
        <ScreenHolder location={props.location} history={props.history}>
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('friends')}</CustomText>
                </div>
                <div style={styles.content}>
                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20, }}>
                        <FormSelector
                            placeholder={t('select')}
                            selected={screen}
                            onClick={() => setIsVisible(true)}
                        />
                    </div>
                    <div style={{ display: 'flex', height: '92%', width: '100%' }}>
                        {props.children}
                    </div>
                </div>
            </div>
            {isVisible && <OptionsDialog
                visible={isVisible}
                list={OPTIONS_LIST}
                title={t('selectOption')}
                selected={screen}
                onClose={() => { setIsVisible(false) }}
                onSelectOption={(item) => { setScreen(item); props.history.push(item.code); setIsVisible(false); }}
                onDissmiss={() => { setIsVisible(false) }}
            />}
        </ScreenHolder>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    // Screen container styles

    screenContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        boxSizing: 'border-box'

    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%'
        // height: currentWindowHeight() - 260,
    },
})