import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ScreenHolder, SurveyItem } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, THEME } from "../enums";
import { MAIN_APP_ROUTES } from "../routes";
import { checkErrorResponse, getRemainingTime } from "../services";
import { CustomScrollView, RefreshWithError, ToastMessage } from "../utils";
import CustomText from "../utils/custom-text";
import { SpinnerScreen } from "../utils/spinner.screen";
import { currentWindowWidth, CURRENT_OS } from "../variables";
import * as fromStore from "../store";

export function HelpOthersByVotingScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const surveys = useSelector((state) => fromStore.selectSurveysList(state));
    const [counter, setCounter] = useState<any>('');
    const { loading, error, countAll } = useSelector((state) => fromStore.selectSurveysListState(state));

    useEffect(() => {
        if (surveys.length === 0 && !error) getSurveys();
        return () => {
        }
    }, [])

    function getSurveys() {
        dispatch(fromStore.getSurveys());
    }

    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
    }

    const redirectScreenFunction =(item)=>{
        item?.resultpermission
      let count =  getRemainingTime(item?.endTime)
      if(count =="00:00" &&(item?.resultpermission==true ||item?.resultpermission==null)){
        history.push(`${MAIN_APP_ROUTES.OWNER_SURVEY_RESULTS}/${item.id}`, { id: item.id })
      }else{
        history.push(`${MAIN_APP_ROUTES.SURVEY}/${item.id}`, { id: item.id })
      }
     
      
    }

    return (
        <ScreenHolder history={history} location={location} >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.shdtxt}>{t('yourHelpMatters')}</CustomText>
                </div>
                <div style={styles.content}>
                    <CustomScrollView
                        listLength={surveys.length}
                        countAll={countAll}
                        error={error}
                        emptyTitle={t('noNewSurveys')}
                        onToast={displayToastMessage}
                        refreshing={loading}
                        onRefresh={getSurveys}
                        fetchMore={() => { dispatch(fromStore.getMoreSurveys()); }}
                    >
                        {surveys.map((item:any, index) => (
                            <SurveyItem
                                key={item.id}
                                item={item}
                                onClick={(doc) => { 
                                    redirectScreenFunction(item)
                                    // if(item?.resultpermission==true && )
                                    // history.push(`${MAIN_APP_ROUTES.SURVEY}/${doc.id}`, { id: doc.id })
                                 }}

                            />
                        ))}
                    </CustomScrollView>
                </div>
            </div>
        </ScreenHolder>
    );
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid',
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowY: 'auto'
    },
    refreshError: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
    }
})