import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ScreenHolder } from "../components";
import { DEVICE_TYPE, DRAWER_TYPE, NOTIFICATIONS_THEME, THEME } from "../enums";
import { ActionDialogHolder, ActionDialogHolderType, ActionMessage, CustomScrollView, IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, CURRENT_OS } from "../variables";
import * as fromStore from "../store";
import { checkErrorResponse, getCustomDateFormat, getTimeElapsed } from "../services";
import { AppNotification } from "../interfaces";
import { DeleteIcon } from "../components/delete-icon";
import * as NotificationService from '../services/notifications.service';
const actionSize = currentWindowWidth() / 3;

const NOTIFICATIONS = [
    { id: "123sdsjdklajdasi1", type: "reminder", title: "Reminder: Please respond to the survey which John has shared before 1PM.", timestamp: "2021-05-29T01:45:10.0Z", icon: 'tag' },
    { id: "123sdsjdklajdasi2", type: "offer", title: `Offer: Don't miss out the opportunity to join the "Learners Group".`, timestamp: "2021-05-28T01:45:10.0Z", icon: 'tag' },
    { id: "123sdsjdklajdasi3", type: "group", title: "Steve joined the group and will get notification for all the comments posted.", timestamp: "2020-05-27T01:45:10.0Z", icon: 'groups' },
    { id: "123sdsjdklajdasi4", type: "user", title: "Bell voted on your survey please check.", timestamp: "2021-02-18T01:45:10.0Z", icon: 'notif-user' },
]



export function NotificationScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const actionRef = useRef<ActionDialogHolderType>(null);
    const notifications = useSelector((state) => fromStore.selectNotifications(state));
    const notificationState = useSelector((state) => fromStore.selectNotificationState(state));
    const [visible, setVisible] = useState<boolean>(false);
    const [delId, setDelId] = useState<string | null>(null);

    useEffect(() => {
        if (notifications && notifications.length === 0) getMyNotifications();
        // dispatch(fromStore.addNotifications(NOTIFICATIONS));
        return () => {

        }
    }, [])

    function getMyNotifications() {
        dispatch(fromStore.getNotifications());
    }

    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
    }

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.screenContainer}>
                <div style={styles.screenHeader}>
                    <CustomText customStyle={styles.shdtxt}>{t('notifications')}</CustomText>
                </div>
                <div style={styles.content}>
                    <CustomScrollView
                        listLength={notifications.length}
                        countAll={notificationState.countAll}
                        emptyTitle={t('noNotifications')}
                        error={notificationState.error}
                        refreshing={notificationState.loading}
                        onToast={displayToastMessage}
                        onRefresh={getMyNotifications}
                        fetchMore={() => { dispatch(fromStore.getMoreNotifications()); }}
                    >
                        {notifications.map((item, index) => (
                            <NotificationItem
                                key={index}
                                item={item}
                                deleteState={notificationState.delete}
                                onClick={onNotification}
                                onDelete={(item) => { setVisible(true); setDelId(item.id) }}

                            />
                        ))}
                    </CustomScrollView>
                </div>

            </div>
            {visible && <ActionDialogHolder
                ref={actionRef}
                visible={visible}
                wrapperWidth={'65%'}
                onClose={() => { }}
                onDissmiss={() => { }}
            >
                <ActionMessage
                    text={t('areYouSureYouWantRemove')}
                    onCancel={onCancelDelete}
                    onAccept={onConfirmDelete}
                    headerText={t('attention!')}
                    actions={[`${t('yes')}`, `${t('no')}`]}
                />
            </ActionDialogHolder>}
        </ScreenHolder>
    )

    function onNotification(doc: AppNotification) {
        if (doc) {
            if (doc.markAsRead === false) {
                dispatch(fromStore.updateNotifications({
                    id: doc.id,
                    markAsRead: true,
                }));
            }
            if (doc?.data && doc?.data?.GO_TO_SCREEN) history.push(NotificationService.navigateToAppScreen(doc?.data));
        }
    }

    function onCancelDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            setVisible(false);
            setDelId(null);
        })

    }

    function onConfirmDelete() {
        if (actionRef && actionRef.current) actionRef.current.closeAction(() => {
            if (delId) dispatch(fromStore.deleteNotification(delId));
            setVisible(false);
        })

    }

}
interface NotificationItemProps {
    item: AppNotification,
    deleteState: any,
    onClick: (doc: AppNotification) => void,
    onDelete: (doc: AppNotification) => void
}
function NotificationItem(props: NotificationItemProps) {
    const { item, deleteState, onClick, onDelete } = props;
    const { t, i18n } = useTranslation();

    function getBackgroundColor(type: string) {
        switch (type) {
            case "reminder": return NOTIFICATIONS_THEME.PURPLE;
            case "offer": return NOTIFICATIONS_THEME.ORANGE;
            case "group": return NOTIFICATIONS_THEME.BLUE;
            case "user": return NOTIFICATIONS_THEME.GREEN;
            default: return NOTIFICATIONS_THEME.BLUE;
        }
    }
    return (
        <div
            className={"on-press-cls"}
            style={{
                ...{
                    paddingLeft: 20,
                    paddingRight: 20,
                    cursor: 'pointer',
                },
                // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE }
            }}
            
        >
            <div style={{ ...styles.notifContainer, }}>
                {item?.data?.GO_TO_SCREEN && <div style={{ ...styles.notifIconContainer, ...{ backgroundColor: getBackgroundColor(item.type) } }} onClick={() => onClick(item)}>
                    <div style={{
                        width: 20,
                        height: 20,
                    }}>
                        <IconProvider type={"tag"} />
                    </div>
                </div>}
                <div style={styles.notifBody}>
                    <div style={{display: 'flex', flexDirection: 'column'}} onClick={() => onClick(item)}>
                        {item?.title && <CustomText customStyle={{ fontSize: 14, fontWeight: item.markAsRead === true ? 'none' : 600 }}>{item?.title}</CustomText>}
                        {item?.message && <CustomText customStyle={{ fontSize: 13, fontWeight: item.markAsRead === true ? 'none' : 600 }}>{item?.message}</CustomText>}
                    </div>
                    <div style={styles.actionBody}>
                        {item.createdDate && <CustomText customStyle={{ fontSize: 12, color: '#A9A9B0' }}>{getTimeElapsed(item.createdDate)}</CustomText>}
                        <DeleteIcon
                            customStyle={{ padding: 10, borderRadius: 50, marginLeft: 10, }}
                            item={item}
                            state={deleteState}
                            onClick={() => onDelete(item)}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        boxSizing: 'border-box'

    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid',
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '92%',
        overflowY: 'auto',
        boxSizing: 'border-box'
        // height: currentWindowHeight() - 260,
    },

    notifContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingTop: 15,
        paddingBottom: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid',
    },

    notifIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 35,
        height: 35,
        borderRadius: 20,
        marginRight: 20,
        // marginTop: 5,
    },
    notifBody: {
        display: 'flex',
        flexDirection: 'column',
        width: '85%'
    },

    actionBody: {
        // marginTop: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})