import React, { Component } from 'react';
import { ContactInfo, InviteFriendInfo } from '../interfaces';
import restService from './rest.service';

export const getInviteLink = (): Promise<InviteFriendInfo> => {
    return restService.getUserInviteLink().then(response => response.data);
}

export const requestNewInviteLink = (): Promise<InviteFriendInfo> => {
    return restService.requestInviteLink().then(response => response.data);
}

export const acceptInvitationRequest = (key:string): Promise<ContactInfo> => {
    return restService.acceptInvitationRequest(key).then(response => response.data);
}
