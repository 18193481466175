import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveyService from '../../services/surveys.service';
import { SurveyCategory } from '../../interfaces';

const removeAppStateEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.removeAppState),
    switchMap(() => [
        fromActions.removeMySurvays(),
        fromActions.removeDrafts(),
        fromActions.removeSurveys(),
    ])
);

const toastMessageEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.toastMessage),
    delay(3000),
    switchMap(() => [
        fromActions.clearToastMessage(),
        fromActions.clearInviteLinkError(),
        // fromActions.clearMySurveysError()
    ])
);


export const deviceEpics = combineEpics(
    removeAppStateEpic,
    toastMessageEpic
);