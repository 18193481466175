import { createSelector } from "reselect";
import * as fromReducer from '../reducers/notification.reducer';

const selectState = state => state.notificationState;

export const selectNotificationState = createSelector(selectState, fromReducer.selectState);
export const selectNotifications = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectNotificationsEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);

export const selectUnViewedNotifications = createSelector(
    selectNotifications,
    docs => docs.filter(doc => doc.markAsRead === false)
);


