import { createAction } from '@reduxjs/toolkit';
import { AppError, SurveyCategory } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: SurveyCategory[]
}

export const getCategories = createAction('@categories/get');
export const getCategoriesSuccess = createAction<PageNation>('@categories/get/success');
export const getCategoriesError = createAction<AppError | null>('@categories/get/error');

export const getMoreCategories = createAction('@categories/get/more');
export const getMoreCategoriesSuccess = createAction<PageNation>('@categories/get/more/success');
export const getMoreCategoriesError = createAction<AppError | null>('@categories/get/more/error');

export const removeCategories = createAction('@categories/remove');
export const clearCategoriesError = createAction('@categories/clear/error');