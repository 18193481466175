import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveyService from '../../services/surveys.service';
import { SurveyCategory } from '../../interfaces';
import * as fromSelectors from '../selectors';

const getCategoriesEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getCategories),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const categoriesState = fromSelectors.selectCategoriesState(state);
        return SurveyService.getAllCategories({ page: 0, limit: categoriesState.limit })
            .then((response) => fromActions.getCategoriesSuccess(response))
            .catch(error => fromActions.getCategoriesError(extractError(error)))
    })
);

const getMoreCategoriesEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreCategories),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const categoriesState = fromSelectors.selectCategoriesState(state);
        return SurveyService.getAllCategories({ page: categoriesState.page + 1, limit: categoriesState.limit })
            .then((response) => fromActions.getMoreCategoriesSuccess(response))
            .catch(error => fromActions.getMoreCategoriesError(extractError(error)))
    })
);

export const categoriesEpics = combineEpics(
    getCategoriesEpic,
    getMoreCategoriesEpic
);