import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { THEME } from "../enums";
import { getFileSize, formatBytes } from "../services";
import CustomText from "../utils/custom-text";
import { FILE_SIZE_LIMIT } from "../variables";

interface Props {
    bytes: number,
}

export function VideoErrorMessage(props: Props) {
    const { bytes } = props;
    const { t, i18n } = useTranslation();
    const size = getFileSize(bytes);
    //` File size ${formatBytes(bytes)} exceeded the allowed limit of ${FILE_SIZE_LIMIT} MB`
    // console.log(size, formatBytes(bytes));
    if (size > FILE_SIZE_LIMIT) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5, backgroundColor: "#f55f5f", marginBottom: 10, borderRadius: 5 }}>
                <AiOutlineWarning color={"yellow"} size={20} style={{marginRight: 10}} />
                <CustomText customStyle={{ color: THEME.WHITE, }}>{t('exceededFileSizeLimit', { size: formatBytes(bytes), limit: FILE_SIZE_LIMIT })}</CustomText>
            </div>
        );
    }
    return null;
}


