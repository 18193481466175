import { createSelector } from "reselect";
import { AppState } from '../reducers';
import * as fromReducer from '../reducers/answers.reducer';

const selectState = state => state.answersState;

export const selectAnswersState = createSelector(selectState, fromReducer.selectState);
export const selectAnswers = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectAnswersEntities = createSelector(selectState, fromReducer.selectEntities);
