import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { THEME } from "../enums";
import { ImageWithSpinner } from "./icon-provider";


interface PreviewImage {
    uri: string,
    visible: boolean,
    onClose: () => void,
    onDissmiss?: () => void
}

export function PreviewImage(props: PreviewImage) {
    const {
        uri,
        visible,
        onClose,
        onDissmiss
    } = props;
    const { t, i18n } = useTranslation();


    return (
        <div style={styles.container}>
            <div style={styles.contentContainer}>
                <ImageWithSpinner
                    uri={uri}
                    resizeMode={"contain"}
                    customStyle={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'transparent'
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 15,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '50%',
                        padding: 5,
                        cursor: 'pointer'
                    }}
                    onClick={onClose}
                >
                    {/* <Icon name={"close"} type={"ionicons"} size={40} color={THEME.WHITE} /> */}
                    <AiOutlineClose size={40} color={THEME.WHITE} />
                </div>
            </div>
        </div>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({

    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flex: 1,
        zIndex: 100,
        flexDirection: 'column',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
        // marginTop: 22
    },

    contentContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%'
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid',
    },

    modalText: {
        // marginBottom: 15,
        textAlign: "center"
    },

    messageContainer: {
        // paddingTop: 10, paddingBottom: 10,
        // paddingLeft: 15, paddingRight: 15,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        padding: 5,
        backgroundColor: THEME.RED,
        borderRadius: 20,
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    actionBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: `${100 / 2}%`,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 15, paddingBottom: 15,


    },
    actionBtnText: {
        fontWeight: 500,
        fontSize: 16
    },

})