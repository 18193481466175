import React from 'react';
import './App.scss';
import { AppNavigation } from './app/nav';
import { Provider } from 'react-redux';
import store from './app/store/reducers';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div className={'app-container'}>
      <Provider store={store}>
        <BrowserRouter>
          <AppNavigation />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
