import React, { useState } from "react";
import { DRAWER_TYPE, FONT, THEME } from "../enums";
import { useTranslation } from 'react-i18next';
import { currentWindowWidth } from "../variables";
import CustomText from "../utils/custom-text";
import { CustomButton, ScreenHolder } from "../components";
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES } from "../routes";

const actionSize = currentWindowWidth() / 3;
export function NotFoundScreen(props: any) {
    const { history, location } = props;
    const { t, i18n } = useTranslation();
    const [terms, setTerms] = useState<boolean>(false)

    return (
        <div style={styles.container}>
            {/* <View style={styles.header}>
                    <CustomText customStyle={styles.headerText}>{t('notFound')}</CustomText>
                </View> */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
                <CustomText
                    customStyle={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                        fontSize: 25,
                        fontWeight: 'bold',
                        color: THEME.DEFAULT_BORDER,
                        marginBottom: 15
                    }}
                >{t('pageNotFound')}</CustomText>
                <div style={{
                    width: '50%'
                }}>
                    <CustomButton
                        textStyle={{ textTransform: 'uppercase' }}
                        name={t('goToHome')}
                        enable={true}
                        onClick={() => history.push(MAIN_APP_ROUTES.HOME)}
                    />
                </div>

            </div>
        </div>
    )
}


export function NoAuthNotFound(props) {
    const { history, route } = props;
    const { t, i18n } = useTranslation();
    const [terms, setTerms] = useState<boolean>(false);

    return (
        <div style={styles.container}>
            {/* <div style={styles.header}>
            <CustomText customStyle={styles.headerText}>{t('notFound')}</CustomText>
        </div> */}
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
                <CustomText
                    customStyle={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                        fontSize: 25,
                        fontWeight: 'bold',
                        color: THEME.DEFAULT_BORDER,
                        marginBottom: 15
                    }}
                >{t('userLoginRequired')}</CustomText>
                <div style={{
                    width: '50%'
                }}>
                    <CustomButton
                        textStyle={{ textTransform: 'uppercase' }}
                        name={t('goToLogin')}
                        enable={true}
                        onClick={() => history.push(LOGIN_SIGNUP_ROUTES.LOGIN)}
                    />
                </div>

            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    headerText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        fontSize: 17,
        textTransform: 'uppercase',
        color: THEME.BLACK,
    },
    newsLetter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20
    },
    checkbox: {
        backgroundColor: THEME.WHITE,
        borderWidth: 0
    },
})
