import * as fromActions from '../actions/sent-requests.actions';
import { AppError, FriendRequest } from '../../interfaces';
import { createEntityAdapter, createReducer, EntityAdapter, EntityState } from '@reduxjs/toolkit'


export interface State extends EntityState<FriendRequest> {
    countAll: number,
    limit: number,
    page: number,
    loading: boolean,
    nextLoading: boolean,
    error?: AppError | null,
}

const sentRequestsAdapter: EntityAdapter<FriendRequest> = createEntityAdapter<FriendRequest>({
    selectId: (doc) => doc.id
})

const initialState: State = sentRequestsAdapter.getInitialState({
    countAll: 0,
    limit: 20,
    page: 0,
    loading: false,
    nextLoading: false,
});

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.getSentRequests, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.getSentRequestsSuccess, (state, action) => sentRequestsAdapter.setAll({ ...state, loading: false, }, action.payload))
        .addCase(fromActions.getSentRequestsError, (state, action) => ({ ...state, error: action.payload, loading: false }))

        .addCase(fromActions.removeOneSentRequest, (state, action) => sentRequestsAdapter.removeOne({ ...state, error: null }, action.payload))
        .addCase(fromActions.addOneSentRequest, (state, action) => sentRequestsAdapter.upsertOne({ ...state, error: null }, action.payload))

        .addCase(fromActions.removeSentRequests, (state, action) => sentRequestsAdapter.removeAll({ ...state, error: null }))
        .addCase(fromActions.clearSentRequestsError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase(state => ({ ...state }));
});

export const { selectAll, selectEntities, selectIds, selectById } = sentRequestsAdapter.getSelectors();
export const selectState = (state: State) => state;
export const selectLoading = (state: State) => state.loading;
export const selectError = (state: State) => state.error;