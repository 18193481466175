import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FriendsList } from "../components";
import { THEME } from "../enums";
import { ContactInfo, GlobalContactInfo } from "../interfaces";
import CustomText from "../utils/custom-text";
import { currentWindowHeight, currentWindowWidth, } from "../variables";
import * as fromStore from "../store";
import { ErrorPopup } from "../utils";
import { checkErrorResponse, getUserName } from "../services";

const actionSize = currentWindowWidth() / 3;
const halfSize = currentWindowHeight() / 2;
const contactsWindowHeight = halfSize + halfSize / 2 - 10;

export function FriendsScreen(props: any) {
    const { history, location, match } = props;
    const { params } = match;
    const { t, i18n } = useTranslation();
    const inviteFriendState = useSelector(state => fromStore.selectInviteFriendState(state));
    const dispatch = useDispatch();
    const [state, setState] = useState<any>({
        remove: false,
    });
    const [selectedContacts, setSelectedContacts] = useState<any[]>([]);

    useEffect(() => {
        if (params && params.inviteKey) acceptInvitation(params.inviteKey);
        dispatch(fromStore.getContacts());

        return () => {
            dispatch(fromStore.removeContacts());
        }
    }, []);


    useEffect(() => {

        if (inviteFriendState.error) displayToastMessage(inviteFriendState.error);
        return () => {
            // dispatch(fromStore.clearDecisions());
        }
    }, [inviteFriendState.error])

    function acceptInvitation(inviteKey: string) {
        // console.log("------------->", inviteKey);
        dispatch(fromStore.acceptInviteLink(inviteKey));
        // history.setParams({ inviteKey: null });

    }

    function displayToastMessage(error) {
        if (!error) return;
        dispatch(fromStore.toastMessage(checkErrorResponse(error)));
      }

    function addContact(item: GlobalContactInfo) {
        setSelectedContacts([...selectedContacts, item]);
    }

    function removeContact(item: GlobalContactInfo) {
        let index = selectedContacts.indexOf(item);
        selectedContacts.splice(index, 1);
        let newList = [...selectedContacts];
        setSelectedContacts(newList);
    }

    

    return (
        <div style={styles.screenContainer}>
            <div style={styles.screenContent}>
                <FriendsList
                    selected={selectedContacts}
                    contactsWindowHeight={'80%'}
                    onRefresh={() => { dispatch(fromStore.getContacts()); }}
                    onRemove={onRemove}
                />
            </div>
        </div>

    );

    function onRemove(doc: ContactInfo) {
        setState({ ...state, remove: true, contact: doc.recipientUser });
    }

    function closePopup() {
        setState({ ...state, remove: false });
    }
}

const styles: { [key: string]: React.CSSProperties } = ({
    // Screen container styles

    screenContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        backgroundColor: THEME.WHITE
    },
    screenHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: THEME.DEFAULT_BORDER,
        borderBottomStyle: 'solid'
    },
    shdtxt: {
        fontSize: 17,
        color: THEME.ORANGE_RED,
        textTransform: 'uppercase',
    },
    screenContent: {
        width: '100%',
        height: '100%',
        padding: 20,
        boxSizing: 'border-box'
    },
})