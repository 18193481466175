import React from "react";
import { THEME } from "../enums";
import { ContactIcon } from "../icons";
import { ContactInfo } from "../interfaces";
import { getUserName } from "../services";
import { ImageWithSpinner } from "../utils";
import CustomText from "../utils/custom-text";


interface UserFriendProps {
    item: ContactInfo,
    onAccept: (doc: ContactInfo) => void,
    onReject: (doc: ContactInfo) => void,
    onRemove: (doc: ContactInfo) => void,
}
export function UserFriend(props: UserFriendProps) {
    const { item, onAccept, onReject, onRemove } = props;
    const { recipientUser } = item;
    return (
        <div style={styles.contactPressable}>
            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={recipientUser.profilePicUrl}
                            resizeMode={"cover"}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                            spinnerSize={15}
                        />
                    </div>
                    <div style={{flexDirection:'column',display:'flex'}}>
                        <div style={{fontSize: 14}}>{getUserName(recipientUser)}</div>
                        {recipientUser?.email &&<div style={{fontSize: 13}}>{recipientUser?.email}</div>}
                        {/* {recipientUser?.email &&<CustomText customStyle={{ fontSize: 14 }}>{recipientUser?.email}</CustomText>} */}
                    </div>
                    
                </div>
                {/* <div style={{flexDirection:'row',justifyContent:'flex-start'}}>
                        <CustomText customStyle={{ fontSize: 14 ,flexDirection:'row'}}>{getUserName(recipientUser)}</CustomText>
                        {recipientUser?.email &&<CustomText customStyle={{ fontSize: 14 }}>{recipientUser?.email}</CustomText>}
                    </div> */}
                {/* <div
                    style={{}}
                    onPress={() => onRemove(item)}
                >
                    <Icon name={"delete"} type={"ionicons"} size={25} color={pressed ? THEME.RED : THEME.DEFAULT_BORDER} />
                </div> */}
            </div>

        </div>

    )
}

export function UserListItem(props) {
    const { item } = props;
    return (
        <div style={styles.contactPressable}>
            <div style={styles.contactContainer}>
                <div style={styles.contactInfo}>
                    <div style={styles.contactIcon}>
                        <ImageWithSpinner
                            uri={item.profilePicUrl}
                            resizeMode={"cover"}
                            customStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                            imgStyle={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "transparent",
                                borderRadius: 45
                            }}
                            spinnerSize={15}
                        />
                    </div>
                    
                </div>
                <div style={{ width: '77%' }}>
                        <CustomText customStyle={{ fontSize: 14 }} numberOfLines={2} ellipsizeMode={"tail"}>{getUserName(item)}</CustomText>
                    </div>
                {/* <Pressable
                    style={{}}
                    onPress={() => onRemove(item)}
                >
                    <Icon name={"delete"} type={"ionicons"} size={25} color={pressed ? THEME.RED : THEME.DEFAULT_BORDER} />
                </Pressable> */}
            </div>

        </div>

    )
}


const styles: { [key: string]: React.CSSProperties } = ({
    contactPressable: {
        width: '100%',
        paddingTop: 10, 
        paddingBottom: 10, 
        borderBottomWidth: 1, 
        borderBottomColor: THEME.INPUT_BORDER, 
        borderBottomStyle: 'solid', 
        boxSizing: 'border-box'
    
    },
    contactContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    contactInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%'
    },
    contactIcon: {
        width: 45, height: 45, borderRadius: 45, marginRight: 20
    },
    indicator: {
        width: 24,
        height: 24,
        padding: 2,
        borderWidth: 1,
        borderColor: THEME.INPUT_BORDER,
        borderRadius: 12,
        borderStyle: 'solid'
    },
})