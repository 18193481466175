import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveysService from '../../services/surveys.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';

const getSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const surveyState = fromSelectors.selectSurveysListState(state);
        return SurveysService.getSharedSurveys({ page: 0, limit: surveyState.limit })
            .then(response => fromActions.getSurveysSuccess(response))
            .catch(error => fromActions.getSurveysError(extractError(error, MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST)))
    })
);


const getMoreSurveysEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreSurveys),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const surveyState = fromSelectors.selectSurveysListState(state);
        return SurveysService.getSharedSurveys({ page: surveyState.page + 1, limit: surveyState.limit })
            .then(response => fromActions.getMoreSurveysSuccess(response))
            .catch(error => fromActions.getMoreSurveysError(extractError(error, MAIN_APP_ROUTES.ACTIVE_SURVEYS_LIST)))
    })
);


export const surveysListEpics = combineEpics(
    getSurveysEpic,
    getMoreSurveysEpic
);