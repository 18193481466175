import React, { Component } from 'react';
import { TFunction } from 'react-i18next';
import { MySurvey, SurveyDuration } from '../interfaces';

const durationList: SurveyDuration[] = require("../../assets/others/duration.json");
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

//Converts date timestamp to number to years/months/days/hours/minutes
export const getSubscriptionCount = (endDate) => {
    if (endDate) {
        let date1 = new Date();
        let date2 = endDate ? new Date(endDate) : new Date();
        let diff = new Date(date2.getTime() - date1.getTime());

        let years = diff.getUTCFullYear() - 1970; // Gives difference as year
        let months = diff.getUTCMonth(); // Gives month count of difference
        let days = diff.getUTCDate() - 1; // Gives day count of difference
        let hours = diff.getUTCHours() // Gives hours count of difference
        let minutes = diff.getUTCMinutes() // Gives minutes count of difference
        let seconds = diff.getUTCSeconds() // Gives secounds 
        if (years >= 0 && months >= 0 && days >= 0 && hours >= 0 && minutes > 0) return `${dateFormat(years, months, days, hours, minutes, seconds)}`;
        if (years >= 0 && minutes > 0) return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
        if (years >= 0 && months == 0 && days == 0 && hours == 0 && minutes == 0 && seconds > 0) return `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
        if (seconds === 0) return '0s';
        return '';
    }
    return '';

    function dateFormat(years, months, days, hours, minutes, seconds) {
        let expire = "";
        if (years > 0) {
            expire += years + " years "
        }
        if (months > 0) {
            expire += months + " months "
        }
        if (days > 0 && days === 1) {
            expire += days + " day "
        }
        if (days > 1) {
            expire += days + " days "
        }
        if (years == 0 && months == 0 && days >= 0 && hours > 0) {
            expire += hours + " hours " + minutes + " minutes "
        }
        if (years == 0 && months == 0 && days == 0 && hours == 0) {
            expire += minutes + " minutes " + seconds + " seconds "
        }
        return expire;
    }
}


export const getTimeElapsed = (endDate: any) => {
    if (endDate) {
        let date1 = new Date();
        let date2 = endDate ? new Date(endDate) : new Date();
        let diff = new Date(date1.getTime() - date2.getTime());

        let years = diff.getUTCFullYear() - 1970; // Gives difference as year
        let months = diff.getUTCMonth(); // Gives month count of difference
        let days = diff.getUTCDate() - 1; // Gives day count of difference
        let hours = diff.getUTCHours() // Gives hours count of difference
        let minutes = diff.getUTCMinutes() // Gives minutes count of difference
        let seconds = diff.getUTCSeconds() // Gives secounds 
        // console.log(years, months, days, hours, minutes, seconds);
        if (days > 1) return `${getCustomDateFormat(endDate)}`;
        if (days === 1) return `Yesterday, ${formatAMPM(date2)}`;
        if (days === 0 && hours > 10)`Today, ${formatAMPM(date2)}`;
        return `${dateFormat(years, months, days, hours, minutes, seconds)}`;
    }


    function dateFormat(years, months, days, hours, minutes, seconds) {
        let expire = "";
        if (years > 0) {
            expire += years + "y";
        }
        if (months > 0) {
            expire += months + "m";
        }
        if (days > 0) {
            expire += days + "d";
        }
        if (hours > 0) {
            expire += hours + "h ";
        }
        if (minutes > 0) {
            expire += minutes + "min";
        }

        if (hours === 0 && minutes <= 1) {
            expire += seconds + "sec";
        }


        return expire + " ago";
    }
}

//Custom Date Format
export const getCustomDateFormat = (value: string) => {
    const date = new Date(value);
    let time = formatAMPM(date);
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    return `${month} ${day}, ${year}, ${time}`;
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


//Converts date timestamp to number to years/months/days/hours/minutes
export const getRemainingTime = (endDate: string) => {
    if (endDate) {
        let date1 = new Date();
        let date2 = endDate ? new Date(endDate) : new Date();
        let diff = new Date(date2.getTime() - date1.getTime());
        if (date2 <= date1) return '00:00';
        let years = diff.getUTCFullYear() - 1970; // Gives difference as year
        let months = diff.getUTCMonth(); // Gives month count of difference
        let days = diff.getUTCDate() - 1; // Gives day count of difference
        let hours = diff.getUTCHours() // Gives hours count of difference
        let minutes = diff.getUTCMinutes() // Gives minutes count of difference
        let seconds = diff.getUTCSeconds() // Gives secounds 
        if (days > 0) return days + (days === 1 ? ' day left' : ' days left');
        if (hours > 0) return getHoursFormat(hours, minutes, seconds);
        if (minutes > 0) return getMinutesFormat(minutes, seconds);
        return getSecondsFormat(seconds);
    }
    return '00:00';

    function getHoursFormat(hours, minutes, seconds) {
        if (hours < 10) {
            return `0${hours}:${getMinutesFormat(minutes, seconds)}`;
        }
        return `${hours}:${getMinutesFormat(minutes, seconds)}`;
    }

    function getMinutesFormat(minutes, seconds) {
        if (minutes < 10) {
            return `0${minutes}:${getSecondsFormat(seconds)}`
        }
        return `${minutes}:${getSecondsFormat(seconds)}`;
    }

    function getSecondsFormat(seconds) {
        if (seconds < 10) {
            return `0${seconds}`;
        }
        return seconds;
    }

}


export function getUserName(user: any): string | null | undefined {
    if (!user) return null;
    const { firstName, lastName, email, login } = user;
    if (firstName && lastName) return `${firstName} ${lastName}`;
    if (firstName) return `${firstName}`;
    if (lastName) return `${lastName}`;
    if (email) return `${email}`;
    return `${login}`;
}


export function getDurationByDate(startTime: string | any, endTime: string | any): SurveyDuration | undefined {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const diff = new Date(endDate.getTime() - startDate.getTime());
    let years = diff.getUTCFullYear() - 1970; // Gives difference as year
    let months = diff.getUTCMonth(); // Gives month count of difference
    let days = diff.getUTCDate() - 1; // Gives day count of difference
    let hours = diff.getUTCHours() // Gives hours count of difference
    let minutes = diff.getUTCMinutes() // Gives minutes count of difference
    let seconds = diff.getUTCSeconds() // Gives secounds 

    if (days > 0) return durationList.find(doc => doc.code === (days * 24 * 60));
    if (hours > 0) return durationList.find(doc => doc.code === (hours * 60));
    return durationList.find(doc => doc.code === minutes);
}


export const getImageFormData = (doc: File) => {
    const formData = new FormData();
    formData.append('file', doc);
    return formData;
}

//video

export const getVideoFormData = (doc: File) => {
    const formData = new FormData();
    formData.append('file', doc);
    return formData;
}


export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export function getFileSize(bytes, decimals = 2) {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
}

export function formValidators(t: TFunction<"translation">, text: string, field: string, pattern, password) {
    switch (field) {
        case "username": {
            if (text == '') return t('validators.required');
            if (text && text.length >= 40) return t('validators.usernameLessThan40Characters');
            return null;
        };
        case "firstName": {
            if (text == '') return t('validators.required');
            if (text && text.length >= 40) return t('validators.firstnameLessThan40Characters');
            return null;
        };
        case "lastName": {
            if (text == '') return t('validators.required');
            if (text && text.length >= 40) return t('validators.lastnameLessThan40Characters');
            return null;
        };
        case "email": {
            if (text == '') return t('validators.required');
            if (pattern.test(text) === false) return t('validators.enterValidEmail');
            return null;
        };
        case "emailId": {
            if (text == '') return t('validators.required');
            if (pattern.test(text) === false) return t('validators.enterValidEmail');
            return null;
        };
        case "phone": {
            if (!text) return null;
            // if (text == '') return t('validators.required');
            if (isNaN(parseInt(text))) return t('validators.enterValidPhoneNumber');
            if (text.length > 10 || text.length < 10) return t('validators.enterValidPhoneNumber');
            return null;
        };
        case "currentPassword": {
            if (text == '') return t('validators.required');
            if (text.match(' ')) return t('validators.passwordCannotContainSpaces');
            if (text && text.length < 6) return t('validators.passwordAtleast');
            return null;
        };
        case "password": {
            if (text == '') return t('validators.required');
            if (text && text.length < 6) return t('validators.passwordAtleast');
            if (text && text.length >= 15) return t('validators.passwordInbetween');
            if (text.match(' ')) return t('validators.passwordCannotContainSpaces');
            // if (confirmPassword === text) return t('validators.passwordMustBeDifferent');
            return null;
        };
        case "confirmPassword": {
            if (text === '') return t('validators.required');
            if (text && text.length < 6) return t('validators.passwordAtleast');
            if (text && text.length >= 15) return t('validators.passwordInbetween');
            if (text.match(' ')) return t('validators.passwordCannotContainSpaces');
            if (password !== text) return t('validators.passwordDoNotMatch');
            return null;
        };
        case "promoCode": {
            if (text == '') return t('validators.required');
            if (text && text.length < 4) return t('validators.promocodeAtleast');
            if (pattern.test(text) === false) return t('validators.enterValidPromocode');
            return null;
        };
        default: {
            return null;
        }
    }
}

export function isCompleted(item: MySurvey): boolean {
    const date = new Date();
    const endDate = new Date(item.endTime);
    if (date >= endDate) return true;
    return false;
}