import { createSelector } from "reselect";
import * as fromReducer from '../reducers/friend-requests.reducer';

const selectState = state => state.friendRequestsState;

export const selectFriendRequestsState = createSelector(selectState, fromReducer.selectState);
export const selectFriendRequests = createSelector(
    selectState,
    fromReducer.selectAll
);

export const selectFriendRequestsEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);

export const selectAddFriendState = createSelector(selectState, fromReducer.selectAddState);
export const selectAcceptFriendState = createSelector(selectState, fromReducer.selectAcceptState);
export const selectRejectFriendState = createSelector(selectState, fromReducer.selectRejectState);