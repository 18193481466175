import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { AppError } from "../interfaces";

import { currentWindowWidth, scale } from "../variables";
import CustomText from "./custom-text";
import { SpinnerScreen } from "./spinner.screen";


interface Props {
    visible: boolean,
    loading?: boolean,
    wrapperWidth: any,
    onClose: () => void,
    onDissmiss?: () => void,
    onShown?: () => void,
    children: any
}

interface RefProps {
    closeAction: (onCloseSuccess?: () => void) => void
}

export const ActionDialogHolder = forwardRef((props: Props, ref: React.Ref<RefProps>) => {
    const {
        visible,
        loading,
        wrapperWidth,
        onClose,
        onDissmiss,
        onShown,
        children
    } = props;

    // // const width = useSharedValue<number>(0)
    // const scale = useSharedValue<number>(0);
    // const opacity = useSharedValue<number>(0);
    const scaleRef = useRef<number>(0.5);
    const [style, animate] = useSpring(() => ({ scale: scaleRef.current, opacity: 0 }))
    const { t, i18n } = useTranslation();

    useEffect(() => {
        animate({ scale: 1, opacity: 1 });
        scaleRef.current = 1;
        return () => { }
    }, [])

    useImperativeHandle(ref, () => ({
        closeAction: (onCloseSuccess?: () => void) => closeAction(onCloseSuccess)
    }));



    return (
        <div
            style={styles.container}
            onClick={() => {
                // handleClose()
                //background press ignore
            }}
        >
            <animated.div style={{ ...styles.contentContainer, width: wrapperWidth, ...style }}>
                <div style={styles.touchableHolder}>{props.children}</div>
            </animated.div>
        </div>
    );

    function handleClose() {
        if (loading) return;
        closeAction();
    }

    function closeAction(onCloseSuccess?: () => void) {
        animate({ scale: 0.5 , opacity: 0 });
        scaleRef.current = 0.5;
        setTimeout(() => {
            if (onCloseSuccess) onCloseSuccess();
            else onClose();
        }, 300)
    }
})

export type ActionDialogHolderType = React.ElementRef<typeof ActionDialogHolder>;


const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
        // marginTop: 22
    },

    contentContainer: {
        position: 'relative',
        backgroundColor: THEME.WHITE,
        borderRadius: 10,
        minWidth: '50%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 100
    },

    touchableHolder: {
        position: 'relative',
        // backgroundColor: 'green',
        borderRadius: 10,
        minHeight: 100,
        // minWidth: '50%',
        width: '100%'
    }

})