import { createAction } from '@reduxjs/toolkit';
import { AppError, IdToken, ProfileSettingsForm, User } from '../../interfaces';

export const updateProfile = createAction<User>('@profile/update');
export const updateProfileSuccess = createAction<User>('@profile/update/success');
export const updateProfileError = createAction<AppError | null>('@profile/update/error');

export const uploadProfileImage = createAction<any>('@profile/image');
export const uploadProfileImageSuccess = createAction<{path: string, signedUrl: string}>('@profile/image/success');
export const uploadProfileImageError = createAction<AppError | null>('@profile/image/error');

export const updateProfileSettings = createAction<ProfileSettingsForm>('@profile/update-settings');
export const updateProfileSettingsSuccess = createAction<User>('@profile/update-settings/success');
export const updateProfileSettingsError = createAction<AppError | null>('@profile/update-settings/error');

export const clearProfileError = createAction('@profile/clear/error');