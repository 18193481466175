import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';
import { MAIN_APP_ROUTES } from '../../routes';
import * as fromSelectors from '../selectors';
import { getUserName } from '../../services';

const getSentRequestsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getSentRequests),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        // const surveyState = fromSelectors.selectSurveysListState(state);
        return ContactsService.getSentRquests()
            .then(response => fromActions.getSentRequestsSuccess(response))
            .catch(error => fromActions.getSentRequestsError(extractError(error, MAIN_APP_ROUTES.SENT)))
    })
);

export const sentRequestsEpics = combineEpics(
    getSentRequestsEpic,
)