import * as fromActions from '../actions/error.actions';
import { AppError } from '../../interfaces';
import { createReducer } from '@reduxjs/toolkit'


export interface State {
    loading: boolean,
    error?: AppError | null,
}

const initialState: State = {
    loading: false,
}

export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.normalError, (state, action) => ({ ...state }))
        .addCase(fromActions.sessionExpired, (state, action) => ({ ...state, error: action.payload }))
        .addCase(fromActions.clearSessionError, (state, action) => ({ ...state, error: null }))
        .addDefaultCase((state, action) => ({ ...state }));
})

