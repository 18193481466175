import { createSelector } from "reselect";
import * as fromReducer from '../reducers/survey.reducer';

const selectState = state => state.surveyState;

export const selectSurveyState = createSelector(selectState, fromReducer.selectState);

export const selectSurvey= createSelector(
    selectState,
    fromReducer.selectSurvey
);