import * as fromActions from '../actions';
import { ofType, combineEpics, StateObservable } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';
import * as fromSelectors from '../selectors';
import { MAIN_APP_ROUTES } from '../../routes';


const getGlobalContactsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getGlobalContacts),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const globalContactsState = fromSelectors.selectGlobalContactsState(state);
        return ContactsService.getAppUsers({ page: 0, limit: globalContactsState.limit })
            .then(response => {
                
                return fromActions.getGlobalContactsSuccess(response)
            })
            .catch(error => fromActions.getGlobalContactsError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

const getMoreGlobalContactsEpic = (action$: Observable<Action>, state$: StateObservable<any>): Observable<Action> => action$.pipe(
    ofType(fromActions.getMoreGlobalContacts),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
        const globalContactsState = fromSelectors.selectGlobalContactsState(state);
        return ContactsService.getAppUsers({ page: globalContactsState.page + 1, limit: globalContactsState.limit })
            .then(response => fromActions.getMoreGlobalContactsSuccess(response))
            .catch(error => fromActions.getMoreGlobalContactsError(extractError(error, MAIN_APP_ROUTES.ADD_FRIENDS)))
    })
);

export const globalContactsEpics = combineEpics(
    getGlobalContactsEpic,
    getMoreGlobalContactsEpic
);