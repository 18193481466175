
import { DEVICE_TYPE, ERROR_KEYS } from "../enums";
import { AppError } from "../interfaces";
import { LOGIN_SIGNUP_ROUTES, MAIN_APP_ROUTES, ADD_EMAIL } from "../routes";
import { CURRENT_OS } from "../variables";



export const ERRORS = {
    BAD_REQUEST: `Bad Request`,
    SESSION_EXPIRED: `Session expired. please login again.`,
    NO_NETWORK: `Check your internet connection and try again.`,
    DATA_NOT_FOUND: `Requested data not found.`,
    UNABLE_TO_PROCESS: `Unable to process your request.`,
    UNKNOWN: `Something went wrong. Try again.`,
    REJECTED: `Invalid data supplied.`,
    PURCHASE_SUBMISSION: `Something went wrong while contacting MentoringMinds. tap to refresh.`,
    UN_AUTHERIZED: `User does not have permission`,
}

//error extractor 
export const extractError = (error: AppError, type?: string): AppError | null => {
    if (!error) return null;
    const { response, message, config, isAxiosError, code } = error;
    return {
        response: response,
        message: message,
        isAxiosError: isAxiosError,
        config: config,
        code: setErrorCode(response, code, message),
        type: type
    }
}

const setErrorCode = (response, code, message) => {
    if (response) return setResponseErrorCode(response);
    else if (code) return code;
    else if (message) return setMessageRelatedErrorCode(message);
    else return 0;
}

const setResponseErrorCode = (response) => {
    const { data, status } = response;
    if (status) return status;
    else if (data && data != "") {
        const { status, message } = data;
        if (status) return status;
        return 0;

    }
    else return 0;
}

const setMessageRelatedErrorCode = (message) => {
    if (message == "Empty Response") return "BOOK_ERROR";
    return 0;
}

export const checkErrorResponse = (error) => {
    if (!error) return;
    const { response, code, message, type } = error;

   
    if (type && code !== 0 && code !== 400) return identifyTypeOfTheError(type, code);
    else if (type && code !== 0 && code === 400) return identifyTypeOfTheError(type, code);
    else if (response && response.data && typeof response.data !== 'string') return checkResponseData(response.data);
    else if (code === 0) return defaultErrors(code);
    else if (message) return message;
    else return ERRORS.UNKNOWN;
}

const checkResponseData = (data: any) => {
    const { errorKey, title, loginType, status } = data;
    if (errorKey) return identifyErrorKey(errorKey);
    else if (status) return defaultErrors(status);
    else if (title) return title;
    else return ERRORS.UNKNOWN;
}

const identifyErrorKey = (key) => {
    switch (key) {
        case ERROR_KEYS.USER_EXIST: return "User with same email id exist";
        case ERROR_KEYS.EMAIL_EXIST: return "User with same email id exist";
        default: return ERRORS.UNKNOWN;
    }
}



const identifyTypeOfTheError = (type, code) => {
    // console.log(type,code, 'error codes');
    switch (type) {
        case LOGIN_SIGNUP_ROUTES.LOGIN: return loginSignupErrors(code);
        case LOGIN_SIGNUP_ROUTES.REGISTER: return registerErrors(code);
        case MAIN_APP_ROUTES.SINGLE_OPTION: return questionErrors(code);
        case MAIN_APP_ROUTES.MULTIPLE_CHOICE: return questionErrors(code);
        case MAIN_APP_ROUTES.SHORT_ANSWER: return questionErrors(code);
        case MAIN_APP_ROUTES.POST_QUESTION: return postQuestionErrors(code);
        case MAIN_APP_ROUTES.SURVEY: return questionErrors(code);
        case MAIN_APP_ROUTES.INVITE_FRIENDS: return inviteFriendsError(code);
        case MAIN_APP_ROUTES.CREATE_GROUP: return createGroupError(code);
        case MAIN_APP_ROUTES.EDIT_GROUP: return groupsErrors(code);
        case LOGIN_SIGNUP_ROUTES.FORGOT_PASSWORD: return changePasswordErrors(code);
        case LOGIN_SIGNUP_ROUTES.RESET_PASSWORD: return changePasswordErrors(code);
        case LOGIN_SIGNUP_ROUTES.ACCOUNT_ACTIVATE: return accountActiveError(code);
        default: return defaultErrors(code);
    }
}

const accountActiveError = (code: any) => {
    switch (code) {
        case 0: return ERRORS.NO_NETWORK;
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return `No user was found with activation key`;
        case 401: return ERRORS.UN_AUTHERIZED;
        case 403: return "Group does not exist";
        case 404: return ERRORS.DATA_NOT_FOUND;
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}

const groupsErrors = (code: any) => {
    switch (code) {
        case 0: return ERRORS.NO_NETWORK;
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.UN_AUTHERIZED;
        case 403: return "Group does not exist";
        case 404: return ERRORS.DATA_NOT_FOUND;
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}


//create Group error

const createGroupError = (code: any) => {
    switch (code) {
        case 0: return ERRORS.NO_NETWORK;
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return "Maximum image upload size exceeded.";
        case 401: return ERRORS.SESSION_EXPIRED;
        // case 409: return "User with same email id exist";
        case 404: return ERRORS.DATA_NOT_FOUND;
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}

//invite friends error

const inviteFriendsError = (code: any) => {
    switch (code) {
        case 0: return ERRORS.NO_NETWORK;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 404: return `Invalid user invite link`;
        default: return ERRORS.UNKNOWN;
    }
}



//default errors
const defaultErrors = (code: any) => {
    switch (code) {
        case "EUNSPECIFIED": return ERRORS.NO_NETWORK;
        case "E_UNKNOWN": return "Cannot connect to iTunes Store. Try again ";
        case "7": return ERRORS.NO_NETWORK;
        case "-5": return ERRORS.NO_NETWORK;
        case "12501": return "Sign in action cancelled";
        case 0: return ERRORS.NO_NETWORK;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 500: return "Internal server error";
        default: return ERRORS.UNKNOWN;
    }
}

//User state change
const userStateChangeErrors = (code) => {
    switch (code) {
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 409: return "User with same email id exist";
        case 404: return "User not found";
        default: return ERRORS.UNKNOWN;
    }
}

//Add email 
const addEmailErrors = (code) => {
    switch (code) {
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 409: return "User with same email id exist";
        case 404: return "User not found";
        default: return ERRORS.UNKNOWN;
    }
}

//Change password 
const changePasswordErrors = (code) => {
    switch (code) {
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return `Invalid key`;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 409: return "User with same email id exist";
        case 404: return "User not found";
        default: return ERRORS.UNKNOWN;
    }
}



//LoginSignupError

const registerErrors = (code: any) => {
    switch (code) {
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 401: return "Invalid user id or password";
        case 409: return "User already exists with another social media account";
        case 404: return "User not found";
        case 400: return "User with same email id exists";
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}

const loginSignupErrors = (code: any) => {
    switch (code) {
        case "EUNSPECIFIED": return ERRORS.NO_NETWORK;
        case "E_UNKNOWN": return "Cannot connect to iTunes Store. Try again ";
        case "7": return ERRORS.NO_NETWORK;
        case "-5": return ERRORS.NO_NETWORK;
        case "12501": return "Sign in action cancelled";
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return "Invalid user id or password";
        case 409: return "User already exists with another social media account";
        case 404: return "User not found";
        case 400: return "User already exists with another social media account";
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}


//Questions error

const questionErrors = (code: any) => {
    switch (code) {
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return ERRORS.BAD_REQUEST;
        case 401: return ERRORS.SESSION_EXPIRED;
        case 403: return "User not allowed to post comment";
        // case 409: return "User with same email id exist";
        case 404: return ERRORS.DATA_NOT_FOUND;
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}


const postQuestionErrors = (code: any) => {
    switch (code) {
        case 0: return ERRORS.NO_NETWORK;
        case 304: return ERRORS.UNABLE_TO_PROCESS;
        case 400: return "Maximum image upload size exceeded.";
        case 401: return ERRORS.SESSION_EXPIRED;
        case 403: return "User not allowed to post";
        // case 409: return "User with same email id exist";
        case 404: return ERRORS.DATA_NOT_FOUND;
        // case 406: return "";
        default: return ERRORS.UNKNOWN;
    }
}

