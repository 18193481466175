import React, { } from "react";
import { useTranslation } from "react-i18next";
import { DEVICE_TYPE, THEME } from "../enums";
import { currentWindowHeight, CURRENT_OS } from "../variables";
import CustomText from "../utils/custom-text";
import { UserListItem } from "./user-friend";
import { SharedUser } from "../interfaces";
import { BottomDialogHolder } from "../utils/bottom-dialog-holder";
import { CustomScrollView } from "../utils";


const wrapperHeight = currentWindowHeight() / 2;

interface Props {
    visible: boolean,
    sharedUsers: SharedUser[] | undefined,
    title: string,
    onClose: () => void,
    onAccept?: () => void,
    onDissmiss?: () => void
}


export function PostedToUsersDialog(props: Props) {
    const {
        visible,
        title,
        sharedUsers,
        onClose,
        onDissmiss,
        onAccept
    } = props;
    const { t, i18n } = useTranslation();

    return (
        <BottomDialogHolder
            visible={visible}
            wrapperHeight={wrapperHeight}
            onClose={onClose}
            onDissmiss={onDissmiss}
        >
            <div style={styles.actionContainer}>
                <div style={styles.actionHeader}>
                    <CustomText customStyle={{ color: 'red', fontSize: 18 }}>{title}</CustomText>
                </div>
                <div style={{ paddingLeft: 20, paddingRight: 20, height: wrapperHeight - 60, overflowY: 'auto' }}>
                    {sharedUsers?.map((item, index) => (
                        <UserListItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>
            </div>
        </BottomDialogHolder>
    );


}


const styles: { [key: string]: React.CSSProperties } = ({
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    actionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: THEME.INPUT_BORDER,
        borderBottomStyle: 'solid'
    },
    actionContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,

    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10
    },
    option: { width: 70, height: 70, marginRight: 25, padding: 5, borderRadius: 5 }
})