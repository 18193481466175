import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as SurveyService from '../../services/surveys.service';
import { SurveyComment } from '../../interfaces';
import { MAIN_APP_ROUTES } from '../../routes';

const getCommentsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getComments),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveyService.getComments(payload)
            .then((docs: SurveyComment[]) => fromActions.getCommentsSuccess(docs))
            .catch(error => fromActions.getCommentsError(extractError(error)))
    })
);

const postCommentEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.postComment),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveyService.postComment(payload)
            .then((docs: SurveyComment) => fromActions.postCommentSuccess(docs))
            .catch(error => fromActions.postCommentError(extractError(error)))
    })
);

const deleteCommentEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteComment),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return SurveyService.deleteComment(payload)
            .then((res) => fromActions.deleteCommentSuccess(payload))
            .catch(error => fromActions.deleteCommentError(extractError(error, MAIN_APP_ROUTES.DRAFTS)))
    })
);

const deleteCommentSuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteCommentSuccess),
    switchMap(() => [fromActions.toastMessage('Removed successfully')])
);

export const commentsEpics = combineEpics(
    getCommentsEpic,
    postCommentEpic,
    deleteCommentEpic,
    deleteCommentSuccessEpic
);