

export enum MODULE_CODES {
    TCT = "TCT",
    ACT = "ACT",
    SMS = "SMS",
    VOC = "VOC",
    PEG = "PEG",
    SEL = "SEL",
    ELL = "ELL",
    MBL = "MBL",
}

export enum MODULE_NAMES {
    TCT = "9 Traits of Critical Thinking",
    SMS = "Standards Mastery",
    ACT = "Word Play Activities",
    VOC = "Vocabulary Cards",
    SEL = "Social and Emotional Learning",
    ELL = "English Language Learners",
    PEG = "Parent Engagement",
    MBL = "Learn With Us: MM Blog"
}

export enum SUBJECT_CODES {
    ELA = "ELA",
    MAT = "MAT",
    SCI = "SCI",
}


export enum TRAITS {
    ADAPT = "Adapt",
    COLLABORATE = "Collaborate",
    CREATE = "Create",
    EXAMINE = "Examine",
    INQUIRE = "Inquire",
    REFLECT = "Reflect",
    COMMUNICATE = "Communicate",
    STRIVE = "Strive",
    LINK = "Link",
}

export enum HEADER_NAME {
    TRAITS = "9 Traits",
    VIDEOS = "Videos",
}

export enum TRAIT_COLOR {
    ADAPT = "#a3238e",
    COLLABORATE = "#0066b3",
    CREATE = "#fcaf17",
    EXAMINE = "#522e91",
    INQUIRE = "#d71c2d",
    REFLECT = "#009944",
    COMMUNICATE = "#00abc5",
    STRIVE = "#f37021",
    LINK = "#8dc63f",
    DEFAULT = "#fff"
}