import { createAction } from '@reduxjs/toolkit';
import { AppError, Country, Draft } from '../../interfaces';

interface PageNation{
    page: number,
    limit: number,
    countAll: number,
    result: Draft[]
}

export const getDrafts = createAction('@drafts/get');
export const getDraftsSuccess = createAction<PageNation>('@drafts/get/success');
export const getDraftsError = createAction<AppError | null>('@drafts/get/error');

export const getMoreDrafts = createAction('@drafts/get/more');
export const getMoreDraftsSuccess = createAction<PageNation>('@drafts/get/more/success');
export const getMoreDraftsError = createAction<AppError | null>('@drafts/get/more/error');

export const saveDraft = createAction<any>('@drafts/save');
export const saveDraftSuccess = createAction<Draft>('@drafts/save/success');
export const saveDraftError = createAction<AppError | null>('@drafts/save/error');

export const updateDraft = createAction<any>('@drafts/update');
export const updateDraftSuccess = createAction<Draft>('@drafts/update/success');
export const updateDraftError = createAction<AppError | null>('@drafts/update/error');

export const deleteDraft = createAction<any>('@drafts/delete');
export const deleteDraftSuccess = createAction<any>('@drafts/delete/success');
export const deleteDraftError = createAction<AppError | null>('@drafts/delete/error');

export const addOneDraft = createAction<any>('@drafts/add/one');
export const removeOneDraft = createAction<string>('@drafts/remove/one');
export const clearDrafts = createAction('@drafts/clear/all');

export const removeDrafts = createAction('@drafts/remove/all');
export const clearDraftsError = createAction('@drafts/clear/error');