import { createAction, createReducer } from '@reduxjs/toolkit'
import { AppError } from '../../interfaces';
import * as fromActions from '../actions/auth.actions';

export interface State {
    error?: AppError | null,
    ssError?: AppError | null,
    accError?: AppError | null,
    accLoading: boolean,
    loading: boolean,
    response?: any,
    country: string | null,
}

const initialState: State = {
    accLoading: false,
    loading: false,
    country: null
};



export const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fromActions.emailLogin, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.emailLoginSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.emailLoginError, (state, action) => ({ ...state, loading: false, error: action.payload }))

        .addCase(fromActions.emailSignup, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.emailSignupSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.emailSignupError, (state, action) => ({ ...state, loading: false, error: action.payload }))

        .addCase(fromActions.googleLogin, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.googleLoginSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.googleLoginError, (state, action) => ({ ...state, loading: false, ssError: action.payload }))

        .addCase(fromActions.appleLogin, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.appleLoginSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.appleLoginError, (state, action) => ({ ...state, loading: false, ssError: action.payload }))

        .addCase(fromActions.facebookLogin, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.facebookLoginSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.facebookLoginError, (state, action) => ({ ...state, loading: false, ssError: action.payload }))
        .addCase(fromActions.getFacebookUserDetails, (state, action) => ({ ...state }))

        .addCase(fromActions.logout, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.logoutSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.logoutError, (state, action) => ({ ...state, loading: false,  })) //error: action.payload
        .addCase(fromActions.logoutCancel, (state, action) => ({ ...state, loading: false }))

        .addCase(fromActions.updateUserProfile, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.updateUserProfileSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.updateUserProfileError, (state, action) => ({ ...state, loading: false, error: action.payload }))

        .addCase(fromActions.changeUserPassword, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.changeUserPasswordSuccess, (state, action) => ({ ...state, loading: false }))
        .addCase(fromActions.changeUserPasswordError, (state, action) => ({ ...state, loading: false, error: action.payload }))

        .addCase(fromActions.resetUserPassword, (state, action) => ({ ...state, loading: true, error: null }))
        .addCase(fromActions.resetUserPasswordSuccess, (state, action) => ({ ...state, loading: false, response: action.payload }))
        .addCase(fromActions.resetUserPasswordError, (state, action) => ({ ...state, loading: false, error: action.payload }))

        .addCase(fromActions.activateAccount, (state, action) => ({ ...state, accLoading: true, accError: null }))
        .addCase(fromActions.activateAccountSuccess, (state, action) => ({ ...state, accLoading: false, response: action.payload }))
        .addCase(fromActions.activateAccountError, (state, action) => ({ ...state, accLoading: false, accError: action.payload }))

        .addCase(fromActions.saveUserCountry, (state, action) => ({ ...state }))
        .addCase(fromActions.saveUserCountrySuccess, (state, action) => ({ ...state, country: action.payload }))
        .addCase(fromActions.saveUserCountryError, (state, action) => ({ ...state, error: action.payload }))

        .addCase(fromActions.getUserCountry, (state, action) => ({ ...state }))
        .addCase(fromActions.getUserCountrySuccess, (state, action) => ({ ...state, country: action.payload }))
        .addCase(fromActions.getUserCountryError, (state, action) => ({ ...state, error: action.payload }))

        .addCase(fromActions.clearAuthError, (state, action) => ({ ...state, error: null, ssError: null }))
        .addDefaultCase((state, action) => ({ ...state }))
})

export const selectState = (state: State) => state;