// import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { combineReducers, configureStore, getDefaultMiddleware, } from '@reduxjs/toolkit'
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import { environment } from '../../app.config';
import * as deviceReducer from './device.reducer';
import * as authReducer from './auth.reducer';
import * as userReducer from './user.reducer';
import * as errorReducer from './error.reducer';
import * as countriesReducer from './countries.reducer';
import * as notificationReducer from './notification.reducer';
import * as draftReducer from './draft.reducer';
import * as draftsListReducer from './drafts-list.reducer';
import * as contactsReducer from './contacts.reducer';
import * as groupsReducer from './groups.reducer';
import * as myActiveSurveysReducer from './active-surveys-list.reducer';
import * as myCompletedSurveysReducer from './completed-surveys-list.reducer';
import * as mySurveysReducer from './my-surveys.reducer';
import * as surveysListReducer from './surveys-list.reducer';
import * as categoriesReducer from './categories.reducer';
import * as commentsReducer from './comments.reducer';
import * as answersReducer from './answers.reducer';
import * as surveyReducer from './survey.reducer';
import * as decisionResultsReducer from './decision-results.reducer';
import * as globalContactsReducer from './global-contacts.reducer';
import * as inviteFriendReducer from './invite-friend.reducer';
import * as profileReducer from './profile.reducer';
import * as friendRquestsReducer from './friend-requests.reducer';
import * as searchReducer from './search.reducer';
import * as searchFriendReducer from './search-friend.reducer';
import * as sentRequestReducer from './sent-requests.reducer';
import * as contactUSReducer from './contact-us.reducer';

import {
    authEpics,
    userEpics,
    errorEpics,
    notificationEpics,
    countriesEpics,
    draftEpics,
    draftsListEpics,
    contactsEpics,
    groupsEpics,
    myActiveSurveysEpics,
    myCompletedSurveysEpics,
    mySurveysEpics,
    surveysListEpics,
    categoriesEpics,
    commentsEpics,
    answersEpics,
    deviceEpics,
    surveyEpics,
    decisionResultsEpics,
    globalContactsEpics,
    inviteFriendsEpics,
    profileEpics,
    friendRequestsEpics,
    searchEpics,
    searchFriendEpics,
    sentRequestsEpics
} from '../epics';

const rootEpics = combineEpics(
    authEpics,
    userEpics,
    errorEpics,
    notificationEpics,
    countriesEpics,
    draftEpics,
    draftsListEpics,
    contactsEpics,
    groupsEpics,
    myActiveSurveysEpics,
    myCompletedSurveysEpics,
    mySurveysEpics,
    surveysListEpics,
    categoriesEpics,
    commentsEpics,
    answersEpics,
    deviceEpics,
    surveyEpics,
    decisionResultsEpics,
    globalContactsEpics,
    inviteFriendsEpics,
    profileEpics,
    friendRequestsEpics,
    searchEpics,
    searchFriendEpics,
    sentRequestsEpics
);
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();

export interface AppState {
    deviceState: deviceReducer.State,
    authState: authReducer.State,
    userState: userReducer.State,
    errorState: errorReducer.State,
    countriesState: countriesReducer.State,
    notificationState: notificationReducer.State,
    draftState: draftReducer.State,
    draftsListState: draftsListReducer.State,
    contactsState: contactsReducer.State,
    groupsState: groupsReducer.State,
    decisionResultsState: decisionResultsReducer.State,
    myActiveSurveysState: myActiveSurveysReducer.State,
    myCompletedSurveysState: myCompletedSurveysReducer.State,
    mySurveysState: mySurveysReducer.State,
    surveysListState: surveysListReducer.State,
    categoriesState: categoriesReducer.State,
    commentsState: commentsReducer.State, 
    answersState: answersReducer.State,
    surveyState: surveyReducer.State,
    globalContactsState: globalContactsReducer.State,
    inviteFriendState: inviteFriendReducer.State,
    profileState: profileReducer.State,
    friendRequestsState: friendRquestsReducer.State,
    searchState: searchReducer.State,
    searchFriendState: searchFriendReducer.State,
    sentRequestsState: sentRequestReducer.State,
    contactUSState: contactUSReducer.State
}

const reducer = combineReducers({
    deviceState: deviceReducer.reducer,
    authState: authReducer.reducer,
    userState: userReducer.reducer,
    errorState: errorReducer.reducer,
    countriesState: countriesReducer.reducer,
    notificationState: notificationReducer.reducer,
    draftState: draftReducer.reducer,
    draftsListState: draftsListReducer.reducer,
    contactsState: contactsReducer.reducer,
    groupsState: groupsReducer.reducer,
    decisionResultsState: decisionResultsReducer.reducer,
    myActiveSurveysState: myActiveSurveysReducer.reducer,
    myCompletedSurveysState: myCompletedSurveysReducer.reducer,
    mySurveysState: mySurveysReducer.reducer,
    surveysListState: surveysListReducer.reducer,
    categoriesState: categoriesReducer.reducer,
    commentsState: commentsReducer.reducer,
    answersState: answersReducer.reducer,
    surveyState: surveyReducer.reducer,
    globalContactsState: globalContactsReducer.reducer,
    inviteFriendState: inviteFriendReducer.reducer,
    profileState: profileReducer.reducer,
    friendRequestsState: friendRquestsReducer.reducer,
    searchState: searchReducer.reducer,
    searchFriendState: searchFriendReducer.reducer,
    sentRequestsState: sentRequestReducer.reducer,
    contactUSState: contactUSReducer.reducer
});

// const store = createStore(reducers, composeEnhancers(applyMiddleware(epicMiddleware)));
const middleware = [
    // ...getDefaultMiddleware(), 
    epicMiddleware
];
// if(__DEV__){
//     // import createFlipperDebugger from 'redux-flipper';
//     const createFlipperDebugger = require('redux-flipper').default;
//     middleware.push(createFlipperDebugger());
// }
const store = configureStore({
    reducer,
    middleware,
    devTools: environment.ENV !== 'production',
    // preloadedState,
    // enhancers: [reduxBatch],
})

epicMiddleware.run(rootEpics);

export default store;