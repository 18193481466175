import { createSelector } from "reselect";
import { AppState } from '../reducers';
import * as fromReducer from '../reducers/comments.reducer';

const selectState = state => state.commentsState;

export const selectCommentsState = createSelector(selectState, fromReducer.selectState);
export const selectComments = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectCommentsEntities = createSelector(selectState, fromReducer.selectEntities);
