import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as ContactsService from '../../services/contacts.service';
import { MAIN_APP_ROUTES } from '../../routes';

const getGroupsEpic = (action$: Observable<Action>): Observable<Action> => action$.pipe(
    ofType(fromActions.getGroups),
    switchMap(() => {
        return ContactsService.getGroups()
            .then(response => fromActions.getGroupsSuccess(response))
            .catch(error => fromActions.getGroupsError(extractError(error)))
    })
);

const deleteGroupEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.deleteGroup),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return ContactsService.deleteGroup(payload)
            .then((res) => {
                return fromActions.deleteGroupSuccess(payload);
            })
            .catch(error => fromActions.deleteGroupError(extractError(error, MAIN_APP_ROUTES.EDIT_GROUP)))
    })
);

const getGroupByIdEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.getGroupById),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return ContactsService.getGroupById(payload)
            .then(response => fromActions.getGroupByIdSuccess(response))
            .catch(error => fromActions.getGroupByIdError(extractError(error, MAIN_APP_ROUTES.EDIT_GROUP)))
    })
);

export const groupsEpics = combineEpics(
    getGroupsEpic,
    deleteGroupEpic,
    getGroupByIdEpic

);