import React, { useState } from "react";
import { FONT, THEME } from "../enums";
import { useTranslation } from 'react-i18next';
import { currentWindowWidth } from "../variables";
import CustomText from "../utils/custom-text";
import { ScreenHolder } from "../components";
import { CircularProgress } from "@material-ui/core";

const actionSize = currentWindowWidth() / 3;
export function AboutUsScreen(props: any) {
    const { history, location } = props;
    const { state } = location;
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [terms, setTerms] = useState<boolean>(false);

    return (
        <ScreenHolder location={location} history={history} >
            <div style={styles.container}>
                <div style={styles.header}>
                    <CustomText customStyle={styles.headerText}>{t('aboutUs')}</CustomText>
                </div>
                <div style={{ height: '90%', paddingLeft: 20, paddingRight: 20, overflowY: 'auto'}}>

                    <CustomText customStyle={{}}>{
                        `Aliqua elit excepteur officia reprehenderit sunt nulla sit. Eu minim voluptate cupidatat culpa non. In laborum incididunt pariatur ullamco. Culpa enim Lorem dolor officia eu. Officia est irure irure mollit reprehenderit cillum ullamco deserunt. Incididunt exercitation reprehenderit non cillum labore deserunt et aliquip labore do anim occaecat. Culpa consectetur ea velit consequat non veniam deserunt pariatur commodo est nostrud labore.Velit mollit ea magna occaecat anim adipisicing nostrud ea sit. Aliqua culpa veniam irure exercitation qui amet cillum fugiat ipsum. Proident sint laborum Lorem qui commodo in. Elit proident quis tempor cupidatat quis duis eu est et elit consectetur velit. Reprehenderit sit non consectetur adipisicing duis.
                                
                         Sunt mollit amet et in voluptate occaecat incididunt. Ea sunt enim mollit sit pariatur pariatur Lorem laboris sunt. Esse do labore exercitation tempor anim amet do et fugiat deserunt non.
                        
                         Laborum eu pariatur magna enim est elit. Duis consectetur ullamco sit fugiat reprehenderit ipsum fugiat irure pariatur qui non nisi eiusmod consequat. Cillum ad ex veniam eu officia ad do fugiat in. Nostrud culpa in Lorem sit quis laboris irure ipsum proident exercitation pariatur. Ea laborum voluptate cillum reprehenderit cillum pariatur.
                                
                        `
                    }</CustomText>
                </div>
                {isLoading && <div style={styles.spinner}>
                    <CircularProgress size={40} />
                </div>
                }
            </div>
        </ScreenHolder>
    )
}


const styles: { [key: string]: React.CSSProperties } = ({

    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    headerText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 15,
        fontSize: 17,
        textTransform: 'uppercase',
        color: THEME.ORANGE_RED,
    },
    newsLetter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20
    },
    checkbox: {
        backgroundColor: THEME.WHITE,
        borderWidth: 0
    },

    spinner: {
        height: '100%', position: 'absolute', width: '100%', alignItems: 'center', justifyContent: 'center'
    }
})
