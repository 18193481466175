import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScreenHolder } from '../components';
import { DEVICE_TYPE, DRAWER_TYPE, THEME, WEB_URLS } from '../enums';
import { MAIN_APP_ROUTES } from '../routes';
import { CustomScrollView, IconProvider } from '../utils';
import CustomText from '../utils/custom-text';
import { currentWindowWidth, CURRENT_OS } from '../variables';

const MENU_OPTIONS = [
  {
    title: 'mySurvey',
    subTitle: 'surveyManagement',
    icon: 'inbox',
    route: MAIN_APP_ROUTES.MY_SURVEYS ,
    params: {},
  },
  {
    title: 'createSurvey',
    subTitle: 'postSurvey',
    icon: 'heart',
    route: MAIN_APP_ROUTES.CREATE_QUESTION,
    params: {},
  },
  {
    title: 'drafts',
    subTitle: 'surveyManagement',
    icon: 'inbox',
    route: MAIN_APP_ROUTES.DRAFTS ,
    params: {},
  },
  {
    title: 'voteOnSurvey',
    subTitle: 'helpOthers',
    icon: 'user',
    route: MAIN_APP_ROUTES.YOUR_HELP_MATTERS,
    params: {},
  },
  // {
  //   title: 'answeredSurveys',
  //   subTitle: 'helpOthers',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.COMPLETED_SURVEYS_LIST,
  //   params: {},
  // },
  {
    title: 'notifications',
    subTitle: 'pushNotificationsAndEmail',
    icon: 'star',
    route: MAIN_APP_ROUTES.NOTIFICATIONS,
    params: {},
  },
  // {
  //   title: 'createGroup',
  //   subTitle: 'groupManagement',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.CREATE_GROUP,
  //   params: {},
  // },
  {
    title: 'myGroups',
    subTitle: 'groupManagement',
    icon: 'user',
    route: MAIN_APP_ROUTES.GROUPS,
    params: {},
  },
  // {
  //   title: 'myProfile',
  //   subTitle: 'changeYourProfileDetails',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.EDIT_PROFILE,
  //   params: {},
  // },
  // {
  //   title: 'addFriends',
  //   subTitle: 'addFriends',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.ADD_FRIENDS,
  //   params: {},
  // },
  // {
  //   title: 'Friends',
  //   subTitle: 'Friends',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.FRIENDS,
  //   params: {},
  // },
  // {
  //   title: 'friends',
  //   subTitle: 'friends',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.FRIENDS,
  //   params: {},
  // },
  // {
  //   title: 'inviteFriends',
  //   subTitle: 'inviteFriends',
  //   icon: 'user',
  //   route: MAIN_APP_ROUTES.INVITE_FRIENDS,
  //   params: {},
  // },
 // {
   // title: 'questionBank',
   // subTitle: 'questionBank',
   // icon: 'user',
   // route: MAIN_APP_ROUTES.QUESTION_BANK,
   // params: {},
 // },
  // {
  //   title: 'helpCenter',
  //   subTitle: 'techSupportAndTips&Tricks',
  //   icon: 'head-phones',
  //   route: MAIN_APP_ROUTES.PRIVACY_POLICY,
  //   params: { title: 'helpCenter', uri: WEB_URLS.HELP_CENTER },
  // },
  {
    title: 'settings',
    subTitle: 'passwordAndSecurity',
    icon: 'settings-s',
    route: MAIN_APP_ROUTES.SETTINGS,
    params: {},
  },
  {
    title: 'aboutUs',
    subTitle: 'privacyPoliciesAndTerms&Conditions',
    icon: 'settings-s',
    route: MAIN_APP_ROUTES.ABOUT_US,
    params: { title: 'aboutUs', uri: WEB_URLS.ABOUT_US },
  },
];
const actionSize = currentWindowWidth() / 3;

export function MenuScreen(props: any) {
  const { history, location } = props;
  const { t, i18n } = useTranslation();
  return (
    <ScreenHolder location={location} history={history}>
      <div style={styles.screenContainer}>
        <div style={styles.screenHeader}>
          <CustomText customStyle={styles.shdtxt}>{t('menu')}</CustomText>
        </div>
        <div
          style={{
            backgroundColor: THEME.WHITE,
            paddingTop: 20,
            paddingBottom: 50,
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            overflowY: 'auto'
          }}
        >
          {MENU_OPTIONS.map((doc, index) => (
            <MenuItem
              key={index}
              index={index}
              item={doc}
              onClick={(route, params) => {
                history.push(route, params);
              }}
            />
          ))}
        </div>
      </div>
    </ScreenHolder>
  );
}

interface MenuItemProps {
  index: number;
  item: any;
  onClick: (route: string, params: any) => void;
}

function MenuItem(props: MenuItemProps) {
  const { item, index, onClick } = props;
  const { t, i18n } = useTranslation();
  return (
    <div
      // key={index}
      className={"on-press-cls"}
      style={{
        ...{
          cursor: 'pointer',
          paddingLeft: 20,
          paddingRight: 20,
        },
        // ...{ backgroundColor: pressed ? 'rgba(230,230,230,0.2)' : THEME.WHITE },
      }}
      onClick={() => onClick(item.route, item.params)}>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 10,
          paddingBottom: 10,
          borderBottomWidth: 1,
          borderBottomColor: THEME.INPUT_BORDER,
          borderBottomStyle: 'solid'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <div style={{ width: 15, height: 15, marginRight: 20 }}>
            <IconProvider type={item.icon} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <CustomText customStyle={{ fontSize: 16, fontWeight: '400' }}>
              {t(item.title)}
            </CustomText>
            <CustomText customStyle={{ fontSize: 13, color: '#A9A9B0' }}>
              {t(item.subTitle)}
            </CustomText>
          </div>
        </div>
        <div style={{ width: 6, height: 10 }}>
          <IconProvider type={'ios-arrow-forward'} />
        </div>
      </div>

    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = ({
  // Screen container styles

  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  screenHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: THEME.DEFAULT_BORDER,
    borderBottomStyle: 'solid',
  },
  shdtxt: {
    fontSize: 17,
    color: THEME.ORANGE_RED,
    textTransform: 'uppercase',
  },
  screenContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
  },
});
