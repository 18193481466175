import * as fromActions from '../actions';
import { ofType, combineEpics } from 'redux-observable';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
 
import { extractError } from '../../services/error-handler';
import AppStorage from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import * as AuthService from '../../services/auth.service';
import { MAIN_APP_ROUTES } from '../../routes';

const submitUserQueryEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.submitUserQuery),
    map((action: any) => action.payload),
    switchMap((payload) => {
        return AuthService.contactUs(payload)
            .then((response) => {
                // navigate(MAIN_APP_ROUTES.SETTINGS);
                return fromActions.submitUserQuerySuccess(response);
            })
            .catch(error => fromActions.submitUserQueryError(extractError(error)))
    })
);

const submitUserQuerySuccessEpic = (action$: Observable<Action>) : Observable<Action> => action$.pipe(
    ofType(fromActions.submitUserQuerySuccess),
    map((action: any) => action.payload),
    switchMap((payload) => [fromActions.toastMessage('Submitted successfully')])
);

export const contactUSEpics = combineEpics(
    submitUserQueryEpic,
    submitUserQuerySuccessEpic
);