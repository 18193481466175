import React from "react";


export function CustomCheckBox(props) {

    return (
        <div className={""} style={styles.container}>
            <div className={""} style={styles.boxContainer} onClick={props.onClick}>
                <input
                    type={"checkbox"}
                    id={props.name}
                    name={props.name}
                    checked={props.checked}
                    onClick={() => { }}
                    onChange={() => { }}
                />
                <label style={{ fontSize: 11, cursor: 'pointer' }}>{props.title}</label>
            </div>
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({

    //selector styles
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },

})