import { createSelector } from "reselect";
import { isCompleted } from "../../services";
import * as fromReducer from '../reducers/completed-surveys-list.reducer';

const selectState = state => state.myCompletedSurveysState;

export const selectMyCompletedSurveysState = createSelector(selectState, fromReducer.selectState);
export const selectMyCompletedSurveys = createSelector(
    selectState,
    fromReducer.selectAll
);
export const selectMyCompletedSurveysEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);