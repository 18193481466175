import { createAction } from '@reduxjs/toolkit';
import { AppError, IdToken, User } from '../../interfaces';

export const checkUserExist = createAction('@user/check');
export const userExist = createAction<string | undefined | null>('@user/exist');
export const newUser = createAction<string | null>('@user/new');

export const missingUserInfo = createAction('@user/missing-info');

export const initializeApp = createAction('@user/initialize-app');

export const getUser = createAction('@user/get');
export const getUserSuccess = createAction<User>('@user/get/success');
export const getUserError = createAction<AppError | null>('@user/get/error');

export const addUser = createAction<User>('@user/add');
// export const addUserSuccess = createAction('@user/add/success');
// export const addUserError = createAction<AppError | null>('@user/add/error');

export const removeUser = createAction('@user/remove');
export const removeUserSuccess = createAction('@user/remove/success');
export const removeUserError = createAction<AppError | null>('@user/remove/error');

export const saveUserIdToken = createAction<IdToken>('@user/save-state');
export const saveUserIdTokenSuccess = createAction('@user/save-state/success');
export const saveUserIdTokenError = createAction<AppError | null>('@user/save-state/error');



export const clearUserError = createAction('@user/error/clear');
