import { createSelector } from "reselect";
import * as fromReducer from '../reducers/search-friend.reducer';

const selectState = state => state.searchFriendState;

export const selectSearchFriendState = createSelector(selectState, fromReducer.selectState);
export const selectSearchFriendList = createSelector(
    selectState,
    fromReducer.selectAll
);



export const selectSearchFriendEntities = createSelector(
    selectState,
    fromReducer.selectEntities
);