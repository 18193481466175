import React, { PureComponent, useState } from 'react';
// import { TextInput, View, Keyboard, Text, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import CustomText from '../utils/custom-text';
import { APP_THEME_COLOR } from '../variables';
import { FONT, THEME } from '../enums';
// import Svg, { Path } from "react-native-svg";
import { IconProvider } from './icon-provider';
import '../scss/custom-input.scss';
import { formValidators } from '../services';

interface Props {
    value: any,
    secure?: any,
    placeholder: string,
    keyboardtype?: any,
    type?: any,
    id: string,
    pattern?: any,
    label: string,
    password?: string,
    fieldStyles?: any,
    clearButtonMode?: any,
    editable?: any,
    placeholderTextColor?: string,
    currentPassword?: string,
    onChange: (id: string, value: any) => void
}

export function CustomInput(props: Props) {
    const [error, setError] = useState<string | null>('');
    const { t } = useTranslation();
    const {
        value,
        secure,
        placeholder,
        keyboardtype,
        type,
        id,
        pattern,
        label,
        password,
        fieldStyles,
        clearButtonMode,
        editable,
        placeholderTextColor
    } = props;


    const handleChange = (value: any) => {
        const text = value;
        props.onChange(props.id, text);
    }

    const handleValidation = (text: any) => {
        const { id, pattern, password, currentPassword, } = props;
        setError(formValidators(t, text, id, pattern, password));
    }



    return (
        <div className={"custom-input-container"} style={{ ...fieldStyles }}>
            <div style={{ ...styles.inputIconView, ...(editable === false ? { opacity: 0.4, } : null) }}>
                <div style={styles.inputContainer}>
                    <div style={styles.iconHolder}>
                        <IconProvider type={id} />
                    </div>

                    <input
                        className={"custom-input"}
                        // style={styles.input}
                        placeholder={placeholder}
                        // placeholderTextColor={placeholderTextColor ? placeholderTextColor : "#000000"}
                        // textContentType={type}
                        // secureTextEntry={secure}
                        // keyboardType={keyboardtype}
                        onChange={event => {
                            handleChange(event.target.value);
                            handleValidation(event.target.value);
                        }}
                        value={value}
                        autoCapitalize={"none"}
                        // autoCompleteType={"off"}
                        autoFocus={false}
                        type={type}
                    // caretHidden={false}
                    // clearButtonMode={clearButtonMode ? clearButtonMode : "while-editing"}
                    // contextMenuHidden={false}
                    // editable={editable}
                    //onBlur={Keyboard.dismiss} 
                    />
                </div>

                {error ? <CustomText customStyle={styles.validatorError}>{error}</CustomText> : null}
            </div>
            {label ? <CustomText customStyle={styles.lableHeader}>{label}</CustomText> : null}
        </ div>
    )

}



const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    lableHeader: {
        position: 'absolute',
        fontSize: 10,
        fontFamily: FONT.MANROPE_REGULAR,
        color: THEME.BLACK,
        top: -5,
        left: 15,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: THEME.WHITE
    },
    inputIconView: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 45,
        backgroundColor: THEME.WHITE,
        width: '100%',
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: THEME.INPUT_BORDER
    },
    iconHolder: {
        position: 'relative',
        marginTop: 11,
        marginLeft: 10,
        marginRight: 10,
        width: 20,
        height: 20
    },
    input: {
        color: THEME.BLACK,
        fontFamily: FONT.MANROPE_REGULAR,
    },
    validatorError: {
        color: 'red',
        padding: 5,
        fontSize: 10,
        fontFamily: FONT.MANROPE_REGULAR

    },

});

// export default withTranslation()(FieldForm) ;