import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { THEME } from "../enums";
import { getRemainingTime } from "../services";
import { IconProvider } from "../utils";
import CustomText from "../utils/custom-text";
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
} from 'react-share';
import { InviteLink } from "./invite-link";
import { environment } from "../app.config";
import { MAIN_APP_ROUTES } from "../routes";

interface Props {
    timeStamp: string
}
const link = environment.WEB_APP_DOMAIN +  MAIN_APP_ROUTES.MY_FRIENDS;
export function SurveyTimer(props: Props) {
    const { timeStamp } = props;
    const { t, i18n } = useTranslation();
    const [counter, setCounter] = useState<any>(getRemainingTime(timeStamp));
    const [inviteLink, setInviteLink] = useState<string>(link);
    // console.log(timeStamp);


    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(getRemainingTime(timeStamp));
        }, 1000)
        return () => {
            if (interval) clearInterval(interval);
        }
    }, [timeStamp])

    const onShare = async () => {
        
    };
const sharePost=(e)=>{
    e.preventDefault();
    console.log('post');
}

    function copyToClipboard() {
        // navigator.clipboard.writeText(inviteLink)
        // displayToast(t('copied'));
        //   <key>LSApplicationQueriesSchemes</key>
        //   <array>
        //     <string>whatsapp</string>
        //     <string>mailto</string>
        //   </array>
    }

    return (
        <>
         
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }}> 
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                <CustomText customStyle={{ marginRight: 5, fontWeight: '600' }}>{t('surveyTimeLeft')}</CustomText>
                <div style={{
                    width: 25, height: 25
                }}>
                    <IconProvider type={"stopwatch"} />
                </div>           
                
            </div>
            
            <div className="row" style={{}}>
                
                <CustomText customStyle={{ color: THEME.ORANGE_RED, fontSize: 14 }}>{counter}</CustomText>
                
            </div>
           

            

        </div>

        {/* <div className="row" style={{}}>
                <CustomText>
                    <InviteLink
                        inviteLink={inviteLink}
                        onCopy={copyToClipboard}
                        onShare={onShare}
                    />
                </CustomText>
            </div> */}

            
        </>
    )
}

const styles: { [key: string]: React.CSSProperties } = ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
})
