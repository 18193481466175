import { createAction } from '@reduxjs/toolkit';
import { AppError, ContactInfo, InviteFriendInfo } from '../../interfaces';

export const getInviteLink = createAction('@invite-friend/get');
export const getInviteLinkSuccess = createAction<InviteFriendInfo>('@invite-friend/get/success');
export const getInviteLinkError = createAction<AppError | null>('@invite-friend/get/error');

export const requestInviteLink = createAction('@invite-friend/post');
export const requestInviteLinkSuccess = createAction<InviteFriendInfo>('@invite-friend/post/success');
export const requestInviteLinkError = createAction<AppError | null>('@invite-friend/post/error');

export const acceptInviteLink = createAction<string>('@invite-friend/accept');
export const acceptInviteLinkSuccess = createAction<ContactInfo>('@invite-friend/accept/success');
export const acceptInviteLinkError = createAction<AppError | null>('@invite-friend/accept/error');

export const removeInviteLink = createAction('@invite-friend/remove');
export const clearInviteLinkError = createAction('@invite-friend/clear/error');